import { Box } from '@mui/material';
import { Tooltip, __SWEEP_DESIGN_TYPOGRAPHY_STYLES___ } from '@sweep-io/sweep-design';
import { ReactNode, useRef, useState } from 'react';
import { getObjectTypeColor } from '../helpers/getObjectTypeColor';
import { isEllipsisActive } from '../../lib/isEllipsisActive';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';

export const ObjectTypeChip = ({
  objectType,
  height = '20px',
  label,
  background,
  dataTestId,
  typographyVariant = 'caption',
  noObjectNameTooltip,
}: {
  objectType: string;
  label?: ReactNode;
  height?: string;
  background?: string;
  typographyVariant?: SweepTypographyVariants;
  dataTestId?: string;
  noObjectNameTooltip?: boolean;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const _isEllipsisActive = isEllipsisActive(ref.current);
  const [showTooltip, setShowTooltip] = useState(false);
  const _label = _isEllipsisActive ? `${label} (${objectType})` : objectType;

  return (
    <Box
      data-testid={dataTestId}
      sx={{
        height,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
      }}
      onMouseEnter={() => setShowTooltip(noObjectNameTooltip ? _isEllipsisActive : true)}
      onMouseLeave={() => setShowTooltip(false)}
      className="object-type-chip"
    >
      <Tooltip title={noObjectNameTooltip ? label : _label} open={!!showTooltip}>
        <Box
          ref={ref}
          component="span"
          sx={{
            background: background ?? getObjectTypeColor(objectType)?.step,
            padding: '4px',
            lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.lineHeight,
            fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.fontSize,
            fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.fontWeight,
            borderRadius: '2px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {label}
        </Box>
      </Tooltip>
    </Box>
  );
};
