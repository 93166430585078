import { Box, Typography } from '@mui/material';
import { DASHBOARD_HEADER_HEIGHT_PX } from '../../../constants';
import { colors } from '@sweep-io/sweep-design';

const SIDEBAR_MENU_WIDTH = '51px';

export const PageTemplateFullPage = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        width: `calc(100% - ${SIDEBAR_MENU_WIDTH})`,
        minWidth: '800px',
        height: `calc(100vh - ${DASHBOARD_HEADER_HEIGHT_PX})`,
        position: 'fixed',
        background: 'white',
        zIndex: '100',
        p: '72px 160px',
        top: '56px',
        left: SIDEBAR_MENU_WIDTH,
        overflow: 'scroll',
      }}
    >
      <Typography variant="h1">{title}</Typography>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body" color={colors.grey[800]}>
          Build it now and deploy whenever you're ready
        </Typography>
      </Box>

      <Box sx={{ mt: '48px', minWidth: '800px', overflow: 'scroll' }}>{children}</Box>
    </Box>
  );
};
