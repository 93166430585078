import { VisibilityLayers } from '../../../../types/enums/VisibilityLayers';
import { VisibilityMap } from '../../../../types/VisibilityTypes';
import { VisibilityElements } from '../../configuration-canvas/types';
import { WithConfigurationCanvas } from '../../configuration-canvas/WithConfigurationCanvas';
import { HubspotCanvasPanel } from './HubspotCanvasPanel';

const visibilityElements: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
  [VisibilityLayers.FUNNEL_HUBSPOT_ENROLLMENTS]: true,
};

const visibilityElementsMap: VisibilityElements = {
  type: 'map',
  visibilityMap: visibilityElements,
};

export const CanvasHubspotPage = () => {

  return (
    <WithConfigurationCanvas visibilityElements={visibilityElementsMap}>
      <HubspotCanvasPanel />
    </WithConfigurationCanvas>
  );
};
