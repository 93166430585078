export const innerScrollStyle = (heightMargin: string) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${heightMargin})`,
    overflowY: 'auto',
    marginLeft: -2,
    marginRight: -2,
  };
};
