import { Ref, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useFunnelsApiFacade } from '../../../../apis/facades/useFunnelsApiFacade';
import { addFunnel, selectFunnelIds } from '../../../../reducers/multiFunnelFlowCanvasReducer';
import {
  addTransientFunnel,
  selectImportFunnelTransient,
  selectNewFunnelTransientData,
  updateStageMetadataForLeadingField,
} from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { openNewFunnelDialog } from '../../../../reducers/newFunnelReducer';
import { usePanels } from '../../../panels/PanelsContext';
import { importFunnelPanelType } from '../dialogs/import-funnel/importFunnelPanelType';
import { ACTIONS_EVENTS } from '../../../../services/events';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { usePageHelpers } from '../../canvas-pages/united-canvas/usePageHelpers';

type Environments = 'SALESFORCE' | 'HUBSPOT';

interface CreateOrAddFunnelMenuItem {
  value: string;
  label: string;
  smallLabel: string;
}

interface AddFunnelProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => any;
  stepItems: CreateOrAddFunnelMenuItem[];
  onItemClick: (item: CreateOrAddFunnelMenuItem) => any;
  onAddNewClick: () => any;
  loadingItems?: boolean;
}

export const useAddFunnel = ({
  crmOrgId,
  addFunnelRef,
}: {
  crmOrgId: string;
  addFunnelRef: Ref<any>;
}) => {
  const dispatch = useDispatch();
  const sendBiEvent = useSendBiEvent();
  const { maybeOpenPanel } = usePanels();

  const { shouldDisplayAddNewFunnel } = usePageHelpers();
  const { get_funnel, get_funnels, post_funnel } = useFunnelsApiFacade();
  const usedFunnelIds = useSelector(selectFunnelIds);
  const transientFunnelData = useSelector(selectNewFunnelTransientData);
  const transientFunnel = useSelector(selectImportFunnelTransient);

  const [buttonAnchorEl, setButtonAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [funnelsItems, setFunnelItems] = useState<CreateOrAddFunnelMenuItem[]>();

  const handleButtonClick = useCallback(
    async (event: any) => {
      setButtonAnchorEl(event.currentTarget ?? event.current);

      // TODO: Change this to get the short version
      const funnels = await get_funnels();

      const _funnelItems: CreateOrAddFunnelMenuItem[] = funnels
        .filter(
          (funnel) => !usedFunnelIds.includes(funnel.id) && funnel.funnelDetails?.leadingObject,
        ) // Filters funnels already used
        .map((funnel) => {
          const objectName = funnel.funnelDetails.leadingObject.objectName;

          return {
            value: funnel.id,
            label: funnel.name,
            smallLabel: objectName,
          };
        });
      setFunnelItems(_funnelItems);
    },
    [setFunnelItems, usedFunnelIds, get_funnels],
  );

  useEffect(() => {
    if (
      !!addFunnelRef &&
      !usedFunnelIds.length &&
      !transientFunnelData &&
      !transientFunnel &&
      shouldDisplayAddNewFunnel
    ) {
      handleButtonClick(addFunnelRef);
    }
  }, [
    addFunnelRef,
    usedFunnelIds,
    transientFunnelData,
    handleButtonClick,
    shouldDisplayAddNewFunnel,
    transientFunnel,
  ]);

  const handleCloseMenu = () => {
    setButtonAnchorEl(null);
  };

  const onCreateFunnel = useCallback(
    async ({
      funnelName,
      funnelDetails,
      position,
    }: {
      funnelName: string;
      funnelDetails: FunnelDetails;
      position: { column: number; row: number };
    }) => {
      const funnel = await post_funnel(funnelName, crmOrgId, funnelDetails);
      sendBiEvent({
        name: ACTIONS_EVENTS.funnelsAddFunnel,
        props: { object: funnel.funnelDetails.leadingObject.objectName },
      });
      dispatch(
        addFunnel({
          funnel,
          position,
        }),
      );

      dispatch(
        updateStageMetadataForLeadingField({
          leadingFieldStageMetadata: funnel?.stageMetadata,
        }),
      );

      return funnel;
    },
    [crmOrgId, dispatch, post_funnel, sendBiEvent],
  );

  const onItemClick = useCallback(
    async (item: CreateOrAddFunnelMenuItem) => {
      handleCloseMenu();
      const funnel = await get_funnel(item.value);

      if (!usedFunnelIds.length) {
        //by product definition first funnel should be dropped automatically on canvas
        dispatch(
          addFunnel({
            funnel,
            position: {
              column: 0,
              row: 0,
            },
          }),
        );
        return;
      }

      dispatch(
        addTransientFunnel({
          transientFunnel: funnel,
        }),
      );
    },
    [dispatch, get_funnel, usedFunnelIds],
  );

  const onAddNewClick = () => {
    handleCloseMenu();
    dispatch(openNewFunnelDialog({ crmOrgId }));
  };

  const onEnvironmentClick = (environment: Environments) => {
    switch (environment) {
      case 'SALESFORCE':
        maybeOpenPanel({ panelType: importFunnelPanelType });
        break;
      default:
        break;
    }
    handleCloseMenu();
  };

  //TODO these props and pass directly
  const addFunnelProps: AddFunnelProps = {
    anchorEl: buttonAnchorEl || null,
    handleClose: handleCloseMenu,
    onItemClick,
    stepItems: funnelsItems || [],
    loadingItems: Boolean(funnelsItems),
    onAddNewClick,
  };

  return {
    handleButtonClick,
    addFunnelProps,
    onCreateFunnel,
    setButtonAnchorEl,
    onEnvironmentClick,
  };
};
