import { FormControl, MenuItem, SelectChangeEvent, SxProps, Select, Box } from '@mui/material';
import { useState } from 'react';
import SelectNoBorder from '../../SelectNoBorder';
import { useSelector } from 'react-redux';
import { selectTerritoriesList } from '../../../../reducers/territoriesReducer';
import { useTerritoriesActions } from '../../../../hooks/useTerritoriesActions';
import { useRunOnce } from '../../useRunOnce';
import { Button, Typography } from '@sweep-io/sweep-design';
import useSweepNavigate from '../../useSweepNavigate';
import { ROUTING_TERRITORIES } from '../../../../constants/appRoutes';
import { Warning } from '@sweep-io/sweep-design/dist/icons';

export interface TerritoryListSelectorProps {
  initialValue?: string;
  onChange: Function;
  shouldBeDisabled?: boolean;
  sx?: SxProps;
  placeholder?: string;
  removeBorders?: boolean;
}

const TerritoryListSelector = ({
  initialValue,
  onChange,
  shouldBeDisabled,
  sx,
  placeholder,
  removeBorders,
}: TerritoryListSelectorProps) => {
  const territories = useSelector(selectTerritoriesList);
  const { getTerritories } = useTerritoriesActions();
  const { navigate } = useSweepNavigate();

  useRunOnce(async () => {
    await getTerritories();
  });

  const [newValue, setValue] = useState(initialValue);
  const SelectComponent = removeBorders ? SelectNoBorder : Select;

  return (
    <FormControl fullWidth>
      <SelectComponent
        placeholder={placeholder}
        value={newValue ?? ''}
        onChange={(event: SelectChangeEvent<unknown>) => {
          const _newValue = event.target.value as string;
          const newValue = territories?.find((e) => e.id === _newValue);
          setValue(newValue?.id);
          onChange(newValue?.id);
        }}
        disabled={shouldBeDisabled}
        sx={sx}
        displayEmpty
        renderValue={(selected) => {
          const current = territories?.find((item) => item.id === selected);
          return current?.name ?? '';
        }}
      >
        {!territories?.length && (
          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center', padding: '8px' }}>
            <Warning variant="default" />
            <Typography variant="body" textAlign="center">
              Create your first Territory
            </Typography>
            <Button
              variant="flat"
              disabled={shouldBeDisabled}
              onClick={() => navigate(ROUTING_TERRITORIES)}
            >
              here
            </Button>
          </Box>
        )}
        {territories?.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </SelectComponent>
    </FormControl>
  );
};

export { TerritoryListSelector };
