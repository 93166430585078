export enum SweepError {
  ManagedPackageRequired,
  ProductionEnvMissing,
  CrmOrgNotFound,
  CrmOrgBadCredentials,
  CrmOrgLockTimeout, // Deprecated
  UserNotConnected,
  SfdcUserBadCredentials,
  SfdcUserNoCredentialsYet,
  NoFieldPermission,
  HubspotNoCredentials,
  CrmOrgInvalidGrant,
}

export type EnvConnectionError = {
  crmOrgId: string;
  error: SweepError.CrmOrgBadCredentials | SweepError.CrmOrgNotFound;
};
