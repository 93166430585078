import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useSweepApi } from '../apis/sweep';
import { useStageMetadataFacade } from '../apis/facades/useStageMetadataFacade';
import {
  setFunnelStageMetadata,
  updateStageMetadataForLeadingField,
} from '../reducers/multiFunnelFlowNoHistoryReducer';
import { FunnelMapModel } from '../models/funnelMapModel';
import { selectFunnelMap } from '../reducers/multiFunnelFlowCanvasReducer';
import {
  selectOpportunityTypesList,
  setOpportunityLists,
} from '../reducers/funnel-flow-page/stageDialogReducer';
import flatten from 'lodash/flatten';
import { telemetry } from '../telemetry';

export enum StageMetadataConflictResolutionStrategy {
  ABORT = 'abort', // check if there's a conflict and save if there's no conflict
  SKIP = 'skip', // dont update with incoming data
  OVERRIDE = 'override',
}

const useStageMetadata = () => {
  const dispatch = useDispatch();
  const sweepApi = useSweepApi();
  const funnelMap = useSelector(selectFunnelMap);
  const options = useSelector(selectOpportunityTypesList);

  const { get_allStagesStageMetadata, get_singleStageMetadata, get_opportunityList } =
    useStageMetadataFacade();

  const getStagesMetadataByLeadingField = useCallback(
    async ({ crmOrgId, _leadingFieldIds }: { crmOrgId: string; _leadingFieldIds: string[] }) =>
      await get_allStagesStageMetadata(_leadingFieldIds, crmOrgId),
    [get_allStagesStageMetadata],
  );

  const getAllStagesMetadata = useCallback(
    async (crmOrgId: string) => {
      let leadingFieldIds: string[] = [];

      if (funnelMap) {
        leadingFieldIds = new FunnelMapModel(funnelMap).getFunnelMapLeadingFieldIds();
      }

      if (!leadingFieldIds || !leadingFieldIds.length) {
        return;
      }

      const stageMetadata = await getStagesMetadataByLeadingField({
        crmOrgId,
        _leadingFieldIds: leadingFieldIds,
      });

      dispatch(setFunnelStageMetadata({ stageMetadata }));
    },
    [getStagesMetadataByLeadingField, dispatch, funnelMap],
  );

  const getSharedMetadataByStageName = async ({
    newStageName,
    crmOrgId,
    leadingFieldId,
  }: {
    newStageName: string;
    crmOrgId: string;
    leadingFieldId: string;
  }): Promise<{
    stageMetadata?: StageMetadata;
    usedInFunnels?: { id: string; name: string }[];
  }> => {
    const stageData = await get_singleStageMetadata({
      stageName: newStageName,
      leadingFieldId,
      crmOrgId,
    });

    return { stageMetadata: stageData.stageMetadata, usedInFunnels: stageData.funnels };
  };

  const patchStageMetadata = useCallback(
    async ({
      funnelId,
      stageMetadata,
      stageMetadataOnConflict,
    }: {
      funnelId: string;
      stageMetadata?: StageMetadata;
      stageMetadataOnConflict: StageMetadataConflictResolutionStrategy;
    }) => {
      const response = await sweepApi.patch(`/funnels/${funnelId}/stage-metadata`, {
        stageMetadata: [stageMetadata],
        stageMetadataOnConflict,
      });

      dispatch(
        updateStageMetadataForLeadingField({
          leadingFieldStageMetadata: response?.data?.stageMetadata,
        }),
      );

      return response.data;
    },
    [dispatch, sweepApi],
  );

  const patchStageMetadataWithAbort = useCallback(
    async ({ funnelId, stageMetadata }: { funnelId: string; stageMetadata?: StageMetadata }) => {
      return await patchStageMetadata({
        funnelId,
        stageMetadata,
        stageMetadataOnConflict: StageMetadataConflictResolutionStrategy.ABORT,
      });
    },
    [patchStageMetadata],
  );

  const patchStageMetadataWithOverride = useCallback(
    async ({ funnelId, stageMetadata }: { funnelId: string; stageMetadata?: StageMetadata }) => {
      try {
        const res = await patchStageMetadata({
          funnelId,
          stageMetadata,
          stageMetadataOnConflict: StageMetadataConflictResolutionStrategy.OVERRIDE,
        });
        return res;
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [patchStageMetadata],
  );

  const getOpportunityStageMetadataOptions = async (crmOrgId: string) => {
    const types = await get_opportunityList(crmOrgId);
    dispatch(setOpportunityLists({ types }));
  };

  const mapForecastCategoryValueToLabel = useCallback(
    (value: string) => {
      const forecastCategories = flatten(options?.map((item) => item.forecastCategories));
      return forecastCategories.find((fc) => fc.value === value)?.label ?? value;
    },
    [options],
  );

  return {
    getSharedMetadataByStageName,
    getStagesMetadataByLeadingField,
    getAllStagesMetadata,
    patchStageMetadataWithAbort,
    patchStageMetadataWithOverride,
    patchStageMetadata,
    getOpportunityStageMetadataOptions,
    mapForecastCategoryValueToLabel,
  };
};

export { useStageMetadata };
