import { TabList } from '@sweep-io/sweep-design';
import { useCallback, useContext, useEffect, useState } from 'react';
import { HubspotCampaignForm } from './HubspotCampaignForm';
import { CampaignBase, Campaign } from './campaignTypes';
import { selectCampaignById } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { EnrollmentRulesTab } from './EnrollmentRulesTab';
import { useCampaigns } from './useCampaigns';
import { isEqual } from 'lodash';
import { HubspotCampaignCtx } from './HubspotCampaignCtx';
import { telemetry } from '../../../../../telemetry';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';

interface HubspotCampaignContentProps {
  initialBaseCampaignId?: string;
}

const emptyCampaign: CampaignBase = {
  Name: '',
};

export const HubspotCampaignContent = ({ initialBaseCampaignId }: HubspotCampaignContentProps) => {
  const [baseCampaignId, setBaseCampaignId] = useState(initialBaseCampaignId);
  const { addNotification } = useSweepNotifications();

  const baseCampaign = useSelector(selectCampaignById(baseCampaignId || ''));

  const { createCampaign, updateCampaign } = useCampaigns();
  const [tab, setTab] = useState<'settings' | 'rules'>('settings');

  const [editingCampaign, setEditingCampaign] = useState<
    Campaign | (CampaignBase & { Id?: string })
  >(baseCampaign || emptyCampaign);

  useEffect(() => {
    // If base campaign changes, reset editing campaign
    // This happens when a new campaign is created
    setEditingCampaign(baseCampaign ?? emptyCampaign);
  }, [baseCampaign]);

  const [isSaving, setIsSaving] = useState(false);

  const { setIsPristine } = useContext(HubspotCampaignCtx);
  const isPristine = isEqual(editingCampaign, baseCampaign);

  useEffect(() => {
    setIsPristine(isPristine);
  }, [isPristine, setIsPristine]);

  const handleCampaignChange = useCallback((partialCampaign: Partial<CampaignBase>) => {
    setEditingCampaign((workflow) => ({ ...workflow, ...partialCampaign }));
  }, []);

  const handleCampaignDeployCreate = useCallback(async () => {
    setIsSaving(true);
    let savedCampaign: Campaign;
    try {
      if (editingCampaign.Id) {
        await updateCampaign(editingCampaign as Campaign);
        savedCampaign = editingCampaign as Campaign;
      } else {
        savedCampaign = await createCampaign(editingCampaign);
      }
      setBaseCampaignId(savedCampaign.Id);
      setTab('rules');
    } catch (error: any) {
      telemetry.captureError('Error saving campaign', error);
      addNotification({
        message: `Fail to save campaign`,
        keepOpen: true,
        details: error.response.data.message,
        variant: SweepNotificationVariant.Error,
      });
    }

    setIsSaving(false);
  }, [editingCampaign, updateCampaign, createCampaign, addNotification]);

  return (
    <TabList
      list={[
        {
          content: (
            <HubspotCampaignForm
              campaign={editingCampaign}
              onChange={handleCampaignChange}
              onDeploy={handleCampaignDeployCreate}
              isSaving={isSaving}
            />
          ),
          label: 'Campaign Settings',
          value: 'settings',
        },
        {
          content: (
            <EnrollmentRulesTab
              campaignId={baseCampaign?.Id}
              campaignName={baseCampaign?.Name || ''}
            />
          ),
          label: 'Enrollment Rules',
          value: 'rules',
        },
      ]}
      value={tab}
      onChange={(e, tab) => {
        setTab(tab);
      }}
    />
  );
};
