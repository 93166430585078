import Box from '@mui/material/Box';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { ObjectWithPills } from '../types';
import { useCallback, useMemo } from 'react';
import { ObjectTypeChip } from '../../common/ObjectTypeChip';
import { useDocumentation } from '../useDocumentation';
import { AddObjectButton } from './AddObjectButton';
import { DataTable } from '../../common/table/DataTable';
import { InitialsAvatar } from '../InitialsAvatar';

const columns = [
  { field: '_label', headerName: 'Label', width: '15%' },
  { field: '_name', headerName: 'API Name', width: '15%' },
  { field: DocumentationTabTypes.FIELDS, headerName: 'Fields' },
  { field: DocumentationTabTypes.RECORD_TYPES, headerName: 'Record Types' },
  { field: DocumentationTabTypes.SF_AUTOMATIONS, headerName: 'SF Automations' },
  { field: DocumentationTabTypes.VALIDATION_RULES, headerName: 'Validation Rules' },
  { field: DocumentationTabTypes.APEX, headerName: 'Apex Triggers' },
  { field: DocumentationTabTypes.LAYOUTS, headerName: 'Layouts' },
  { field: 'lastModifiedBy', headerName: 'Last modified by' },
];

const renderRows = (
  items: ObjectWithPills[],
  objectsBeingParsed: {
    [objectName: string]: boolean;
  },
) => {
  return items.map((item) => {
    const pills = item.pills ?? ({} as { [key: string]: number });
    const isLoading = objectsBeingParsed?.[item.objectType];

    if (isLoading) {
      return {
        _label: (
          <ObjectTypeChip
            dataTestId="object-card"
            height="24px"
            typographyVariant="body"
            label={item.label}
            objectType={item.objectType}
            noObjectNameTooltip
          />
        ),
        label: item.label, //required in onClick
        id: item.objectType,
        _name: (
          <Box ml={-1}>
            <AddObjectButton isLoading object={item} />
          </Box>
        ),
      };
    }

    return {
      _label: (
        <ObjectTypeChip
          dataTestId="object-card"
          height="24px"
          typographyVariant="body"
          label={item.label}
          objectType={item.objectType}
          noObjectNameTooltip
        />
      ),
      label: item.label,
      id: item.objectType,
      _name: item.objectType,
      isClickable: true,
      lastModifiedBy: item.annotations?.changedBy ? (
        <InitialsAvatar
          userIdOrName={item.annotations?.changedBy}
          changedAt={item.annotations?.changedAt}
          showUserFullName
        />
      ) : (
        '-'
      ),
      ...pills,
    };
  });
};

export const ObjectCardTable = ({
  items,
  onClick,
}: {
  items: ObjectWithPills[];
  onClick: (event: any, tab?: DocumentationTabTypes) => void;
}) => {
  const { objectsBeingParsed } = useDocumentation();
  const rows = useMemo(() => renderRows(items, objectsBeingParsed), [items, objectsBeingParsed]);

  const _onClick = useCallback(
    (event: any, row: any) => {
      event.stopPropagation();
      onClick({ label: row.label, objectType: row.id });
    },
    [onClick],
  );

  return (
    <Box pl={2} pr={2}>
      <DataTable onRowClick={_onClick} columns={columns} rows={rows} />
    </Box>
  );
};
