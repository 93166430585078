import { Box, Skeleton, Stack } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { Users as UsersIcon, Calendar as CalendarIcon } from '@sweep-io/sweep-design/dist/icons';
import { AvatarPicture } from '../../../avatar/AvatarPicture';
import { AvatarType } from '../../../avatar/avatarTypes';
import { ActionsMenu } from '../../../common/actions-menu/ActionsMenu';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';
import usePermission from '../../../common/permissions/usePermission';

enum AssignmentActionsEnum {
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
}

export const ASSIGNMENTS_CARD_HEIGHT = '124px';

const AssignmentDetailsRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box display="flex">
      <Box
        width="auto"
        display="flex"
        gap="4px"
        alignItems="center"
        sx={{ color: colors.grey[800] }}
      >
        {children}
      </Box>
    </Box>
  );
};

interface GroupListItemProps {
  name: string;
  avatar?: AvatarType;
  description: string;
  activeMembers: number;
  lastModified: string;
  onClick: () => any;
  onDuplicate: () => any;
  onDelete: () => any;
  readonly?: boolean;
}

export const GroupListItem = ({
  name,
  description,
  activeMembers,
  lastModified,
  avatar,
  onClick,
  onDuplicate,
  onDelete,
  readonly,
}: GroupListItemProps) => {
  const [isDeleteAllowed] = usePermission(['delete:assignment-groups']);
  return (
    <Box
      height="64px"
      display="flex"
      flexDirection="column"
      sx={{
        borderBottom: `1px solid ${colors.grey[200]}`,
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
          background: colors.grey[100],
        },
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <Box sx={{ width: 24, height: 24 }}>
            <AvatarPicture dimension={24} avatar={avatar} isInactive={false} />
          </Box>

          <Stack justifyContent="center" gap="2px">
            <TruncatedTextTooltip variant="body-medium">{name}</TruncatedTextTooltip>

            <Typography variant="caption" color={colors.grey[800]}>
              {description}
            </Typography>
          </Stack>
        </Box>
        <Box display="flex" gap={1}>
          <Box display="flex" flexDirection="row" gap="8px" sx={{ color: colors.night[900] }}>
            <AssignmentDetailsRow>
              <UsersIcon />
              <Typography variant="caption">{activeMembers} Active members</Typography>
            </AssignmentDetailsRow>
            <AssignmentDetailsRow>
              <CalendarIcon />
              <Typography variant="body">Modified on {lastModified}</Typography>
            </AssignmentDetailsRow>
          </Box>
          <ActionsMenu
            actions={[
              { label: 'Duplicate', value: AssignmentActionsEnum.DUPLICATE, disabled: readonly },
              {
                label: 'Delete',
                value: AssignmentActionsEnum.DELETE,
                disabled: readonly || !isDeleteAllowed,
              },
            ]}
            onClick={(action) => {
              switch (action.value) {
                case AssignmentActionsEnum.DUPLICATE:
                  onDuplicate();
                  break;
                case AssignmentActionsEnum.DELETE:
                  onDelete();
                  break;
              }
            }}
            iconTooltip={
              readonly
                ? 'To make changes to this assignment group, please contact your admin.'
                : undefined
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export const GroupListItemSkeleton = () => {
  return (
    <Box
      height="64px"
      display="flex"
      flexDirection="column"
      sx={{ borderBottom: `1px solid ${colors.grey[200]}`, justifyContent: 'center' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex" gap={1}>
          <Box sx={{ width: 24, height: 24 }}>
            <Skeleton variant="circular" width={24} height={24} />
          </Box>

          <Stack justifyContent="center" gap="2px">
            <Skeleton variant="rectangular" width={109} height={20} />
            <Skeleton variant="rectangular" width={567} height={16} />
          </Stack>
        </Box>
        <Box display="flex" gap={1}>
          <Box display="flex" flexDirection="row" gap="8px" sx={{ color: colors.night[900] }}>
            <AssignmentDetailsRow>
              <Skeleton variant="circular" width={16} height={16} />
              <Skeleton variant="rectangular" width={109} height={16} />
            </AssignmentDetailsRow>
            <AssignmentDetailsRow>
              <Skeleton variant="circular" width={16} height={16} />
              <Skeleton variant="rectangular" width={109} height={16} />
            </AssignmentDetailsRow>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
