import { ReactNode } from 'react';
import {
  Apex,
  ApexTrigger,
  Assign,
  At,
  Automations,
  Clock,
  Copy,
  CPQ,
  Cube,
  Fields,
  Flows,
  Layout,
  PlaybookAlert,
  RecordType,
  Reporting,
  Shuffle,
  Stamp,
  Target,
  ValidationRules,
  Wrench,
} from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';
import { ObjectWithPills } from '../types';
import { LayoutData } from '../ParserTypes';

import { ConfigurationType } from '@server/parser';
export { ConfigurationType };

export enum DependenciesContentViewType {
  default = 'default',
  comments = 'comments',
  sourceCode = 'sourceCode',
  editTag = 'editTag',
}

export const SWEEP_AUTOMATION_TEMPLATE_TYPES = [
  ConfigurationType.sweepAutomation,
  ConfigurationType.sweepAlert,
  ConfigurationType.sweepAssignment,
  ConfigurationType.sweepScheduledAssignment,
];

export const SWEEP_DEDUP_MATCHING_TEMPLATE_TYPES = [
  ConfigurationType.sweepDedupe,
  ConfigurationType.sweepMatching,
];

export const ConfigurationToName: { [key in ConfigurationType]: string } = {
  [ConfigurationType.validationRules]: 'Validation Rule',
  [ConfigurationType.workflowRules]: 'Workflow Rule',
  [ConfigurationType.flows]: 'Flow',
  [ConfigurationType.processBuilderFlows]: 'Process Builder',
  [ConfigurationType.approvalProcesses]: 'Approval Process',
  [ConfigurationType.apexTriggers]: 'Apex Trigger',
  [ConfigurationType.apexClasses]: 'Apex Class',
  [ConfigurationType.reports]: 'Report',
  [ConfigurationType.layouts]: 'Layout',
  [ConfigurationType.fields]: 'Field',
  [ConfigurationType.objects]: 'Object',
  [ConfigurationType.emailTemplates]: 'Email Template',
  [ConfigurationType.sweepAutomation]: 'Sweep Automation',
  [ConfigurationType.sweepAlert]: 'Sweep Alert',
  [ConfigurationType.sweepDedupe]: 'Sweep Deduplication',
  [ConfigurationType.sweepMatching]: 'Sweep Matching',
  [ConfigurationType.sweepAssignment]: 'Sweep Assignment',
  [ConfigurationType.sweepScheduledAssignment]: 'Sweep Scheduled Assignment',
  [ConfigurationType.rollups]: 'Rollup',
  [ConfigurationType.cpqData]: 'CPQ Configuration',
  [ConfigurationType.recordTypes]: 'Record Type',
  [ConfigurationType.paths]: 'Paths',
};

const iconColor = colors.grey[700];

export const configurationTypeToIcon: { [key in ConfigurationType]: ReactNode } = {
  [ConfigurationType.fields]: <Fields color={iconColor} />,
  [ConfigurationType.apexTriggers]: <ApexTrigger color={iconColor} />,
  [ConfigurationType.apexClasses]: <Apex color={iconColor} />,
  [ConfigurationType.validationRules]: <ValidationRules color={iconColor} />,
  [ConfigurationType.workflowRules]: <Shuffle color={iconColor} />,
  [ConfigurationType.flows]: <Flows color={iconColor} />,
  [ConfigurationType.processBuilderFlows]: <Wrench color={iconColor} />,
  [ConfigurationType.approvalProcesses]: <Stamp color={iconColor} />,
  [ConfigurationType.reports]: <Reporting color={iconColor} />,
  [ConfigurationType.layouts]: <Layout color={iconColor} />,
  [ConfigurationType.objects]: <Cube color={iconColor} />,
  [ConfigurationType.emailTemplates]: <At color={iconColor} />,
  [ConfigurationType.sweepAutomation]: <Automations color={iconColor} />,
  [ConfigurationType.sweepAlert]: <PlaybookAlert color={iconColor} />,
  [ConfigurationType.sweepDedupe]: <Copy color={iconColor} />,
  [ConfigurationType.sweepMatching]: <Target color={iconColor} />,
  [ConfigurationType.sweepAssignment]: <Assign color={iconColor} />,
  [ConfigurationType.sweepScheduledAssignment]: <Clock color={iconColor} />,
  [ConfigurationType.rollups]: <Fields color={iconColor} />,
  [ConfigurationType.cpqData]: <CPQ color={iconColor} />,
  [ConfigurationType.recordTypes]: <RecordType color={iconColor} />,
  [ConfigurationType.paths]: <></>,
};

export const CONFIGURATION_ITEMS_FOR_DEPENDS_ON = [
  ConfigurationType.validationRules,
  ConfigurationType.flows,
  ConfigurationType.processBuilderFlows,
  ConfigurationType.apexTriggers,
  ConfigurationType.apexClasses,
  ConfigurationType.approvalProcesses,
  ConfigurationType.workflowRules,
  ConfigurationType.sweepAutomation,
  ConfigurationType.sweepAlert,
  ConfigurationType.sweepDedupe,
  ConfigurationType.sweepMatching,
  ConfigurationType.rollups,
  ConfigurationType.sweepAssignment,
  ConfigurationType.sweepScheduledAssignment,
  ConfigurationType.cpqData,
  ConfigurationType.layouts,
];

export const CONFIGURATIONS_FOR_USED_BY = [
  ConfigurationType.flows,
  ConfigurationType.processBuilderFlows,
  ConfigurationType.apexClasses,
  ConfigurationType.fields,
  ConfigurationType.rollups,
  ConfigurationType.sweepAssignment,
  ConfigurationType.sweepScheduledAssignment,
  ConfigurationType.cpqData,
];

export type NewDependencyProps = SelectedDependency & {
  clearHistory?: boolean;
};

export type ConfigurationTypesWithIcon =
  | ConfigurationType.sweepAlert
  | ConfigurationType.sweepAutomation
  | ConfigurationType.sweepAssignment
  | ConfigurationType.sweepScheduledAssignment;

export enum UnclassifiedTypes {
  tag = 'tag',
}

export interface SelectedDependency {
  id: string;
  name: string;
  dependencyType?: string;
  parentType: ConfigurationType | UnclassifiedTypes;
  objectName?: string;
  contentType: DependenciesContentViewType;
}

export interface TabFiltersAndSort {
  selectedStageValue?: string;
  selectedTypeValue?: string;
  selectedUsageValue?: string;
  selectedRecordTypeValue?: string;
  activeOnly?: boolean;
  sortKey?: string;
}

export interface ObjectWithPillsMap {
  favorites: ObjectWithPills[];
  standardObjects: ObjectWithPills[];
  cpqObjects: ObjectWithPills[];
  customObjects: ObjectWithPills[];
}

export interface LayoutsByObjectName {
  [objectName: string]: { isLoading: boolean; layouts: LayoutData[] };
}
