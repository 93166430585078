import { Paper } from '@mui/material';
import { Button, Typography, colors, IconButton } from '@sweep-io/sweep-design';
import {
  Close,
  Info,
  CheckCircle,
  Warning,
  Prohibit,
  Notification,
} from '@sweep-io/sweep-design/dist/icons';
import { VerticalCenteredBox } from '../pages/environments/styledComponents';
import { SweepNotificationVariant } from '../../reducers/notificationsReducer';
import { forwardRef } from 'react';

interface NotificationAlertProps {
  message: React.ReactNode;
  onClose: () => any;
  severity?: SweepNotificationVariant;
  action?: {
    actionText: string;
    onActionClick: () => any;
  };
  details?: string;
  isVariant: boolean;
}

const colorsMap = ({ variant }: { variant: SweepNotificationVariant }) => {
  const possibilities = {
    [SweepNotificationVariant.Success]: {
      background: colors.mint[100],
    },
    [SweepNotificationVariant.Error]: {
      background: colors.blush[300],
    },
    [SweepNotificationVariant.Default]: {
      background: colors.grey[200],
    },
    [SweepNotificationVariant.Info]: {
      background: colors.mint[100],
    },
    [SweepNotificationVariant.Warning]: {
      background: colors.sun[300],
    },
  };

  return possibilities[variant];
};

const getSeverityIcon = (severity: SweepNotificationVariant, isVariant: boolean) => {
  switch (severity) {
    case SweepNotificationVariant.Success:
      return CheckCircle;
    case SweepNotificationVariant.Error:
      return isVariant ? Notification : Prohibit;
    case SweepNotificationVariant.Warning:
      return Warning;
    case SweepNotificationVariant.Default:
    case SweepNotificationVariant.Info:
    default:
      return Info;
  }
};

export const NotificationAlert = forwardRef<any, NotificationAlertProps>(
  (
    {
      message,
      onClose,
      severity = SweepNotificationVariant.Success,
      action,
      details,
      isVariant,
    }: NotificationAlertProps,
    ref,
  ) => {
    const SeverityIcon = getSeverityIcon(severity, isVariant);
    const mappedColors = colorsMap({ variant: severity });
    const textColor = colors.black;

    //"variant" doesn't have title and subtitle, so need to combine them.
    const shouldCombine = isVariant && typeof message === 'string' && details;
    const _message = shouldCombine ? `${message}. ${details}` : message;
    const _details = shouldCombine ? undefined : details;

    return (
      <Paper
        ref={ref}
        elevation={4}
        sx={{
          width: isVariant ? '684px' : '480px',
          maxWidth: '100%',
          borderRadius: '4px',
          padding: 1.5,
          paddingLeft: 2,
          background: mappedColors.background,
        }}
      >
        <VerticalCenteredBox
          sx={{
            padding: '0',
            justifyContent: 'space-between',
          }}
        >
          <VerticalCenteredBox sx={{ gap: 1 }} flex={1}>
            <VerticalCenteredBox flex="0 0 24px" justifyContent="center">
              <SeverityIcon color={textColor} />
            </VerticalCenteredBox>

            <Typography color={textColor} variant={isVariant ? 'caption' : 'caption-bold'}>
              {_message}
            </Typography>
          </VerticalCenteredBox>
          <VerticalCenteredBox sx={{ gap: 1 }}>
            {action && (
              <Button variant="flat" size="small" onClick={action.onActionClick}>
                <Typography textDecoration="underline" color={textColor} variant="caption-bold">
                  {action.actionText}
                </Typography>
              </Button>
            )}

            <IconButton size="small" variant="flat" onClick={onClose}>
              <Close />
            </IconButton>
          </VerticalCenteredBox>
        </VerticalCenteredBox>
        {_details && (
          <VerticalCenteredBox ml={3}>
            <Typography ellipsis whiteSpace="normal" color={textColor} variant="caption">
              {_details}
            </Typography>
          </VerticalCenteredBox>
        )}
      </Paper>
    );
  },
);
