import { useEffect, useMemo, useState } from 'react';
import useObjectTypes from './useObjectTypes';
import { useSelector } from 'react-redux';
import {
  selectIsLoadingObjectTypes,
  selectObjectTypesIncludesRecordTypes,
} from '../components/pages/environments/environmentsReducer';
import keyBy from 'lodash/keyBy';

interface UseObjectTypesWithFetchProps {
  crmOrgId?: string;
  useSfObjects?: boolean;
  includesRecordTypes?: boolean;
}

export interface ObjectTypePropertyFilterType {
  triggerable?: boolean;
  updateable?: boolean;
  createable?: boolean;
}

export const objectTypesPropertyFilter = ({
  objectTypes,
  filters,
}: {
  objectTypes?: ObjectTypeName[];
  filters: ObjectTypePropertyFilterType;
}) => {
  if (!objectTypes) return undefined;
  const { triggerable, updateable, createable } = filters;

  return objectTypes.filter((objectType) => {
    return (
      (triggerable === undefined || objectType.triggerable === triggerable) &&
      (updateable === undefined || objectType.updateable === updateable) &&
      (createable === undefined || objectType.createable === createable)
    );
  });
};

//useSfObjects is necessary cause sf objects by product request should appear only in documentation regardless of FT
const useObjectTypesWithFetch = ({
  crmOrgId = '',
  includesRecordTypes,
}: UseObjectTypesWithFetchProps) => {
  const { objectTypes, fetchCrmOrgObjectTypes } = useObjectTypes({ crmOrgId });
  const [errorFetching, setErrorFetching] = useState(false);

  const isLoadingCrmOrgObjectTypes = useSelector(selectIsLoadingObjectTypes(crmOrgId));
  const currentObjectTypesIncludesRecordTypes = useSelector(
    selectObjectTypesIncludesRecordTypes(crmOrgId),
  );

  useEffect(() => {
    if (!crmOrgId || errorFetching) return;

    /*  
        Verifies that objectTypes are not already fetched and that the fetch is not in progress
        If includesRecordTypes is true, it also checks that the current objectTypes do not include record types
        If the conditions are met, it fetches the objectTypes
    */
    const shouldFetch = !objectTypes && !isLoadingCrmOrgObjectTypes;
    const shouldFetchToIncludeRecordTypes =
      includesRecordTypes &&
      !currentObjectTypesIncludesRecordTypes &&
      isLoadingCrmOrgObjectTypes !== 'withRecordTypes';

    if (shouldFetch || shouldFetchToIncludeRecordTypes) {
      fetchCrmOrgObjectTypes({
        crmOrgId,
        includesRecordTypes,
      }).then(({ error }) => {
        setErrorFetching(Boolean(error));
      });
    }
  }, [
    fetchCrmOrgObjectTypes,
    objectTypes,
    isLoadingCrmOrgObjectTypes,
    crmOrgId,
    currentObjectTypesIncludesRecordTypes,
    includesRecordTypes,
    errorFetching,
  ]);

  const objectTypesByName = useMemo(() => keyBy(objectTypes, 'objectType'), [objectTypes]);

  return {
    objectTypes,
    isLoading: Boolean(isLoadingCrmOrgObjectTypes),
    objectTypesByName,
  };
};

export default useObjectTypesWithFetch;
