import 'reactflow/dist/base.css';
import debounce_ from 'lodash/debounce';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Node as ReactFlowNode,
  Edge,
  NodeTypes,
  NodeChange,
  useEdgesState,
  useNodesState,
  MarkerType,
  ReactFlowInstance,
  NodePositionChange,
  useViewport,
  FitViewOptions,
} from 'reactflow';
import { uniqueId } from '../../lib/uniqueId';
import { SWEEP_GRID_SIZE } from './const';
import { FloatingEdge, RFEdgeData } from './edges/FloatingEdge';
import { SweepCanvasReactFlowEdgeDataType } from './edges/SweepCanvasReactFlowEdgeDataType';
import {
  NewNurturingBucketNode,
  NewRegularNode,
  RegularStep,
  NurturingBucketStep,
  VirtualDropNode,
} from './nodes';
import {
  calculateHandlePositions,
  reactFlowPositionToCanvasIndexPosition,
  _canvasIndexPositionToReactFlowPosition,
} from './helpers/calculateHandlePositionsBasedOnCoords';
import { SweepCanvasBackground } from './SweepCanvasBackground';
import { getObjectTypeColor } from './helpers/getObjectTypeColor';
import { SweepCanvasControls } from './SweepCanvasControls';
import { useCalculateEdgeDetours } from './useCalculateEdgeDetours';

import { useHighlightNode } from './useHighlightNode';
import { shiftStepsRight } from './helpers/sweepCanvasHelper';
import { useNodeAnimations } from './useNodeAnimations';
import { useRepositionCanvas } from './useRepositionCanvas';
import { useCenterOnFirstNode } from './useCenterOnFirstNode';

import {
  CanvasElementType,
  CanvasEdgeConnectionType,
  SweepCanvasEdge,
  SweepCanvasNode,
  DEFAULT_CANVAS_ZOOM,
  SweepCanvasEdgeTransformations,
  SweepCanvasNodeTransformations,
  SweepNodesChangeEvent,
  SweepCanvasGroup,
  CanvasMode,
  DEFAULT_PREVIEW_MIN_ZOOM,
  RFNodeData,
  OnNodeClickProps,
  DEFAULT_CANVAS_MIN_ZOOM,
} from './canvasTypes';
import { useDropZoneNode } from './useDropZoneNode';
import { GroupNode } from './nodes/GroupNode';
import { useGroupNodes } from './useGroupNodes';
import { Box } from '@mui/material';
import { isNbNode } from './helpers/isNbNode';
import { VirtualDropGroupNode } from './nodes/VirtualDropGroupNode';
import { useSweepCanvasFitView } from './useSweepCanvasFitView';
import { useHighlightEdge } from './useHighlightEdge';
import GroupLabelNode from './nodes/GroupLabelNode';
import { useReactFlowNodes } from './useReactFlowNodes';
import { useManageFirstGroupDrop } from './useManageFirstGroupDrop';
import { MoveCanvasGroups, useMoveGroups } from './useMoveGroups';
import { MousePositionProvider } from './internal-context/MousePositionContext';
import {
  CanvasWrapperResizeObserverContext,
  CanvasWrapperResizeObserverProvider,
} from './internal-context/CanvasWrapperDimensionsContext';
import { CanvasContext, CanvasContextProvider } from './CanvasContext';
import { PluginTypes } from '../../types/enums/PluginTypes';
import { GroupOverlay } from './nodes/GroupOverlay';
import React from 'react';
import {
  CanvasCommunicationLayerProvider,
  InternalCanvasCtx,
} from './internal-context/InternalCanvasCtx';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { useContextZoomCss } from './useContextZoomCss';
import { CanvasHighlightProvider } from './internal-context/HighlightContext';
import { VisibilityLayers } from '../../types/enums/VisibilityLayers';
import { useDragEvents } from './useDragEvents';
import { GhostNode } from './nodes/GhostNode';

const edgeTypes = {
  floating: FloatingEdge,
};

export type OnSweepNodesChange = (change: SweepNodesChangeEvent) => any;

export type OnPluginClickEvent = (props: {
  parentId: string;
  pluginId: PluginTypes;
  objectType: string;
  event: React.MouseEvent;
}) => void;

interface SweepCanvasProps<GroupMetadata = any> {
  sweepNodes: SweepCanvasNode[];
  sweepEdges: SweepCanvasEdge[];
  sweepGroups: SweepCanvasGroup<GroupMetadata>[];
  onSweepNodesChange?: OnSweepNodesChange;
  onNodeClick?: (props: OnNodeClickProps) => any;
  onPillClick?: (props: OnNodeClickProps) => any;
  onLabelClick?: (props: OnNodeClickProps) => any;
  onGateClick?: (sourceNodeId: string, targetNodeId: string, sourceNodeParentId: string) => any;
  onEdgeDeleteClick?: (
    sourceNodeId: string,
    targetNodeId: string,
    sourceNodeParentId: string,
    targetNodeParentId: string,
  ) => any;
  onConnectClick?: (
    nodeId: string,
    parentId: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => any;
  readonly?: boolean;
  onRedPillClick?: () => any;
  showPreviewBackground?: boolean;
  selectedNodeId?: string;
  selectedEdgeId?: string;
  fitViewTimeoutValue?: number;
  validators?: {
    [event: string]: Function;
  };

  moveGroups?: MoveCanvasGroups;
  holdNodeHighlighted?: boolean;
  hideGroupInfo?: boolean;
  backgroundObjectType?: string;
  disableGroups?: boolean;
  autoFitViewOnFirstNodes?: boolean;
  showControls?: boolean;
  noFitView?: boolean;
  isLoadingCursor?: boolean;
  showGroupOverlays?: boolean;
  onPluginClick?: OnPluginClickEvent;

  // Context Props
  canvasMode?: CanvasMode;

  controlsRightMargin?: number;
  disableNodeHighlight?: boolean;
}

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
};

const nodeTypes: NodeTypes = {
  [CanvasElementType.REGULAR]: RegularStep,
  [CanvasElementType.EDIT]: NewRegularNode,
  [CanvasElementType.EDIT_NB]: NewNurturingBucketNode,
  [CanvasElementType.NURTURING_BUCKET]: NurturingBucketStep,
  [CanvasElementType.DROP_ZONE_NODE]: VirtualDropNode,
  [CanvasElementType.GROUP]: GroupNode,
  [CanvasElementType.GROUP_LABEL]: GroupLabelNode,
  [CanvasElementType.GROUP_DROP_ZONE_NODE]: VirtualDropGroupNode,
  [CanvasElementType.GROUP_OVERLAY]: GroupOverlay,
  [CanvasElementType.GHOST_NODE]: GhostNode,
};

const PreviewOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,0)',
      zIndex: 12,
    }}
  />
);

const fitViewOptions: { [K in CanvasMode]: FitViewOptions } = {
  [CanvasMode.DEFAULT]: {
    padding: 0.3,
    minZoom: DEFAULT_CANVAS_MIN_ZOOM,
    maxZoom: DEFAULT_CANVAS_ZOOM,
  },
  [CanvasMode.PREVIEW1]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.3,
  },
  [CanvasMode.PREVIEW2]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
  [CanvasMode.PREVIEW3]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
  [CanvasMode.FIT_NODES_PREVIEW]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
};

export interface TemporaryTransformations {
  originNode?: SweepCanvasNode;
  newNode?: SweepCanvasNode;
  newEdge?: SweepCanvasEdge;
  newGroup?: SweepCanvasGroup;
  nodeTransformations: SweepCanvasNodeTransformations;
  edgeTransformations: SweepCanvasEdgeTransformations;
}

export enum ContextZoomType {
  DEFAULT = 'DEFAULT',
  LEVEL1 = 'LEVEL1',
  LEVEL1_5 = 'LEVEL1_5', // no actions on hover
  LEVEL2 = 'LEVEL2',
}

const SweepMultiCanvas = ({
  sweepEdges,
  sweepNodes,
  sweepGroups,
  onSweepNodesChange,
  onNodeClick,
  onPillClick,
  onLabelClick,
  onGateClick,
  onConnectClick,
  readonly,
  onRedPillClick,
  showPreviewBackground,
  selectedNodeId,
  selectedEdgeId,
  fitViewTimeoutValue = 0,
  validators,
  onEdgeDeleteClick,
  holdNodeHighlighted,
  hideGroupInfo,
  backgroundObjectType,
  disableGroups,
  autoFitViewOnFirstNodes,
  moveGroups,
  showControls,
  noFitView,
  isLoadingCursor,
  showGroupOverlays,
  onPluginClick,
  canvasMode = CanvasMode.DEFAULT,
  controlsRightMargin,
  disableNodeHighlight,
}: SweepCanvasProps) => {
  const { temporaryTransformations, setTemporaryTransformations } = useContext(InternalCanvasCtx);

  const { hoveredElement, setHoveredElement } = React.useContext(CanvasContext);

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();

  const isInEditMode = Boolean(temporaryTransformations);
  const isInGroupMouseMoveMode = Boolean(moveGroups);
  const { zoom } = useViewport();
  const selectedFitViewOptions = fitViewOptions[canvasMode];

  const preview = canvasMode !== CanvasMode.DEFAULT;

  const { highlightNode, highlightedNodeData, removeNodeHighlight, isEdgePartOfHighlightedNode } =
    useHighlightNode({
      disableHighlight: preview || isInEditMode || isInGroupMouseMoveMode,
      zoom,
      holdNodeHighlighted,
    });

  const { highlightEdge, highlightedEdgeId, removeEdgeHighlight } = useHighlightEdge({
    disableHighlight: preview || isInEditMode || isInGroupMouseMoveMode,
  });

  const { withAnimation, addAnimationsToReactFlowNodes } = useNodeAnimations();

  const { contextZoomType, visibilityMap } = useContext(InternalCanvasCtx);

  // external sweep edges plus the shadow edges
  const internalSweepEdges: SweepCanvasEdge[] = useMemo(() => {
    let edges = temporaryTransformations?.newEdge
      ? sweepEdges.concat(temporaryTransformations?.newEdge)
      : sweepEdges;

    if (contextZoomType === ContextZoomType.LEVEL2) {
      edges = edges.filter((edge) => edge.data.type === 'removable');
    }

    return edges.map((sweepEdge) =>
      temporaryTransformations?.edgeTransformations[sweepEdge.id]
        ? temporaryTransformations?.edgeTransformations[sweepEdge.id]
        : sweepEdge,
    );
  }, [
    temporaryTransformations?.newEdge,
    temporaryTransformations?.edgeTransformations,
    sweepEdges,
    contextZoomType,
  ]);

  useCenterOnFirstNode({ reactFlowInstance, sweepNodes });

  // external sweep edges plus the shadow nodes
  const internalSweepNodes = useMemo(() => {
    const nodes = temporaryTransformations?.newNode
      ? sweepNodes.concat(temporaryTransformations?.newNode)
      : sweepNodes;
    return nodes.map((sweepNode) =>
      temporaryTransformations?.nodeTransformations[sweepNode.id]
        ? { ...temporaryTransformations?.nodeTransformations[sweepNode.id] }
        : sweepNode,
    );
  }, [
    temporaryTransformations?.newNode,
    temporaryTransformations?.nodeTransformations,
    sweepNodes,
  ]);
  const { draggingNode, dropZoneNode } = useDropZoneNode({
    internalSweepNodes,
    sweepNodes,
  });

  const { groupNodes, groupOverlayNodes, draggingGroupNodeId, firstEmptyGroup, groupLabelNodes } =
    useGroupNodes({
      sweepGroups,
      internalSweepNodes,
      dropZoneNode,
      readonly,
      hideGroupInfo,
      canvasMode,
      disableGroups,
      onPluginClick,
      showGroupOverlays,
      onPillClick,
      onLabelClick,
      onNodeClick,
    });

  const {
    width: canvasWrapperWidth,
    height: canvasWrapperHeight,
    canvasWrapperRef,
  } = useContext(CanvasWrapperResizeObserverContext);

  // Empty Group State
  useEffect(() => {
    if (firstEmptyGroup) {
      const group = sweepGroups.find((group) => group.id === firstEmptyGroup);

      const newNode: SweepCanvasNode = {
        id: uniqueId(),
        position: { row: 0, column: 0 },
        type: CanvasElementType.EDIT,
        name: '',
        objectType: (group?.objectType as ObjectTypeValues) || 'Lead',
        parentId: firstEmptyGroup,
      };

      setTemporaryTransformations({
        newNode,
        nodeTransformations: {},
        edgeTransformations: {},
      });
    }
  }, [firstEmptyGroup, setTemporaryTransformations, sweepGroups]);

  // Repositions the canvas to a new empty funnel with an empty node.
  useEffect(() => {
    if (temporaryTransformations?.newNode?.id && !moveGroups && zoom < 0.6) {
      const group = sweepGroups.find(
        (node) => node.id === temporaryTransformations?.newNode?.parentId,
      );

      if (group) {
        const xyPosition = _canvasIndexPositionToReactFlowPosition(group.position);
        reactFlowInstance?.setCenter(xyPosition.x, xyPosition.y, {
          zoom: 0.7,
        });
      }
    }
  }, [
    moveGroups,
    reactFlowInstance,
    sweepGroups,
    temporaryTransformations?.newNode?.id,
    temporaryTransformations?.newNode?.parentId,
    zoom,
  ]);

  const onEdgeAddBetweenClick = useCallback(
    (edgeId: string) => {
      const edge = sweepEdges.find((edge) => edge.id === edgeId);
      if (edge) {
        const startingNode = sweepNodes.find((node) => node.id === edge.target);
        if (!startingNode) return;

        const nodeTransformations = shiftStepsRight({
          startingNode,
          includeOwn: true,
          sweepNodes,
        });

        const onAnimationComplete = () => {
          const [newNodeId, newEdgeId] = [uniqueId(), uniqueId()];

          const originNode = sweepNodes.find((node) => node.id === edge.source);
          if (!originNode) {
            return;
          }

          const newNode: SweepCanvasNode = {
            id: newNodeId,
            position: startingNode.position,
            type: CanvasElementType.EDIT,
            name: '',
            objectType: originNode.objectType,
            originStepName: originNode.name,
            parentId: originNode.parentId,
          };

          const editedEdge = { ...edge };

          editedEdge.target = newNodeId;

          const newEdge: SweepCanvasEdge = {
            id: newEdgeId,
            source: newNodeId,
            target: edge.target,
            data: {
              label: '',
              type: SweepCanvasReactFlowEdgeDataType.DASHED_CIRCLE,
            },
          };

          setTemporaryTransformations({
            originNode: startingNode,
            newNode,
            newEdge,
            nodeTransformations,
            edgeTransformations: { [editedEdge.id]: editedEdge },
          });
        };

        withAnimation({
          nodeTransformations,
          onComplete: onAnimationComplete,
        });
      }
    },
    [setTemporaryTransformations, sweepEdges, sweepNodes, withAnimation],
  );

  const sweepEdgeToReactFlowEdge = useCallback(
    (sweepEdge: SweepCanvasEdge): Edge<RFEdgeData> => {
      const { id, source, target, data } = sweepEdge;
      const sourceNode = sweepNodes.find((node) => node.id === source);
      const targetNode = sweepNodes.find((node) => node.id === target);
      const sourceParentId = sourceNode?.parentId;
      const targetParentId = targetNode?.parentId;

      const isNodeHighlighted =
        highlightedNodeData && [source, target].includes(highlightedNodeData.highlightedNodeId);

      const isEdgeHighlighted = id === highlightedEdgeId;

      const { sourceHandle, targetHandle, arrowDirection } = calculateHandlePositions(
        sourceNode?.position,
        targetNode?.position,
      );

      const sourceColors = getObjectTypeColor(sourceNode?.objectType);
      const targetColors = getObjectTypeColor(targetNode?.objectType || sourceNode?.objectType);

      const isDraggingNode = draggingNode?.id === source || draggingNode?.id === target;
      const isDraggingGroup =
        draggingGroupNodeId === sourceNode?.parentId ||
        draggingGroupNodeId === targetNode?.parentId;

      const isDetourConnection =
        sweepEdge.data.connectionType === CanvasEdgeConnectionType.HorizontalDetour ||
        sweepEdge.data.connectionType === CanvasEdgeConnectionType.VerticalDetour;

      let _connectionType = sweepEdge.data.connectionType;

      if (
        isDraggingGroup &&
        sweepEdge.data.type === SweepCanvasReactFlowEdgeDataType.REMOVABLE &&
        isDetourConnection
      ) {
        _connectionType = CanvasEdgeConnectionType.Bezier;
      }
      if (isDraggingNode && isDetourConnection) {
        _connectionType = CanvasEdgeConnectionType.Bezier;
      }

      const _data: RFEdgeData = {
        ...data,
        arrowDirection,
        onEdgeAddBetweenBtnClick: onEdgeAddBetweenClick,
        sourceColor: sourceColors.connection,
        targetColor: targetColors.connection,
        gateColor: sourceColors.step,
        onGateClick,
        readonly,
        connectionType: _connectionType || CanvasEdgeConnectionType.Bezier,
        noAddBetweenBtn: !isNbNode(sourceNode) && isNbNode(targetNode),
        showButtonsOnHighlight: Boolean(highlightedNodeData?.showButtons),
        sourceParentId,
        targetParentId,
        onEdgeDeleteClick: onEdgeDeleteClick,
        canvasMode,
        contextZoomType,
        showGates: visibilityMap[VisibilityLayers.GATES],
        hideNurturingEdges: !visibilityMap[VisibilityLayers.NURTURING_STEPS],
      };

      if (isNodeHighlighted || isEdgeHighlighted) {
        _data.highlightType =
          highlightedNodeData?.highlightedNodeId === source ? 'source' : 'target';
      }

      return {
        id,
        source,
        target,
        type: 'floating',
        markerEnd: { type: MarkerType.Arrow },
        data: _data,
        zIndex: isNodeHighlighted || isEdgeHighlighted ? 2 : 1,
        sourceHandle,
        targetHandle,
        selected: selectedEdgeId === id,
        className: `edge-${id}`,
      };
    },
    [
      sweepNodes,
      highlightedNodeData,
      highlightedEdgeId,
      draggingNode?.id,
      draggingGroupNodeId,
      onEdgeAddBetweenClick,
      onGateClick,
      readonly,
      onEdgeDeleteClick,
      canvasMode,
      contextZoomType,
      visibilityMap,
      selectedEdgeId,
    ],
  );

  const [, , onNodesChange] = useNodesState<RFNodeData>([]);

  const [, , onEdgesChange] = useEdgesState<RFEdgeData>([]);

  const _onNodesChange = useCallback(
    (nodeChanges: NodeChange[]) => {
      onNodesChange(nodeChanges);

      const nodeChangePosition = nodeChanges.find(
        (nodeChange) => nodeChange.type === 'position' && nodeChange.dragging,
      ) as NodePositionChange;
      if (nodeChangePosition?.position) {
        const sweepNode = sweepNodes.find((node) => node.id === nodeChangePosition.id);
        if (!sweepNode) return;

        const position = reactFlowPositionToCanvasIndexPosition(nodeChangePosition.position);

        setTemporaryTransformations({
          nodeTransformations: {
            [nodeChangePosition.id]: {
              ...sweepNode,
              position,
            },
          },
          edgeTransformations: {},
        });
      }
    },
    [onNodesChange, setTemporaryTransformations, sweepNodes],
  );

  const internalSweepEdgesWithDetours = useCalculateEdgeDetours({
    sweepNodes,
    internalSweepNodes: internalSweepNodes,
    sweepEdges: internalSweepEdges,
    sweepGroups,
  });

  const internalReactFlowEdges = useMemo(() => {
    return (internalSweepEdgesWithDetours || []).map(sweepEdgeToReactFlowEdge);
  }, [internalSweepEdgesWithDetours, sweepEdgeToReactFlowEdge]);

  const { reactFlowNodes } = useReactFlowNodes({
    groupNodes,
    highlightedNodeData,
    sweepEdges,
    sweepNodes,
    onSweepNodesChange,
    validators,
    isInGroupMouseMoveMode,
    onConnectClick,
    draggingNode,
    holdNodeHighlighted,
    onNodeClick,
    onPillClick,
    readonly,
    selectedNodeId,
    withAnimation,
    disableGroups,
    reactFlowInstance,
    hoveredElement,
    disableNodeHighlight,
  });

  addAnimationsToReactFlowNodes(reactFlowNodes);

  const _onNodeMouseEnter = useCallback(
    (event: any, node: ReactFlowNode) => {
      setHoveredElement({ type: 'node', node });
      if (!isInEditMode && node.type !== 'GROUP') {
        highlightNode(node);
        removeEdgeHighlight();
      }
    },
    [highlightNode, isInEditMode, removeEdgeHighlight, setHoveredElement],
  );

  const _onEdgeMouseEnter = useCallback(
    (event: any, edge: any) => {
      if (!isInEditMode && !isEdgePartOfHighlightedNode(edge)) {
        highlightEdge(edge.id);
        removeNodeHighlight();
      }
    },
    [highlightEdge, isEdgePartOfHighlightedNode, isInEditMode, removeNodeHighlight],
  );

  useRepositionCanvas({ newNodeId: temporaryTransformations?.newNode?.id });

  const { handleOnGroupsDropClick } = useMoveGroups({
    groupNodes,
    moveGroups,
    sweepGroups,
  });

  const nodes: ReactFlowNode[] = [
    ...reactFlowNodes,
    ...groupLabelNodes,
    ...groupOverlayNodes,
    ...groupNodes,
  ];
  dropZoneNode && nodes.push(dropZoneNode);

  const { hideFirstFunnel } = useManageFirstGroupDrop({
    sweepGroups,
    selectedFitViewOptions,
    autoFitViewOnFirstNodes,
  });

  const showZoomControls =
    showControls ?? [CanvasMode.DEFAULT, CanvasMode.PREVIEW2].includes(canvasMode);

  const { fitAroundNodes } = useSweepCanvasFitView();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    debounce_(
      () => {
        const nodeIds = nodes.slice(0, 2).map((node) => node.id) ?? [];
        const isNodesPreview = canvasMode === CanvasMode.FIT_NODES_PREVIEW;
        const sharedOptions: FitViewOptions = {
          ...selectedFitViewOptions,
          duration: 200,
        };

        fitAroundNodes(isNodesPreview ? nodeIds : [], { ...sharedOptions });
      },
      fitViewTimeoutValue,
      {
        maxWait: fitViewTimeoutValue,
      },
    ),
    [fitAroundNodes],
  );

  useEffect(() => {
    if (reactFlowInstance && preview) {
      debounceFn();
    }
  }, [
    canvasWrapperWidth,
    canvasWrapperHeight,
    reactFlowInstance,
    fitViewTimeoutValue,
    fitAroundNodes,
    sweepNodes,
    debounceFn,
    preview,
  ]);

  const { sx, classes } = useContextZoomCss({
    canvasMode,
    groupLabelsCount: groupLabelNodes.length,
  });

  const { onDrag, onDragStart, onDragStop } = useDragEvents({
    groupNodes,
    internalSweepNodes,
    sweepNodes,
    sweepGroups,
    onSweepNodesChange,
  });

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: '#fff',

        '& .react-flow__node, & .react-flow__edge': {
          visibility: hideFirstFunnel ? 'hidden !important' : 'visible',
        },
        '& .react-flow__pane': {
          cursor: isLoadingCursor ? 'wait' : undefined,
        },
        ...sx,
      }}
      ref={canvasWrapperRef}
      data-testid="sweep-canvas"
      className={classes.join(' ')}
      id="sweep-canvas"
    >
      <ReactFlow
        proOptions={proOptions}
        nodes={nodes}
        edges={internalReactFlowEdges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        snapGrid={[SWEEP_GRID_SIZE.width, SWEEP_GRID_SIZE.height]}
        selectNodesOnDrag={true}
        onEdgesChange={onEdgesChange}
        onEdgeMouseEnter={_onEdgeMouseEnter}
        onNodesChange={_onNodesChange}
        onNodeDragStart={onDragStart}
        onNodeDrag={onDrag}
        onNodeDragStop={onDragStop}
        onNodeMouseEnter={_onNodeMouseEnter}
        onNodeMouseLeave={() => setHoveredElement(undefined)}
        onClick={() => {
          removeNodeHighlight();
          removeEdgeHighlight();
          handleOnGroupsDropClick();
        }}
        onInit={setReactFlowInstance}
        minZoom={preview ? DEFAULT_PREVIEW_MIN_ZOOM : DEFAULT_CANVAS_MIN_ZOOM}
        fitView
        fitViewOptions={{
          nodes: reactFlowNodes,
          ...selectedFitViewOptions,
        }}
        zoomOnScroll={!preview}
        panOnScroll={true}
      >
        <SweepCanvasBackground
          backgroundObjectType={backgroundObjectType}
          showPreviewBackground={showPreviewBackground}
        />
        {showZoomControls ? (
          <SweepCanvasControls
            onRedPillClick={onRedPillClick}
            isPreview2={canvasMode === CanvasMode.PREVIEW2}
            noFitView={noFitView}
            controlsRightMargin={controlsRightMargin}
          />
        ) : (
          <PreviewOverlay />
        )}
      </ReactFlow>
    </Box>
  );
};

const SweepCanvasWithAllInternalProviders = (
  props: SweepCanvasProps & { visibilityMap: VisibilityMap },
) => {
  const { canvasMode, visibilityMap } = props;
  return (
    <CanvasCommunicationLayerProvider canvasMode={canvasMode} visibilityMap={visibilityMap}>
      <CanvasWrapperResizeObserverProvider>
        <MousePositionProvider>
          <CanvasHighlightProvider>
            <SweepMultiCanvas {...props} />
          </CanvasHighlightProvider>
        </MousePositionProvider>
      </CanvasWrapperResizeObserverProvider>
    </CanvasCommunicationLayerProvider>
  );
};

const SweepMultiCanvasWithReactFlowContext = (
  props: SweepCanvasProps & { visibilityMap: VisibilityMap },
) => (
  <CanvasContextProvider>
    <SweepCanvasWithAllInternalProviders {...props} />
  </CanvasContextProvider>
);

export { SweepMultiCanvasWithReactFlowContext as SweepMultiCanvas };
export { SweepCanvasWithAllInternalProviders as SweepMultiCanvasInternal };
