import { XYPosition } from 'reactflow';
import { useCallback } from 'react';
import { SweepCanvasNode } from './canvasTypes';
import {
  _reactFlowPositionToRoundedCanvasIndexPosition,
  SweepCanvasNodePosition,
} from './helpers/calculateHandlePositionsBasedOnCoords';

export enum DropInvalidReason {
  OVERLAP = 'OVERLAP',
}

export const useCalculateDragAndDrop = ({
  internalSweepNodes,
  sweepNodes,
}: {
  internalSweepNodes: SweepCanvasNode[];
  sweepNodes: SweepCanvasNode[];
}) => {
  const getDropInvalidReason = useCallback(
    (internalNodePos: XYPosition, id: string): DropInvalidReason | undefined => {
      const node = sweepNodes.find((node) => node.id === id);
      if (node) {
        const parentId = node?.parentId;

        const newCanvasPos = _reactFlowPositionToRoundedCanvasIndexPosition({
          x: internalNodePos.x,
          y: internalNodePos.y,
        });

        const isOverlappingAnotherStep = Boolean(
          internalSweepNodes.find((node) => {
            if (node.id === id || node.parentId !== parentId) {
              return false;
            }

            const nodePos: SweepCanvasNodePosition = {
              row: node.position.row,
              column: node.position.column,
            };

            return (
              nodePos.column === newCanvasPos.column &&
              nodePos.row === newCanvasPos.row &&
              node.id !== id
            );
          }),
        );

        if (isOverlappingAnotherStep) {
          return DropInvalidReason.OVERLAP;
        }
      }
    },
    [internalSweepNodes, sweepNodes],
  );

  return {
    getDropInvalidReason,
  };
};
