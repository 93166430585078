import { colors } from '@sweep-io/sweep-design';

export const objectTypeToColors: {
  [key: string]: {
    step: string;
    connection: string;
    leadingColorName: string;
  };
} = {
  Lead: {
    step: colors.Lead,
    connection: colors.sun[500],
    leadingColorName: 'sun',
  },
  Opportunity: {
    step: colors.lilac[300],
    connection: colors.lilac[500],
    leadingColorName: 'lilac',
  },
  Contact: {
    step: colors.sky[300],
    connection: colors.sky[500],
    leadingColorName: 'sky',
  },
  Account: {
    step: colors.seafoam[300],
    connection: colors.seafoam[500],
    leadingColorName: 'seafoam',
  },
  Case: {
    step: colors.gum[300],
    connection: colors.gum[500],
    leadingColorName: 'gum',
  },
  __default: {
    step: colors.blue[100],
    connection: colors.blue[100],
    leadingColorName: 'blue',
  },
};

export const getObjectTypeColor = (objectType: string = '__default') =>
  objectTypeToColors[objectType] || objectTypeToColors.__default;
