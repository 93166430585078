import { Box } from '@mui/material';
import { Close, Sparkle } from '@sweep-io/sweep-design/dist/icons';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';

const CopilotHeader = ({
  closeCopilot,
  resetChat,
}: {
  closeCopilot: () => void;
  resetChat: () => void;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="56px"
      pl={2}
      pr={1}
      sx={{
        backgroundColor: colors.storm[50],
      }}
    >
      <Box display="flex" gap={0.5} alignItems="center">
        <Sparkle variant="large" color={colors.night[900]} />
        <Typography variant="h4" color={colors.night[900]}>Copilot</Typography>
      </Box>
      <Box display="flex" gap={0.5} alignItems="center">
        <Button startIconName="Redo" variant="outlined" onClick={resetChat} size="small">
          Start again
        </Button>
        <IconButton onClick={closeCopilot} variant="flat" size="small">
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CopilotHeader;
