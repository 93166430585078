import { Box } from '@mui/material';
import { VerifyUrlActionList } from './VerifyUrlActionList';
import { useCallback } from 'react';
import { URLStatus } from '@server/verify-url';
import flatten from 'lodash/flatten';
import { uniqueId } from '../../lib/uniqueId';

interface VerifyUrlActionWrapperProps {
  readonly?: boolean;
  action: VerifyUrlAutomationAction;
  crmOrgId: string;
  onChange: (action: VerifyUrlAutomationAction) => any;
  objectName: string;
  objectTypes?: ObjectTypeNameAndTypes[];
}

export const VerifyUrlActionWrapper = ({
  action,
  crmOrgId,
  objectName,
  onChange,
  objectTypes,
}: VerifyUrlActionWrapperProps) => {
  const getActionsByType = useCallback(
    (urlStatus: URLStatus) => {
      const allActions = action?.actionParams?.verifyResultFollowUps;
      const item = allActions?.find((el) => el.urlStatus === urlStatus);
      return flatten(item?.followUpActions?.map((action) => action.actions)) ?? [];
    },
    [action],
  );

  const setActionsByType = useCallback(
    (urlStatus: URLStatus, _actions: AutomationAction[]) => {
      const allActions =
        (action?.actionParams as AutomationVerifyUrlActionParams)?.verifyResultFollowUps ?? [];
      const allButType = allActions?.filter((el) => el.urlStatus !== urlStatus);
      const toSend = [
        ...allButType,
        {
          urlStatus,
          followUpActions: [{ _id: uniqueId(), _name: 'follow up actions', actions: _actions }],
        },
      ];

      onChange({
        ...(action as VerifyUrlAutomationAction),
        actionParams: {
          ...action?.actionParams,
          verifyResultFollowUps: toSend,
        },
      });
    },
    [onChange, action],
  );

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, flexDirection: 'column' }}>
      <VerifyUrlActionList
        type={URLStatus.REDIRECT}
        title={'On Redirect (URL Redirects)'}
        objectTypes={objectTypes}
        crmOrgId={crmOrgId}
        objectName={objectName}
        actionList={getActionsByType(URLStatus.REDIRECT)}
        onChange={(_actions) => setActionsByType(URLStatus.REDIRECT, _actions)}
      />

      <VerifyUrlActionList
        type={URLStatus.ERROR}
        objectTypes={objectTypes}
        crmOrgId={crmOrgId}
        objectName={objectName}
        title={'On Error (URL is Broken)'}
        actionList={getActionsByType(URLStatus.ERROR)}
        onChange={(_actions) => setActionsByType(URLStatus.ERROR, _actions)}
      />

      <VerifyUrlActionList
        type={URLStatus.VALID}
        objectTypes={objectTypes}
        crmOrgId={crmOrgId}
        objectName={objectName}
        title={'On Success (URL is Live)'}
        actionList={getActionsByType(URLStatus.VALID)}
        onChange={(_actions) => setActionsByType(URLStatus.VALID, _actions)}
      />
    </Box>
  );
};
