import { useViewport } from 'reactflow';
import './background-styles.css';
import { getObjectTypeColor } from './helpers/getObjectTypeColor';

interface SweepCanvasBackgroundProps {
  showPreviewBackground?: boolean;
  backgroundObjectType?: string;
}

// React Node doesn't support the type of binding moving background we need
// This component is bounded to the viewport and creates a custom
// background.
export const SweepCanvasBackground = ({
  backgroundObjectType,
  showPreviewBackground,
}: SweepCanvasBackgroundProps) => {
  const { zoom: tScale, x: tX, y: tY } = useViewport();

  const patternId = `pattern-${tX}-${tY}-${tScale}`;
  const gap = 50;
  const scaledGap = gap * tScale || 1;
  const xOffset = tX % scaledGap;
  const yOffset = tY % scaledGap;
  const size = 6 * tScale;

  return (
    <svg
      className="react-flow__background react-flow__container"
      style={{ width: '100%', height: '100%' }}
    >
      <pattern
        id={patternId}
        x={xOffset}
        y={yOffset}
        width={scaledGap}
        height={scaledGap}
        patternUnits="userSpaceOnUse"
      >
        <svg
          width={size}
          height={size}
          viewBox="0 0 6 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 3H5" stroke="#F2F2F2" strokeWidth="2" strokeLinecap="round" />
          <circle cx="3" cy="3" r="2" fill="#F2F2F2" />
          <path d="M3 1L3 5" stroke="#F2F2F2" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </pattern>
      <rect x="0" y="0" width="100%" height="100%" fill={`url(#${patternId})`}></rect>
      {showPreviewBackground && (
        <rect
          className="sweep-canvas-background-preview"
          x="0"
          y={0}
          width="100%"
          height="100%"
          fill="rgba(244, 246, 255, 1)"
        ></rect>
      )}
      {backgroundObjectType && (
        <rect
          className="sweep-canvas-background-preview"
          x="0"
          y={0}
          width="100%"
          height="100%"
          fill={getObjectTypeColor(backgroundObjectType).connection + '1A'}
        ></rect>
      )}
    </svg>
  );
};
