import _ from 'lodash';

import { useEffect, useState } from 'react';
import { useFunnelMapApiFacade } from '../../../../apis/facades/funnel-map/useFunnelMapApiFacade';
import { useFunnelMapFlowPageApi } from '../useFunnelMapFlowPageApi';
import { useCalculateLastUpdate } from './useCalculateLastUpdate';
import { useObjectFlags } from './useObjectFlags';
import usePermission from '../../../common/permissions/usePermission';
import { setHasUnsavedChanges } from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { useDispatch } from 'react-redux';
import { simpleHash } from '../../../../third-party/simpleHash';
import { telemetry } from '../../../../telemetry';

const FUNNEL_MAP = 'FUNNEL_MAP';

export type FunnelMapSaveData = Pick<FunnelMap, 'id' | 'funnels' | 'name'>;

type FunnelSavePayload = Pick<Funnel, 'id' | 'funnelDetails' | 'name' | 'description'>;

export interface AutoSaveProps {
  funnelMapSaveData: FunnelMapSaveData;
  funnelsData: FunnelsData;
  funnelMapLastUpdatedAt: string;
  readonly?: boolean;
  crmOrgId?: string | null;
}

const getSavableFunnelData = ({
  id,
  name,
  description,
  funnelDetails,
}: Funnel): FunnelSavePayload => ({
  id,
  name,
  description,
  funnelDetails,
});

const omitNotChangedProperties = ({
  previousVersion,
  currentVersion,
}: {
  previousVersion: FunnelSavePayload;
  currentVersion: FunnelSavePayload;
}) => {
  Object.keys(currentVersion).forEach((key) => {
    const funnelSavePayloadKey = key as keyof FunnelSavePayload;

    if (
      _.isEqual(currentVersion[funnelSavePayloadKey], previousVersion[funnelSavePayloadKey]) &&
      key !== 'id'
    ) {
      delete currentVersion[funnelSavePayloadKey];
    }
  });

  return currentVersion;
};

export const useAutoSave = ({
  funnelMapSaveData,
  funnelsData,
  funnelMapLastUpdatedAt,
  crmOrgId,
}: AutoSaveProps) => {
  const [lastSavedFunnelMapSaveData, setLastSavedFunnelMapSaveData] = useState(funnelMapSaveData);

  const [hasEditFunnelPermissions] = usePermission(['edit:funnels']);

  const { put_funnelMap } = useFunnelMapApiFacade();
  const { saveFunnelData } = useFunnelMapFlowPageApi();

  const dispatch = useDispatch();

  const [savedFunnelById, setSavedFunnelById] = useState(() =>
    Object.fromEntries(
      Object.keys(funnelsData).map((funnelId) => [
        funnelId,
        getSavableFunnelData(funnelsData[funnelId]),
      ]),
    ),
  );

  const { setObjectFlagFor: setFunnelEntityIsDirty, hasAny: dirtyObjectsExists } = useObjectFlags();

  const {
    setObjectFlagFor: setErrorsFor,
    hasAny: hasAnyErrors,
    getObjectFlagFor: getErrorHashFor,
    removeObject: removeError,
  } = useObjectFlags<string>();
  const {
    setObjectFlagFor: setIsSavingFor,
    getObjectFlagFor: getIsSavingFor,
    hasAny: isSaving,
  } = useObjectFlags();

  const { lastUpdated, setLastUpdatedFor } = useCalculateLastUpdate({
    funnelsData,
    funnelMapLastUpdatedAt,
  });

  useEffect(() => {
    dispatch(setHasUnsavedChanges({ hasUnsavedChanges: dirtyObjectsExists }));
  }, [dirtyObjectsExists, dispatch]);

  useEffect(() => {
    if (!hasEditFunnelPermissions) {
      return;
    }
    const isSavingFunnelMap = getIsSavingFor(FUNNEL_MAP);
    const funnelMapHasChanges = !_.isEqual(lastSavedFunnelMapSaveData, funnelMapSaveData);
    const funnelMapSaveDataHash = simpleHash(JSON.stringify(funnelMapSaveData));
    const funnelMapSavingError = getErrorHashFor(FUNNEL_MAP) === funnelMapSaveDataHash;

    if (!funnelMapSavingError && !isSavingFunnelMap && funnelMapHasChanges) {
      const { funnels, id, name } = funnelMapSaveData;
      const funnelsArray = Object.keys(funnels).map((funnelId) => ({
        funnelId,
        ...funnels[funnelId].position,
        isHidden: !!funnels[funnelId].isHidden,
      }));

      setFunnelEntityIsDirty(FUNNEL_MAP, true);
      setIsSavingFor(FUNNEL_MAP, true);

      const funnelMapPayload = {
        name,
        defaultCrmOrgId: null,
        funnels: funnelsArray,
      };
      // TODO: Use a stack
      put_funnelMap(id, funnelMapPayload)
        .then((funnelMap) => {
          setLastSavedFunnelMapSaveData(funnelMapSaveData);
          setLastUpdatedFor(FUNNEL_MAP, funnelMap.updatedAt);
          setFunnelEntityIsDirty(FUNNEL_MAP, false);
          removeError(FUNNEL_MAP);
        })
        .catch((err) => {
          telemetry.captureError(err);
          setErrorsFor(FUNNEL_MAP, funnelMapSaveDataHash);
        })
        .finally(() => {
          setIsSavingFor(FUNNEL_MAP, false);
        });
    }
  }, [
    funnelMapSaveData,
    getIsSavingFor,
    lastSavedFunnelMapSaveData,
    put_funnelMap,
    setErrorsFor,
    setFunnelEntityIsDirty,
    setIsSavingFor,
    setLastUpdatedFor,
    getErrorHashFor,
    removeError,
    hasEditFunnelPermissions,
  ]);

  useEffect(() => {
    if (!hasEditFunnelPermissions) {
      return;
    }
    const funnelsToSave: FunnelSavePayload[] = [];
    const funnelDetailsById = Object.fromEntries(
      Object.keys(funnelsData).map((funnelId) => [funnelId, funnelsData[funnelId].funnelDetails]),
    );

    const funnelIds = Object.keys(funnelDetailsById);

    // Checks witch funnels have changed and adds them to the funnelsToSave list
    funnelIds.forEach((funnelId) => {
      const savableFunnelData = getSavableFunnelData(funnelsData[funnelId]);
      const previousFunnelVersion = savedFunnelById[funnelId];

      const isFirstTimeInFunnel = !Boolean(previousFunnelVersion);

      if (isFirstTimeInFunnel) {
        // Do not save since this funnel already exists and saved in the current condition
        // however add it to the savedFunnelDetailsById
        setSavedFunnelById((savedFunnel) => {
          savedFunnel[funnelId] = savableFunnelData;
          return { ...savedFunnel };
        });
      } else {
        // Verify if there are changes in the current funnel and add
        // to the funnelsToSave list

        const cleanedFunnel = _(savableFunnelData).omitBy(_.isUndefined).value();
        const cleanedPreviousFunnelVersion = _(previousFunnelVersion).omitBy(_.isUndefined).value();

        const isSavingFunnel = getIsSavingFor(funnelId);
        const funnelHasChanges = () => !_.isEqual(cleanedPreviousFunnelVersion, cleanedFunnel);

        if (!isSavingFunnel && funnelHasChanges()) {
          setFunnelEntityIsDirty(funnelId, true);
          funnelsToSave.push(
            omitNotChangedProperties({
              currentVersion: savableFunnelData,
              previousVersion: previousFunnelVersion,
            }),
          );
        }
      }
    });

    const saveFunnels = async () => {
      const promises = funnelsToSave.map(async (payload) => {
        const { id: funnelId } = payload;
        const payloadHash = simpleHash(JSON.stringify(payload));
        if (getErrorHashFor(funnelId) === payloadHash) {
          return;
        }
        try {
          setIsSavingFor(funnelId, true);
          const funnel = await saveFunnelData({ ...payload, crmOrgId });
          removeError(funnelId);

          setLastUpdatedFor(funnelId, funnel.updatedAt);
          setFunnelEntityIsDirty(funnelId, false);

          setSavedFunnelById((_funnelsById) => {
            _funnelsById[funnelId] = funnel;
            return { ..._funnelsById };
          });
        } catch (err) {
          telemetry.captureError(err);
          setErrorsFor(funnelId, payloadHash);
        } finally {
          setIsSavingFor(funnelId, false);
        }
      });
      await Promise.all(promises);
    };

    saveFunnels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelsData, hasEditFunnelPermissions]);

  return {
    isAutoSaving: isSaving,
    autoSavingError: hasAnyErrors,
    updatedAt: lastUpdated ? new Date(lastUpdated) : undefined,
  };
};
