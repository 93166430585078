import { UserInfo } from '../../../types/UserInfoTypes';
import { ParsedRecordType } from '../ParserTypes';
import { ConfigurationType } from '../dependencies/types';
import {
  AutomationStructureWithParentType,
  ParsedRuleWithParentType,
  RecordTypesLabelsByNameAndObjectName,
} from '../types';

export const getCardListFromParsedRules = (
  parsedRules: ParsedRuleWithParentType[],
  parentObjectName: string,
) => {
  return parsedRules.map((rule) => ({
    id: rule.id,
    dependencyType: rule.type,
    parentType: rule.parentType,
    name: rule.name,
    label: rule.label,
    objectName: parentObjectName,
    changedAt: rule.annotations?.changedAt,
    changedBy: rule.annotations?.changedBy,
    isActive: rule.isActive,
  }));
};

export const getCardListFromParsedRecordTypes = (
  parsedRecordTypes: ParsedRecordType[],
  parentObjectName: string,
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName,
) => {
  return parsedRecordTypes.map((rt) => ({
    id: rt.id,
    parentType: ConfigurationType.recordTypes,
    dependencyType: ConfigurationType.recordTypes,
    description: rt.description,
    name: rt.name,
    label: rt.label,
    link: rt.link,
    objectName: parentObjectName,
    changedAt: rt.annotations?.changedAt,
    changedBy: rt.annotations?.changedBy,
    withHideButton: !!recordTypeNamesUsedInCanvas?.[parentObjectName]?.[rt.name],
    isActive: rt.isActive,
  }));
};

export const getCardListFromAutomationStructure = (
  automations: AutomationStructureWithParentType[],
  users?: UserInfo[],
) => {
  return automations.map((automation) => ({
    id: automation.automationId,
    dependencyType: automation.type,
    parentType: automation.parentType,
    name: automation.name,
    label: automation.name,
    objectName: automation.objectName,
    changedAt: automation.updatedAt,
    changedBy: users?.find((user) => user.id === automation.updatedById)?.name,
    isActive: true,
  }));
};

export const getRecordTypeNames = ({
  funnelsData = {},
  recordTypesData = {},
}: {
  funnelsData: FunnelsData;
  recordTypesData: RecordTypesData;
}) => {
  const recordTypesByObjectName: RecordTypesLabelsByNameAndObjectName = {};

  Object.values(funnelsData).forEach((funnel) => {
    const { name, label } = funnel?.recordType ?? {};
    const objectName = funnel?.recordType?.objectName;
    const _label = label ?? name;

    if (objectName) {
      if (!recordTypesByObjectName[objectName]) {
        recordTypesByObjectName[objectName] = { [name]: _label };
      } else {
        recordTypesByObjectName[objectName][name] = _label;
      }
    }
  });

  Object.values(recordTypesData).forEach((rt) => {
    const { name, objectName, label } = rt ?? {};
    const _label = label ?? name;

    if (objectName) {
      if (!recordTypesByObjectName[objectName]) {
        recordTypesByObjectName[objectName] = { [name]: _label };
      } else {
        recordTypesByObjectName[objectName][name] = _label;
      }
    }
  });

  return recordTypesByObjectName;
};
