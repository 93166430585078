import { useCallback } from 'react';
import {
  AiChatWithOrgPayload,
  useCrmOrgsApiFacade,
} from '../../../apis/facades/useCrmOrgsApiFacade';

//this file content is part of preparations for ai chats infra, will put it in separate files when i work on Jira SWEEP-5092
type ContextTextKeys = 'configurationName' | 'configurationKey' | 'objectName' | 'fieldName';

export const createContextText = (args: {
  [key in ContextTextKeys]?: string;
}) => {
  let txt = `The context of this message is:`;
  Object.keys(args).forEach((key) => {
    const value = args[key as keyof typeof args];
    const _key = key
      .split(/(?=[A-Z])/g)
      ?.join('_')
      .toLowerCase();
    txt += `\n${_key}=${value}`;
  });
  return txt;
};

export const useAiChat = () => {
  const { post_aiChatWithOrg } = useCrmOrgsApiFacade();

  const onSendMessage = useCallback(
    async ({ payload }: { payload: AiChatWithOrgPayload }) => {
      const res = await post_aiChatWithOrg({ ...payload });
      return res;
    },
    [post_aiChatWithOrg],
  );

  return { onSendMessage };
};
