import { Stack, TextField } from '@mui/material';
import { GroupSettingsAvatar } from './GroupSettingsAvatarHeader';
import { useGroupSettingsContext } from '../GroupSettingsContext';
import { useCallback } from 'react';
import { AssignmentGroup } from '../../../../../reducers/assignmentGroupTypes';

export const isNameAndDescriptionValid = (assignmentGroup: DeepPartial<AssignmentGroup>) => {
  return !!assignmentGroup.name;
};

export const NameAndDescriptionStepHeader = () => (
  <GroupSettingsAvatar title="Create a new Assignment Group" />
);

export const NameAndDescriptionStepContent = () => {
  const { assignmentGroup, setAssignmentGroup } = useGroupSettingsContext();

  const setDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAssignmentGroup((localGroupData) => ({
        ...localGroupData,
        description: e.target.value,
      }));
    },
    [setAssignmentGroup],
  );

  const setName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAssignmentGroup((localGroupData) => ({
        ...localGroupData,
        name: e.target.value,
      }));
    },
    [setAssignmentGroup],
  );

  return (
    <Stack gap={3}>
      <TextField
        fullWidth
        required
        placeholder="Group name i.e Gabriella’s SDR’s"
        value={assignmentGroup.name}
        onChange={setName}
      />

      <TextField
        fullWidth
        multiline
        placeholder="Add description here (optional)"
        value={assignmentGroup.description}
        onChange={setDescription}
        minRows={2}
      />
    </Stack>
  );
};
