import {
  ActionableDataTable,
  ActionableDataTableActionTypes,
} from '../common/table/ActionableDataTable';
import { humanReadableCriteria } from '../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { DataTableOnOrderChangeClbk } from '../common/table/TableTypes';
import usePermission from '../common/permissions/usePermission';
import { useSelector } from 'react-redux';
import { useTerritoriesActions } from '../../hooks/useTerritoriesActions';
import { selectTerritoriesList } from '../../reducers/territoriesReducer';
import { useRunOnce } from '../common/useRunOnce';
import { Permission } from '@server/permissions';
import { useSweepFieldsLabels } from '../../sweep-fields/useSweepFieldsLabels';

interface AssignmentRulesTableProps {
  assignmentRules: AutomationAssignmentRule[];
  onAssignmentRulesChange: (assignmentRules: AutomationAssignmentRule[]) => any;
  onAssignmentRuleEdit: (assignmentRuleId: string) => any;
  onAssignmentRuleDuplicate: (assignmentRuleId: string) => any;
  readonly?: boolean;
}

const columns = [
  {
    field: 'priority',
    headerName: 'Priority',
    width: '78px',
    showDragHandle: true,
  },
  { field: 'name', headerName: 'Name', width: '33%' },
  { field: 'rule', headerName: 'Rule', width: '33%' },
  { field: 'assignedTo', headerName: 'Assigned to', width: '33%' },
];

interface AssignmentsDataRow {
  id: string;
  priority: string;
  name: string;
  rule: string;
  assignedTo: string;
}

export const AssignmentRulesTable = ({
  assignmentRules,
  onAssignmentRulesChange,
  onAssignmentRuleEdit,
  onAssignmentRuleDuplicate,
  readonly,
}: AssignmentRulesTableProps) => {
  const permissionString: Permission[] = ['edit:funnels'];
  const [isAllowedBtn] = usePermission(permissionString);

  const territories = useSelector(selectTerritoriesList);
  const { getTerritories } = useTerritoriesActions();
  const { enrichCriteriaWithLabels: populateCriteriaWithLabels } = useSweepFieldsLabels();

  useRunOnce(async () => {
    await getTerritories();
  });

  const rows: AssignmentsDataRow[] = assignmentRules.map((rule, idx) => ({
    id: rule._id,
    priority: '' + (idx + 1),
    assignedTo: rule.assignedTo._name ?? '',
    name: rule.name,
    rule: humanReadableCriteria(
      rule.when?.criteria?.criteria.length
        ? rule.when?.criteria?.criteria.map((c) => populateCriteriaWithLabels(c))
        : [],
      rule.when?.criteria?.criteriaLogic,
      territories,
    ),
  }));

  const onOrderChange: DataTableOnOrderChangeClbk = ({ sourceIndex, destinationIndex }) => {
    const newAssignments = [...assignmentRules];
    newAssignments.splice(sourceIndex, 1);
    newAssignments.splice(destinationIndex, 0, assignmentRules[sourceIndex]);
    onAssignmentRulesChange(newAssignments);
  };
  const getActions =
    !isAllowedBtn || readonly
      ? []
      : [
          { type: ActionableDataTableActionTypes.EDIT },
          {
            type: ActionableDataTableActionTypes.DUPLICATE,
          },
          {
            type: ActionableDataTableActionTypes.DELETE,
            confirm: true,
            confirmButtonOkLabel: 'Yes, Delete',
            confirmButtonCancelLabel: 'Cancel',
            confirmTitle: 'Delete Rule',
            confirmSubtitle: 'Are you sure you want to delete this rule?',
          },
        ];
  return (
    <ActionableDataTable
      allowReorder={isAllowedBtn && !readonly}
      columns={columns}
      rows={rows}
      onOrderChange={onOrderChange}
      actions={getActions}
      onActionClick={({ actionType, row }) => {
        switch (actionType) {
          case ActionableDataTableActionTypes.DELETE:
            const newAssignmentRules = assignmentRules.filter((r) => r._id !== row.id);
            onAssignmentRulesChange(newAssignmentRules);
            break;
          case ActionableDataTableActionTypes.EDIT:
            onAssignmentRuleEdit(row.id);
            break;
          case ActionableDataTableActionTypes.DUPLICATE:
            onAssignmentRuleDuplicate(row.id);
            break;
        }
      }}
      onRowClick={(
        e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
        row: AssignmentsDataRow,
      ) => {
        onAssignmentRuleEdit(row.id);
      }}
    />
  );
};
