import { Box, DialogContent, Stack } from '@mui/material';
import TerritoriesList from '../pages/territories/TerritoriesList';
import { IconButton, Typography } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';

const TerritoriesDialog = ({
  closeDialog,
  transitionDuration,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
}) => {
  return (
    <DialogOnCanvas onClose={closeDialog} open={true} transitionDuration={transitionDuration}>
      <DialogContent sx={{ paddingTop: 4 }}>
        <Stack gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h1">Territories</Typography>
            </Box>
            <IconButton variant="flat" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TerritoriesList />
        </Stack>
      </DialogContent>
    </DialogOnCanvas>
  );
};

export default TerritoriesDialog;
