import { Box } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, memo, useCallback, useMemo } from 'react';
import { PillProps } from '@sweep-io/sweep-design/dist/components/Pill';
import { ListElementWrapper } from '../shared/ListElementWrapper';
import { HoveredPin, ObjectWithPills } from '../types';
import { ObjectTypeChip } from '../../common/ObjectTypeChip';
import { colors, Pill } from '@sweep-io/sweep-design';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { useDocumentation } from '../useDocumentation';
import { pillOptions } from '../selected-object/PillOptions';
import { AddObjectButton } from './AddObjectButton';
import { useExpandedMode } from '../../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';

export type PillPropsExtended = PillProps & { key: DocumentationTabTypes; icon: ReactNode };

interface ObjectCardPillsProps {
  item: ObjectWithPills;
  onCardClick: (objectTypeName: ObjectTypeName, tab?: DocumentationTabTypes) => void;
  displayPills: boolean;
  setHoveredPin?: Dispatch<SetStateAction<HoveredPin | undefined>>;
  hideAddObjectButton?: boolean;
}

export const ObjectCardPills = memo(
  ({
    item,
    onCardClick,
    displayPills,
    setHoveredPin,
    hideAddObjectButton,
  }: ObjectCardPillsProps) => {
    const { isExpandedMode } = useExpandedMode();
    const { objectsBeingParsed } = useDocumentation();
    const isLoading = objectsBeingParsed?.[item.objectType];

    const onClick = useCallback(
      (event: any, tab?: DocumentationTabTypes) => {
        event.stopPropagation();
        onCardClick(item, tab);
      },
      [item, onCardClick],
    );

    const leftContent = useMemo(
      () => (
        <Box display="inline-flex" minWidth={0} gap={1} alignItems="center">
          <ObjectTypeChip
            dataTestId="object-card"
            height="24px"
            typographyVariant="body"
            label={item.label}
            objectType={item.objectType}
          />
          {isExpandedMode && !item.pills && (
            <Box className={isLoading ? '' : 'button-wrapper'}>
              <AddObjectButton object={item} isLoading={isLoading} />
            </Box>
          )}
        </Box>
      ),
      [item, isLoading, isExpandedMode],
    );

    const rightContent = useMemo(() => {
      const pills = item.pills ?? ({} as { [key: string]: number });
      const mappedPills = pillOptions.map((option) => ({
        ...option,
        key: option.key,
        value: pills[option.key] ?? 0,
      }));

      return displayPills ? (
        <PillsRow
          objectName={item.objectType}
          setHoveredPin={setHoveredPin}
          onClick={onClick}
          pills={mappedPills}
        />
      ) : (
        <>
          {!hideAddObjectButton && (
            <Box
              className="button-wrapper"
              sx={{
                display: 'flex',
                '& button': {
                  whiteSpace: 'nowrap',
                },
              }}
            >
              {!isLoading && !isExpandedMode && <AddObjectButton object={item} />}
            </Box>
          )}

          {isLoading && !isExpandedMode && <AddObjectButton isLoading object={item} />}
        </>
      );
    }, [
      item,
      isLoading,
      displayPills,
      hideAddObjectButton,
      onClick,
      setHoveredPin,
      isExpandedMode,
    ]);

    return (
      <Box
        sx={{
          '& .button-wrapper': {
            display: 'none',
          },
          '&:hover .button-wrapper': {
            display: 'block',
          },
        }}
      >
        <ListElementWrapper
          sx={{ height: '56px' }}
          leftContent={leftContent}
          rightContent={rightContent}
          onWrapperClick={onClick}
          elementId={item.objectType}
        />
      </Box>
    );
  },
);

const PillsRow = ({
  pills,
  onClick,
}: {
  objectName: string;
  pills: PillPropsExtended[];
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tab?: DocumentationTabTypes,
  ) => void;
  setHoveredPin?: Dispatch<SetStateAction<HoveredPin | undefined>>;
}) => {
  const _onPillClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, key?: DocumentationTabTypes) =>
      onClick(event, key),
    [onClick],
  );
  //TODO
  // const _onHover = useCallback(
  //   (isEmpty?: boolean) => {
  //     onHover &&
  //       onHover(isEmpty ? undefined : { hoveredObjectTypeName: objectName, hoveredTab: key });
  //   },
  //   [key, onHover, objectName],
  // );
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} pl={1}>
      {pills.map((pill) => {
        const { key, ..._pill } = pill;
        return (
          <Pill
            key={key}
            {..._pill}
            backgroundColor={colors.storm[70]}
            onClick={(e) => _onPillClick(e, key as DocumentationTabTypes)}
          />
        );
      })}
    </Box>
  );
};
