import { useReactFlow } from 'reactflow';
import { addPlacingTemplate } from '../../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { PanelConsumer, usePanels } from '../../../../panels/PanelsContext';
import { TemplatesDialogContent } from '../../../funnel-map-flow/templates/dialog/TemplatesDialog';
import { templatesPanelType } from '../../../funnel-map-flow/templates/dialog/panelTypes';
import { useDispatch } from 'react-redux';
import { TEMPLATES_DIALOG_WIDTH } from '../../../funnel-map-flow/templates/dialog/consts';
import { ConfigurationCanvasLeftPanel } from '../../../configuration-canvas-panel';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';
import { useRunOnceWhenTruthy } from '../../../../common/useRunOnceWhenTruthy';

export const CanvasUnitedTemplatesDialog = () => {
  const { canvasFunnelMap, canvasCrmOrgId: crmOrgId } = useConfigurationCanvas();
  const dispatch = useDispatch();
  const { getZoom, zoomTo } = useReactFlow();
  const isFunnelMapEmpty = Object.keys(canvasFunnelMap.funnelsData).length === 0;
  const { maybeOpenPanel } = usePanels();

  useRunOnceWhenTruthy(() => {
    maybeOpenPanel({ panelType: templatesPanelType });
  }, isFunnelMapEmpty);

  return (
    <PanelConsumer panelType={templatesPanelType}>
      {({ maybeClosePanel, isOpen }) => (
        <>
          {isOpen && (
            <ConfigurationCanvasLeftPanel width={TEMPLATES_DIALOG_WIDTH}>
              <TemplatesDialogContent
                isOpen={isOpen}
                crmOrgId={crmOrgId}
                onAddTemplate={(template, useFieldMapping) => {
                  const zoom = getZoom();
                  if (zoom > 0.3) {
                    zoomTo(0.3, {
                      duration: 1000,
                    });
                  }
                  dispatch(addPlacingTemplate({ template, useFieldMapping }));
                }}
                closeDialog={() => maybeClosePanel()}
              />
            </ConfigurationCanvasLeftPanel>
          )}
        </>
      )}
    </PanelConsumer>
  );
};
