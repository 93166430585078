import { Button } from '@sweep-io/sweep-design';
import { useExpandedMode } from './ReserveSpaceForPanelsCtx';
import Box from '@mui/material/Box/Box';
import { ACTIONS_EVENTS } from '../../../../services/events';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';

export const ExpandedModeButton = ({
  onExpandedModeButtonClickCb,
}: {
  onExpandedModeButtonClickCb?: (isExpanded: boolean) => void;
}) => {
  const sendBiEvent = useSendBiEvent();

  const { isExpandedMode, setIsExpandedMode } = useExpandedMode();
  const text = isExpandedMode ? 'Canvas View' : 'Full Page';
  const iconName = isExpandedMode ? 'Minimize' : 'Expand';

  return (
    <Box sx={{ button: { whiteSpace: 'nowrap' } }}>
      <Button
        startIconName={iconName}
        variant="outlined"
        size="tiny"
        onClick={() => {
          const state = !isExpandedMode;
          setIsExpandedMode(state);
          onExpandedModeButtonClickCb && onExpandedModeButtonClickCb(state);

          const actionName = state
            ? ACTIONS_EVENTS.documentationFullPageClick
            : ACTIONS_EVENTS.documentationCanvasViewClick;

          sendBiEvent({
            name: actionName,
          });
        }}
      >
        {text}
      </Button>
    </Box>
  );
};
