export const getFunnelIdBySweepStage = (funnelsData: FunnelsData): { [stageId: string]: string } =>
  Object.keys(funnelsData)
    .map((funnelId) =>
      funnelsData[funnelId].funnelDetails.stages.map((stage) => ({
        funnelId,
        stageId: stage._stageId,
      })),
    )
    .flat()
    .reduce(
      (
        prev: {
          [stageId: string]: string;
        },
        curr,
      ) => {
        prev[curr.stageId] = curr.funnelId;
        return prev;
      },
      {},
    );
