import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { addFunnel, removeFunnel, setInitialFunnelMap } from './multiFunnelFlowCanvasReducer';
import uniq from 'lodash/uniq';
import { TemplateInterface } from '../components/pages/funnel-map-flow/templates/templatesTypes';
import {
  NewFunnelTransientData,
  PlacingTemplate,
} from '../components/funnel-map-canvas/funnelMapCanvasTypes';

export interface MultiFunnelFlowPageNoHistoryState {
  usedInFunnelMapsDialog?: {
    mapsList?: PartialFunnelMap[];
    isLoading: boolean;
    isOpen: boolean;
  };
  funnelStageMetadata: StageMetadata[];
  importFunnelTransient?: Funnel;
  newFunnelTransientData?: NewFunnelTransientData;
  placingTemplate?: PlacingTemplate;
  hasUnsavedChanges?: boolean;
  isCreateOrAddDialogOpen?: boolean;
  isPageLoading?: boolean;
  hasFetchingErrors?: boolean;
  deployment?: FunnelDeployment;
  isFreshlyCreatedFunnelMap?: boolean;
  isLoadingCursor?: boolean;
}

const initialState: MultiFunnelFlowPageNoHistoryState = {
  funnelStageMetadata: [],
  isPageLoading: true,
};

const multiFunnelFlowPageSlice = createSlice({
  name: 'multiFunnelFlowPageNoHistory',
  initialState,
  reducers: {
    setIsUsedInFunnelMapsDialogOpen: (state) => {
      state.usedInFunnelMapsDialog = {
        isOpen: true,
        isLoading: true,
      };
    },
    setIsPageLoading: (state, action: PayloadAction<{ isPageLoading: boolean }>) => {
      state.isPageLoading = action.payload.isPageLoading;
    },
    setHasFetchingErrors: (state, action: PayloadAction<{ hasErrors: boolean }>) => {
      state.hasFetchingErrors = action.payload.hasErrors;
    },
    closeIsUsedInFunnelMapsDialog: (state) => {
      state.usedInFunnelMapsDialog = undefined;
    },
    setMapsForIsUsedInFunnelMaps: (
      state,
      action: PayloadAction<{
        funnelMaps: PartialFunnelMap[];
      }>,
    ) => {
      state.usedInFunnelMapsDialog = {
        isOpen: true,
        isLoading: false,
        mapsList: action.payload.funnelMaps,
      };
    },
    setFunnelStageMetadata: (
      state,
      action: PayloadAction<{
        stageMetadata?: StageMetadata[];
      }>,
    ) => {
      state.funnelStageMetadata = action.payload.stageMetadata ?? [];
    },
    updateStageMetadataForLeadingField: (
      state,
      action: PayloadAction<{
        leadingFieldStageMetadata?: StageMetadata[];
      }>,
    ) => {
      const { leadingFieldStageMetadata } = action.payload;

      if (leadingFieldStageMetadata && leadingFieldStageMetadata.length) {
        const currentLeadingFieldIds = uniq(
          leadingFieldStageMetadata.map((stageMetadata) => stageMetadata.leadingFieldId),
        );
        const currentStageMetadata = state.funnelStageMetadata;
        const oldStageMetadataWithoutCurrentLeadingField =
          currentStageMetadata &&
          currentStageMetadata.filter(
            (stageMetadata) => !currentLeadingFieldIds.includes(stageMetadata.leadingFieldId),
          );

        state.funnelStageMetadata = [
          ...(Array.isArray(oldStageMetadataWithoutCurrentLeadingField)
            ? oldStageMetadataWithoutCurrentLeadingField
            : []),
          ...leadingFieldStageMetadata,
        ];
      }
    },
    addTransientFunnel: (state, action: PayloadAction<{ transientFunnel: Funnel }>) => {
      state.importFunnelTransient = action.payload.transientFunnel;
    },
    addPlacingTemplate: (
      state,
      action: PayloadAction<{ template: TemplateInterface; useFieldMapping: boolean }>,
    ) => {
      const { template, useFieldMapping } = action.payload;
      state.placingTemplate = { template: template, useFieldMapping };
    },
    removeAllTransientFunnelData: (state) => {
      delete state.importFunnelTransient;
      delete state.newFunnelTransientData;
      delete state.placingTemplate;
    },
    setNewFunnelTransientData: (
      state,
      action: PayloadAction<{
        name: string;
        leadingObject: FunnelLeadingObject;
        position: FunnelMapPosition;
        isPlacingFunnel: boolean;
      }>,
    ) => {
      const { name, leadingObject, position, isPlacingFunnel } = action.payload;
      state.newFunnelTransientData = {
        isPlacingFunnel,
        name,
        leadingObject,
        position,
      };
    },
    setNewFunnelTransientDataStage: (
      state,
      action: PayloadAction<{
        stage: SweepStage;
      }>,
    ) => {
      const { stage } = action.payload;
      if (state.newFunnelTransientData) {
        state.newFunnelTransientData.stage = stage;
      }
    },
    dropTransientNewFunnelInPlace: (
      state,
      action: PayloadAction<{ position: FunnelMapPosition }>,
    ) => {
      if (state.newFunnelTransientData) {
        state.newFunnelTransientData.position = action.payload.position;
        state.newFunnelTransientData.isPlacingFunnel = false;
      }
    },
    removeNewFunnelTransientData: (state) => {
      delete state.newFunnelTransientData;
    },
    setHasUnsavedChanges: (state, action: PayloadAction<{ hasUnsavedChanges: boolean }>) => {
      state.hasUnsavedChanges = action.payload.hasUnsavedChanges;
    },
    setIsCreateOrAddDialogOpen: (state, action: PayloadAction<{ isOpen: boolean }>) => {
      state.isCreateOrAddDialogOpen = action.payload.isOpen;
    },
    setDeployment: (state, action: PayloadAction<{ deployment: FunnelDeployment }>) => {
      state.deployment = action.payload.deployment;
    },
    setFunnelMapIsFreshlyCreated: (state, action: PayloadAction<boolean>) => {
      state.isFreshlyCreatedFunnelMap = action.payload;
    },
    setIsLoadingCursor: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCursor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addFunnel.type, (state) => {
      delete state.newFunnelTransientData;
    }),
      builder.addCase(removeFunnel.type, (state) => {
        delete state.newFunnelTransientData;
      });
    builder.addCase(setInitialFunnelMap.type, (state) => {
      delete state.hasUnsavedChanges;
    });
  },
});

export const selectIsUsedInFunnelMapsDialogOpen = (state: RootState) =>
  Boolean(state.multiFunnelFlowPage.usedInFunnelMapsDialog?.isOpen);

export const selectMapsFromIsUsedInFunnelMaps = (state: RootState) =>
  state.multiFunnelFlowPage.usedInFunnelMapsDialog?.mapsList;

export const selectIsUsedInFunnelMapsLoading = (state: RootState) =>
  state.multiFunnelFlowPage.usedInFunnelMapsDialog?.isLoading;

export const selectFunnelStagesMetadata = (state: RootState) =>
  state.multiFunnelFlowPage.funnelStageMetadata;

export const selectImportFunnelTransient = (state: RootState) =>
  state.multiFunnelFlowPage.importFunnelTransient;

export const selectPlacingTemplate = (state: RootState) =>
  state.multiFunnelFlowPage.placingTemplate;

export const selectNewFunnelTransientData = (state: RootState) =>
  state.multiFunnelFlowPage.newFunnelTransientData;

export const selectHasUnsavedChanges = (state: RootState) =>
  state.multiFunnelFlowPage.hasUnsavedChanges;

export const selectIsCreateOrAddFunnelDialogOpen = (state: RootState) =>
  state.multiFunnelFlowPage.isCreateOrAddDialogOpen;

export const selectIsPageLoading = (state: RootState) => state.multiFunnelFlowPage.isPageLoading;
export const selectHasFetchingErrors = (state: RootState) =>
  state.multiFunnelFlowPage.hasFetchingErrors;

export const selectDeployment = (state: RootState) => state.multiFunnelFlowPage.deployment;

export const selectIsFreshlyCreatedFunnelMap = (state: RootState) =>
  Boolean(state.multiFunnelFlowPage.isFreshlyCreatedFunnelMap);

export const selectIsLoadingCursor = (state: RootState) =>
  Boolean(state.multiFunnelFlowPage.isLoadingCursor);

export const {
  setFunnelStageMetadata,
  updateStageMetadataForLeadingField,
  addTransientFunnel,
  removeAllTransientFunnelData,
  closeIsUsedInFunnelMapsDialog,
  setIsUsedInFunnelMapsDialogOpen,
  setMapsForIsUsedInFunnelMaps,
  setNewFunnelTransientData,
  removeNewFunnelTransientData,
  dropTransientNewFunnelInPlace,
  setNewFunnelTransientDataStage,
  setHasUnsavedChanges,
  setIsCreateOrAddDialogOpen,
  setIsPageLoading,
  setHasFetchingErrors,
  setDeployment,
  setFunnelMapIsFreshlyCreated,
  addPlacingTemplate,
  setIsLoadingCursor,
} = multiFunnelFlowPageSlice.actions;

export default multiFunnelFlowPageSlice.reducer;
