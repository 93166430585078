import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { AutomationsTemplatesList } from './AutomationsTemplatesList';
import { AutoTemplatesStruct } from '../../constants/automationTemplates';
import { PageTemplateCanvasDialog, PageTemplateFullPage } from '../pages/pages-templates';

interface AutomationsTemplatesWrapperProps {
  crmOrgId: string;
  automationVariant: AutomationFormVariant;
  onSelectTemplate: (item: AutoTemplatesStruct) => void;
  createEmptyButton: JSX.Element;
  isFullPage: boolean;
  title: string;
  onCancel?: () => any; //needed only when "isFullPage" is "false"
}

export const AutomationsTemplatesWrapper = ({
  automationVariant,
  crmOrgId,
  createEmptyButton,
  onSelectTemplate,
  isFullPage,
  title,
  onCancel,
}: AutomationsTemplatesWrapperProps) => {

  const templatesList = (
    <AutomationsTemplatesList
      crmOrgId={crmOrgId}
      automationVariant={automationVariant}
      onSelectTemplate={onSelectTemplate}
      createEmptyButton={createEmptyButton}
    />
  );

  return isFullPage ? (
    <PageTemplateFullPage title={title}>
      {templatesList}
    </PageTemplateFullPage>
  ) : (
    <PageTemplateCanvasDialog
      isOpen={true}
      onCancel={onCancel}
      title={title}
    >
      {templatesList}
    </PageTemplateCanvasDialog>
  );
};
