import { Box } from '@mui/material';
import { ConfigurationType, ConfigurationToName, NewDependencyProps } from './types';
import { Fragment } from 'react';
import { DialogLoader } from '../../common/dialogs/DialogLoader';
import { DefaultConfigurationTable } from '../configuration-item-tables/DefaultConfigurationTable';
import { FieldsTable } from '../configuration-item-tables/FieldsTable';
import { FlowsTable } from '../configuration-item-tables/FlowsTable';
import { NoButtonDefaultTable } from '../configuration-item-tables/NoButtonDefaultTable';
import { ObjectsTable } from '../configuration-item-tables/ObjectsTable';
import { TableHeaderVariant } from '../configuration-item-tables/shared/TableWithHeader';
import { setSfFieldName } from '../../pages/rollups/rollupHelpers';
import { FieldLabelWithNameInTooltip } from '../shared/FieldLabelWithNameInTooltip';
import { CpqTables } from '../configuration-item-tables/CpqTables';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { isItemActive } from '../helpers';

interface DependenciesTableItem {
  id: string;
  name: string;
  lastModified: string;
  label: string;
  type?: ConfigurationType;
  objectName?: string;
  objectLabel?: string;
  flowType?: string;
  isRollup?: boolean;
  isActive: boolean;
}

export interface DependenciesTableMap {
  [key: string]: DependenciesTableItem[];
}

interface DependenciesTableProps {
  headerVariant?: TableHeaderVariant;
  configurationItems: DependenciesTableMap;
  onChooseNewRule: (props: NewDependencyProps) => void;
  listViewCounter?: number;
  isLoadingDependency?: boolean;
  activeItemId?: string;
  crmOrgId: string;
  clearHistory?: boolean;
}

export const DependenciesTable = ({
  configurationItems,
  onChooseNewRule,
  headerVariant,
  listViewCounter,
  isLoadingDependency,
  activeItemId,
  clearHistory,
  crmOrgId,
}: DependenciesTableProps) => {
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId, useSfObjects: true });

  if (!configurationItems) {
    return <></>;
  }

  const configurationKeys = Object.keys(configurationItems);

  return (
    <Box
      sx={{
        '& .MuiTableBody-root .MuiTableRow-root': {
          '& .chevron-button': {
            opacity: 0,

            '& > .MuiButton-root': {
              minWidth: 0, //to fix table overflow
            },
          },

          '&:hover .chevron-button': {
            opacity: 1,
          },
        },
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <DialogLoader visible={isLoadingDependency} variant="light" />

      {configurationKeys.map((confKey, idx) => {
        const key = confKey as ConfigurationType;
        const items = configurationItems[key];

        if (!items.length) {
          return <Fragment key={`no-configuration-items_${idx}`} />;
        }

        switch (key) {
          case ConfigurationType.fields:
            return (
              <FieldsTable
                key={key}
                onFieldClick={onChooseNewRule}
                items={items.map((item) => ({
                  id: item.id ?? setSfFieldName(item.name, item.objectName ?? ''),
                  name: item.name,
                  objectName: item.objectName ?? '',
                  objectLabel: objectTypesByName?.[item.objectName ?? '']?.label ?? item.objectName,
                  label: (
                    <FieldLabelWithNameInTooltip
                      key={item.id}
                      label={item.label}
                      name={item.name}
                    />
                  ),
                  type: item.type as any, //to display in the table any relevant field type
                  isRollup: !!item.isRollup,
                }))}
                headerVariant={headerVariant}
                shortListViewCounter={listViewCounter}
                activeItemId={activeItemId}
                clearHistory={clearHistory}
              />
            );

          case ConfigurationType.layouts:
            return (
              <DefaultConfigurationTable
                headerVariant={headerVariant}
                tableHeader={ConfigurationToName[key]}
                items={items}
                onItemClick={onChooseNewRule}
                key={key}
                shortListViewCounter={listViewCounter}
                parentType={key}
                activeItemId={activeItemId}
                clearHistory={clearHistory}
              />
            );

          case ConfigurationType.emailTemplates:
          case ConfigurationType.reports:
            return (
              <NoButtonDefaultTable
                key={key}
                tableHeader={ConfigurationToName[key]}
                items={items}
                headerVariant={headerVariant}
                shortListViewCounter={listViewCounter}
              />
            );

          case ConfigurationType.flows:
            return (
              <FlowsTable
                key={key}
                items={items.map((item) => ({
                  id: item.id,
                  name: item.name,
                  label: item.label,
                  lastModified: item.lastModified,
                  type: item.flowType ?? item.type ?? '',
                  objectName: item.objectName ?? '',
                  isActive: isItemActive(item),
                }))}
                onFlowClick={onChooseNewRule}
                shortListViewCounter={listViewCounter}
                headerVariant={headerVariant}
                activeItemId={activeItemId}
                clearHistory={clearHistory}
              />
            );

          case ConfigurationType.objects:
            return (
              <ObjectsTable
                key={key}
                items={items.map((item) => ({
                  id: item.name,
                  name: item.name,
                  label: item.label,
                }))}
                onItemClick={onChooseNewRule}
                shortListViewCounter={listViewCounter}
                headerVariant={headerVariant}
                clearHistory={clearHistory}
              />
            );

          case ConfigurationType.cpqData:
            return (
              <CpqTables
                crmOrgId={crmOrgId}
                key={key}
                items={items}
                onItemClick={onChooseNewRule}
                headerVariant={headerVariant}
                clearHistory={!!clearHistory}
                activeItemId={activeItemId}
              />
            );

          default:
            return (
              <DefaultConfigurationTable
                headerVariant={headerVariant}
                tableHeader={ConfigurationToName[key] ?? key}
                items={items}
                onItemClick={onChooseNewRule}
                key={key}
                shortListViewCounter={listViewCounter}
                parentType={key}
                activeItemId={activeItemId}
                clearHistory={clearHistory}
              />
            );
        }
      })}
    </Box>
  );
};
