import { useCallback, useMemo } from 'react';
import { Tag, useUserInputsApiFacade } from '../../../../../apis/facades/useUserInputsApiFacade';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTagsList,
  addTag,
  updateTag,
  deleteTag,
  setTagConfigurations,
  selectTagsList,
  clearTagConfigurations,
} from '../../../../../reducers/tagsReducer';
import { telemetry } from '../../../../../telemetry';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { TagType } from '../../header/TagsHeader';
import { UserInputTypes } from '@server/user-inputs';
import { selectAccountUsers } from '../../../../../reducers/accountUsersReducer';
import {
  selectLayoutsByObjectName,
  selectParsedConfigurationItems,
} from '../../../../../reducers/documentationReducer';
import { selectEnvironments } from '../../../../../reducers/global/globalReducer';
import { selectCrmOrgRollups } from '../../../../../reducers/global/rollupReducers';
import { selectParsedFields, selectParsedRecordTypes } from '../../../../../reducers/parserReducer';
import { getConfigurationItem } from '../../hooks/useGetConfigurationItem';
import { parseIdToValueAndNameToLabel } from './utils';
import { RecordTypesLabelsByNameAndObjectName } from '../../../types';
import groupBy from 'lodash/groupBy';
import { ConfigurationItemMap } from '../../../configuration-item-tables/shared/helpers';
import { useGetFilteredParsedRules } from '../../../selected-object/filters/useGetFilteredParsedRules';

export const useTags = (
  crmOrgId: string,
  recordTypeNamesUsedInCanvas?: RecordTypesLabelsByNameAndObjectName,
) => {
  const dispatch = useDispatch();
  const {
    get_tags,
    post_tag,
    patch_tag,
    delete_tag,
    get_tagConfigurations,
    delete_userConfigurations,
    get_userConfigurations,
  } = useUserInputsApiFacade();
  const { addNotification } = useSweepNotifications();

  const objectLayouts = useSelector(selectLayoutsByObjectName(crmOrgId));
  const parsedConfigurationItems = useSelector(selectParsedConfigurationItems(crmOrgId));
  const parsedFields = useSelector(selectParsedFields);
  const rollups = useSelector(selectCrmOrgRollups(crmOrgId));
  const globalEnvironments = useSelector(selectEnvironments);
  const tags = useSelector(selectTagsList);
  const users = useSelector(selectAccountUsers);
  const _parsedUsers = parseIdToValueAndNameToLabel(users ?? []);
  const parsedRecordTypes = useSelector(selectParsedRecordTypes);
  const parsedRules = useGetFilteredParsedRules();

  const { automations, alerts, matching, dedup, assignments, scheduledAssignments, sweepFields } =
    globalEnvironments?.[crmOrgId]?.data ?? {};

  const allLayouts = useMemo(
    () =>
      Object.values(objectLayouts ?? {})
        .map((layouts) => layouts.layouts)
        .flat(),
    [objectLayouts],
  );

  const makeNotUndefined = (items?: AutomationStructureNew[]) => items ?? [];
  const mergedAutomations = useMemo(
    () =>
      groupBy(
        [
          ...makeNotUndefined(automations),
          ...makeNotUndefined(alerts),
          ...makeNotUndefined(assignments),
          ...makeNotUndefined(matching),
          ...makeNotUndefined(dedup),
          ...makeNotUndefined(scheduledAssignments),
        ].flat(),
        'automationId',
      ),
    [automations, alerts, matching, dedup, assignments, scheduledAssignments],
  );

  const fetchTags = useCallback(async () => {
    try {
      const tags = await get_tags();
      dispatch(setTagsList({ tags }));
    } catch (error) {
      telemetry.captureError(error);
    }
  }, [get_tags, dispatch]);

  const clearTagOrUserConfigurations = useCallback(() => {
    dispatch(clearTagConfigurations());
  }, [dispatch]);

  const _getConfigurationItem = useCallback(
    (configurationId: string) => {
      return getConfigurationItem({
        id: configurationId,
        tags,
        _parsedUsers,
        parsedRules,
        allLayouts,
        rollups,
        recordTypeNamesUsedInCanvas: recordTypeNamesUsedInCanvas ?? {},
        parsedFields,
        parsedConfigurationItems,
        parsedRecordTypes,
        sweepFields,
        mergedAutomations,
      });
    },
    [
      tags,
      _parsedUsers,
      parsedRules,
      allLayouts,
      rollups,
      recordTypeNamesUsedInCanvas,
      parsedFields,
      parsedConfigurationItems,
      parsedRecordTypes,
      sweepFields,
      mergedAutomations,
    ],
  );

  const createConfigurationItemMap = useCallback(
    (response: string[]) => {
      return groupBy(
        response.map((itemId) => _getConfigurationItem(itemId)).filter((item) => !!item),
        'parentType',
      ) as ConfigurationItemMap;
    },
    [_getConfigurationItem],
  );

  const fetchTagConfigurations = useCallback(
    async (tagId: string, crmOrgId: string) => {
      try {
        const response = await get_tagConfigurations(tagId, crmOrgId);
        dispatch(
          setTagConfigurations({
            configurations: {
              [UserInputTypes.Tags]: createConfigurationItemMap(response),
              [UserInputTypes.Owner]: createConfigurationItemMap([]),
              [UserInputTypes.Collaborators]: createConfigurationItemMap([]),
            },
            tagId,
          }),
        );
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [get_tagConfigurations, dispatch, createConfigurationItemMap],
  );

  const fetchUserConfigurations = useCallback(
    async (tagId: string, crmOrgId: string) => {
      try {
        const response = await get_userConfigurations(tagId, crmOrgId);
        dispatch(
          setTagConfigurations({
            configurations: {
              [UserInputTypes.Tags]: createConfigurationItemMap([]),
              [UserInputTypes.Owner]: createConfigurationItemMap(response[UserInputTypes.Owner]),
              [UserInputTypes.Collaborators]: createConfigurationItemMap(
                response[UserInputTypes.Collaborators],
              ),
            },
            tagId,
          }),
        );
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [get_userConfigurations, dispatch, createConfigurationItemMap],
  );

  const onAddTag = useCallback(
    async (tag: Omit<Tag, 'id'>) => {
      try {
        const tagId = await post_tag(tag);
        dispatch(addTag({ tag: { ...tag, id: tagId } }));
        return tagId;
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [post_tag, dispatch],
  );

  const onUpdateTag = useCallback(
    async (tag: Tag) => {
      try {
        dispatch(updateTag({ tag }));
        await patch_tag(tag);
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [patch_tag, dispatch],
  );

  const onDeleteTag = useCallback(
    async (tagId: string, tagType: TagType) => {
      try {
        if (tagType === TagType.tag) {
          await delete_tag(tagId);
        } else {
          await delete_userConfigurations(tagId);
        }
        dispatch(deleteTag({ tagId }));
        addNotification({
          message: `Tag successfully removed`,
          variant: SweepNotificationVariant.Success,
        });
      } catch (error) {
        telemetry.captureError(error);
        addNotification({
          message: `Failed to delete tag due to technical issues`,
          keepOpen: true,
          variant: SweepNotificationVariant.Error,
        });
      }
    },
    [delete_tag, delete_userConfigurations, addNotification, dispatch],
  );

  return {
    fetchTags,
    onAddTag,
    onDeleteTag,
    onUpdateTag,
    fetchTagConfigurations,
    fetchUserConfigurations,
    clearTagOrUserConfigurations,
  };
};
