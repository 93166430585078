import { BasePluginPanel } from '../../../panel/BasePluginPanel';
import { useDispatch, useSelector } from 'react-redux';
import {
  removePlugin,
  selectFunnelPlugin,
  selectFunnelsData,
  setPlugin,
} from '../../../../../../../reducers/multiFunnelFlowCanvasReducer';
import { PluginTypes } from '../../../../../../../types/enums/PluginTypes';
import { PluginDynamicPathSvg } from '../../icons';
import { MaybeClosePanelFunc } from '../../../../../../panels/types';
import { DynamicPathStages } from './path/DynamicPathStages';
import { useCallback, useMemo, useState } from 'react';
import { DynamicPathStageContent } from './DynamicPathStageContent';
import {
  generatePath,
  getExitCriteria,
  getStage,
  initializeExitCriteria,
  initializePluginStages,
  mapIdToStageName,
  slicePath,
  updateStage,
} from './helpers';
import SweepStagesModel from '../../../../../../../models/stagesModel';
import Box from '@mui/material/Box';
import { StageType } from '../../../../../../../types/enums/StageType';

const DynamicPathPanel = ({
  funnelId,
  crmOrgId,
  anchorEl,
  maybeClosePanel,
  isReadOnly,
}: DynamicPathPluginData & {
  maybeClosePanel: MaybeClosePanelFunc;
}) => {
  const dispatch = useDispatch();
  const plugin = useSelector(selectFunnelPlugin(funnelId, PluginTypes.DYNAMIC_PATH));
  const _plugin = useMemo(
    () =>
      (plugin || {
        id: PluginTypes.DYNAMIC_PATH,
        stages: [],
      }) as DynamicPathPlugin,
    [plugin],
  );

  if (!_plugin) throw new Error('Plugin not found');

  const funnelsData = useSelector(selectFunnelsData);
  const funnel = funnelsData?.[funnelId];

  if (!funnel) throw new Error('Funnel not found id: ' + funnelId);

  const funnelStages =
    funnel.funnelDetails.stages.filter((stage) => stage.stageType !== StageType.LOST) ?? [];
  const objectName = funnel.funnelDetails.leadingObject.objectName;
  const stagesModel = new SweepStagesModel(funnelStages ?? []);

  // By product definition we always take first stage that was deduced and follow its path
  // (even if there are multiple first stages)
  const stageOrder = stagesModel.deduceStageOrder();
  const [path, setPath] = useState(
    generatePath({
      stages: funnelStages,
      order: stageOrder,
    }),
  );

  const mappedIdToStageName = mapIdToStageName(funnelStages);

  const [selectedStageId, setSelectedStageId] = useState(stageOrder[0]);
  const selectedStage = funnelStages.find((stage) => stage._stageId === selectedStageId);

  const [selectedExitCriteriaId, setSelectedExitCriteriaId] = useState(
    selectedStage?.exitCriteria[0]?._exitCriteriaId,
  );

  const pluginStage = getStage(_plugin, selectedStageId, selectedExitCriteriaId);
  const pluginStages = initializePluginStages(_plugin, selectedStageId, selectedExitCriteriaId);

  const onPluginSave = useCallback(
    (newPlugin: DynamicPathPlugin) => {
      //Plugins are part of funnelDetails - it's saved using autosave
      dispatch(setPlugin({ plugin: newPlugin, funnelId }));
    },
    [dispatch, funnelId],
  );

  const onAdditionalFieldsChange = useCallback(
    (_additionalFields: AdditionalField[]) => {
      const stages = updateStage({
        stages: pluginStages,
        stageId: selectedStageId,
        key: 'additionalFields',
        value: _additionalFields,
      });

      onPluginSave({ ..._plugin, stages });
    },
    [selectedStageId, _plugin, pluginStages, onPluginSave],
  );

  const onDescriptionChange = useCallback(
    (newDescription: string) => {
      const stages = updateStage({
        stages: pluginStages,
        stageId: selectedStageId,
        key: 'description',
        value: newDescription,
      });
      onPluginSave({ ..._plugin, stages });
    },
    [_plugin, selectedStageId, pluginStages, onPluginSave],
  );

  const onChangePath = (
    _stageIdToReplace: string,
    _stageIdToReplaceWith: string,
    _stageIdPrevious: string,
  ) => {
    const slicedPath = slicePath(path, _stageIdPrevious, _stageIdToReplace);
    const newPath = generatePath({
      stages: funnelStages,
      order: stageOrder,
      currentStagesInPath: slicedPath,
      stageIdToReplaceWith: _stageIdToReplaceWith,
    });

    setSelectedStageId(_stageIdPrevious);
    setSelectedExitCriteriaId(
      getExitCriteria(slicedPath[slicedPath.length - 1], _stageIdToReplaceWith),
    );
    setPath(newPath);
  };

  const onGateGuidanceChange = useCallback(
    (newGuidance: string) => {
      const stages = pluginStages.map((stage) => {
        if (stage.stageId === selectedStageId) {
          const isExitCriteria = stage.exitCriteria.find(
            (ec) => ec.exitCriteriaId === selectedExitCriteriaId,
          );
          let exitCriteria = [...stage.exitCriteria];

          if (!isExitCriteria) {
            exitCriteria = initializeExitCriteria(
              _plugin.stages,
              selectedStageId,
              selectedExitCriteriaId,
            );
          }

          return {
            ...stage,
            exitCriteria: exitCriteria.map((ec) =>
              ec.exitCriteriaId === selectedExitCriteriaId ? { ...ec, guidance: newGuidance } : ec,
            ),
          };
        }

        return stage;
      });
      onPluginSave({ ..._plugin, stages });
    },
    [selectedStageId, _plugin, selectedExitCriteriaId, pluginStages, onPluginSave],
  );

  const onRemove = useCallback(() => {
    maybeClosePanel({
      onCloseConfirm: () => {
        dispatch(removePlugin({ funnelId, pluginId: PluginTypes.DYNAMIC_PATH }));
      },
    });
  }, [dispatch, funnelId, maybeClosePanel]);

  return (
    <BasePluginPanel
      anchorEl={anchorEl}
      maybeClosePanel={maybeClosePanel}
      pluginIcon={<PluginDynamicPathSvg />}
      title="Dynamic Path Plugin"
      onRemove={onRemove}
    >
      <Box sx={{ width: '936px' }}>
        <DynamicPathStages
          key={'dynamic_stages_' + path.length}
          onChangePath={onChangePath}
          onStageSelect={(selectedStageId, selectedExitCriteriaId) => {
            setSelectedStageId(selectedStageId);
            setSelectedExitCriteriaId(selectedExitCriteriaId);
          }}
          stages={path}
          objectName={objectName ?? ''}
          selectedStageId={selectedStageId}
          mappedIdToStageName={mappedIdToStageName}
        />

        {pluginStage && selectedStage && (
          <DynamicPathStageContent
            key={selectedExitCriteriaId ?? selectedStageId}
            isReadOnly={isReadOnly}
            selectedStageId={selectedStageId}
            mappedIdToStageName={mappedIdToStageName}
            stageExitCriteria={selectedStage.exitCriteria}
            selectedExitCriteriaId={selectedExitCriteriaId ?? ''}
            onAdditionalFieldsChange={onAdditionalFieldsChange}
            onGateGuidanceChange={onGateGuidanceChange}
            objectName={objectName ?? ''}
            pluginStage={pluginStage}
            onDescriptionChange={onDescriptionChange}
            crmOrgId={crmOrgId}
          />
        )}
      </Box>
    </BasePluginPanel>
  );
};

export { DynamicPathPanel };
