import { CreateEditDialogButtons } from './CreateEditDialogButtons';
import {
  CreateEditDialogHeaderBase,
  CreateEditDialogHeaderBaseProps,
} from './CreateEditDialogHeaderBase';

export interface CreateEditDialogHeaderProps extends CreateEditDialogHeaderBaseProps {
  buttonContent?: React.ReactNode;
  buttonStartIcon?: string;
  onCancelClick?: () => any;
  onButtonClick: (any: any) => any;
  isButtonLoading?: boolean;
  buttonDisabled?: boolean;
  showCancelButton?: boolean;
  rightSideContent?: React.ReactNode;
}

export const CreateEditDialogHeader = ({
  buttonContent,
  buttonStartIcon,
  onButtonClick,
  buttonDisabled,
  isButtonLoading,
  showCancelButton,
  onCancelClick,
  rightSideContent,
  ...restProps
}: CreateEditDialogHeaderProps) => {
  return (
    <CreateEditDialogHeaderBase
      {...restProps}
      rightSideContent={
        <>
          {rightSideContent}
          <CreateEditDialogButtons
            confirmButtonContent={buttonContent}
            confirmButtonStartIcon={buttonStartIcon}
            onConfirm={onButtonClick}
            confirmButtonDisabled={buttonDisabled}
            showCancelButton={showCancelButton}
            onCancel={onCancelClick}
            isButtonLoading={isButtonLoading}
          />
        </>
      }
    />
  );
};
