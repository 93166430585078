import { Box, Menu, MenuItem } from '@mui/material';
import { Badge, Button, ExpandableTypography, Typography, colors } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DIMENSION_AVATAR_SMALL } from '../../../../constants';
import { selectUserById } from '../../../../reducers/accountUsersReducer';
import { open as openDeploymentChangeDialog } from '../../../../reducers/deploymentChangesUiReducer';
import { getCrmOrgById } from '../../environments/environmentsReducer';
import { selectFunnelMapFunnelById } from '../../../../reducers/multiFunnelFlowCanvasReducer';
import { AvatarPicture } from '../../../avatar/AvatarPicture';
import { humanizedTimeAgo } from '../../../comments/helper';
import usePermission from '../../../common/permissions/usePermission';
import { useRequestChangesApiFacade } from '../../../../apis/facades/useRequestChangesApiFacade';
import { useRunOnce } from '../../../common/useRunOnce';
import { selectRequestChangesByFunnelIds } from '../../../../reducers/requestChangesApiReducer';
import { getIsUserInactive } from '../../../../lib/getIsUserActive';

const RequestChangesButton = ({
  onClick,
  amountOfRequests,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  amountOfRequests: number;
}) => {
  return (
    // this an ugly css to move counter into button
    <Box
      display="flex"
      alignItems="center"
      sx={{
        '&': {
          display: 'block',
          position: 'relative',
        },
        '.MuiButton-root': {
          paddingRight: '38px',
        },
        'div:last-child': {
          position: 'absolute',
          right: '10px',
          top: '5px',
        },
      }}
    >
      <Button variant="outlined" type="error" size="small" onClick={onClick}>
        Deploy requests
      </Button>
      <Badge variant="secondary" label={amountOfRequests} />
    </Box>
  );
};

const RequestChangesList = ({
  isOpen,
  onClose,
  onSelect,
  anchorEl,
  changesList,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (changeSet: any) => void;
  anchorEl: HTMLElement | null;
  changesList: any[];
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      transformOrigin={{ horizontal: 0, vertical: -25 }}
    >
      {changesList
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((changeSet) => {
          return (
            <MenuItem key={changeSet.id}>
              <RequestChangeListItem changeSet={changeSet} onSelect={onSelect} />
            </MenuItem>
          );
        })}
    </Menu>
  );
};

const RequestChangeListItem = ({ changeSet, onSelect }: any) => {
  const createdAgo = humanizedTimeAgo(changeSet.createdAt ?? '');
  const author = useSelector(selectUserById(changeSet.createdById));
  const funnel = useSelector(selectFunnelMapFunnelById(changeSet.funnelId));
  const crmOrg = useSelector(getCrmOrgById(changeSet.crmOrgId));

  return (
    <Box
      mt={0.5}
      display="flex"
      width="100%"
      gap={2}
      maxWidth="480px"
      onClick={() => onSelect(changeSet)}
      sx={{
        cursor: 'pointer',
        '.MuiButton-root': {
          visibility: 'hidden',
        },
        '&:hover': {
          backgroundColor: colors.grey[100],
          '.MuiButton-root': {
            visibility: 'visible',
          },
        },
      }}
    >
      <Box>
        <AvatarPicture
          avatar={{ emoji: author?.emoji, imageUrl: author?.imageUrl }}
          dimension={DIMENSION_AVATAR_SMALL}
          clickable={false}
          isInactive={getIsUserInactive(author?.status)}
        />
      </Box>
      <Box width="100%">
        <Box
          mb={1.5}
          sx={{
            display: 'inline-block',
            whiteSpace: 'break-spaces',
          }}
        >
          <Typography variant="body-bold">{author?.name}</Typography>
          <span> wants to deploy </span>
          <Typography variant="body-bold">{funnel?.name}</Typography>
          <span> to </span>
          <Typography variant="body-bold">{crmOrg?.name}</Typography>
        </Box>
        {changeSet.note && (
          <ExpandableTypography variant="body">
            {changeSet.note.replaceAll('\n', ' ')}
          </ExpandableTypography>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
          <Typography variant="caption" color={colors.grey[700]}>
            {createdAgo}
          </Typography>
          <Button variant="flat" endIconName="ChevronRight" size="small">
            Review
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const RequestChanges = ({ funnelIds = [] }: { funnelIds: string[] }) => {
  const dispatch = useDispatch();
  const { getRequestChanges } = useRequestChangesApiFacade();
  const changesList = useSelector(selectRequestChangesByFunnelIds(funnelIds)) ?? [];
  const [isAllowedToReviewRequestChanges] = usePermission(['edit:request-changes']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useRunOnce(() => {
    getRequestChanges(funnelIds);
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleSelect = (changeSet: any) => {
    const { snapshotId, crmOrgId } = changeSet;
    handleClose();
    dispatch(openDeploymentChangeDialog({ snapshotId, crmOrgId }));
  };

  const isButtonVisible = () => {
    return isAllowedToReviewRequestChanges && changesList.length > 0;
  };

  if (!isButtonVisible()) {
    return null;
  }

  return (
    <>
      <RequestChangesButton amountOfRequests={changesList.length} onClick={handleClick} />
      <RequestChangesList
        isOpen={open}
        onSelect={handleSelect}
        onClose={handleClose}
        anchorEl={anchorEl}
        changesList={changesList}
      />
    </>
  );
};

export default RequestChanges;
