import { useRef } from 'react';
import { colors, Typography } from '@sweep-io/sweep-design';
import Box from '@mui/material/Box/Box';
import { highlightMatch } from '../../../../lib/highlightMatch';
import { renderToStaticMarkup } from 'react-dom/server';
import { getIndentation } from './utils';
import unescape from 'lodash/unescape';

interface ListWithScrollProps {
  sourceCode: string;
  searchTxt?: string;
}

export const ListWithScroll = ({ sourceCode, searchTxt }: ListWithScrollProps) => {
  const childrenRefs = useRef<Array<HTMLDivElement | null>>([]);
  const parentRef = useRef<HTMLDivElement>();
  const lines = renderToStaticMarkup(sourceCode)?.trim().split('\n');

  //timeout used to run calculation after all refs exists
  //refs changes have no impact on useEffect hence i had troubles making it work as i expected
  setTimeout(() => {
    //find first existing child and scroll to the highlighted child
    const itemIdx = childrenRefs?.current.findIndex((child) => !!child);

    if (itemIdx && parentRef?.current) {
      parentRef?.current?.scrollTo({ top: itemIdx * 24 });
    }
  }, 150);

  return (
    <Box component="pre" pl={2.5} pr={2.5} height="100%" overflow="auto" ref={parentRef}>
      {lines?.map((line, index) => {
        // Calculate the indentation for each line
        const indentation = getIndentation(line);
        const highlightedLine = highlightMatch(unescape(line).trim(), searchTxt) ?? <></>;
        const isHighlighted = renderToStaticMarkup(highlightedLine).includes('bolded');

        return (
          <Box
            key={index}
            sx={{
              background: isHighlighted ? colors.lemon[100] : '',
              pl: `${indentation * 4}px`,
              minWidth: 0,
              '&  .bolded': { background: colors.spark, fontWeight: 400 },
            }} // Multiply by 4px for each space
            ref={(ref) =>
              (childrenRefs.current[index] = isHighlighted ? (ref as HTMLDivElement) : null)
            }
          >
            <Typography
              variant="body"
              whiteSpace="break-spaces"
              wordBreak="break-word"
              color={colors.grey[800]}
            >
              {highlightedLine}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
