import { useCallback } from 'react';
import { useTabFiltersFunctions } from './filters/useTabFilterFunctions';
import { SortOrder } from '../../common/types';
import {
  getSortOptions,
  getSelectedSortOption,
  sortByProperty,
  defaultSortOptionsMap,
} from './sortUtilsAndConsts';
import {
  ParsedRuleWithParentType,
  AutomationStructureWithParentType,
  SelectedObjectListElements,
} from '../types';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { useSelector } from 'react-redux';
import { selectSingleObjectTabSortKey } from '../../../reducers/documentationReducer';
import { sortAutomations } from '../../Automations/helper';
import { ParsedRecordType } from '../ParserTypes';

export const useSortElements = ({
  activeTab,
  isManagePackageInstalled,
  items,
}: {
  activeTab: DocumentationTabTypes;
  isManagePackageInstalled: boolean;
  items:
    | ParsedRuleWithParentType[]
    | FieldMetadataRecordProperties[]
    | AutomationStructureWithParentType[]
    | SelectedObjectListElements
    | ParsedRecordType[];
}) => {
  const documentationElementsSortKey = useSelector(selectSingleObjectTabSortKey);
  const _sortKey = documentationElementsSortKey ?? defaultSortOptionsMap[activeTab];

  const { registerSortChange } = useTabFiltersFunctions();

  const onSortChange = useCallback(
    (sortKey: string) => {
      registerSortChange({
        sortKey,
      });
    },
    [registerSortChange],
  );

  const _sortOptions = getSortOptions(activeTab, isManagePackageInstalled);
  const selectedSortOption = getSelectedSortOption(_sortOptions, _sortKey);

  let sortedItems = items;
  const itemsNotElementsMap = items as ParsedRuleWithParentType[] | FieldMetadataRecordProperties[];

  switch (activeTab) {
    case DocumentationTabTypes.CARDS_LIST:
      sortedItems = sortByProperty(items, _sortKey, selectedSortOption?.sortOrder ?? SortOrder.ASC);
      break;

    case DocumentationTabTypes.APEX:
    case DocumentationTabTypes.VALIDATION_RULES:
    case DocumentationTabTypes.SF_AUTOMATIONS:
    case DocumentationTabTypes.FIELDS:
    case DocumentationTabTypes.CPQ_DATA:
    case DocumentationTabTypes.RECORD_TYPES:
    case DocumentationTabTypes.LAYOUTS:
      sortedItems = sortByProperty(
        itemsNotElementsMap,
        _sortKey,
        selectedSortOption?.sortOrder ?? SortOrder.ASC,
      );
      break;

    case DocumentationTabTypes.SCHEDULED_ASSIGNMENTS:
    case DocumentationTabTypes.ASSIGNMENTS:
    case DocumentationTabTypes.MATCHING_DEDUPE:
    case DocumentationTabTypes.PLAYBOOK_ALERTS:
    case DocumentationTabTypes.SWEEP_AUTOMATIONS:
      const automations = items as AutomationStructureWithParentType[];
      sortedItems = sortAutomations({
        automations,
        sortBy: selectedSortOption?.value ?? (_sortOptions as any)?.[0]?.value,
        selectedSortOption,
      }) as AutomationStructureWithParentType[];
      break;
  }

  return {
    onSortChange,
    sortedItems,
    _sortOptions,
    _sortKey,
  };
};
