import { IconProps } from '@sweep-io/sweep-design/dist/icons/common';
import { ReactNode } from 'react';

export enum PaywallState {
  CUSTOMER = 'CUSTOMER',
  PRE_TRIAL = 'PRE_TRIAL',
  IN_TRIAL = 'IN_TRIAL',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
}

export type PaywallItemData = {
  Icon?: React.FunctionComponent<IconProps>;
  header: string;
  subHeader: ReactNode;
  cta: string;
  gainAccessTo?: string[];
  quote?: {
    text: string;
    quoteBy: string;
    logo: ReactNode;
  };
  imageHorizontal?: ReactNode;
  imageVertical?: ReactNode;
  imageVerticalWide?: ReactNode;
};

export enum PaywallSource {
  DEPENDENCIES = 'dependencies_custom_object',
  FUNNEL_DEPLOY = 'deploy_funnel',
  AUTO = 'auto',
  AI_CHAT = 'ai_chat',

  //full page blocks
  AUTOMATIONS = 'automations',
  ALERTS = 'alerts',
  DEDUP = 'dedupe',
  ROUTING = 'routing',
  REPORTS = 'reports',
  DEVOPS_CENTER = 'devops_center',
  AI_ASSISTANT = 'ai_assistant',
  HUBSPOT = 'hubspot',

  //v1 sources: can be deleted when FT is removed SWEEP-5282
  AUTOMATION_DEPLOY = 'deploy_automation',
  DEVOPS_CENTER_DEPLOY = 'deploy_devops_center',
}

export type PaywallBlockProps = { source: PaywallSource; objectName?: string };
