import { DocumentationTabTypes } from '../../types/enums/DocumentationTabTypes';
import { getRulesPerTabAndStage, funnelStepRulesPerTabAndStage } from '../parser/parserUtils';
import { ParsedRecordType, ParsedRule } from '../documentation/ParserTypes';
import { PillsMap } from './canvasPillsReducer';
import { MASTER_KEY } from '../pages/funnel-map-flow/dialogs/import-funnel/utils';

export const calculateDocumentationPills = ({
  funnelsData,
  recordTypesData,
  recordTypes,
  parsedRules,
  parsedRecordTypes,
}: {
  funnelsData: FunnelsData;
  recordTypesData: RecordTypesData;
  recordTypes: RecordTypes;
  parsedRules: ParsedRule[];
  parsedRecordTypes: ParsedRecordType[];
}) => {
  const documentationPills: Pick<
    PillsMap,
    | 'sdfcAutomations'
    | 'validationRules'
    | 'apex'
    | 'funnelSfcAutomations'
    | 'funnelValidationRules'
    | 'funnelApex'
  > = {
    sdfcAutomations: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    validationRules: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    apex: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    funnelSfcAutomations: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    funnelValidationRules: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    funnelApex: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
  };

  // Calculates pills for the funnel and steps
  Object.values(funnelsData).forEach((funnel) => {
    const funnelRecordType: FunnelRecordType = {
      label: funnel.recordType?.label,
      name: funnel.recordType?.name ?? MASTER_KEY,
      objectName: funnel.funnelDetails.leadingObject.objectName,
      description: funnel.description,
    };

    if (!funnel.recordType) {
      return;
    }

    const optionsForPills = {
      parsedRules,
      recordType: funnelRecordType,
      objectName: funnel.funnelDetails.leadingObject.objectName,
      leadingFieldName: funnel.funnelDetails.leadingObject.fieldName,
    };

    documentationPills.funnelSfcAutomations.funnels[funnel.id] = getRulesPerTabAndStage({
      ...optionsForPills,
      tab: DocumentationTabTypes.SF_AUTOMATIONS,
    }).length;

    documentationPills.funnelValidationRules.funnels[funnel.id] = getRulesPerTabAndStage({
      ...optionsForPills,
      tab: DocumentationTabTypes.VALIDATION_RULES,
    }).length;

    documentationPills.funnelApex.funnels[funnel.id] = getRulesPerTabAndStage({
      ...optionsForPills,
      tab: DocumentationTabTypes.APEX,
    }).length;

    funnel.funnelDetails.stages.forEach((stage) => {
      const leadingObject = funnel.funnelDetails.leadingObject;

      const optionsForPills = {
        parsedRules,
        parsedRecordTypes,
        recordType: funnelRecordType,
        stageData: {
          stageName: stage.stageName,
          leadingObjectFieldName: leadingObject.fieldName,
        },
        objectName: leadingObject.objectName,
      };

      documentationPills.sdfcAutomations.steps[stage._stageId] = funnelStepRulesPerTabAndStage({
        ...optionsForPills,
        tab: DocumentationTabTypes.SF_AUTOMATIONS,
      }).length;

      documentationPills.validationRules.steps[stage._stageId] = funnelStepRulesPerTabAndStage({
        ...optionsForPills,
        tab: DocumentationTabTypes.VALIDATION_RULES,
      }).length;

      documentationPills.apex.steps[stage._stageId] = funnelStepRulesPerTabAndStage({
        ...optionsForPills,
        tab: DocumentationTabTypes.APEX,
      }).length;
    });
  });

  // Calculates pills for the record types
  Object.entries(recordTypes).forEach(([recordTypeId]) => {
    const recordTypeData = recordTypesData[recordTypeId];
    if (recordTypeData) {
      const optionsForPills = {
        parsedRules,
        recordType: recordTypeData,
        objectName: recordTypeData.objectName,
        leadingFieldName: recordTypeData?.leadingField?.name || '',
      };

      documentationPills.funnelSfcAutomations.recordType[recordTypeId] = getRulesPerTabAndStage({
        ...optionsForPills,
        tab: DocumentationTabTypes.SF_AUTOMATIONS,
      }).length;

      documentationPills.funnelValidationRules.recordType[recordTypeId] = getRulesPerTabAndStage({
        ...optionsForPills,
        tab: DocumentationTabTypes.VALIDATION_RULES,
      }).length;

      documentationPills.funnelApex.recordType[recordTypeId] = getRulesPerTabAndStage({
        ...optionsForPills,
        tab: DocumentationTabTypes.APEX,
      }).length;
    }

    const leadingObjectFieldName = recordTypes[recordTypeId]?.leadingFieldName;
    if (leadingObjectFieldName) {
      const values = recordTypesData[recordTypeId]?.leadingField?.values;
      if (!values) return [];

      values.forEach((recordTypeDataValue) => {
        const recordType = recordTypesData[recordTypeId];

        const stepId = `${recordTypeId}.${recordTypeDataValue.fullName}`;

        const optionsForPills = {
          parsedRules,
          parsedRecordTypes,
          recordType,
          stageData: {
            leadingObjectFieldName: recordType?.leadingField?.name || '',
            stageName: recordTypeDataValue.fullName,
          },
          objectName: recordType.objectName,
        };

        documentationPills.sdfcAutomations.steps[stepId] = funnelStepRulesPerTabAndStage({
          ...optionsForPills,
          tab: DocumentationTabTypes.SF_AUTOMATIONS,
        }).length;

        documentationPills.validationRules.steps[stepId] = funnelStepRulesPerTabAndStage({
          ...optionsForPills,
          tab: DocumentationTabTypes.VALIDATION_RULES,
        }).length;

        documentationPills.apex.steps[stepId] = funnelStepRulesPerTabAndStage({
          ...optionsForPills,
          tab: DocumentationTabTypes.APEX,
        }).length;
      });
    }
  });

  return documentationPills;
};
