import { Box } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { useDispatch, useSelector } from 'react-redux';
import { useSweepApi } from '../../../apis/sweep';
import { useIntegrations } from '../../../hooks/useIntegrations';
import {
  selectCloudingoIntegrationStatus,
  setCloudingoIntegrationStatus,
  CloudingoConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { useRunOnce } from '../../common/useRunOnce';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';
import usePermission from '../../common/permissions/usePermission';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import usePopover from '../../../hooks/usePopover';
import InstallManagedPackagePopoverContent from '../../common/install-managed-package/InstallManagedPackagePopoverContent';
import { ActionsRequireMp } from '../../common/install-managed-package/getInstallManagedPackageText';
import IntegrationsButtonPopover from './IntegrationsButtonPopover';

export const CloudingoConnectButton = () => {
  const cloudingoStatus = useSelector(selectCloudingoIntegrationStatus);
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();
  const integrations = useIntegrations();
  const productionEnvironment = useSelector(selectProductionCrmOrg);
  const managedPackagePopover = usePopover();

  const [isConnectCloudingoAllowed, isDisconnectCloudingoAllowed] = usePermission([
    'create:data-integrations:cloudingo',
    'delete:data-integrations:cloudingo',
  ]);

  useRunOnce(async () => {
    await integrations.getCloudingoStatus();
  });

  const onConnectToCloudingoClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (productionEnvironment?.isManagedPackageInstalled) {
      connectToCloudingo();
    } else {
      managedPackagePopover.openPopover(e);
    }
  };

  const connectToCloudingo = async () => {
    sweepApi.post('/data-integrations/cloudingo');
    dispatch(setCloudingoIntegrationStatus(CloudingoConnectionStatus.ENABLED));
  };

  const disconnectCloudingo = () => {
    sweepApi.delete(`/data-integrations/cloudingo`);
    dispatch(setCloudingoIntegrationStatus(CloudingoConnectionStatus.DISABLED));
  };
  return (
    <>
      <IntegrationsButtonPopover
        open={managedPackagePopover.isPopoverOpen}
        anchorEl={managedPackagePopover.anchorElement}
        onClose={() => managedPackagePopover.closePopover()}
      >
        <InstallManagedPackagePopoverContent action={ActionsRequireMp.CLOUDINGO} />
      </IntegrationsButtonPopover>
      <Box>
        {CloudingoConnectionStatus.ENABLED !== cloudingoStatus ? (
          <RestrictedTooltip
            to={['create:data-integrations:cloudingo']}
            notAllowedTitle={'To adjust integration settings, please contact your admin.'}
          >
            <Button
              variant={'filled'}
              disabled={!isConnectCloudingoAllowed}
              onClick={(e) => onConnectToCloudingoClick(e)}
              ref={managedPackagePopover.anchorRef}
              size="small"
            >
              Connect
            </Button>
          </RestrictedTooltip>
        ) : (
          <RestrictedTooltip
            to={['delete:data-integrations:cloudingo']}
            notAllowedTitle={'To adjust integration settings, please contact your admin.'}
          >
            <Button
              variant="outlined"
              onClick={disconnectCloudingo}
              disabled={!isDisconnectCloudingoAllowed}
              size="tiny"
            >
              Disconnect
            </Button>
          </RestrictedTooltip>
        )}
      </Box>
    </>
  );
};
