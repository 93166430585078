import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { Permission } from '@server/permissions';

const usePermission = (permissionArray: Permission[]) => {
  const userInfo = useSelector(selectUserInfoData);
  const permissions = userInfo?.permissions || [];

  const isAllowedTo = (permissionArray: Permission[]) =>
    permissionArray.map((permission) => {
      return permissions.includes(permission);
    });
  return isAllowedTo(permissionArray);
};

export default usePermission;
