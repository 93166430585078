import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { selectAlerts } from '../../reducers/global/alertsReducers';
import { automationsSortOptions } from '../pages/canvas-pages/consts';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import { useGetAutomationFilters } from '../pages/canvas-pages/automationFilters';
import { AutomationAlertsContextProvider } from './AutomationAlertsContextProvider';

interface AlertsProps {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  externalFilter?: (assignment: AutomationStructureNew) => boolean;
  disableCanvasTemplates?: boolean;
  selectedFilters: AutomationDialogFilters;
  setSelectedFilters: (filters: Partial<AutomationDialogFilters>) => void;
  setHoveredItem?: (automationId?: string | undefined) => void;
}

const Alerts = ({
  recordTypesData,
  funnelsData,
  externalFilter,
  disableCanvasTemplates,
  selectedFilters,
  setSelectedFilters,
  setHoveredItem,
}: AlertsProps) => {
  const alerts = useSelector(selectAlerts);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.PLAYBOOK_ALERT });
  const filters = useGetAutomationFilters(alerts);

  const alertsToDisplay = externalFilter ? alerts?.filter(externalFilter) : alerts;

  if (!alerts) return <CenteredCircularProgress />;

  return (
    <Box pb={2} flex={1}>
      {crmOrg && (
        <AutomationDialogContent
          automations={alertsToDisplay ?? []}
          onDeleteAutomation={onDeleteAutomation}
          crmOrgId={crmOrg.id}
          onSaveOrCreate={onSaveOrCreate}
          recordTypesData={recordTypesData}
          funnelsData={funnelsData}
          onToggleActivation={onToggleActivation}
          sortOptions={automationsSortOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          disableCanvasTemplates={disableCanvasTemplates}
          setHoveredItem={setHoveredItem}
          onDeployAutomation={onDeployAutomation}
        />
      )}
      <ConfigurationCanvasFilters filters={filters} automations={alerts} />
    </Box>
  );
};

const AlertsWithContext = (alertsProps: AlertsProps) => {
  return (
    <AutomationAlertsContextProvider>
      <Alerts {...alertsProps} />
    </AutomationAlertsContextProvider>
  );
};

export default AlertsWithContext;
