import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { RootState } from '../../reducers';
import { uniqueId } from '../../lib/uniqueId';

export interface CopilotState {
  messages: AiChatMessage[];
  error?: string;
  isLoading: boolean;
  sessionId: string;
}

const initialState: CopilotState = {
  messages: [],
  isLoading: false,
  sessionId: uniqueId(),
};

export const copilotSlice = createSlice({
  name: 'copilot',
  initialState,
  reducers: {
    addSyncMessage: (state, action: PayloadAction<{ message: AiChatMessage }>) => {
      const { message } = action.payload;
      state.messages.push(message);
      state.isLoading = true;
      state.error = '';
    },
    addAsyncResponse: (
      state,
      action: PayloadAction<{ message?: AiChatMessage[]; error?: string }>,
    ) => {
      const { message, error } = action.payload;
      state.isLoading = false;
      state.error = error;
      const currentMessages = state.messages;
      state.messages = message ? [...currentMessages, ...message] : currentMessages;
    },
    deprecated_addAsyncResponse: (
      state,
      action: PayloadAction<{ messages?: AiChatMessage[]; error?: string }>,
    ) => {
      const { messages, error } = action.payload;
      state.isLoading = false;
      state.error = error;
      if (messages) {
        state.messages = messages;
      }
    },
    reset: () => ({ ...initialState, sessionId: uniqueId() }),
  },
});

export const { addAsyncResponse, deprecated_addAsyncResponse, addSyncMessage, reset } =
  copilotSlice.actions;

export const selectCopilotState = (state: RootState) => state.copilot;

export default copilotSlice.reducer;
