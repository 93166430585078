import { Box, Menu } from '@mui/material';
import SweepTextField from '../common/SweepTextField';
import {
  Dispatch,
  MouseEvent,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useState,
  ReactNode,
} from 'react';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import isNil from 'lodash/isNil';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../common/fieldsSelectors/NestedFieldsSelector';
import FormulaMenu, { FormulaMenuItem } from './FormulaMenu';

enum MenuTypes {
  OPERATORS = 'operators',
  FUNCTIONS = 'functions',
}

const FUNCTIONS: FormulaMenuItem[] = [
  { value: 'QUARTER(date)' },
  { value: 'YEAR(date)' },
  { value: 'MONTH(date)' },
  { value: 'TODAY()' },
  { value: 'ADDDAYS(date, num)' },
  { value: 'ADDMONTHS(date, num)' },
  { value: 'TEXT(value)' },
  { value: 'AND(logical1, logical2, ...)' },
  { value: 'OR(logical1, logical2, ...)' },
  { value: 'NOT(logical)' },
  { value: 'IF(logical_test, value_if_true, value_if_false)' },
  { value: 'CASE(expression, value1, result1, value2, result2, ..., else_result)' },
  { value: 'ISBLANK(expression)' },
  { value: 'ISPICKVAL(picklist_field, text_literal)' },
  { value: 'ABS(number)' },
  { value: 'ROUND(number, num_digits)' },
  { value: 'CEILING(number)' },
  { value: 'FLOOR(number)' },
  { value: 'SQRT(number)' },
  { value: 'MAX(number, number)' },
  { value: 'MIN(number, number)' },
  { value: 'MOD(number, divisor)' },
  { value: 'DATEVALUE(expression)' },
  { value: 'LEN(text)' },
  { value: 'LEFT(text, num_chars)' },
  { value: 'RIGHT(text, num_chars)' },
  { value: 'MID(text, start_num, num_chars)' },
  { value: 'DATETIMEVALUE(expression)' },
  { value: 'SUBSTITUTE(text, old_text, new_text)' },
  { value: 'TRIM(text)' },
  { value: 'FIND(search_text, text)' },
];

const OPERATORS: FormulaMenuItem[] = [
  { label: 'Concatenate &', value: '&' },
  { label: 'Add +', value: '+' },
  { label: 'Subtract -', value: '-' },
  { label: 'Multiply *', value: '*' },
  { label: 'Divide /', value: '/' },
  { label: 'Exponentiation ^ ', value: '^' },
  { label: 'Equal =', value: '=' },
  { label: 'Less Than <', value: '<' },
  { label: 'Greater Than >', value: '>' },
  { label: 'Less Than or Equal <=', value: '<=' },
  { label: 'Greater Than or Equal >=', value: '>=' },
  { label: 'Open Parentheses ( ', value: '(' },
  { label: 'Close Parentheses ) ', value: ')' },
];

const insertTextAtCursor = (el?: HTMLInputElement, text?: string) => {
  if (!el || !text || isNil(el.selectionStart) || isNil(el.selectionEnd)) return {};
  const val = el.value;
  const endIndex = el.selectionEnd;
  const newValue = val.slice(0, endIndex) + text + val.slice(endIndex);
  const newCursorIndex = endIndex + text.length;
  return { newValue, newCursorIndex };
};

const FormulaBuilder = ({
  value,
  setValue,
  crmOrgId,
  objectName,
}: {
  crmOrgId: string;
  objectName: string;
  value?: string;
  setValue: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>();
  const inputRef = useCallback((node: HTMLInputElement) => {
    if (node) {
      setInputEl(node);
    }
  }, []);

  const onAddItem = useCallback(
    (item: string) => {
      if (!inputEl) return;
      const { newValue, newCursorIndex } = insertTextAtCursor(inputEl, item);
      if (!isNil(newValue) && !isNil(newCursorIndex)) {
        setValue(newValue);
        //after adding item using the buttons, need to re-focus the input element, with cursor in the new location
        setTimeout(() => {
          inputEl.focus();
          inputEl.selectionStart = inputEl.selectionEnd = newCursorIndex;
        }, 50);
      } else {
        setValue((value) => value + item);
      }
    },
    [inputEl, setValue],
  );

  return (
    <Box position="relative">
      <Box>
        <SweepTextField
          autoFocus
          inputRef={inputRef}
          value={value}
          onChange={(e) => {
            setValue(e?.target.value);
          }}
          FormControlProps={{
            fullWidth: true,
          }}
          disableConfirmPropagation
          TextFieldProps={{
            placeholder: `Write your formula here. Use " " when writing text strings`,
            multiline: true,
            rows: 3,
            InputProps: {
              sx: {
                height: '172px',
                display: 'block',
              },
            },
            inputProps: {
              maxLength: 3900,
            },
          }}
        />
      </Box>
      <Box sx={{ position: 'absolute', bottom: '20px', left: '20px' }}>
        <FormulaBuilderButtons onAddItem={onAddItem} crmOrgId={crmOrgId} objectName={objectName} />
      </Box>
    </Box>
  );
};

const FormulaBuilderButtons = ({
  crmOrgId,
  objectName,
  onAddItem,
}: {
  crmOrgId: string;
  objectName: string;
  onAddItem: (item: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [activeMenu, setActiveMenu] = useState<MenuTypes | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>, menuToOpen: MenuTypes) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(menuToOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveMenu(null);
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="caption" color={colors.grey[700]}>
          Add
        </Typography>
        <NestedFieldsSelector
          crmOrgId={crmOrgId}
          objectType={objectName}
          nestedPath={[]}
          onChange={(field: NestedFieldsField) => {
            const apiNames = field.fullPath.map((path) => path.data.sfFieldName);
            const apiNamesAsString = transformApiNames(apiNames);
            onAddItem(apiNamesAsString);
          }}
          CustomButtonComponent={(props: any) => <ChevronButton {...props}>Fields</ChevronButton>}
        />
        <ChevronButton
          onClick={(e: MouseEvent<HTMLButtonElement>) => handleClick(e, MenuTypes.FUNCTIONS)}
        >
          Functions
        </ChevronButton>
        <ChevronButton
          onClick={(e: MouseEvent<HTMLButtonElement>) => handleClick(e, MenuTypes.OPERATORS)}
        >
          Operators
        </ChevronButton>
      </Box>
      {activeMenu && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              sx: {
                maxHeight: '320px',
                maxWidth: '300px',
                '.MuiList-root': {
                  padding: 0,
                },
                '.MuiMenuItem-root': {
                  paddingLeft: 2,
                },
              },
            },
          }}
        >
          {activeMenu === MenuTypes.FUNCTIONS && (
            <FormulaMenu
              menuItems={FUNCTIONS}
              closeMenu={() => {
                setActiveMenu(null);
              }}
              onAddItem={onAddItem}
            />
          )}
          {activeMenu === MenuTypes.OPERATORS && (
            <FormulaMenu
              menuItems={OPERATORS}
              closeMenu={() => setActiveMenu(null)}
              onAddItem={onAddItem}
            />
          )}
        </Menu>
      )}
    </>
  );
};

const ChevronButton = ({
  onClick,
  children,
}: {
  onClick: MouseEventHandler<any>;
  children: ReactNode;
}) => (
  <Button variant="outlined" endIconName="ChevronDown" size="small" onClick={onClick}>
    {children}
  </Button>
);

const transformApiNames = (apiNames: string[]) => {
  //this following logic is a requirement from SF
  let res = '';
  let counter = 0;
  const fullArray = apiNames.join('.').split('.'); //in case of lookup, "apiName" has 2 names seperated by "." . This way we get a single array.
  while (counter < fullArray.length) {
    let currentField = fullArray[counter];
    const isLast = counter === fullArray.length - 1;
    const isFirst = counter === 0;
    if (!isLast && !isFirst) {
      //special handling for lookup fields:
      if (currentField.endsWith('Id')) {
        currentField = currentField.slice(0, currentField.length - 2); //remove "Id"
        counter++; //skip the next array item
      } else if (currentField.endsWith('__c')) {
        currentField = currentField.replace('__c', '__r');
        counter++; //skip the next array item
      }
    }
    res += `.${currentField}`;
    counter++;
  }
  return res.slice(1); //remove first dot
};

export default FormulaBuilder;
