import { CanvasContextProvider } from '../../../../multi-canvas/CanvasContext';
import { PanelsProvider } from '../../../../panels/PanelsContext';
import { usePageHelpers } from '../usePageHelpers';
import { ErrorPage } from '../../../../common/ErrorPage';
import {
  ConfigurationCanvasProvider,
  useConfigurationCanvasContext,
} from '../../../configuration-canvas/ConfigurationCanvasContext';
import { useSelector } from 'react-redux';
import { selectFunnelMap } from '../../../../../reducers/multiFunnelFlowCanvasReducer';
import { selectDefaultCreationCrmOrgId } from '../../../../../reducers/userInfoReducer';
import { noop } from 'lodash';
import { DASHBOARD_HEADER_HEIGHT } from '../../../../../constants';
import { CanvasBoard } from '../../../configuration-canvas/canvas-container/CanvasBoard';
import { Box } from '@mui/material';
import { Loader } from '../../../../common/Loader';
import { useInitializeReadOnly } from '../../../funnel-map-flow/read-only/useInitializeReadOnly';
import { FunnelMapCanvas } from '../../../../funnel-map-canvas/FunnelMapCanvas';
import { VisibilityMap } from '../../../../../types/VisibilityTypes';
import { partialVisibilityMapToVisibilityMap } from '../../../configuration-canvas/utils/partialVisibilityMapToVisibilityMap';
import { VisibilityLayers } from '../../../../../types/enums/VisibilityLayers';
import { useConfigurationCanvasState } from '../../../configuration-canvas/useConfigurationCanvasState';
import { useLoadEnv } from '../useInitializeFunnelMapPage';
import { Navigate } from 'react-router';
import { appRoutes } from '../../../../../constants/appRoutes';
import { ReadOnlyCanvasUnitedConfigurationPage } from './ReadOnlyCanvasUnitedConfigurationPage';
import { ReadOnlyUnitedDashboardHeader } from './ReadOnlyUnitedDashboardHeader';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';
import useObjectTypesWithFetch from '../../../../../hooks/useObjectTypesWithFetch';

const readOnlyVisibilityMap: VisibilityMap = partialVisibilityMapToVisibilityMap({
  [VisibilityLayers.GATES]: true,
  [VisibilityLayers.NURTURING_STEPS]: true,
});

const ConnectedReadOnlyFunnelMapFlowCanvas = () => {
  const { canvasFunnelMap: funnelMap } = useConfigurationCanvasState();
  const { appCrmOrgId } = useConfigurationCanvas();
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId: appCrmOrgId });

  const {
    configurationCanvasProps: {
      onFunnelStepClick,
      onFunnelLabelClick,
      onStageGateClick,
      selectedStageId,
      selectedGateId,
    },
  } = useConfigurationCanvasContext();

  return (
    <FunnelMapCanvas
      funnelMap={funnelMap}
      visibilityMap={readOnlyVisibilityMap}
      onStageGateClick={onStageGateClick}
      onFunnelStepClick={onFunnelStepClick}
      onFunnelLabelClick={onFunnelLabelClick}
      onFunnelLabelPillClick={onFunnelLabelClick}
      hideGroupInfo
      selectedStageId={selectedStageId}
      selectedGateId={selectedGateId}
      readonly
      objectTypesByName={objectTypesByName}
    />
  );
};

const UnitedConfigurationReadOnlyCanvasContextConsumer = ({
  funnelMap,
  crmOrgId,
}: {
  funnelMap: FunnelMap;
  funnelId?: string;
  crmOrgId: string;
}) => {
  return (
    <ConfigurationCanvasProvider
      appCrmOrgId={crmOrgId}
      appFunnelMap={funnelMap}
      canvasCrmOrgId={crmOrgId}
      canvasFunnelMap={funnelMap}
      setCanvasFunnelMap={noop}
      onSweepElementsChange={noop}
    >
      <CanvasContextProvider>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100vh',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: DASHBOARD_HEADER_HEIGHT,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <CanvasBoard
              page={<ReadOnlyCanvasUnitedConfigurationPage />}
              crmOrgId={crmOrgId}
              funnelMap={funnelMap}
              hideCommentsButton
            >
              <ConnectedReadOnlyFunnelMapFlowCanvas />
            </CanvasBoard>
          </Box>
          <Box sx={{ position: 'absolute', top: 0, right: 0, left: 0 }}>
            <ReadOnlyUnitedDashboardHeader />
          </Box>
        </Box>
      </CanvasContextProvider>
    </ConfigurationCanvasProvider>
  );
};

const ReadOnlyUnitedConfigurationCanvas = () => {
  const funnelMap = useSelector(selectFunnelMap);
  const { hasFetchingErrors, funnelId } = usePageHelpers();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  useLoadEnv();
  useInitializeReadOnly();

  if (!funnelId) {
    return <Navigate to={appRoutes.funnelMapsPage.route} />;
  }

  if (hasFetchingErrors) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="funnel-flow-page">
      {!crmOrgId || !funnelMap ? (
        <Loader />
      ) : (
        <div data-testid="funnel-flow-page-loaded-canvas">
          <UnitedConfigurationReadOnlyCanvasContextConsumer
            funnelMap={funnelMap}
            funnelId={funnelId}
            crmOrgId={crmOrgId}
          />
        </div>
      )}
    </div>
  );
};

const ReadOnlyUnitedDashboardWithPanels = () => {
  return (
    <PanelsProvider confirmOpen={async ({ action }) => action()}>
      <ReadOnlyUnitedConfigurationCanvas />
    </PanelsProvider>
  );
};

export { ReadOnlyUnitedDashboardWithPanels as ReadOnlyUnitedDashboard };
