import DialogOnCanvas from '../canvas-pages/DialogOnCanvas';
import { PageTemplateDialogContent } from './PageTemplateDialogContent';

export const PageTemplateCanvasDialog = ({
  children,
  isOpen,
  onCancel,
  title,
}: {
  isOpen: boolean;
  onCancel?: () => any;
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <DialogOnCanvas open={isOpen} onClose={onCancel}>
      <PageTemplateDialogContent title={title} onCancel={onCancel}>
        {children}
      </PageTemplateDialogContent>
    </DialogOnCanvas>
  );
};
