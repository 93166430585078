import noop from 'lodash/noop';
import React, { useState } from 'react';
import { ConfigurationCanvasProps } from './types';
import { SweepNodesChangeEvent } from '../../multi-canvas/canvasTypes';
import { MoveCanvasGroups } from '../../multi-canvas/useMoveGroups';
import { ReserveSpaceForPanelsProvider } from './panels-reserve-space/ReserveSpaceForPanelsCtx';

const notSetFunnelMap: FunnelMap = {
  id: 'NOT_SET',
  funnels: {},
  recordTypes: {},
  name: 'NOT_SET',
  isPinned: false,
  createdAt: 'NOT_SET',
  createdById: 'NOT_SET',
  updatedAt: 'NOT_SET',
  updatedById: 'NOT_SET',
  funnelsData: {},
  recordTypesData: {},
  hubspotFunnels: {},
  hubspotFunnelsData: {},
};

export interface ConfigurationCanvasContextType {
  setConfigurationCanvasProps: (props: ConfigurationCanvasProps) => void;
  configurationCanvasProps: ConfigurationCanvasProps;

  /**
   * The CRM org ID of the app.
   * This is supposed to be used in the panels like
   * Automations, Documentation, etc.
   * */
  appCrmOrgId: string;

  /**
   * The CRM org ID of the canvas.
   * This is what is used by the canvas itself
   * */
  canvasCrmOrgId: string;

  lastFunnelMapInServer?: FunnelMap;
  setLastFunnelMapInServer: (funnelMap?: FunnelMap) => void;
  commentsOn: boolean;
  setCommentsOn: (commentsOn: boolean) => void;
  appFunnelMap: FunnelMap;
  canvasFunnelMap: FunnelMap;
  setCanvasFunnelMap: (funnelMap: FunnelMap) => void;
  onSweepElementsChange: (event: SweepNodesChangeEvent) => void;
  moveGroups?: MoveCanvasGroups;
  showOnlyDeployedElementsInPills?: boolean;
}

export const ConfigurationCanvasContext = React.createContext<ConfigurationCanvasContextType>({
  setConfigurationCanvasProps: noop,
  configurationCanvasProps: {
    onStageGateClick: noop,
    visibilityElements: {
      type: 'map',
      visibilityMap: {},
    },
  },
  appCrmOrgId: 'NOT_SET',
  canvasCrmOrgId: 'NOT_SET',
  setLastFunnelMapInServer: noop,
  setCommentsOn: noop,
  commentsOn: false,
  appFunnelMap: { ...notSetFunnelMap },
  canvasFunnelMap: { ...notSetFunnelMap },
  setCanvasFunnelMap: noop,
  onSweepElementsChange: noop,
  showOnlyDeployedElementsInPills: false,
});

export const ConfigurationCanvasProvider = ({
  children,
  appCrmOrgId,
  canvasCrmOrgId,
  appFunnelMap,
  canvasFunnelMap,
  setCanvasFunnelMap,
  onSweepElementsChange,
  showOnlyDeployedElementsInPills,
}: {
  children: React.ReactNode;
  appCrmOrgId: string;
  draftFunnelMapId?: string;
  appFunnelMap: FunnelMap;
  canvasCrmOrgId: string;
  canvasFunnelMap: FunnelMap;
  setCanvasFunnelMap: (funnelMap: FunnelMap) => void;
  onSweepElementsChange: (event: SweepNodesChangeEvent) => void;
  showOnlyDeployedElementsInPills?: boolean;
}) => {
  const [lastFunnelMapInServer, setLastFunnelMapInServer] = useState<FunnelMap>();

  const [commentsOn, setCommentsOn] = useState(false);

  const [configurationCanvasProps, setConfigurationCanvasProps] =
    useState<ConfigurationCanvasProps>({
      onStageGateClick: noop,
      visibilityElements: {
        type: 'map',
        visibilityMap: {},
      },
    });

  return (
    <ReserveSpaceForPanelsProvider>
      <ConfigurationCanvasContext.Provider
        value={{
          setConfigurationCanvasProps,
          configurationCanvasProps,
          appCrmOrgId,
          canvasCrmOrgId,
          lastFunnelMapInServer,
          setLastFunnelMapInServer,
          commentsOn,
          setCommentsOn,
          appFunnelMap,
          canvasFunnelMap,
          setCanvasFunnelMap,
          onSweepElementsChange,
          showOnlyDeployedElementsInPills,
        }}
      >
        {children}
      </ConfigurationCanvasContext.Provider>
    </ReserveSpaceForPanelsProvider>
  );
};

export const useConfigurationCanvasContext = () => {
  const context = React.useContext(ConfigurationCanvasContext);
  if (!context) {
    throw new Error(
      'useConfigurationCanvasContext must be used within a ConfigurationCanvasProvider',
    );
  }
  return context;
};
