import { AutomationsTemplatesList } from '../Automations/AutomationsTemplatesList';
import { AutoTemplatesStruct } from '../../constants/automationTemplates';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { PageTemplateDialogContent } from '../pages/pages-templates/PageTemplateDialogContent';
import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';
import { getAddAutomationButtonByType } from '../Automations/helper';
import _noop from 'lodash/noop';
import { AutomationType } from '../../types/enums/AutomationType';
import useCompileTemplate from '../../hooks/useCompileTemplate';

const AssignmentsTemplatesDialog = ({
  closeDialog,
  setAutomationInEdit,
  crmOrgId,
  title,
  transitionDuration,
}: {
  closeDialog: () => void;
  setAutomationInEdit: (automation: Partial<AutomationStructureNew>) => void;
  crmOrgId: string;
  title: string;
  transitionDuration?: number;
}) => {
  const compileTemplate = useCompileTemplate();
  const { getSweepFieldsById } = useSweepFields();
  const getFieldsList = async (fieldsList: string[]) => {
    await getSweepFieldsById({
      fieldIds: fieldsList,
    });
  };
  return (
    <DialogOnCanvas onClose={closeDialog} open={true} transitionDuration={transitionDuration}>
      <PageTemplateDialogContent title={title}>
        <AutomationsTemplatesList
          crmOrgId={crmOrgId}
          automationVariant={AutomationFormVariant.ASSIGNMENT_RULES}
          onSelectTemplate={(item: AutoTemplatesStruct) => {
            const automationToEdit = compileTemplate(item.automation);
            setAutomationInEdit(automationToEdit);
            getFieldsList(item.fieldsList);
          }}
          createEmptyButton={getAddAutomationButtonByType({
            variant: AutomationFormVariant.ASSIGNMENT_RULES,
            props: {
              disabled: false,
              onSelect: (objectName: string) => {
                setAutomationInEdit({ objectName, type: AutomationType.Assignment });
              },
              crmOrgId,
              buttonText: 'Get started',
              automationVariant: AutomationFormVariant.ASSIGNMENT_RULES,
              isEmptyState: true,
              onAddClick: _noop,
              variant: 'outlined',
            },
          })}
        />
      </PageTemplateDialogContent>
    </DialogOnCanvas>
  );
};

export default AssignmentsTemplatesDialog;
