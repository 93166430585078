import { useCallback, useEffect, useState } from 'react';
import { usePlacingPluginContext } from '../placing-plugin/PlacingPluginContext';
import { setPlugin } from '../../../../../reducers/multiFunnelFlowCanvasReducer';
import { useDispatch } from 'react-redux';
import { useSweepCanvas } from '../../../../multi-canvas/useSweepCanvas';
import { PluginTypes } from '../../../../../types/enums/PluginTypes';
import { OnPluginClickEvent } from '../../../../multi-canvas/SweepCanvas';
import { usePanels } from '../../../../panels/PanelsContext';
import { dataManagementPanelType } from '../plugins/data-management/panel/dataManagementPanelType';
import { bantPanelType } from '../plugins/bant/panel/bantPanelType';
import { dynamicPathPanelType } from '../plugins/dynamic-path/panel/dynamicPathPanelType';
import { meddicPanelType } from '../plugins/meddic/panel/meddicPanelType';
import { meddpiccPanelType } from '../plugins/meddpicc/panel/meddpiccPanelType';

const panelTypesByPluginType = {
  [PluginTypes.BANT]: bantPanelType,
  [PluginTypes.MEDDIC]: meddicPanelType,
  [PluginTypes.MEDDPICC]: meddpiccPanelType,
  [PluginTypes.DYNAMIC_PATH]: dynamicPathPanelType,
  [PluginTypes.DATA_MANAGEMENT]: dataManagementPanelType,
};

export const usePlacePluginIntoFunnel = ({ crmOrgId }: { crmOrgId: string }) => {
  const dispatch = useDispatch();
  const { maybeOpenPanel } = usePanels();
  const {
    placingPluginType,
    setPlacingPlugin,
    setConfiguringPluginType,
    configuringPluginType,
    isPlacingPlugin,
  } = usePlacingPluginContext();
  const { clickPluginButton, pluginButtonExists, removePluginButton } = useSweepCanvas();
  const [openPluginType, setOpenPluginType] = useState<{
    type: PluginTypes;
    funnelId: string;
  }>();

  useEffect(() => {
    if (openPluginType) {
      setTimeout(() => {
        clickPluginButton(openPluginType.type, openPluginType.funnelId);
      }, 100);

      setOpenPluginType(undefined);
    }
  }, [clickPluginButton, openPluginType]);

  const placePlugin = useCallback(
    (funnelId: string) => {
      if (!placingPluginType) return;
      if (pluginButtonExists(funnelId, placingPluginType)) return;

      switch (placingPluginType) {
        case PluginTypes.DATA_MANAGEMENT: {
          setPlacingPlugin(null);

          const plugin: DataManagementPlugin = {
            id: placingPluginType,
            flags: [],
          };

          dispatch(
            setPlugin({
              funnelId,
              plugin,
            }),
          );
          setOpenPluginType({ type: placingPluginType, funnelId });
          break;
        }
        case PluginTypes.DYNAMIC_PATH: {
          setPlacingPlugin(null);

          const plugin: DynamicPathPlugin = {
            id: placingPluginType,
            stages: [],
          };

          dispatch(
            setPlugin({
              funnelId,
              plugin,
            }),
          );
          setOpenPluginType({ type: placingPluginType, funnelId });
          break;
        }
        case PluginTypes.BANT:
        case PluginTypes.MEDDIC:
        case PluginTypes.MEDDPICC:
          setPlacingPlugin(null);
          setConfiguringPluginType({ funnelId, pluginType: placingPluginType });
          setOpenPluginType({ type: placingPluginType, funnelId });
          break;
      }
    },
    [dispatch, placingPluginType, pluginButtonExists, setConfiguringPluginType, setPlacingPlugin],
  );

  const onPluginClick: OnPluginClickEvent = useCallback(
    ({ parentId, pluginId, objectType, event }) => {
      maybeOpenPanel({
        panelType: panelTypesByPluginType[pluginId],
        data: {
          funnelId: parentId,
          anchorEl: event.currentTarget,
          crmOrgId,
          objectType,
        },
        onClose: () => {
          if (configuringPluginType?.pluginType === pluginId) {
            removePluginButton(parentId, pluginId);
            setConfiguringPluginType(null);
          }
        },
      });
    },
    [
      configuringPluginType?.pluginType,
      crmOrgId,
      maybeOpenPanel,
      removePluginButton,
      setConfiguringPluginType,
    ],
  );

  const unsetConfiguringPluginType = useCallback(() => {
    setConfiguringPluginType(null);
  }, [setConfiguringPluginType]);

  return {
    placePlugin,
    onPluginClick,
    unsetConfiguringPluginType,
    isPlacingPlugin,
  };
};
