import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setObjects } from '../../reducers/documentationReducer';
import {
  selectGlobalDocumentationPills,
  selectPillsFromObjectParsedOnDemand,
  selectTransientObjects,
} from '../../reducers/parserReducer';

export const useInitializeObjectsStructure = ({
  recordTypesData,
  funnelsData,
  crmOrgId,
  objectTypes,
}: {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  crmOrgId: string;
  objectTypes?: ObjectTypeName[];
}) => {
  const dispatch = useDispatch();

  const pills = useSelector(selectGlobalDocumentationPills(crmOrgId));
  const pillsFromObjectsParsedOnDemand = useSelector(selectPillsFromObjectParsedOnDemand(crmOrgId));
  const transientObjects = useSelector(selectTransientObjects(crmOrgId));

  useEffect(() => {
    dispatch(
      setObjects({
        funnelsData: funnelsData ?? {},
        recordTypesData: recordTypesData ?? {},
        objectTypeNames: objectTypes ?? [],
        transientObjects,
        pills,
        pillsFromObjectsParsedOnDemand,
      }),
    );
  }, [
    funnelsData,
    recordTypesData,
    objectTypes,
    dispatch,
    pills,

    pillsFromObjectsParsedOnDemand,
    transientObjects,
  ]);
};
