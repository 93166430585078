import { Box, DialogContent, Stack } from '@mui/material';
import { Button, IconButton, Typography, colors } from '@sweep-io/sweep-design';
import { GroupMembersTable } from './GroupMembersTable';
import { useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import {
  AssignmentGroupDialogElements,
  GroupSettingsDialog as DeprecatedGroupSettingsDialog,
} from '../group-settings/deprecated/GroupSettingsDialog';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { AvatarPicture } from '../../../avatar/AvatarPicture';
import { selectAssignmentGroupById } from '../../../../reducers/assignmentGroupsPageReducer';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { GroupMembersSkeletonTable } from './GroupMembersSkeletonTable';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../common/permissions/usePermission';
import { AssignmentGroup, AssignmentGroupMember } from '../../../../reducers/assignmentGroupTypes';
import { cloneDeep } from 'lodash';
import { SearchInput } from '../../../common/SearchInput';
import { useRunOnce } from '../../../common/useRunOnce';
import { telemetry } from '../../../../telemetry';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { ManageMembersDialog } from '../group-settings/ManageMembersDialog';
import { GroupSettingsDialog } from '../group-settings/NewGroupSettingsDialog';
import { GroupsDialogHeader } from '../../../routing/groups-dialog/GroupsDialogHeader';
import { ChevronLeft } from '@sweep-io/sweep-design/dist/icons';

const ONLY_MEMBERS_ELEMENTS = [AssignmentGroupDialogElements.Members];
const AVATARS_AND_DESCRIPTION_AND_LIMIT_MEMBERS_ELEMENTS = [
  AssignmentGroupDialogElements.AvatarAndDescription,
  AssignmentGroupDialogElements.LimitMembers,
];

const GroupMembersList = ({
  onBackClick,
  groupId,
  onClose,
}: {
  onBackClick: () => void;
  groupId: string;
  onClose: () => void;
}) => {
  const { addNotification } = useSweepNotifications();
  const [membersDialogOpen, setMembersDialogOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const assignmentGroupData = useSelector(selectAssignmentGroupById(groupId));
  const [isAllowedBtn] = usePermission(['edit:assignment-groups']);
  const { assignmentGroupsV2 } = useFeatureToggle();

  const closeSettingsDialog = useCallback(() => {
    setSettingsDialogOpen(false);
  }, []);

  const closeMembersDialog = useCallback(() => setMembersDialogOpen(false), []);

  const { getAssignmentGroupData, saveAssignmentGroupData } = useAssignmentsApiWithReducer();

  const writableAssignmentGroupData = useMemo(
    () => cloneDeep(assignmentGroupData),
    [assignmentGroupData],
  );

  const partialSave = useCallback(
    async (assignmentGroup: Partial<AssignmentGroup>, resetAllMembersLimit?: number) => {
      if (!writableAssignmentGroupData) {
        return;
      }
      try {
        await saveAssignmentGroupData(
          { ...writableAssignmentGroupData, ...assignmentGroup },
          resetAllMembersLimit,
        );
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error Saving Assignment Group',
          variant: SweepNotificationVariant.Error,
        });
      }
    },
    [addNotification, writableAssignmentGroupData, saveAssignmentGroupData],
  );

  useRunOnce(() => {
    (async function () {
      try {
        await getAssignmentGroupData(groupId);
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error Loading Assignment Groups',
          variant: SweepNotificationVariant.Error,
        });
      }
    })();
  });

  return (
    <>
      <GroupsDialogHeader
        onClose={onClose}
        headerActions={
          <>
            <RestrictedTooltip
              to={['edit:assignment-groups']}
              notAllowedTitle={
                'To modify the settings of this assignment group, please contact your admin.'
              }
            >
              <Button
                disabled={!isAllowedBtn}
                startIconName="Settings"
                onClick={() => setSettingsDialogOpen(true)}
                variant="outlined"
              >
                Group settings
              </Button>
            </RestrictedTooltip>
            <RestrictedTooltip
              to={['edit:assignment-groups']}
              notAllowedTitle={
                'To modify the settings of this assignment group, please contact your admin.'
              }
            >
              <Button
                disabled={!isAllowedBtn}
                startIconName="UserPlus"
                onClick={() => setMembersDialogOpen(true)}
              >
                Manage members
              </Button>
            </RestrictedTooltip>
          </>
        }
      >
        <Box display="flex" gap={1}>
          <Box display="flex" gap={0.5}>
            <IconButton variant="flat" onClick={onBackClick}>
              <ChevronLeft />
            </IconButton>
            <Box sx={{ width: '40px', height: '40px' }}>
              <AvatarPicture
                dimension={40}
                avatar={writableAssignmentGroupData?.avatar}
                isInactive={false}
              />
            </Box>
          </Box>

          <Stack justifyContent="center">
            <Typography variant="h1">{writableAssignmentGroupData?.name}</Typography>
            {writableAssignmentGroupData?.description && (
              <Typography variant="body" color={colors.grey[700]}>
                {writableAssignmentGroupData?.description}
              </Typography>
            )}
          </Stack>
        </Box>
      </GroupsDialogHeader>
      <DialogContent>
        <Box
          sx={{
            position: 'relative',

            pointerEvents: 'none',
          }}
        >
          {!writableAssignmentGroupData && <GroupMembersSkeletonTable />}
          {writableAssignmentGroupData && (
            <>
              <Box
                p={0}
                pt="10px"
                display="flex"
                flexDirection="column"
                gap="10px"
                sx={{ pointerEvents: 'all' }}
              >
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">
                      {writableAssignmentGroupData?.members.length} Group Member
                      {writableAssignmentGroupData?.members &&
                      writableAssignmentGroupData.members.length > 1
                        ? 's'
                        : ''}
                    </Typography>
                    <Box>
                      <SearchInput
                        withFixedMagnifyingGlassIcon
                        TextFieldProps={{
                          value: searchText,
                          placeholder: 'Search',
                          onChange: (e) => {
                            setSearchText(e.target.value);
                          },
                        }}
                        onClearButtonClick={() => setSearchText('')}
                      />
                    </Box>
                  </Box>
                  {writableAssignmentGroupData && (
                    <GroupMembersTable
                      groupData={writableAssignmentGroupData}
                      filterFunc={(member: AssignmentGroupMember) =>
                        member.name.toLowerCase().includes(searchText.toLowerCase())
                      }
                    />
                  )}
                </Box>
              </Box>
              {writableAssignmentGroupData && membersDialogOpen && !assignmentGroupsV2 && (
                <DeprecatedGroupSettingsDialog
                  open
                  assignmentGroupData={writableAssignmentGroupData}
                  onClose={closeMembersDialog}
                  onSave={partialSave}
                  elements={ONLY_MEMBERS_ELEMENTS}
                />
              )}
              {writableAssignmentGroupData && membersDialogOpen && assignmentGroupsV2 && (
                <ManageMembersDialog
                  assignmentGroup={writableAssignmentGroupData}
                  onClose={closeMembersDialog}
                  onSave={partialSave}
                />
              )}
              {writableAssignmentGroupData && settingsDialogOpen && !assignmentGroupsV2 && (
                <DeprecatedGroupSettingsDialog
                  open
                  assignmentGroupData={writableAssignmentGroupData}
                  onClose={closeSettingsDialog}
                  onSave={partialSave}
                  elements={AVATARS_AND_DESCRIPTION_AND_LIMIT_MEMBERS_ELEMENTS}
                />
              )}
              {writableAssignmentGroupData && settingsDialogOpen && assignmentGroupsV2 && (
                <GroupSettingsDialog
                  assignmentGroup={writableAssignmentGroupData}
                  onClose={closeSettingsDialog}
                  onSave={partialSave}
                />
              )}
            </>
          )}
        </Box>
      </DialogContent>
    </>
  );
};

export default GroupMembersList;
