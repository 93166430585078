import { Stack, Box } from '@mui/material';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { Typography, Tag, colors } from '@sweep-io/sweep-design';
import CopilotScrollArea from './CopilotScrollArea';
import CopilotHeader from './CopilotHeader';
import CopilotInput from './CopilotInput';
import { COPILOT_PROMPTS } from './copilotConsts';

const GRADIENT_HEIGHT = '42px';

const CopilotChat = ({
  messages,
  closeCopilot,
  resetChat,
  onConfirm,
  isLoading,
  errorMsg,
  disableInput,
}: {
  messages: AiChatMessage[];
  closeCopilot: () => void;
  resetChat: () => void;
  onConfirm: (message: string) => Promise<void>;
  isLoading: boolean;
  disableInput: boolean;
  errorMsg?: string;
}) => {
  return (
    <Stack height="100%">
      <CopilotHeader closeCopilot={closeCopilot} resetChat={resetChat} />
      <CopilotScrollArea messages={messages} errorMsg={errorMsg} isLoading={isLoading} />
      <Stack
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: 'calc(100% - 5px)', //5px to not cover the scroll-bar
            height: GRADIENT_HEIGHT,
            top: `-${GRADIENT_HEIGHT}`,
            background: `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);`,
          }}
        ></Box>
        <CopilotInput
          onConfirm={onConfirm}
          isLoading={isLoading}
          predefinedPrompts={messages.length === 0 ? COPILOT_PROMPTS : undefined}
          disabled={disableInput}
        />
        <Stack p={1.5} pt={0} mt={-0.5}>
          <CopilotNote />
        </Stack>
      </Stack>
    </Stack>
  );
};

const CopilotNote = () => (
  <Box display="flex" gap={1} pl={1.5} alignItems="center">
    <Tag label="Beta" color={colors.storm[70]} />
    <Typography variant="caption" color={colors.grey[700]}>
      AI bots can make mistakes. Check important info.
    </Typography>
  </Box>
);

export default CopilotChat;
