import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';

const defaultColumns = [
  {
    field: 'label',
    headerName: 'Label',
    showSortIcon: true,
  },
  {
    field: 'sfApiName',
    headerName: 'API Name',
    showSortIcon: true,
  },
  {
    field: 'createdAt',
    headerName: 'Created date',
    showSortIcon: true,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    showSortIcon: true,
    width: '15%',
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
    width: '15%',
  },
];

const sfAutomationsColumns = [
  {
    field: 'label',
    headerName: 'Label',
    showSortIcon: true,
  },
  {
    field: 'sfApiName',
    headerName: 'API Name',
    showSortIcon: true,
  },
  {
    field: 'type',
    headerName: 'Type',
    showSortIcon: true,
  },
  {
    field: 'createdAt',
    headerName: 'Created date',
    showSortIcon: true,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    showSortIcon: true,
    width: '15%',
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
    width: '15%',
  },
];

const fieldsTableColumns = [
  {
    field: 'label',
    headerName: 'Label',
    showSortIcon: true,
    width: '20%',
  },
  {
    field: 'sfApiName',
    headerName: 'API Name',
    showSortIcon: true,
    width: '15%',
  },
  {
    field: 'description',
    headerName: 'Description',
    showSortIcon: true,
    width: '20%',
  },
  {
    field: 'type',
    headerName: 'Type',
    showSortIcon: true,
  },
  { field: 'usage', headerName: 'Utilization', showSortIcon: true },
  {
    field: 'createdAt',
    headerName: 'Created date',
    showSortIcon: true,
    width: '8%',
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    showSortIcon: true,
    width: '15%',
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
    width: '8%',
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
    width: '15%',
  },
];

const sweepAutomationsColumns = [
  {
    field: 'label',
    headerName: 'Name',
    showSortIcon: true,
  },
  {
    field: 'trigger',
    headerName: 'Trigger',
    showSortIcon: true,
  },
  {
    field: 'actions',
    headerName: 'Actions',
  },
  {
    field: 'status',
    headerName: 'Status',
    showSortIcon: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
  },
];

const sweepAssignmentsColumns = [
  {
    field: 'label',
    headerName: 'Name',
    showSortIcon: true,
  },
  {
    field: 'trigger',
    headerName: 'Trigger',
    showSortIcon: true,
  },
  {
    field: 'actions',
    headerName: 'Rules',
  },
  {
    field: 'status',
    headerName: 'Status',
    showSortIcon: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
  },
];

const sweepDedupeAndMatchingColumns = [
  {
    field: 'label',
    headerName: 'Name',
    showSortIcon: true,
  },
  {
    field: 'actions',
    headerName: 'Action',
  },
  {
    field: 'status',
    headerName: 'Status',
    showSortIcon: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
  },
];

const layoutsColumns = [
  {
    field: 'label',
    headerName: 'Name',
    showSortIcon: true,
  },
  {
    field: 'createdAt',
    headerName: 'Created date',
    showSortIcon: true,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    showSortIcon: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
  },
];

const recordTypesColumns = [
  {
    field: 'label',
    headerName: 'Label',
    showSortIcon: true,
  },
  {
    field: 'sfApiName',
    headerName: 'API Name',
    showSortIcon: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    showSortIcon: true,
  },
  {
    field: 'createdAt',
    headerName: 'Created date',
    showSortIcon: true,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    showSortIcon: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
  },
];

const apexColumns = [
  {
    field: 'label',
    headerName: 'Label',
    showSortIcon: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
  },
];

const validationRulesColumns = [
  {
    field: 'label',
    headerName: 'Label',
    showSortIcon: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    showSortIcon: true,
  },
  {
    field: 'errorMessage',
    headerName: 'Error message',
    showSortIcon: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified',
    showSortIcon: true,
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
  },
];

export const getColumns = (documentationTabType: DocumentationTabTypes) => {
  switch (documentationTabType) {
    case DocumentationTabTypes.FIELDS:
      return fieldsTableColumns;

    case DocumentationTabTypes.SF_AUTOMATIONS:
      return sfAutomationsColumns;

    case DocumentationTabTypes.MATCHING_DEDUPE:
      return sweepDedupeAndMatchingColumns;

    case DocumentationTabTypes.LAYOUTS:
      return layoutsColumns;

    case DocumentationTabTypes.RECORD_TYPES:
      return recordTypesColumns;

    case DocumentationTabTypes.SWEEP_AUTOMATIONS:
    case DocumentationTabTypes.PLAYBOOK_ALERTS:
      return sweepAutomationsColumns;

    case DocumentationTabTypes.SCHEDULED_ASSIGNMENTS:
    case DocumentationTabTypes.ASSIGNMENTS:
      return sweepAssignmentsColumns;

    case DocumentationTabTypes.APEX:
      return apexColumns;

    case DocumentationTabTypes.VALIDATION_RULES:
      return validationRulesColumns;

    default:
      return defaultColumns;
  }
};
