import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import Assignments from './Assignments';
import { RoutingTabs } from './useRoutingTabs';
import ScheduledAssignments from './ScheduledAssignments';
import { FunctionComponent } from 'react';
import { selectAssignments } from '../../reducers/global/assignmentReducers';
import { selectScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import { RootState } from '../../reducers';

export const RoutingTabsInfo: {
  [key in RoutingTabs]: {
    label: string;
    ContentComponent: FunctionComponent<any> | null;
    automationVariant?: AutomationFormVariant;
    selector?: (state: RootState) => (any[] | undefined);
  };
} = {
  [RoutingTabs.assignment]: {
    label: 'Assignment Rules',
    ContentComponent: Assignments,
    automationVariant: AutomationFormVariant.ASSIGNMENT_RULES,
    selector: selectAssignments,
  },
  [RoutingTabs.scheduled]: {
    label: 'Scheduled Assignments',
    ContentComponent: ScheduledAssignments,
    automationVariant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS,
    selector: selectScheduledAssignments,
  },
  [RoutingTabs.members]: { label: 'Members', ContentComponent: null }, //not drillable
  [RoutingTabs.groups]: { label: 'Groups', ContentComponent: null }, //not drillable
  [RoutingTabs.territories]: { label: 'Territories', ContentComponent: null }, //not drillable
};
