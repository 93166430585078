import { DialogContent, Box, Stack } from '@mui/material';
import MembersList from '../pages/assignments/members/MembersList';
import { useAssignmentGroups } from '../pages/assignments/members/useAssignmentGroups';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';

const MembersDialog = ({
  closeDialog,
  transitionDuration,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
}) => {
  const { crmOrgUsersBase, assignmentGroups } = useAssignmentGroups();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const isLoading = !assignmentGroups || !crmOrgUsersBase || !crmOrgId;

  return (
    <DialogOnCanvas onClose={closeDialog} open={true} transitionDuration={transitionDuration}>
      <DialogContent sx={{ paddingTop: 4 }}>
        <Stack gap={2} height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h1">Members</Typography>
            </Box>
            <IconButton variant="flat" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Box>

          {isLoading && (
            <Box flex={1}>
              <CenteredCircularProgress />
            </Box>
          )}
          {!isLoading && (
            <MembersList
              crmOrgUsersBase={crmOrgUsersBase}
              assignmentGroups={assignmentGroups}
              crmOrgId={crmOrgId}
            />
          )}
        </Stack>
      </DialogContent>
    </DialogOnCanvas>
  );
};

export default MembersDialog;
