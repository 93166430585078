import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import { SectionBox } from './helpers';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { ExpandButtonSimple } from '../../common/ExpandButtonSimple';
import { ConfigurationType } from './types';
import { DotDivider } from '../../common/DotDivider';
import { RelatedListTable } from '../configuration-item-tables/RelatedListTable';
import { humanizeDate } from '../../helpers/humanizeDate';
import Linkify from 'linkify-react';

interface DetailsSectionProps {
  configurationItem: ConfigurationItem;
  isField?: boolean;
  crmOrgId: string;
}

export const DetailsSection = ({ isField, configurationItem, crmOrgId }: DetailsSectionProps) => {
  const {
    flowDescription,
    description,
    formula,
    helpText,
    name,
    isMandatory,
    buttons,
    relatedLists,
    annotations,
  } = configurationItem;
  const [isExpanded, setIsExpanded] = useState(true);

  const isRecordType = configurationItem.type === ConfigurationType.recordTypes;
  const isEmptyField = !helpText && !name && !description && !annotations?.createdAt;
  const isEmptyRecordType = isRecordType && !name && !description && !annotations?.createdAt;

  const isLayout = configurationItem.type === ConfigurationType.layouts;
  const isEmptyLayout = !buttons?.length && !relatedLists?.length && !annotations?.createdAt;

  const isEmptyConfiguration =
    !flowDescription && !description && !formula && !annotations?.createdAt;

  const isEmpty = isField
    ? isEmptyField
    : isRecordType
      ? isEmptyRecordType
      : isLayout
        ? isEmptyLayout
        : isEmptyConfiguration;

  const shouldDisplayApiName = name && (isField || isRecordType);

  return (
    <SectionBox>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ cursor: 'pointer' }}
      >
        <Typography variant="h4">Details</Typography>
        <ExpandButtonSimple buttonSize="tiny" isExpanded={isExpanded} />
      </Box>

      <Collapse in={isExpanded}>
        <Box pt={'12px'} gap="12px" display="inline-flex" flexDirection="column">
          {isEmpty && (
            <Typography variant="caption" color={colors.grey[700]}>
              Couldn’t find any details in your Salesforce configuration
            </Typography>
          )}

          {shouldDisplayApiName && (
            <DetailsItem headerTxt="API Name" contentTxt={name} isMandatory={isMandatory} />
          )}
          {description && <DetailsItem headerTxt="Description" contentTxt={description} />}
          {annotations?.createdAt && (
            <DetailsItem
              headerTxt="Creation date"
              contentTxt={humanizeDate({ dateOrTimestamp: annotations?.createdAt })}
            />
          )}
          {helpText && isField && <DetailsItem headerTxt="Help Text" contentTxt={helpText} />}
          {flowDescription && (
            <DetailsItem headerTxt="Flow description" contentTxt={flowDescription} />
          )}
          {formula && <DetailsItem headerTxt="Formula" contentTxt={formula} />}
          {isLayout && (
            <>
              {!!relatedLists?.length && (
                <RelatedListTable
                  tableHeader="Related list"
                  items={relatedLists}
                  crmOrgId={crmOrgId}
                />
              )}
              {!!buttons?.length && (
                <DetailsItem
                  headerTxt="Buttons"
                  contentTxt={buttons.map((button) => button.label).join(', ')}
                />
              )}
            </>
          )}
        </Box>
      </Collapse>
    </SectionBox>
  );
};

const DetailsItem = ({
  headerTxt,
  contentTxt,
  isMandatory,
}: {
  headerTxt: string;
  contentTxt: string;
  isMandatory?: boolean;
}) => (
  <Box display="flex" flexDirection="column" gap={1}>
    <Box display="flex" gap={1} alignItems="center">
      <DotDivider dotColor={colors.black} />
      <Typography variant="body-medium" color={colors.black}>
        {headerTxt}
      </Typography>
    </Box>
    <Box pl={2} display="inline-flex" alignItems="center">
      <Linkify
        options={{
          target: 'blank',
          validate: {
            url: (value) =>
              /\b(?:https?:\/\/(?:www\.)?|www\.)[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}\b/.test(
                value,
              ),
          },
          render: ({ attributes, content }) => {
            const { href } = attributes;
            return (
              <Box display="inline-flex" alignItems="center">
                <Button variant="flat" size="tiny" onClick={() => window.open(href, '_blank')}>
                  {content}
                </Button>
              </Box>
            );
          },
        }}
      >
        <Typography variant="caption" color={colors.grey[800]} wordBreak="break-word">
          {contentTxt}
          {isMandatory ? (
            <>
              <Box component="span" sx={{ margin: '0 8px' }}>
                •
              </Box>
              <Typography variant="caption" color={colors.blush[600]}>
                Mandatory
              </Typography>
            </>
          ) : (
            ''
          )}
        </Typography>
      </Linkify>
    </Box>
  </Box>
);
