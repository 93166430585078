import noop from 'lodash/noop';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAutomationsCanvasInteractions from '../pages/canvas-pages/useAutomationsCanvasInteractions';
import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { useRoutingTabs } from './useRoutingTabs';
import { Breadcrumbs } from '../documentation/selected-object/Breadcrumbs';
import { selectLeftPanelFilters, setLeftPanelFiltersValues } from '../../reducers/leftPanelReducer';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../pages/configuration-canvas-panel';
import { RoutingTabsInfo } from './routingTabsInfo';
import { AssignmentsProps } from './Assignments';

export const RoutingTabContent = ({ goToRoutingHome }: { goToRoutingHome: () => void }) => {
  const dispatch = useDispatch();
  const { routingTab } = useRoutingTabs();
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { funnelsData, recordTypesData } = canvasFunnelMap;
  const selectedFilters = useSelector(selectLeftPanelFilters);
  const { setHoveredItem } = useAutomationsCanvasInteractions();

  const setSelectedFilters = useCallback(
    (filters: Partial<AutomationDialogFilters>) => {
      dispatch(setLeftPanelFiltersValues(filters));
    },
    [dispatch],
  );

  //same props are used for "assignment rules" and "scheduled assignments"
  //if in the future we'll have Content component that require different pros - might need to change this logic
  const contentProps: AssignmentsProps = useMemo(
    () => ({
      funnelsData,
      recordTypesData,
      selectedFilters,
      setSelectedFilters,
      setHoveredItem,
      goToRoutingHome,
    }),
    [
      funnelsData,
      goToRoutingHome,
      recordTypesData,
      selectedFilters,
      setHoveredItem,
      setSelectedFilters,
    ],
  );

  const ContentComponent = routingTab && RoutingTabsInfo[routingTab].ContentComponent;
  const canDrillIn = !!ContentComponent;
  const breadcrumbList = [
    {
      label: 'Routing',
      value: 'Routing',
      onClick: () => {
        goToRoutingHome();
      },
    },
    {
      label: canDrillIn ? RoutingTabsInfo[routingTab].label : '',
      value: canDrillIn ? RoutingTabsInfo[routingTab].label : '',
      onClick: noop,
    },
  ];

  return (
    <>
      <ConfigurationCanvasPanelHeader>
        <Breadcrumbs breadcrumbList={breadcrumbList} />
      </ConfigurationCanvasPanelHeader>
      <ConfigurationCanvasPanelContent>
        {ContentComponent && <ContentComponent {...contentProps} />}
      </ConfigurationCanvasPanelContent>
    </>
  );
};
