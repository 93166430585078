import { UnitedDashboardHeader } from './UnitedDashboardHeader';
import { CanvasContextProvider } from '../../../multi-canvas/CanvasContext';
import { PanelsProvider } from '../../../panels/PanelsContext';
import { usePageHelpers } from './usePageHelpers';
import { Navigate } from 'react-router';
import { appRoutes } from '../../../../constants/appRoutes';
import { ErrorPage } from '../../../common/ErrorPage';
import { ConfigurationCanvasProvider } from '../../configuration-canvas/ConfigurationCanvasContext';
import { useSelector } from 'react-redux';
import { selectFunnelMap } from '../../../../reducers/multiFunnelFlowCanvasReducer';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { useUnitedConnectedFunnelMapCanvas } from './useUnitedConnectedFunnelMapCanvas';
import { noop } from 'lodash';
import { DASHBOARD_HEADER_HEIGHT } from '../../../../constants';
import { CanvasBoard } from '../../configuration-canvas/canvas-container/CanvasBoard';
import { UnitedConnectedFunnelMapFlowCanvas } from './UnitedConnectedFunnelMapFlowCanvas';
import { CanvasUnitedConfigurationPage } from './canvas-page/CanvasUnitedConfigurationPage';
import { Box } from '@mui/material';
import { PlacingPluginContextProvider } from '../../funnel-map-flow/templates/placing-plugin/PlacingPluginContext';
import useConfirm from '../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { useInitializeFunnelMapPage, useLoadEnv } from './useInitializeFunnelMapPage';
import { Loader } from '../../../common/Loader';
import { useInitializeNonParsedObjectsInCanvas } from '../../../documentation/useInitializeNonParsedObjectsInCanvas';
import { UndoRedo } from '../../funnel-map-flow/UndoRedo';
import { CreateFieldContextProvider } from '../../funnel-map-flow/dialogs/fields-management/create-field-dialog/CreateFieldContext';
import usePaywallTrialCarousel from '../../../dashboard/header/usePaywallTrialCarousel';

const UnitedConfigurationCanvasContextConsumer = ({
  funnelMap,
  crmOrgId,
}: {
  funnelMap: FunnelMap;
  funnelMapId?: string;
  crmOrgId: string;
}) => {
  const { onSweepElementsChange, moveGroups } = useUnitedConnectedFunnelMapCanvas({
    funnelMap,
    readonly: false,
    crmOrgId,
  });
  useLoadEnv();
  useInitializeNonParsedObjectsInCanvas(funnelMap);

  return (
    <ConfigurationCanvasProvider
      appCrmOrgId={crmOrgId}
      appFunnelMap={funnelMap}
      canvasCrmOrgId={crmOrgId}
      canvasFunnelMap={funnelMap}
      setCanvasFunnelMap={noop}
      onSweepElementsChange={onSweepElementsChange}
    >
      <CanvasContextProvider>
        <CreateFieldContextProvider funnelMapId={funnelMap.id}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100vh',
            }}
          >
            <UndoRedo />
            <Box
              sx={{
                position: 'absolute',
                top: DASHBOARD_HEADER_HEIGHT,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <CanvasBoard
                page={<CanvasUnitedConfigurationPage />}
                crmOrgId={crmOrgId}
                funnelMap={funnelMap}
                hideCommentsButton
              >
                <UnitedConnectedFunnelMapFlowCanvas
                  moveGroups={moveGroups}
                  onSweepElementsChange={onSweepElementsChange}
                />
              </CanvasBoard>
            </Box>
            <Box sx={{ position: 'absolute', top: 0, right: 0, left: 0 }}>
              <UnitedDashboardHeader />
            </Box>
          </Box>
        </CreateFieldContextProvider>
      </CanvasContextProvider>
    </ConfigurationCanvasProvider>
  );
};

const UnitedConfigurationCanvas = () => {
  const funnelMap = useSelector(selectFunnelMap);
  const { funnelMapId, hasFetchingErrors, isLoading } = usePageHelpers();

  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  useInitializeFunnelMapPage();

  if (!funnelMapId) {
    return <Navigate to={appRoutes.funnelMapsPage.route} />;
  }

  if (hasFetchingErrors) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="funnel-flow-page">
      {isLoading || !crmOrgId || !funnelMap ? (
        <Loader />
      ) : (
        <div data-testid="funnel-flow-page-loaded-canvas">
          <UnitedConfigurationCanvasContextConsumer
            funnelMap={funnelMap}
            funnelMapId={funnelMapId}
            crmOrgId={crmOrgId}
          />
        </div>
      )}
    </div>
  );
};

const UnitedDashboardWithPanels = () => {
  const { openConfirm } = useConfirm();
  usePaywallTrialCarousel();

  return (
    <PlacingPluginContextProvider>
      <PanelsProvider
        confirmOpen={async ({ action }) => {
          const confirmLeave = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
          if (confirmLeave) {
            action();
          }
        }}
      >
        <UnitedConfigurationCanvas />
      </PanelsProvider>
    </PlacingPluginContextProvider>
  );
};

export { UnitedDashboardWithPanels as UnitedDashboard };
