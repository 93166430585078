import { useMemo, useState } from 'react';
import {
  DataTableSortableColumn,
  DataTableVariant,
  SortCompareTypes,
} from '../../../common/table/TableTypes';
import { groupBy, keyBy, uniq } from 'lodash';

import {
  InlineAutocomplete,
  InlineAutocompleteItem,
} from '../../../common/InlineAutocomplete/InlineAutocomplete';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { Box, Stack } from '@mui/material';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { Tooltip, Typography, colors } from '@sweep-io/sweep-design';

import { useDispatch } from 'react-redux';
import { setCrmOrgUsersBase } from '../../../../reducers/crmOrgUsersReducer';
import { DateTime } from 'luxon';
import { HistoryTimeOffs } from './helpers';
import { FlexBox } from '../../../common/FlexBox';
import { SearchInput } from '../../../common/SearchInput';
import { filterItemsBySearch } from '../../../../lib/filterItemsBySearch';
import { changeSweepFamilyWeight } from '../../../../lib/sweepColorUtilities';
import { useErrorHandling } from '../../../../hooks/useErrorHandling';
import { SortOrder } from '../../../common/types';
import { useRunOnce } from '../../../common/useRunOnce';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { VirtualScrollDataTable } from '../../../common/table/VirtualScrollDataTable';
import { useAssignmentGroups } from './useAssignmentGroups';
import useIsManagedPackageInstalled from '../../../common/install-managed-package/useIsManagedPackageInstalled';
import InlineAutocompleteEmptyState from '../../../common/InlineAutocomplete/InlineAutocompleteEmptyState';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { RolesAndProfilesFilters, useRolesAndProfileFilterProps } from '../RolesAndProfilesFilters';
import { AdvancedFilter } from '../../../common/advanced-filter/AdvancedFilter';
import { MembersListActions } from './MembersListActions';
import { useVerifyManagePackageDialog } from './useVerifyManagePackageDialog';

const EMPTY_STATE_BUTTON_TXT = 'Add to group';
const EMPTY_STATE_TXT = 'Member not assigned';

const deprecatedColumns: DataTableSortableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: '240px',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction(a, b, order) {
        return a.data.name.localeCompare(b.data.name) * (order === SortOrder.ASC ? 1 : -1);
      },
    },
  },
  {
    field: 'role',
    headerName: 'Role',
    hidden: true
  },
  {
    field: 'profile',
    headerName: 'Profile',
    hidden: true,
  },
  {
    field: 'assignmentGroups',
    headerName: 'Assignment Groups',
    className: 'assignment-members__assignment-groups',
    width: 360,
  },
  {
    field: 'actions',
    headerName: '',
    width: 'auto',
    justifyContent: 'flex-end',
  },
];

const columns: DataTableSortableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 240,
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction(a, b, order) {
        return a.data.name.localeCompare(b.data.name) * (order === SortOrder.ASC ? 1 : -1);
      },
    },
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 120,
  },
  {
    field: 'profile',
    headerName: 'Profile',
    width: 120,
  },
  {
    field: 'assignmentGroups',
    headerName: 'Assignment Groups',
    className: 'assignment-members__assignment-groups',
    width: 380
  },
  {
    field: 'actions',
    headerName: '',
    width: 'auto',
    justifyContent: 'flex-end',
  },
];


const TooltipTitle = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Box lineHeight={'14px'}>
      <Typography variant="caption-bold" color={colors.grey[300]}>
        {title}
      </Typography>
      <br />
      <Typography variant="caption-bold" color={colors.grey[500]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

interface MembersListProps {
  crmOrgUsersBase: CrmOrgUserBase[];
  assignmentGroups: AssignmentGroup[];
  crmOrgId: string;
}

const MembersList = ({ assignmentGroups, crmOrgId, crmOrgUsersBase }: MembersListProps) => {
  const dispatch = useDispatch();
  const { addUserToGroup, removeUserFromGroup, createNewAssignmentGroupWithMember } =
    useAssignmentsApiWithReducer();
  const { assignmentGroupRoles } = useFeatureToggle()

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [searchMembers, setSearchMembers] = useState<string>('');
  const { setCompleteSetupDialogOpen, maybeInstallManagePackageDialogDialog } = useVerifyManagePackageDialog()
  const isManagedPackagedInstalled = useIsManagedPackageInstalled();
  const { crmOrgUsers } = useAssignmentGroups();
  const isLoadingUsers = isManagedPackagedInstalled && !crmOrgUsers;

  const [isCreatingSet, setIsCreatingSet] = useState<{ [userId: string]: boolean }>({});
  const rolesAndProfileFilterProps = useRolesAndProfileFilterProps({
    crmOrgUsers: crmOrgUsersBase
  })
  const { selectedProfileItems, selectedRoleItems } = rolesAndProfileFilterProps

  const { errorHandlingBuilder } = useErrorHandling();

  const groupItems: InlineAutocompleteItem<any>[] = assignmentGroups.map((assignmentGroup) => {
    const bgColor = assignmentGroup.avatar?.emoji?.bgColor;
    return {
      label: assignmentGroup.name,
      labelDecorator: assignmentGroup.avatar?.emoji?.content,
      tagColor: bgColor ? changeSweepFamilyWeight(bgColor, 100) : undefined,
      value: assignmentGroup.id,
    };
  });

  const renderName = (crmOrgUserBase: CrmOrgUserBase) => {
    const crmOrgUser = crmOrgUsers?.find((user) => user.id === crmOrgUserBase.id);
    const timeOffs = new HistoryTimeOffs(crmOrgUser?.timeOffs || []);
    const _activeSnooze = timeOffs.getActiveSnooze();
    const _hasActiveTimeOff = timeOffs.getActiveTimeOffUntil();
    const { fontVariant, fontColor } = dataTableVariants[DataTableVariant.default];

    return (
      <FlexBox gap="4px">
        <Typography variant={fontVariant} color={fontColor}>
          {crmOrgUserBase.name}
        </Typography>
        {_activeSnooze && !_hasActiveTimeOff && (
          <Tooltip
            title={
              <TooltipTitle
                title="⏱️ Snoozed"
                subtitle={`Until ${_activeSnooze._dateTimeEndDate.toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}`}
              />
            }
          >
            <Typography
              variant={dataTableVariants[DataTableVariant.default].fontVariant}
              color={colors.grey[500]}
            >
              ⏱️ Snoozed
            </Typography>
          </Tooltip>
        )}
        {_hasActiveTimeOff && (
          <Tooltip
            title={
              <TooltipTitle
                title="🚫 Unavailable"
                subtitle={`Until ${_hasActiveTimeOff._dateTimeEndDate.toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}`}
              />
            }
          >
            <Typography
              variant={dataTableVariants[DataTableVariant.default].fontVariant}
              color={colors.grey[500]}
            >
              🚫 Unavailable
            </Typography>
          </Tooltip>
        )}
      </FlexBox>
    );
  };


  const renderUserGroups = ({
    groups = [],
    crmOrgUser,
  }: {
    groups: { groupId: string }[];
    crmOrgUser: CrmOrgUser;
  }) => {
    const selectedItemValues = groups.map((group) => group.groupId);
    if (!isManagedPackagedInstalled) {
      return (
        <InlineAutocompleteEmptyState
          emptyStateTxt={EMPTY_STATE_TXT}
          emptyStateButtonTxt={EMPTY_STATE_BUTTON_TXT}
          onClick={() => setCompleteSetupDialogOpen(true)}
        />
      );
    }
    return (
      <InlineAutocomplete
        headerTxt="Select a group or create one"
        emptyState={{ text: EMPTY_STATE_TXT, buttonText: EMPTY_STATE_BUTTON_TXT }}
        placeholder="Add groups"
        items={groupItems}
        selectedItemValues={selectedItemValues}
        onSelectItem={(item) => {
          addUserToGroup(item.value, crmOrgUser);
          setSelectedGroups(Array.from(new Set([...selectedGroups, item.value])));
        }}
        onDeleteItem={(item) => {
          removeUserFromGroup(item.value, crmOrgUser.id);
        }}
        createInProgress={isCreatingSet[crmOrgUser.id]}
        onCreate={async (groupName) => {
          errorHandlingBuilder()
            .withErrorNotification('Error creating group')
            .withOnError(() => {
              delete isCreatingSet[crmOrgUser.id];
              setIsCreatingSet({ ...isCreatingSet });
            })
            .execute(async () => {
              const newIsCreatingSet = { ...isCreatingSet, [crmOrgUser.id]: true };
              setIsCreatingSet(newIsCreatingSet);
              const group = await createNewAssignmentGroupWithMember(groupName, crmOrgUser);
              setSelectedGroups(Array.from(new Set([...selectedGroups, group.id])));
              delete newIsCreatingSet[crmOrgUser.id];
              setIsCreatingSet({ ...newIsCreatingSet });
            });
        }}
        width={360}
        closeOnItemSelection={selectedItemValues.length === 0} // Close on first item selected.
      />
    );
  };

  const groupsPerUserIdMap = groupBy(
    assignmentGroups
      .map((assignmentGroup) =>
        assignmentGroup.members.map((member) => {
          return {
            userId: member.userId,
            groupId: assignmentGroup.id,
          };
        }),
      )
      .flat(),
    'userId',
  );

  const assignmentsGroupListById = keyBy(assignmentGroups, 'id');
  const usedGroupItems = uniq(
    Object.values(groupsPerUserIdMap)
      .map((groups) => groups.map((g) => g.groupId))
      .flat(),
  )
    .map((id) => assignmentsGroupListById[id])
    .map((group) => ({
      label: group.name,
      value: group.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useRunOnce(() => {
    // Sort by group existence, then by name.
    // But only when the page loads to avoid user jumps when
    // adding/removing users from groups.

    const sortedCrmOrgUsersBase = [...crmOrgUsersBase].sort((a, b) => {
      const userAHasGroups = Boolean(groupsPerUserIdMap[a.id]?.length);
      const userBHasGroups = Boolean(groupsPerUserIdMap[b.id]?.length);

      const sortByGroupSize = Number(userBHasGroups) - Number(userAHasGroups);

      if (sortByGroupSize === 0) {
        return a.name.localeCompare(b.name); // Sort by name if group size is the same.
      }
      return sortByGroupSize;
    });

    dispatch(setCrmOrgUsersBase({ crmOrgId, crmOrgUsersBase: sortedCrmOrgUsersBase }));
  });

  const filteredCrmOrgUsersBase = filterItemsBySearch<CrmOrgUserBase>(
    crmOrgUsersBase,
    searchMembers,
    (crmOrgUser) => crmOrgUser.name,
  );

  const rows = filteredCrmOrgUsersBase.map((crmOrgUser) => {
    return {
      id: crmOrgUser.id,
      name: renderName(crmOrgUser),
      assignmentGroups: renderUserGroups({
        groups: groupsPerUserIdMap[crmOrgUser.id],
        crmOrgUser,
      }),
      role: crmOrgUser.roleName,
      profile: crmOrgUser.profileName,
      actions: !isLoadingUsers && <MembersListActions crmOrgUserId={crmOrgUser.id} crmOrgId={crmOrgId} crmOrgUsers={crmOrgUsers} />,
      data: crmOrgUser,
    };
  });

  const renderFilters = () => {
    const userItems = [
      {
        label: '(No group)',
        value: '__no-group__',
      },
      ...usedGroupItems,
    ];


    return (
      <Box display="flex">
        <Box
          alignItems="center"
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" spacing={2}>
            <AdvancedFilter
              items={userItems}
              selectedItems={selectedGroups}
              onSelectedItemsChange={setSelectedGroups}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              texts={{
                title: 'Show users from:',
                allSelected: 'All groups',
              }}
            />
            {assignmentGroupRoles && (<RolesAndProfilesFilters {...rolesAndProfileFilterProps} />
            )}
          </Stack>
          <Box>
            <SearchInput
              withFixedMagnifyingGlassIcon
              TextFieldProps={{
                value: searchMembers,
                sx: {
                  maxWidth: '270px',
                },
                onChange: (e) => {
                  setSearchMembers(e.target.value);
                },
                placeholder: 'Search members',
              }}
              onClearButtonClick={() => setSearchMembers('')}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  const filteredRows = rows.filter((row) => {
    if (selectedGroups.length === 0) {
      return true;
    }
    const groups = groupsPerUserIdMap[row.id];
    if (!groups || groups.length === 0) {
      return selectedGroups.includes('__no-group__');
    }
    return groups.some((group) => selectedGroups.includes(group.groupId));
  }).filter((row) => {
    if (selectedRoleItems.length === 0) {
      return true;
    }
    return selectedRoleItems.includes(row.data.roleName || '');
  }).filter((row) => {
    if (selectedProfileItems.length === 0) {
      return true;
    }
    return selectedProfileItems.includes(row.data.profileName || '');
  })

  const emptyStateComponent = useMemo(
    () => <Typography variant="caption">No results matching your filter</Typography>,
    [],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {renderFilters()}
      <Box
        height="calc(100vh - 235px)"
        sx={{
          '.MuiTableCell-root': {
            paddingTop: '2px !important',
            paddingBottom: '2px !important',
          },
          '.SweepDataTableRow .add-to-group': {
            visibility: 'hidden',
          },
          '.SweepDataTableRow:hover .add-to-group': {
            visibility: 'visible',
          },
          '.SweepDataTableRow  .assignments-members-table__action-buttons': {
            visibility: 'hidden',
          },
          '.SweepDataTableRow:hover .assignments-members-table__action-buttons': {
            visibility: 'visible',
          },
          'th.assignment-members__assignment-groups': {
            paddingLeft: '24px !important',
          },
        }}
      >
        <VirtualScrollDataTable
          columns={assignmentGroupRoles ? columns : deprecatedColumns}
          rows={filteredRows}
          TableEmptyStateComponent={emptyStateComponent}
        />
      </Box>
      {maybeInstallManagePackageDialogDialog}
    </Box>
  );
};

export default MembersList;
