import { ReactNode, memo } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import _isNil from 'lodash/isNil';
import StepDragIcon from '../../svg/step-drag.svg?react';
import StyledTooltip from '../../../common/StyledTooltip';
import { NODE_WIDTH, NODE_HEIGHT } from '../../const';
import {
  actionButtonDimensions,
  ActionButtonAdd,
  ActionButtonNurturingStep,
  ActionButtonConnectStep,
} from '../components/CanvasButtons';
import { NodeComponent } from '../components/SweepNodeComponent';
import { SweepNode } from '../SweepNode';
import { DropInvalidReason } from '../../useCalculateDragAndDrop';
import { colors, Typography } from '@sweep-io/sweep-design';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';
import { NodeEntityTypes, RFNodeData } from '../../canvasTypes';
import { Pills } from '../components/pills/Pills';
import { ContextZoomType } from '../../SweepCanvas';

const invalidReasonTooltipTextMap = {
  [DropInvalidReason.OVERLAP]: 'Positions cannot overlap',
};

const MetadataChip = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: '2px',
      display: 'flex',
      padding: '3px 8px',
    }}
  >
    <Typography variant="caption">{children}</Typography>
  </Box>
);

export const BasedStepNode = memo(
  ({
    id,
    name,
    objectType,
    onAddNodeClick,
    onAddSourceClick,
    onAddTargetClick,
    onNodeClick,
    onPillClick,
    onConnectClick,
    readonly,
    dropInvalidReason,
    isHighlighted,
    hasSourceButton,
    hasTargetButton,
    showButtonsOnHighlight,
    metadata,
    parentId,
    isNurturingBucket,
    dragging,
    contextZoomType,
    selected,
    mapForecastCategoryValueToLabel,
    pills,
    disableStepClick,
  }: RFNodeData & {
    isNurturingBucket?: boolean;
    dragging?: boolean;
    selected?: boolean;
    id: string;
    mapForecastCategoryValueToLabel?: (forecastCategoryValue: string) => string;
  }) => {
    const isContextZoomDefault = contextZoomType === ContextZoomType.DEFAULT;
    const isContextZoom1_5 = contextZoomType === ContextZoomType.LEVEL1_5;
    const isContextZoom2 = contextZoomType === ContextZoomType.LEVEL2;

    const nodeHorizontalCenter = NODE_WIDTH / 2 - actionButtonDimensions.width / 2;
    const nodeVerticalCenter = NODE_HEIGHT / 2 - actionButtonDimensions.height / 2;

    const renderAddBranch = () => {
      return (
        <Box
          sx={{
            position: 'absolute',
            left: nodeHorizontalCenter,
            top: -45,
            cursor: 'default',
          }}
        >
          <ActionButtonAdd
            onClick={onAddNodeClick ? () => onAddNodeClick(id, 'branch') : undefined}
          />
        </Box>
      );
    };

    const renderAddSource = () => {
      return (
        <Box
          sx={{
            position: 'absolute',
            left: -45,
            top: nodeVerticalCenter,
            cursor: 'default',
          }}
        >
          <ActionButtonAdd onClick={onAddSourceClick ? () => onAddSourceClick(id) : undefined} />
        </Box>
      );
    };

    const renderAddTarget = () => {
      return (
        <Box
          sx={{
            position: 'absolute',
            right: -45,
            top: nodeVerticalCenter,
            cursor: 'default',
          }}
        >
          <ActionButtonAdd onClick={onAddTargetClick ? () => onAddTargetClick(id) : undefined} />
        </Box>
      );
    };

    const renderAddNurturingBucket = () => {
      return (
        <Box
          sx={{
            position: 'absolute',
            left: nodeHorizontalCenter,
            cursor: 'default',
            top: pills?.length ? NODE_HEIGHT + 35 : NODE_HEIGHT + 14,
          }}
        >
          <ActionButtonNurturingStep
            onClick={onAddNodeClick ? () => onAddNodeClick(id, 'nb') : undefined}
          />
        </Box>
      );
    };

    const renderConnectStepButton = () => (
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: -45,
          cursor: 'default',
        }}
      >
        <ActionButtonConnectStep
          onClick={onConnectClick ? (event) => onConnectClick(id, parentId, event) : undefined}
        />
      </Box>
    );

    const isInvalidPosition = !!dropInvalidReason;
    const dropInvalidTooltip =
      (dropInvalidReason && invalidReasonTooltipTextMap[dropInvalidReason]) || 'asda';

    const showButtons =
      !readonly && isHighlighted && showButtonsOnHighlight && !isContextZoom2 && !isContextZoom1_5;

    const showProbability = !_isNil(metadata?.probability);
    const showMetadata = isContextZoomDefault && (showProbability || metadata?.forecastCategory);

    return (
      <SweepNode>
        {showButtons && (
          <>
            {hasSourceButton && renderAddSource()}
            {hasTargetButton && renderAddTarget()}
            {!isNurturingBucket && (
              <>
                {renderAddBranch()}
                {renderAddNurturingBucket()}
              </>
            )}
            {renderConnectStepButton()}
          </>
        )}
        <StyledTooltip
          title={dropInvalidTooltip}
          open={isInvalidPosition}
          placement="top"
          sx={{
            maxWidth: 'inherit',
            zIndex: '5 !important', //force overwrite to avoid errors appearing over dialogs
          }}
        >
          <NodeComponent
            data-testid="sweep-canvas-regular-node"
            className={classNames({
              highlighted: !isContextZoom2 && (isHighlighted || selected),
              'invalid-position': isInvalidPosition,
              'nb-node': isNurturingBucket,
              shadow: !isContextZoom2,
              [`node-${objectType}`]: true,
              'has-opacity': isContextZoom2,
              'disable-step-click': disableStepClick,
            })}
          >
            <Box
              onClick={
                onNodeClick
                  ? (event) =>
                      onNodeClick({
                        id,
                        parentId,
                        event,
                        entity: {
                          type: NodeEntityTypes.Step,
                        },
                      })
                  : undefined
              }
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                padding: '0 27px 0 12px',
                gap: '6px',
              }}
            >
              {!isContextZoom2 && (
                <>
                  <TruncatedTextTooltip variant={isContextZoomDefault ? 'h4' : 'body-medium'}>
                    {name}
                  </TruncatedTextTooltip>
                  {showMetadata && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '4px',
                      }}
                      className="metadata-data"
                    >
                      {showProbability && <MetadataChip>{metadata?.probability + ''}</MetadataChip>}
                      {metadata?.forecastCategory && (
                        <MetadataChip>
                          {mapForecastCategoryValueToLabel &&
                            mapForecastCategoryValueToLabel(metadata?.forecastCategory)}
                        </MetadataChip>
                      )}
                    </Box>
                  )}
                </>
              )}

              {(dragging || isHighlighted) && !readonly && !isContextZoom2 && (
                <Box
                  className="drag-handle"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 40,
                    height: '100%',
                    cursor: 'grab',
                    '& svg': {
                      fill: colors.blue[500],
                    },

                    '&:active': {
                      cursor: 'grabbing',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: '100%',
                      paddingLeft: '20px',
                    }}
                  >
                    <StepDragIcon />
                  </Box>
                </Box>
              )}
            </Box>
            {isContextZoomDefault && pills && (
              <Box
                sx={{
                  marginTop: '12px', // 10px padding + 2px of shadow
                }}
              >
                <Pills
                  nodeId={id}
                  nodeLabel={name}
                  parentNodeId={parentId}
                  objectType={objectType}
                  onPillClick={onPillClick}
                  pills={pills}
                  entityType={NodeEntityTypes.StepPill}
                  pillsMax={4}
                />
              </Box>
            )}
          </NodeComponent>
        </StyledTooltip>
      </SweepNode>
    );
  },
);
