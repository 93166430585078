import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';

/** This reducer should hold only UI related state, not data !! **/

export interface LeftPanelState {
  filters: AutomationDialogFilters; //TODO can be simplified to only search text
  hoveredItem: {
    automationId?: string;
  };
}

const initialState: LeftPanelState = {
  filters: {
    recordTypesIds: [], //TODO remove
    stepNames: [], //TODO remove
    searchText: '',
  },
  hoveredItem: {},
};

export const leftPanelSlice = createSlice({
  name: 'leftPanel',
  initialState,
  reducers: {
    setLeftPanelFiltersValues: (state, action: PayloadAction<Partial<AutomationDialogFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setLeftPanelHoveredItem: (
      state,
      action: PayloadAction<{
        automationId?: string;
      }>,
    ) => {
      state.hoveredItem = action.payload;
    },
  },
});

export const { setLeftPanelFiltersValues, setLeftPanelHoveredItem } = leftPanelSlice.actions;

export const selectLeftPanelFilters = (state: RootState) => state.leftPanel.filters;
export const selectLeftPanelHoveredItem = (state: RootState) => state.leftPanel.hoveredItem;

export default leftPanelSlice.reducer;
