import { useDispatch, useSelector } from 'react-redux';
import {
  selectGlobalDocumentationData,
  selectObjectsParsedOnDemand,
} from '../../reducers/parserReducer';
import { setShowLoader } from '../../reducers/documentationReducer';
import { getObjectApiNamesFromFunnelMap, getObjectApiNamesFromRecordTypes } from './helpers';
import uniq from 'lodash/uniq';
import useParser from '../parser/useParser';
import { useEffect, useMemo, useState } from 'react';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import { useInitializeObjectsStructure } from './useInitializeObjectsStructure';

export const useInitializeNonParsedObjectsInCanvas = (funnelMap?: FunnelMap) => {
  const dispatch = useDispatch();
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const crmOrgId = defaultCreationEnvironment?.id ?? '';

  const { parseObjectOnDemand } = useParser({ crmOrgId });
  const { objectTypes, isLoading } = useObjectTypesWithFetch({ crmOrgId, useSfObjects: true });

  const [isAddingObjects, setIsAddingObjects] = useState(false);

  const { funnelsData = {}, recordTypesData = {} } = funnelMap ?? {};

  useInitializeObjectsStructure({ funnelsData, recordTypesData, crmOrgId, objectTypes });

  const funnelsObjects = useMemo(() => getObjectApiNamesFromFunnelMap(funnelsData), [funnelsData]);
  const recordTypeObjects = useMemo(
    () => getObjectApiNamesFromRecordTypes(recordTypesData),
    [recordTypesData],
  );
  const mergedObjectNames: string[] = useMemo(
    () => uniq([...funnelsObjects, ...recordTypeObjects]),
    [funnelsObjects, recordTypeObjects],
  );

  const parsedObjectNamesInOrg = useSelector(
    selectGlobalDocumentationData(crmOrgId ?? ''),
  )?.parserObjectNames;
  const objectsParsedOnDemand = useSelector(selectObjectsParsedOnDemand);
  const objectsParsedOnDemandInOrg = objectsParsedOnDemand?.[crmOrgId ?? ''];

  const missingObjectNames = useMemo(
    () =>
      mergedObjectNames.filter(
        (objectName) =>
          ![...(parsedObjectNamesInOrg ?? []), ...(objectsParsedOnDemandInOrg ?? [])].includes(
            objectName,
          ),
      ),
    [mergedObjectNames, parsedObjectNamesInOrg, objectsParsedOnDemandInOrg],
  );

  const missingObjectTypes = useMemo(
    () =>
      objectTypes?.filter((objectType) => missingObjectNames.includes(objectType.objectType)) ?? [],
    [objectTypes, missingObjectNames],
  );

  useEffect(() => {
    if (missingObjectTypes.length > 0 && !isAddingObjects && crmOrgId && !isLoading) {
      setIsAddingObjects(true);

      (async () => {
        dispatch(setShowLoader({ showLoader: true }));
        await Promise.all(
          missingObjectTypes.map((objectType) =>
            parseObjectOnDemand(objectType.objectType, objectType.label),
          ),
        );
        setIsAddingObjects(false);
        dispatch(setShowLoader({ showLoader: false }));
      })();
    }
  }, [dispatch, missingObjectTypes, crmOrgId, isAddingObjects, parseObjectOnDemand, isLoading]);
};
