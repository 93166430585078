import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { StageDialogCollapsePanel } from './StageDialogCollapsePanel';
import { useSelector } from 'react-redux';
import { selectFunnelStageName } from '../../../../../../reducers/multiFunnelFlowCanvasReducer';
import { useState } from 'react';
import { useFunnelsApiFacade } from '../../../../../../apis/facades/useFunnelsApiFacade';
import { useRunOnceWhenTruthy } from '../../../../../common/useRunOnceWhenTruthy';

interface FunnelLinkCollapsePanelProps {
  funnelLink: FunnelLink;
  defaultIsCollapsed: boolean;
  fromStageName: string;
}

const FunnelLinkCollapsePanel = ({
  funnelLink,
  defaultIsCollapsed,
  fromStageName,
}: FunnelLinkCollapsePanelProps) => {
  const { get_funnel } = useFunnelsApiFacade();
  const stageName = useSelector(selectFunnelStageName(funnelLink));
  const [localStageName, setLocalStageName] = useState(stageName);

  // If the funnel is not present it makes a call to retrieve the stage name
  useRunOnceWhenTruthy(async () => {
    const funnel = await get_funnel(funnelLink.funnelId);
    const _stageName = funnel.funnelDetails.stages.find(
      (stage) => stage._stageId === funnelLink.stageId,
    )?.stageName;
    if (_stageName) {
      setLocalStageName(_stageName);
    }
  }, !Boolean(stageName));

  return (
    <StageDialogCollapsePanel
      defaultIsCollapsed={defaultIsCollapsed}
      from={fromStageName}
      to={localStageName || ''}
      funnelId=""
    >
      <Box
        sx={{ padding: 3, background: colors.grey[100], borderRadius: '16px', marginTop: '10px' }}
      >
        <Typography variant="caption" color={colors.grey[700]}>
          Adding gates to connections between funnels is not yet possible. But we will make it
          happen soon!
        </Typography>
      </Box>
    </StageDialogCollapsePanel>
  );
};

export { FunnelLinkCollapsePanel };
