import { useState } from 'react';
import CopilotButton from './CopilotButton';
import RightPanel from '../../common/RightPanel';
import CopilotChatConnected from '../../copilot/CopilotChatConnected';

const CopilotChatDashboard = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openCopilot = () => {
    setIsOpen(true);
  };

  const closeCopilot = () => {
    setIsOpen(false);
  };

  return (
    <>
      <CopilotButton onClick={openCopilot} variant="primary" />
      <RightPanel isOpen={isOpen}>
        <CopilotChatConnected closeCopilot={closeCopilot} />
      </RightPanel>
    </>
  );
};

export default CopilotChatDashboard;
