import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDocumentation } from '../useDocumentation';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { HoveredPin } from '../types';

export const useObjectListInteractions = ({
  objectNamesUsedInCanvas,
  setHoveredPin,
}: {
  objectNamesUsedInCanvas?: string[];
  setHoveredPin?: Dispatch<SetStateAction<HoveredPin | undefined>>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { onObjectInFunnelMapClick, onObjectNotInFunnelMapClick } = useDocumentation();

  const _onAllObjectsCardClick = useCallback(
    async (objectTypeName: ObjectTypeName) => {
      setIsLoading(true);
      await onObjectNotInFunnelMapClick(objectTypeName);
      setIsLoading(false);
      setHoveredPin && setHoveredPin(undefined);
    },
    [onObjectNotInFunnelMapClick, setHoveredPin],
  );

  const _onCardClick = useCallback(
    async (objectTypeName: ObjectTypeName, tab?: DocumentationTabTypes) => {
      onObjectInFunnelMapClick(objectTypeName, tab);
      setHoveredPin && setHoveredPin(undefined);
    },
    [onObjectInFunnelMapClick, setHoveredPin],
  );

  const onCardClick = useCallback(
    async (objectTypeName: ObjectTypeName, tab?: DocumentationTabTypes) => {
      const isUsedInFunnelMap = objectNamesUsedInCanvas?.includes(objectTypeName.objectType);

      if (isUsedInFunnelMap) {
        await _onCardClick(objectTypeName, tab);
      } else {
        await _onAllObjectsCardClick(objectTypeName);
      }
    },
    [objectNamesUsedInCanvas, _onAllObjectsCardClick, _onCardClick],
  );

  return { onCardClick, isLoading };
};
