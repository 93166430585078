import { Box } from '@mui/material';
import { CanvasFilters, CanvasFiltersProps } from '../configuration-canvas-filters/CanvasFilters';
import { useConfigurationCanvas } from './public/useConfigurationCanvas';
import { useGetReservedSpaceForPanels } from './panels-reserve-space/ReserveSpaceForPanelsCtx';

const staticSx = {
  position: 'relative',
  top: 0,
  left: 0,
};

export const ConfigurationCanvasFilters = ({
  filters,
  automations,
  staticView,
}: Pick<CanvasFiltersProps, 'filters' | 'automations'> & {
  staticView?: boolean;
}) => {
  const { canvasFunnelMap, appCrmOrgId } = useConfigurationCanvas();
  const { leftReservedSpace } = useGetReservedSpaceForPanels();

  return (
    <Box
      sx={{
        position: 'absolute',
        left: leftReservedSpace + 16,
        top: 16,
        zIndex: 1,
        ...(staticView ? staticSx : {}),
      }}
      className="comments-layer"
    >
      <CanvasFilters
        filters={filters}
        automations={automations}
        funnelMap={canvasFunnelMap}
        crmOrgId={appCrmOrgId}
      />
    </Box>
  );
};
