import { Box, styled } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

export const Panel = styled(Box)({
  display: 'flex',
  borderBottom: `1px solid${colors.grey[200]}`,
  padding: '16px 20px',
  gap: '8px',
  alignItems: 'center',
});
