import { Box } from '@mui/material';
import { useUniversalSearch } from '../universal-search/useUniversalSearch';
import { UniversalSearchInput } from '../universal-search/UniversalSearchInput';
import { UniversalSearchContent } from '../universal-search/UniversalSearchContent';
import { TableHeaderVariant } from '../configuration-item-tables/shared/TableWithHeader';
import { CenteredCircularProgress } from '../../common/CenteredCircularProgress';
import { OPTION_ALL } from '../../common/single-select-filter/utils';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { HoveredPin, RecordTypesLabelsByNameAndObjectName } from '../types';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../../pages/configuration-canvas-panel';
import { ObjectVirtualList } from './ObjectVirtualList';

interface ObjectListWrapperProps {
  crmOrgId: string;
  setHoveredPin?: Dispatch<SetStateAction<HoveredPin | undefined>>;
  onCloseDialog?: () => void;
  hideAddObjectButton?: boolean;
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
  withExpandedModeButton?: boolean;
  stepNamesUsedInCanvas: string[];
}

export const ObjectListWrapper = ({
  crmOrgId,
  setHoveredPin,
  onCloseDialog,
  hideAddObjectButton,
  recordTypeNamesUsedInCanvas,
  stepNamesUsedInCanvas,
  withExpandedModeButton,
}: ObjectListWrapperProps) => {
  const objectNamesUsedInCanvas = useMemo(
    () => Object.keys(recordTypeNamesUsedInCanvas ?? {}),
    [recordTypeNamesUsedInCanvas],
  );

  const {
    isLoadingDependency,
    onChooseNewRule,
    onSearchKeydown,
    onSearchChange,
    isLoadingObjects,
    isUniversalSearchListOpen,
    results,
    isLoadingResults,
    onClearButtonClick,
    searchTxt,
    filterOptions,
    selectedFilterValue,
    onSelectedFilterItem,
    onListItemClick,
    searchTypeOptions,
    selectedSearchType,
    onSelectSearchTypeOption,
  } = useUniversalSearch(crmOrgId);

  const tableHeaderVariant =
    selectedFilterValue && selectedFilterValue !== OPTION_ALL.value
      ? TableHeaderVariant.justResultCounter
      : TableHeaderVariant.withBackground;

  return (
    <>
      <ConfigurationCanvasPanelHeader
        onClose={onCloseDialog}
        withExpandedModeButton={!!withExpandedModeButton}
      >
        Documentation
      </ConfigurationCanvasPanelHeader>
      <ConfigurationCanvasPanelContent sx={{ overflow: 'hidden' }}>
        <Box data-testid="documentation-dialog" sx={{ height: '100%' }}>
          {isLoadingObjects && <CenteredCircularProgress />}

          {!isLoadingObjects && (
            <>
              <UniversalSearchInput
                onKeyDown={onSearchKeydown}
                onChange={onSearchChange}
                onClearButtonClick={onClearButtonClick}
                searchTxt={searchTxt ?? ''}
                selectedFilterValue={selectedFilterValue}
                filterOptions={filterOptions}
                onSelectedFilterItem={onSelectedFilterItem}
                isUniversalSearchListOpen={isUniversalSearchListOpen}
                menuOptions={searchTypeOptions}
                selectedMenuOption={selectedSearchType}
                onSelectMenuOption={onSelectSearchTypeOption}
              />

              {isUniversalSearchListOpen && (
                <UniversalSearchContent
                  onListItemClick={onListItemClick}
                  onChooseNewRule={onChooseNewRule}
                  isLoadingDependency={isLoadingDependency}
                  results={results}
                  isLoadingResults={isLoadingResults}
                  tableHeaderVariant={tableHeaderVariant}
                  crmOrgId={crmOrgId}
                  recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
                  stepNamesUsedInCanvas={stepNamesUsedInCanvas}
                  selectedFilterValue={selectedFilterValue}
                  filterOptions={filterOptions}
                  onSelectedFilterItem={onSelectedFilterItem}
                />
              )}

              {!isUniversalSearchListOpen && (
                <Box position="relative" ml={-2} mr={-2} height="100%">
                  <ObjectVirtualList
                    setHoveredPin={setHoveredPin}
                    hideAddObjectButton={hideAddObjectButton}
                    objectNamesUsedInCanvas={objectNamesUsedInCanvas}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </ConfigurationCanvasPanelContent>
    </>
  );
};
