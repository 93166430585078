import { Card, CardHeader, Box, CardContent, Skeleton, Stack } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

export const ASSIGNMENTS_CARD_HEIGHT = '124px';

export const GroupSkeletonCard = () => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: 'transparent',
        background: colors.grey[100],
        minHeight: '158px',
        '.MuiCardHeader-root, .MuiCardContent-root': {
          padding: 3,
        },
        '.MuiCardContent-root': { paddingTop: 0 },
        minWidth: '400px',
      }}
    >
      <CardHeader
        sx={{ alignItems: 'flex-start' }}
        avatar={
          <Box>
            <Skeleton variant="circular" width={72} height={72} />
          </Box>
        }
        title={
          <Stack spacing={1}>
            <Skeleton variant="rectangular" width={102} height={20} />
            <Skeleton variant="rectangular" width={232} height={20} />
          </Stack>
        }
      />
      <CardContent>
        <Stack spacing={1} direction="row">
          <Skeleton variant="rectangular" width={102} height={20} />
          <Skeleton variant="rectangular" width={232} height={20} />
        </Stack>
      </CardContent>
    </Card>
  );
};
