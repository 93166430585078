import { Typography } from '@sweep-io/sweep-design';
import { ConfirmDialog } from '../../../common/dialogs/ConfirmDialog';

const ConfirmRemoveFunnelMapDialog = ({
  isOpen,
  closeDialog,
  onConfirm,
}: {
  isOpen: boolean;
  closeDialog: () => void;
  onConfirm: () => any;
}) => {
  return (
    <ConfirmDialog
      title="Remove Funnel View"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={closeDialog}
      handleClose={closeDialog}
      children={
        <Typography variant="body">Are you sure you want to remove this funnel view?</Typography>
      }
    />
  );
};

export { ConfirmRemoveFunnelMapDialog };
