import { ReactNode } from 'react';
import { Drawer } from '@mui/material';
import { DASHBOARD_HEADER_HEIGHT_PX } from '../../constants';

const WIDTH = 420;

const RightPanel = ({ children, isOpen }: { children: ReactNode; isOpen: boolean }) => {
  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isOpen}
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        sx: {
          top: DASHBOARD_HEADER_HEIGHT_PX,
          width: WIDTH + 'px',
          boxShadow: 1,
          height: `calc(100vh - ${DASHBOARD_HEADER_HEIGHT_PX})`,
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default RightPanel;
