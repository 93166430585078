import { useCallback, useMemo } from 'react';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { StageType } from '../../types/enums/StageType';
import {
  SweepCanvasNode,
  CanvasElementType,
  SweepCanvasGroup,
  SweepCanvasEdge,
} from '../multi-canvas/canvasTypes';
import { SweepCanvasReactFlowEdgeDataType } from '../multi-canvas/edges/SweepCanvasReactFlowEdgeDataType';
import { usePlacingPluginContext } from '../pages/funnel-map-flow/templates/placing-plugin/PlacingPluginContext';
import { HighlightEntities, NewFunnelTransientData } from './funnelMapCanvasTypes';
import { getFunnelIdBySweepStage } from './getFunnelIdBySweepStage';
import flatten from 'lodash/flatten';
import * as pillsHelper from './pillsHelper';
import { recordTypeToNodeId } from './helper';
import { PillsMap } from '../canvas-pills/canvasPillsReducer';
import { getRecordTypeIdentifier } from '../Automations/helper';
import { Dictionary, camelCase } from 'lodash';
import { MASTER_KEY } from '../pages/funnel-map-flow/dialogs/import-funnel/utils';

export const TRANSIENT_GROUP_ID = 'TRANSIENT_GROUP';

export const useFunnelMapElements = ({
  funnelMap,
  funnelStageMetadata,
  funnelIdBySweepStage: _funnelIdBySweepStage,
  transientFunnelData,
  highlightEntities,
  renderZeroCountPills,
  visibilityMap,
  pills,
  objectTypesByName,
}: {
  funnelMap: FunnelMap;
  funnelStageMetadata?: StageMetadata[];
  visibilityMap: VisibilityMap;
  funnelIdBySweepStage?: { [stageId: string]: string };
  transientFunnelData?: NewFunnelTransientData;
  highlightEntities?: HighlightEntities[];
  renderZeroCountPills?: boolean;
  pills?: Partial<PillsMap>;
  objectTypesByName: Dictionary<ObjectTypeName>;
}) => {
  const funnelsData: FunnelsData = useMemo(
    () => funnelMap.funnelsData || {},
    [funnelMap.funnelsData],
  );

  const recordTypesData: RecordTypesData = useMemo(
    () => funnelMap.recordTypesData || {},
    [funnelMap.recordTypesData],
  );

  const funnelIdBySweepStage = _funnelIdBySweepStage ?? getFunnelIdBySweepStage(funnelsData);

  const funnelDetailsStages = Object.keys(funnelMap.funnels || {})
    .filter((funnelId) => !funnelMap.funnels[funnelId].isHidden)
    .map((funnelId) => funnelsData[funnelId]?.funnelDetails.stages)
    .filter(Boolean)
    .flat();

  if (transientFunnelData?.stage) {
    funnelDetailsStages.push(transientFunnelData?.stage);
  }

  const getLeadingObjectForFunnel = useCallback(
    (funnelId: string) => funnelsData[funnelId]?.funnelDetails.leadingObject,
    [funnelsData],
  );

  const {
    nodes: hubspotNodes,
    edges: hubspotEdges,
    groups: hubspotGroups,
  } = useMemo(() => {
    const nodes: SweepCanvasNode[] = [];
    const edges: SweepCanvasEdge[] = [];
    const groups: SweepCanvasGroup[] = [];

    Object.entries(funnelMap.hubspotFunnels).forEach(([hubspotFunnelId, hubspotFunnelPosition]) => {
      const hubspotFunnelData = funnelMap.hubspotFunnelsData[hubspotFunnelId];

      if (!hubspotFunnelData) return undefined;

      groups.push({
        id: hubspotFunnelId,
        name: hubspotFunnelData.label || '',
        objectType: '__hubspot__',
        position: hubspotFunnelPosition.position,
        objectLabel: hubspotFunnelData.objectName,
        startingIcon: 'hubspot',
        pills: pillsHelper.hubspotFunnelPills({ pills, visibilityMap }),
      });

      hubspotFunnelData.leadingField.values.forEach((value, idx) => {
        nodes.push({
          id: camelCase(`${hubspotFunnelId}-${value}-${idx}`),
          name: value,
          type: CanvasElementType.REGULAR,
          objectType: '__hubspot__',
          position: {
            column: idx,
            row: 0,
          },
          parentId: hubspotFunnelId,
        });
      });
      nodes.forEach((node, idx, nodes) => {
        if (idx < nodes.length - 1) {
          edges.push({
            id: `${hubspotFunnelId}-${node.id}-edge`,
            source: node.id,
            target: nodes[idx + 1].id,
            data: {
              type: SweepCanvasReactFlowEdgeDataType.SIMPLE,
            },
          });
        }
      });
    });
    return {
      nodes,
      edges,
      groups,
    };
  }, [funnelMap.hubspotFunnels, funnelMap.hubspotFunnelsData, pills, visibilityMap]);

  const sweepNodesFromSteps: SweepCanvasNode[] = useMemo(() => {
    const nodes = funnelDetailsStages
      .filter((stage) => stage.stageType !== StageType.LOST)
      .map((stage) => {
        const funnelId = funnelIdBySweepStage[stage._stageId];
        const _pills = pillsHelper.stepPills({
          funnelId,
          stage,
          renderZeroCountPills,
          highlightEntities,
          visibilityMap,
          pills,
        });

        if (funnelId) {
          const funnelDetail = funnelsData[funnelId]?.funnelDetails;
          const leadingObject = funnelDetail.leadingObject;

          const metadata =
            funnelStageMetadata &&
            funnelStageMetadata.find(
              (metadata) =>
                metadata.stageName === stage.stageName &&
                metadata.leadingFieldId === leadingObject._leadingFieldId,
            );

          const node: SweepCanvasNode = {
            id: stage._stageId,
            position: {
              column: stage._stageColumnIndex || 0,
              row: stage._branchIndex || 0,
            },
            name: stage.stageName,
            type:
              stage.stageType === 'nurturing'
                ? CanvasElementType.NURTURING_BUCKET
                : CanvasElementType.REGULAR,
            objectType: leadingObject.objectName as ObjectTypeValues,
            parentId: funnelId,
            metadata,
            pills: _pills,
          };

          return node;
        }

        const node: SweepCanvasNode = {
          id: stage._stageId,
          position: {
            column: stage._stageColumnIndex || 0,
            row: stage._branchIndex || 0,
          },
          name: stage.stageName,
          type: CanvasElementType.REGULAR,
          objectType: transientFunnelData?.leadingObject.objectName || 'Lead',
          parentId: TRANSIENT_GROUP_ID,
        };
        return node;
      });

    return nodes;
  }, [
    funnelDetailsStages,
    funnelIdBySweepStage,
    funnelStageMetadata,
    funnelsData,
    highlightEntities,
    pills,
    renderZeroCountPills,
    transientFunnelData?.leadingObject.objectName,
    visibilityMap,
  ]);

  const { configuringPluginType } = usePlacingPluginContext();

  const sweepGroupsFromFunnels: SweepCanvasGroup<FunnelLeadingObject>[] = useMemo(() => {
    const _sweepGroups: SweepCanvasGroup<FunnelLeadingObject>[] = Object.keys(funnelMap.funnels)
      .filter((funnelId) => !funnelMap.funnels[funnelId].isHidden)
      .map((funnelId) => {
        const _plugins = funnelsData[funnelId]?.funnelDetails?.plugins || {};

        const plugins = Object.keys(_plugins) as PluginTypes[];

        if (configuringPluginType?.funnelId === funnelId) {
          plugins.push(configuringPluginType.pluginType);
        }
        const funnelData: Funnel | undefined = funnelsData[funnelId];
        const objectType = getLeadingObjectForFunnel(funnelId)?.objectName || '';
        const objectLabel = objectTypesByName[objectType]?.label ?? objectType;
        return {
          id: funnelId,
          name: funnelData?.name,
          position: funnelMap?.funnels[funnelId]?.position || { column: 0, row: 0 },
          objectType,
          objectLabel,
          metadata: funnelData?.funnelDetails.leadingObject || {
            _leadingFieldId: '0',
            _leadingFieldLabels: [],
            fieldName: 'Status',
            objectName: 'Lead',
          },
          plugins,
          pills: pillsHelper.funnelPills({
            funnel: funnelData,
            renderZeroCountPills,
            highlightEntities,
            visibilityMap,
            pills,
            objectType,
            recordTypeId: getRecordTypeIdentifier(
              funnelData?.recordType?.name ?? MASTER_KEY,
              objectType,
            ),
          }),
        };
      });
    if (transientFunnelData) {
      const {
        name,
        leadingObject: { _leadingFieldId, _leadingFieldLabels, fieldName, objectName },
        position,
      } = transientFunnelData;
      const objectLabel = objectTypesByName[objectName]?.label ?? objectName;

      _sweepGroups.push({
        id: TRANSIENT_GROUP_ID,
        name,
        objectType: objectName,
        objectLabel,
        position: position,
        metadata: {
          _leadingFieldId,
          _leadingFieldLabels,
          fieldName,
          objectName,
        },
        plugins: [],
      });
    }
    return _sweepGroups;
  }, [
    configuringPluginType?.funnelId,
    configuringPluginType?.pluginType,
    funnelMap.funnels,
    funnelsData,
    getLeadingObjectForFunnel,
    highlightEntities,
    pills,
    renderZeroCountPills,
    transientFunnelData,
    visibilityMap,
    objectTypesByName,
  ]);

  const { recordTypes } = funnelMap;

  const sweepGroupsFromRecordTypes: SweepCanvasGroup[] = useMemo(() => {
    return Object.keys(recordTypes)
      .filter((recordTypeId) => !recordTypes[recordTypeId].isHidden)
      .map((recordTypeId) => {
        const recordType = recordTypesData[recordTypeId];
        const [objectName] = recordTypeId.split('.');
        const objectLabel = objectTypesByName[objectName]?.label ?? objectName;

        const sweepGroup: SweepCanvasGroup = {
          id: recordTypeId,
          name: recordTypesData[recordTypeId]?.label,
          objectType: objectName,
          objectLabel,
          position: recordTypes[recordTypeId].position,
          pills: pillsHelper.recordTypePills({
            recordType,
            recordTypeId,
            highlightEntities,
            renderZeroCountPills,
            visibilityMap,
            pills,
          }),
          showEditButton: Boolean(recordTypesData[recordTypeId]?.leadingField),
        };
        return sweepGroup;
      });
  }, [
    recordTypes,
    recordTypesData,
    objectTypesByName,
    highlightEntities,
    renderZeroCountPills,
    visibilityMap,
    pills,
  ]);

  const { sweepGhostNodesFromRecordTypes, sweepGhostEdgesFromRecordTypes } = useMemo(() => {
    const nodes: SweepCanvasNode[] = [];
    const edges: SweepCanvasEdge[] = [];

    Object.keys(recordTypes).forEach((recordTypeId) => {
      const [objectName] = recordTypeId.split('.');
      if (!funnelMap.recordTypesData[recordTypeId] || recordTypes[recordTypeId].isHidden) {
        return null;
      }

      const leadingObjectFieldName = recordTypes[recordTypeId].leadingFieldName;
      if (leadingObjectFieldName) {
        if (!funnelMap.recordTypesData) return [];
        const values = funnelMap.recordTypesData[recordTypeId].leadingField?.values;
        if (!values) return [];

        values.forEach((recordTypeDataValue, idx) => {
          const [objectName] = recordTypeId.split('.');
          const recordType = recordTypesData[recordTypeId];

          const node: SweepCanvasNode = {
            id: recordTypeToNodeId(recordTypeId, recordTypeDataValue.fullName),
            name: recordTypeDataValue.label,
            type: CanvasElementType.REGULAR,
            objectType: objectName,
            position: { column: idx, row: 0 },
            parentId: recordTypeId,
            pills: pillsHelper.recordTypeStepPills({
              recordType,
              recordTypeDataValue,
              recordTypeId,
              highlightEntities,
              renderZeroCountPills,
              visibilityMap,
              pills,
            }),
          };
          nodes.push(node);
          if (idx < values.length - 1) {
            edges.push({
              id: `${recordTypeId}-${idx}-edge`,
              source: recordTypeToNodeId(recordTypeId, recordTypeDataValue.fullName),
              target: recordTypeToNodeId(recordTypeId, values[idx + 1].fullName),
              data: {
                type: SweepCanvasReactFlowEdgeDataType.SIMPLE,
              },
            });
          }
        });
      } else {
        nodes.push({
          id: `${recordTypeId}-ghost-node0`,
          name: '',
          type: CanvasElementType.GHOST_NODE,
          objectType: objectName,
          position: { column: 0, row: 0 },
          parentId: recordTypeId,
        });
      }
    });

    return {
      sweepGhostNodesFromRecordTypes: nodes,
      sweepGhostEdgesFromRecordTypes: edges,
    };
  }, [
    funnelMap.recordTypesData,
    highlightEntities,
    pills,
    recordTypes,
    recordTypesData,
    renderZeroCountPills,
    visibilityMap,
  ]);

  const sweepEdgesFromFunnels: SweepCanvasEdge[] = useMemo(() => {
    const edges: SweepCanvasEdge[] = flatten(
      funnelDetailsStages
        .filter((stage) => stage.stageType !== StageType.LOST)
        .map((stage) => {
          const _edges: SweepCanvasEdge[] = stage.exitCriteria.map((exitCriteria) => ({
            id: exitCriteria._exitCriteriaId,
            source: stage._stageId,
            target: exitCriteria._nextStageId,
            data: {
              label: `${exitCriteria.criteria?.length}`,
              type: SweepCanvasReactFlowEdgeDataType.CIRCLE,
            },
          }));
          if (stage.funnelLinks?.length) {
            stage.funnelLinks.forEach((link) => {
              _edges.push({
                id: `${funnelIdBySweepStage[stage._stageId]}-${stage._stageId}-${link.stageId}`,
                source: stage._stageId,
                target: link.stageId,
                data: { type: SweepCanvasReactFlowEdgeDataType.REMOVABLE },
              });
            });
          }

          return _edges;
        }),
    );
    return edges;
  }, [funnelDetailsStages, funnelIdBySweepStage]);

  return {
    sweepGroups: [...sweepGroupsFromFunnels, ...sweepGroupsFromRecordTypes, ...hubspotGroups],
    sweepEdges: [...sweepEdgesFromFunnels, ...sweepGhostEdgesFromRecordTypes, ...hubspotEdges],
    sweepNodes: [...sweepNodesFromSteps, ...sweepGhostNodesFromRecordTypes, ...hubspotNodes],
  };
};
