import { Box } from '@mui/material';
import usePermission from '../common/permissions/usePermission';
import { colors, Tag, Tooltip, Typography } from '@sweep-io/sweep-design';
import { ObjectTypeChip } from '../common/ObjectTypeChip';
import { getDisplayLineTexts } from './helper';
import { Commands } from '../../types/enums/Common';
import {
  ArrowRight,
  Automations,
  Copy,
  Delete,
  Edit,
  SlackLogo,
  SweepBug,
  LightningSlash,
} from '@sweep-io/sweep-design/dist/icons';
import ListItemActionCard from '../common/ListItemActionCard';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import ConfirmDestructiveDialog from '../common/dialogs/ConfirmDestructiveDialog';
import {
  selectDefaultCreationEnvironment,
  selectProductionCrmOrg,
} from '../pages/environments/environmentsReducer';
import { useSelector } from 'react-redux';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { OrgDot } from '../common/OrgDot';
import { useState } from 'react';
import { InfoDialog } from '../common/dialogs/InfoDialog';
import { SlackLogsContent } from './slack/SlackLogsContent';
import { AutomationType } from '../../types/enums/AutomationType';
import useCheckManagedPackage from '../common/install-managed-package/useCheckManagedPackage';

import { innerScrollStyle } from '../pages/innerScrollStyle';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import usePaywall from '../dashboard/paywall/usePaywall';
import AutomationDeployPaywallBlock from '../common/deploy-button/AutomationDeployPaywallBlock';
import { CompleteSfSetupForDeployDialog } from '../common/deploy-button/complete-sf-setup/CompleteSfSetupForDeployDialog';
import { useIsSetupCompleted } from '../../hooks/useIsSetupCompleted';
import { useAutomationsContext } from './AutomationsContext';

interface AutomationsListProps {
  automationList: AutomationStructureNew[];
  onToggle: (
    automationJson: AutomationStructureNew,
    deployToOrgIds: string[],
    isActive: boolean,
  ) => any;
  onDeleteAutomation: (automationId: string, automationType: AutomationType) => any;
  onEdit: (automationItem: AutomationStructureNew) => any;
  onDuplicate: (automationItem: AutomationStructureNew) => any;
  readonly?: boolean;
  selectedId?: string;
  automationVariant: AutomationFormVariant;
  setHoveredItem?: (automationId?: string) => void;
  onDeploy: (automationItem: AutomationStructureNew, crmOrgIds: string[]) => any;
  crmOrgId: string;
}

const PAYWALL_DIALOG_INITIAL = { isOpen: false, objectName: undefined };

export const AutomationsList = ({
  automationList,
  onToggle,
  onEdit,
  onDuplicate,
  onDeleteAutomation,
  readonly,
  selectedId,
  automationVariant,
  setHoveredItem,
  onDeploy,
  crmOrgId,
}: AutomationsListProps) => {
  const { openConfirm, onCancel, onConfirm } = useConfirm();
  const dataByVariant = useAutomationsContext();
  const [isAllowedEditBtn, isAllowedDeployBtn, isAllowedDeleteBtn] = usePermission([
    dataByVariant.permissions.edit,
    dataByVariant.permissions.deploy,
    dataByVariant.permissions.delete,
  ]);
  const getContentColor = (active: boolean) => (active ? colors.grey[800] : colors.grey[500]);
  const getTitleColor = (active: boolean) => (active ? colors.black : colors.grey[700]);

  const [automationForSlackLog, setAutomationForSlackLog] = useState<AutomationStructureNew>();
  const productionEnvironment = useSelector(selectProductionCrmOrg);
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const { getIsManagedPackagedInstalledWithRecheck } = useCheckManagedPackage();
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  const { isPaywallActive } = usePaywall();
  const [paywallDialogProps, setPaywallDialogProps] = useState<{
    isOpen: boolean;
    objectName?: string;
  }>(PAYWALL_DIALOG_INITIAL);
  const [completeSfSetupDialog, setCompleteSfSetupDialog] = useState<{
    isOpen: boolean;
    crmOrgIdsToDeploy: string[];
    automationItem: AutomationStructureNew;
  }>();

  const canDeploy = useIsSetupCompleted();

  const deployOrShowDialog = async (
    automationItem: AutomationStructureNew,
    crmOrgIds: string[],
  ) => {
    if (isPaywallActive) {
      setPaywallDialogProps({ isOpen: true, objectName: automationItem.objectName });
      return;
    }
    getIsManagedPackagedInstalledWithRecheck({
      withPermissionsCheck: true,
    });
    if (canDeploy) {
      onDeploy(automationItem, crmOrgIds);
    } else {
      setCompleteSfSetupDialog({
        isOpen: true,
        crmOrgIdsToDeploy: crmOrgIds,
        automationItem,
      });
    }
  };

  const defaultIsProd = defaultCreationEnvironment?.id === productionEnvironment?.id;
  const showOnlyDefault = defaultIsProd || !productionEnvironment;
  const OCCUPIED_SPACE_TOP_VIEW =
    automationVariant === AutomationFormVariant.ASSIGNMENT_RULES ||
    automationVariant === AutomationFormVariant.DEDUP_MATCHING ||
    automationVariant === AutomationFormVariant.SCHEDULED_ASSIGNMENTS
      ? '270px'
      : automationVariant === AutomationFormVariant.SCHEDULED_REPORTS
        ? '296px'
        : '236px';

  const getActionMenu = (automationItem: AutomationStructureNew) => {
    const { isActive, status } = automationItem;

    const editAndDuplicateItems = [
      {
        label: Commands.Edit,
        value: Commands.Edit,
        disabled: !isAllowedEditBtn || readonly,
        actionIcon: <Edit />,
      },
      {
        label: Commands.Duplicate,
        value: Commands.Duplicate,
        disabled: !isAllowedEditBtn || readonly,
        actionIcon: <Copy />,
      },
    ];
    if (
      automationItem.automationDetails.actions.find(
        (action) => action.actionType === 'SEND_SLACK_MESSAGE',
      )
    ) {
      editAndDuplicateItems.push({
        label: Commands.Slack_Activity,
        value: Commands.Slack_Activity,
        disabled: !isAllowedEditBtn || readonly,
        actionIcon: <SlackLogo />,
      });
    }
    const activationItem = {
      label: isActive ? 'Deactivate' : 'Activate',
      value: 'onToggleDefault',
      disabled: !isAllowedDeployBtn || readonly,
      actionIcon: isActive ? <LightningSlash /> : <Automations />,
      isNested: !showOnlyDefault,
      nestedTitle: !showOnlyDefault
        ? `Deploy as ${isActive ? 'deactivated' : 'activated'} to:`
        : undefined,
      nestedActions: !showOnlyDefault
        ? [
            {
              label: defaultCreationEnvironment?.name ?? '',
              value: 'onToggleDefault',
            },
            {
              label: (
                <Box display="flex" gap={0.5} alignItems="center">
                  <Typography variant="body">
                    {defaultCreationEnvironment?.name} + {productionEnvironment?.name}
                  </Typography>
                  <OrgDot />
                </Box>
              ),
              value: 'onToggleBoth',
            },
          ]
        : undefined,
    };

    const deleteItem = {
      addBottomDivider: true,
      label: Commands.Delete,
      value: Commands.Delete,
      disabled: !isAllowedDeleteBtn || readonly,
      actionIcon: <Delete color={colors.blush[600]} />,
      actionSx: { color: colors.blush[600] },
    };

    const deployItem = {
      label: 'Deploy',
      value: 'onDeployDefault',
      disabled: !isAllowedDeployBtn || readonly,
      actionIcon: <SweepBug />,
      isNested: !showOnlyDefault,
      nestedTitle: !showOnlyDefault
        ? `Deploy ${
            automationItem.type === AutomationType.Default
              ? 'automation'
              : automationItem.type === AutomationType.ScheduledAssignment
                ? 'scheduled assignment'
                : automationItem.type.toLowerCase()
          }:`
        : undefined,
      nestedActions: !showOnlyDefault
        ? [
            {
              label: (
                <Box display="flex" gap={0.5} alignItems="center">
                  <Typography variant="body">
                    Deploy to {defaultCreationEnvironment?.name}
                  </Typography>
                  {defaultIsProd && <OrgDot />}
                </Box>
              ),
              value: 'onDeployDefault',
            },
            {
              label: (
                <Box display="flex" gap={0.5} alignItems="center">
                  <Typography variant="body">
                    {defaultCreationEnvironment?.name} + {productionEnvironment?.name}
                  </Typography>
                  <OrgDot />
                </Box>
              ),
              value: 'onDeployBoth',
            },
          ]
        : undefined,
    };

    const noActivation = status === DeployStatusForTable.Draft;

    return noActivation
      ? [...editAndDuplicateItems, deleteItem, deployItem]
      : [...editAndDuplicateItems, activationItem, deleteItem, deployItem];
  };

  return (
    <>
      {paywallDialogProps.isOpen && (
        <AutomationDeployPaywallBlock
          closeDialog={() => setPaywallDialogProps(PAYWALL_DIALOG_INITIAL)}
          entityNamePlural={dataByVariant.nameInMessageSingular}
          objectName={paywallDialogProps.objectName}
        />
      )}
      <Box sx={{ ...innerScrollStyle(OCCUPIED_SPACE_TOP_VIEW) }}>
        {automationList?.map((automationItem: AutomationStructureNew) => {
          const { triggerText, actionText } = getDisplayLineTexts(automationItem);

          return (
            <Box
              key={`${automationItem.automationId}${automationItem.versionId}`}
              onMouseEnter={() => setHoveredItem && setHoveredItem(automationItem.automationId)}
              onMouseLeave={() => setHoveredItem && setHoveredItem(undefined)}
            >
              <ListItemActionCard
                isSelected={automationItem.automationId === selectedId}
                readonly={readonly}
                title={automationItem.name}
                onLineClick={() => onEdit(automationItem)}
                isAllowedBtn={isAllowedEditBtn}
                actionsMenu={getActionMenu(automationItem)}
                titleSX={{ color: getTitleColor(automationItem.isActive) }}
                headerRightContent={
                  <>
                    {automationItem.notifyAdmin && (
                      <Tooltip title="Pending admin approval" placement="top">
                        <span>
                          <Tag startIconName="Stamp" iconColor={colors.night[900]} />
                        </span>
                      </Tooltip>
                    )}
                    {!automationItem.isActive && (
                      <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <LightningSlash color={colors.blush[600]} />
                        <Typography variant="caption" color={colors.blush[600]}>
                          Inactive
                        </Typography>
                      </Box>
                    )}
                    {automationItem.status === DeployStatusForTable.Pending &&
                      !automationItem.notifyAdmin && (
                        <Tooltip title="Updates pending deploy" placement="top">
                          <span>
                            <Tag startIconName="Refresh" iconColor={colors.night[900]} />
                          </span>
                        </Tooltip>
                      )}
                    {automationItem.status === DeployStatusForTable.Draft && (
                      <Tag startIconName="Edit" label="Draft" iconColor={colors.night[900]} />
                    )}
                    {automationItem.objectName && (
                      <ObjectTypeChip
                        label={
                          objectTypesByName[automationItem.objectName]?.label ||
                          automationItem.objectName
                        }
                        objectType={automationItem.objectName}
                      />
                    )}
                  </>
                }
                onActionMenuClick={(action) => {
                  switch (action) {
                    case Commands.Slack_Activity:
                      setAutomationForSlackLog(automationItem);
                      break;
                    case Commands.Edit:
                      onEdit && onEdit(automationItem);
                      break;
                    case Commands.Duplicate:
                      const newItem: AutomationStructureNew = {
                        ...automationItem,
                        automationId: '',
                        name: 'Copy of ' + automationItem.name,
                      };
                      onDuplicate(newItem);
                      break;
                    case 'onToggleDefault':
                      onToggle(
                        automationItem,
                        [defaultCreationEnvironment?.id ?? ''],
                        !automationItem.isActive,
                      );
                      break;
                    case 'onToggleBoth':
                      onToggle(
                        automationItem,
                        [defaultCreationEnvironment?.id ?? '', productionEnvironment?.id ?? ''],
                        !automationItem.isActive,
                      );
                      break;
                    case 'onDeployDefault':
                      deployOrShowDialog(automationItem, [defaultCreationEnvironment?.id ?? '']);
                      break;
                    case 'onDeployBoth':
                      deployOrShowDialog(automationItem, [
                        defaultCreationEnvironment?.id ?? '',
                        productionEnvironment?.id ?? '',
                      ]);
                      break;
                  }
                }}
                onDeleteLine={async () => {
                  const isConfirmed = await openConfirm(
                    <ConfirmDestructiveDialog
                      open={true}
                      onConfirm={onConfirm}
                      onClose={onCancel}
                      title="Are you sure you want to delete?"
                      confirmText="Delete"
                      cancelText="Cancel"
                    >
                      {(automationItem.status === 'Deployed' ||
                        automationItem?.deployedVersionId) &&
                        'Deleting this automation will remove it from all environments immediately '}
                    </ConfirmDestructiveDialog>,
                  );
                  if (isConfirmed) {
                    onDeleteAutomation(automationItem.automationId, automationItem.type);
                  } else return;
                }}
                content={
                  <Box sx={{ color: getContentColor(automationItem.isActive) }}>
                    {triggerText && `${triggerText}:`}
                    {actionText.map((el, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{ mb: '2px', svg: { position: 'relative', top: '3px', mr: '8px' } }}
                        >
                          <ArrowRight color={getContentColor(automationItem.isActive)} />
                          {el}
                        </Box>
                      );
                    })}
                  </Box>
                }
              />
            </Box>
          );
        })}
        {automationForSlackLog && (
          <InfoDialog
            open={automationForSlackLog !== undefined}
            handleClose={() => {
              setAutomationForSlackLog(undefined);
            }}
            PaperPropsSx={{ paddingBottom: '16px', width: '1120px' }}
            showCloseButton
            titleJsx={
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Typography variant="h1-bold">Slack activity</Typography>
                <Typography variant="h2">{automationForSlackLog.name}</Typography>
              </Box>
            }
          >
            <SlackLogsContent automation={automationForSlackLog} />
          </InfoDialog>
        )}
      </Box>
      {completeSfSetupDialog?.isOpen && (
        <CompleteSfSetupForDeployDialog
          cancelDialog={async () => {
            setCompleteSfSetupDialog(undefined);
          }}
          onCompleteItemsCallback={async () => {
            setCompleteSfSetupDialog(undefined);
            await onDeploy(
              completeSfSetupDialog.automationItem,
              completeSfSetupDialog.crmOrgIdsToDeploy,
            );
          }}
          entityName={dataByVariant.nameInMessage}
        />
      )}
    </>
  );
};
