import { useSelector } from 'react-redux';
import {
  selectSingleObjectActiveTab,
  selectSingleObjectSearchTxt,
} from '../../../../reducers/documentationReducer';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { getObjectRules } from '../../helpers';
import { ParsedRecordType, ParsedRule } from '../../ParserTypes';
import { useCanvasFilters } from '../../../pages/configuration-canvas-filters/useCanvasFilters';
import {
  fieldPropsToFilter,
  filterFields,
  filterItemsByQuerySearch,
  filterRules,
  FiltersMap,
  rulePropsToFilter,
} from './filtersOptions';
import { CanvasFilterTypes } from '../../../pages/configuration-canvas-filters/filterTypes';
import { selectCrmOrgAutomations } from '../../../../reducers/global/automationReducers';
import { selectCrmOrgAlerts } from '../../../../reducers/global/alertsReducers';
import {
  selectCrmOrgDedup,
  selectCrmOrgMatching,
} from '../../../../reducers/global/dedupMatchingReducers';
import { selectCrmOrgAssignments } from '../../../../reducers/global/assignmentReducers';
import { ConfigurationType } from '../../dependencies/types';
import {
  updateParsedRuleWithParentElementType,
  getDeployedVersions,
  updateStructureWithParentElementType,
} from './utils';
import { useCanvasAutomationFilters } from '../../../Automations/useCanvasAutomationFilters';
import { selectCrmOrgScheduledAssignments } from '../../../../reducers/global/scheduledAssignmentReducers';
import noop from 'lodash/noop';
import { useDocumentation } from '../../useDocumentation';
import { selectParsedFields } from '../../../../reducers/parserReducer';
import { ActiveState } from '../../activity-state/helpers';

interface UseFilterTabsProps {
  parsedRules: ParsedRule[];
  crmOrgId: string;
  objectTypeName: ObjectTypeName;
}

export const useFilterTabs = ({ parsedRules, crmOrgId, objectTypeName }: UseFilterTabsProps) => {
  const _fields = useSelector(selectParsedFields)?.[objectTypeName.objectType];

  const { filterValues } = useCanvasFilters();
  const { translateStepId, parsedRecordTypes } = useDocumentation();

  const selectedRecordTypesData = filterValues?.[FiltersMap.recordTypes];
  const selectedStepsData = filterValues?.[FiltersMap.steps];
  const selectedAutomationTypesData = filterValues?.[FiltersMap.automationTypes];
  const selectedActiveOnly = filterValues?.[FiltersMap.activeState]
    ? filterValues[FiltersMap.activeState].selectedValues[0] === ActiveState.activeOnly
    : true;

  const selectedFieldTypes = filterValues?.[FiltersMap.fieldTypes]?.selectedValues;
  const selectedUtilization = filterValues?.[FiltersMap.utilization]?.selectedValues;

  const activeTab = useSelector(selectSingleObjectActiveTab);
  const searchTxt = useSelector(selectSingleObjectSearchTxt);

  const sweepAutomations = useSelector(selectCrmOrgAutomations(crmOrgId));
  const sweepAlerts = useSelector(selectCrmOrgAlerts(crmOrgId));
  const sweepDedupe = useSelector(selectCrmOrgDedup(crmOrgId));
  const sweepMatching = useSelector(selectCrmOrgMatching(crmOrgId));
  const sweepAssignments = useSelector(selectCrmOrgAssignments(crmOrgId));
  const sweepScheduledAssignments = useSelector(selectCrmOrgScheduledAssignments(crmOrgId));

  const _searchTxt = activeTab ? (searchTxt ?? '') : '';

  const isActiveTabApex = activeTab === DocumentationTabTypes.APEX;
  const isActiveTabAutomations = activeTab === DocumentationTabTypes.SF_AUTOMATIONS;
  const isActiveTabValidationRules = activeTab === DocumentationTabTypes.VALIDATION_RULES;
  const isActiveTabLayouts = activeTab === DocumentationTabTypes.LAYOUTS;
  const isActiveTabFields = activeTab === DocumentationTabTypes.FIELDS;
  const isActiveTabCPQ = activeTab === DocumentationTabTypes.CPQ_DATA;
  const isActiveTabRecordTypes = activeTab === DocumentationTabTypes.RECORD_TYPES;

  const rulesByQuerySearch = filterItemsByQuerySearch({
    itemProps: rulePropsToFilter,
    searchTxt: _searchTxt,
    items: parsedRules,
  });

  const _selectedActiveOnly = isActiveTabRecordTypes ? selectedActiveOnly : true;

  const _recordTypes =
    parsedRecordTypes?.filter(
      (rt) =>
        rt.objectApiName === objectTypeName.objectType &&
        (_selectedActiveOnly ? rt.isActive : true),
    ) ?? [];

  const recordTypes = isActiveTabRecordTypes
    ? (filterItemsByQuerySearch({
        itemProps: rulePropsToFilter,
        searchTxt: _searchTxt,
        items: _recordTypes,
      }) as ParsedRecordType[])
    : _recordTypes;

  const _automations = getObjectRules({
    objectName: objectTypeName.objectType,
    tab: DocumentationTabTypes.SF_AUTOMATIONS,
    parsedRules:
      (isActiveTabAutomations ? rulesByQuerySearch : parsedRules)?.map((rule) => ({
        ...rule,
        parentType: rule.type,
      })) ?? [],
  });

  const automations = isActiveTabAutomations
    ? filterRules({
        rules: _automations,
        selectedStepValuesData: selectedStepsData,
        selectedTypeValuesData: selectedAutomationTypesData,
        selectedRecordTypeValuesData: selectedRecordTypesData,
        translateStepId,
      })
    : _automations;

  const _layouts = getObjectRules({
    objectName: objectTypeName.objectType,
    tab: DocumentationTabTypes.LAYOUTS,
    parsedRules:
      (isActiveTabLayouts ? rulesByQuerySearch : parsedRules)?.map((rule) => ({
        ...rule,
        parentType: rule.type,
      })) ?? [],
  });

  const layouts = isActiveTabLayouts
    ? filterRules({
        rules: _layouts,
        selectedRecordTypeValuesData: selectedRecordTypesData,
        translateStepId,
      })
    : _layouts;

  const _validationRules = getObjectRules({
    objectName: objectTypeName.objectType,
    tab: DocumentationTabTypes.VALIDATION_RULES,
    parsedRules: updateParsedRuleWithParentElementType(
      ConfigurationType.validationRules,
      isActiveTabValidationRules ? rulesByQuerySearch : parsedRules,
    ),
  });

  const typeFilterMock = {
    type: CanvasFilterTypes.MULTI_GENERIC,
    label: '',
    selectedValues: [],
    selectedValuesData: [],
    isAllSelected: true,
  };

  const validationRules = isActiveTabValidationRules
    ? filterRules({
        rules: _validationRules,
        selectedStepValuesData: selectedStepsData,
        selectedRecordTypeValuesData: selectedRecordTypesData,
        selectedTypeValuesData: typeFilterMock,
        translateStepId,
      })
    : _validationRules;

  const _apex = getObjectRules({
    objectName: objectTypeName.objectType,
    tab: DocumentationTabTypes.APEX,
    parsedRules: updateParsedRuleWithParentElementType(
      ConfigurationType.apexTriggers,
      isActiveTabApex ? rulesByQuerySearch : parsedRules,
    ),
  });

  const apex = isActiveTabApex
    ? filterRules({
        rules: _apex,
        selectedStepValuesData: selectedStepsData,
        selectedRecordTypeValuesData: selectedRecordTypesData,
        selectedTypeValuesData: typeFilterMock,
        translateStepId,
      })
    : _apex;

  const _cpqData = getObjectRules({
    objectName: objectTypeName.objectType,
    tab: DocumentationTabTypes.CPQ_DATA,
    parsedRules: updateParsedRuleWithParentElementType(
      ConfigurationType.cpqData,
      isActiveTabCPQ ? rulesByQuerySearch : parsedRules,
    ),
  });

  const fieldsByQuerySearch = filterItemsByQuerySearch({
    itemProps: fieldPropsToFilter,
    searchTxt: _searchTxt,
    items: _fields?.map((field) => ({
      ...field,
      parentType: ConfigurationType.fields,
    })),
  });

  const fields = isActiveTabFields
    ? filterFields({
        fields: fieldsByQuerySearch ?? [],
        selectedUsageValues: selectedUtilization,
        selectedTypeValues: selectedFieldTypes,
      })
    : _fields;

  //Sweep elements

  const { filteredAutomations } = useCanvasAutomationFilters({
    automations: sweepAutomations ?? [],
    search: searchTxt ?? '',
    setSearch: noop,
  });

  const deployedSweepAutomations = updateStructureWithParentElementType(
    ConfigurationType.sweepAutomation,
    objectTypeName.objectType,
    getDeployedVersions(filteredAutomations),
  );

  const { filteredAutomations: filteredAlerts } = useCanvasAutomationFilters({
    automations: sweepAlerts ?? [],
    search: searchTxt ?? '',
    setSearch: noop,
  });

  const deployedSweepAlerts = updateStructureWithParentElementType(
    ConfigurationType.sweepAlert,
    objectTypeName.objectType,
    getDeployedVersions(filteredAlerts),
  );

  const { filteredAutomations: filteredAssignments } = useCanvasAutomationFilters({
    automations: sweepAssignments ?? [],
    search: searchTxt ?? '',
    setSearch: noop,
  });

  const deployedAssignments = updateStructureWithParentElementType(
    ConfigurationType.sweepAssignment,
    objectTypeName.objectType,
    getDeployedVersions(filteredAssignments),
  );

  const { filteredAutomations: filteredScheduledAssignments } = useCanvasAutomationFilters({
    automations: sweepScheduledAssignments ?? [],
    search: searchTxt ?? '',
    setSearch: noop,
  });

  const deployedScheduledAssignments = updateStructureWithParentElementType(
    ConfigurationType.sweepScheduledAssignment,
    objectTypeName.objectType,
    getDeployedVersions(filteredScheduledAssignments),
  );

  const { filteredAutomations: filteredDedupe } = useCanvasAutomationFilters({
    automations: sweepDedupe ?? [],
    search: searchTxt ?? '',
    setSearch: noop,
  });

  const dedupeNewStructure = updateStructureWithParentElementType(
    ConfigurationType.sweepDedupe,
    objectTypeName.objectType,
    getDeployedVersions(filteredDedupe),
  );

  const { filteredAutomations: filteredMatching } = useCanvasAutomationFilters({
    automations: sweepMatching ?? [],
    search: searchTxt ?? '',
    setSearch: noop,
  });

  const matchingNewStructure = updateStructureWithParentElementType(
    ConfigurationType.sweepMatching,
    objectTypeName.objectType,
    getDeployedVersions(filteredMatching),
  );

  return {
    automations,
    searchTxt,
    validationRules,
    apex,
    fields,
    cpqData: _cpqData,
    recordTypes,
    layouts,

    sweepAutomations: deployedSweepAutomations,
    sweepAlerts: deployedSweepAlerts,
    sweepDedupe: dedupeNewStructure,
    sweepMatching: matchingNewStructure,
    sweepAssignments: deployedAssignments,
    sweepScheduledAssignments: deployedScheduledAssignments,
  };
};
