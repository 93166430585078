import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RootState } from '..';

type FunnelMapsReducerProps = {
  setEnvironmentFunnelMap: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      funnelMap: FunnelMap;
    }>,
  ) => void;

  setLeadingFieldForRecordType: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      recordTypeApiName: string;
      recordTypeName: string;
    }>,
  ) => void;
  setRecordTypeDataValues: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      recordTypeApiName: string;
      recordTypeDataValues: PicklistValue[];
      leadingField: string;
    }>,
  ) => void;

  addRecordTypeDataEntries: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      recordTypes: SingleRecordType[];
    }>,
  ) => void;
};

export const funnelMapsReducer: FunnelMapsReducerProps = {
  setEnvironmentFunnelMap: (state, action) => {
    if (state.environments[action.payload.crmOrgId].data) {
      // @ts-ignore
      state.environments[action.payload.crmOrgId].data.funnelMap = action.payload.funnelMap;
    }
  },
  setLeadingFieldForRecordType: (
    state,
    action: PayloadAction<{
      crmOrgId: string;
      recordTypeApiName: string;
      recordTypeName: string;
    }>,
  ) => {
    const { crmOrgId, recordTypeApiName, recordTypeName } = action.payload;
    const funnelMap = state.environments[crmOrgId].data?.funnelMap;
    if (!funnelMap) return;
    const recordType = funnelMap.recordTypes[recordTypeApiName];
    if (!recordType) return;

    recordType.leadingFieldName = recordTypeName;
  },
  setRecordTypeDataValues: (
    state,
    action: PayloadAction<{
      crmOrgId: string;
      recordTypeApiName: string;
      recordTypeDataValues: PicklistValue[];
      leadingField: string;
    }>,
  ) => {
    const { crmOrgId, recordTypeDataValues, recordTypeApiName, leadingField } = action.payload;
    const recordTypesData = state.environments[crmOrgId].data?.recordTypes;

    if (!recordTypesData) return;
    if (!recordTypesData[recordTypeApiName]) return;

    recordTypesData[recordTypeApiName].leadingField = {
      name: leadingField,
      values: recordTypeDataValues,
    };
  },
  addRecordTypeDataEntries: (
    state,
    action: PayloadAction<{
      crmOrgId: string;
      recordTypes: SingleRecordType[];
    }>,
  ) => {
    const { crmOrgId, recordTypes } = action.payload;

    const recordTypesData = state.environments[crmOrgId].data?.recordTypes;

    if (!recordTypesData) return;

    const newRecordTypes = recordTypes.reduce((acc, recordType) => {
      acc[`${recordType.objectName}.${recordType.name}`] = recordType;
      return acc;
    }, {} as RecordTypesData);

    Object.assign(recordTypesData, newRecordTypes);
  },
};

export const selectEnvFunnelMap = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.funnelMap;

export const selectEnvRecordTypesData = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.recordTypes;

export const selectEnvFunnelsData = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.funnels;

export const selectEnvHubspotFunnelsData = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.hubspotFunnels;
