import { ConfigurationType } from '../dependencies/types';

export enum SearchType {
  apiNameAndLabel = 'NAME_LABEL_SEARCH',
  content = 'CONTENT_SEARCH',
}

export interface SearchListItem {
  id: string;
  name: string;
  label: string;
  type: string;
  parentType: ConfigurationType;
  lastModifiedAtAndBy: string;
  createdAtAndBy: string;
  objectName?: string;
  objectLabel?: string;
  match?: string;
  filename?: string;
  isActive?: boolean;
}

export type SearchResponse = {
  //should be the same as BE
  [ConfigurationType.apexClasses]: ConfigurationInstanceData[];
  [ConfigurationType.apexTriggers]: ConfigurationInstanceData[];
  [ConfigurationType.approvalProcesses]: ConfigurationInstanceData[];
  [ConfigurationType.fields]: FieldData[];
  [ConfigurationType.flows]: FlowMetadataRecordProperties[];
  [ConfigurationType.objects]: NameProperties[];
  [ConfigurationType.processBuilderFlows]: FlowMetadataRecordProperties[];
  [ConfigurationType.validationRules]: ConfigurationInstanceData[];
  [ConfigurationType.workflowRules]: ConfigurationInstanceData[];
  [ConfigurationType.layouts]: ConfigurationInstanceData[];
  [ConfigurationType.recordTypes]: ConfigurationInstanceData[];
};

export type MatchData = { filename: string; match: string };

type ConfigurationBaseMatchProperties = ConfigurationInstanceData & MatchData;

export type ContentSearchResponse = {
  [key in keyof SearchResponse]: (SearchResponse[key][number] & MatchData)[];
} & {
  files?: ConfigurationBaseMatchProperties[];
  unclassifiedConfigurations: ConfigurationBaseMatchProperties[];
};
