import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';
import { useAutomationsContext } from '../Automations/AutomationsContext';
import { Stack } from '@mui/material';
import { getAddAutomationButtonByType } from '../Automations/helper';
import { colors, Typography } from '@sweep-io/sweep-design';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import _noop from 'lodash/noop';

const ScheduledAssignmentsEmptyStateDialog = ({
  closeDialog,
  transitionDuration,
  onCreate,
  crmOrgId,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
  onCreate: (object: string) => void;
  crmOrgId: string;
}) => {
  const { emptyStateImage, emptyStateTitle, emptyStateSubTitle } = useAutomationsContext();

  return (
    <DialogOnCanvas onClose={closeDialog} open={true} transitionDuration={transitionDuration}>
      <Stack alignItems="center" pt="15%">
        <Stack gap={2} alignItems="center">
          {emptyStateImage}
          <Stack gap={1}  alignItems="center" textAlign="center" maxWidth="532px">
            <Typography variant="h3">{emptyStateTitle}</Typography>
            <Typography variant="body" color={colors.grey[700]}>
              {emptyStateSubTitle}{' '}
            </Typography>
          </Stack>
          {getAddAutomationButtonByType({
            variant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS,
            props: {
              disabled: false,
              onSelect: onCreate,
              crmOrgId,
              buttonText: 'Get started',
              automationVariant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS,
              isEmptyState: true,
              onAddClick: _noop,
            },
          })}
        </Stack>
      </Stack>
    </DialogOnCanvas>
  );
};

export default ScheduledAssignmentsEmptyStateDialog;
