import { IconButton, Tooltip, Typography, colors } from '@sweep-io/sweep-design';
import { Star } from '@sweep-io/sweep-design/dist/icons';
import { Box, CircularProgress } from '@mui/material';
import { useDocumentation } from '../useDocumentation';

interface AddObjectButtonProps {
  object: ObjectTypeName;
  highlightAndDisable?: boolean;
  isLoading?: boolean;
}

export const AddObjectButton = ({
  object,
  highlightAndDisable,
  isLoading,
}: AddObjectButtonProps) => {
  const { onAddObject } = useDocumentation();

  if (isLoading) {
    return (
      <Box display="flex" gap={1} pl={1} alignItems="center" minHeight="32px">
        <Typography variant="caption" whiteSpace="nowrap">
          Scanning object
        </Typography>
        <CircularProgress size={10} />
      </Box>
    );
  }

  return (
    <Tooltip
      placement="top"
      title={highlightAndDisable ? 'Added to favorites' : 'Add to favorites'}
    >
      <Box
        sx={{
          '& svg path': {
            //TODO support in design system
            fill: highlightAndDisable ? colors.lemon[300] : '',
            stroke: highlightAndDisable ? colors.sun[300] : '',
          },

          '& button': {
            pointerEvents: highlightAndDisable ? 'none' : 'all',
          },
        }}
      >
        <IconButton
          variant={highlightAndDisable ? 'outlined' : 'flat'}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onAddObject(object.objectType);
          }}
        >
          <Star />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
