import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDependencies,
  clearDocumentationDialog,
  clearFilters,
  setDependenciesConfigurationItem,
  setObjectName,
  setSingleObjectName,
  setTab,
  updateOrAddFieldToParsedConfigurationItems,
} from '../../reducers/documentationReducer';
import { selectParsedRecordTypes, selectParsedFields } from '../../reducers/parserReducer';
import {
  DocumentationTabTypes,
  SWEEP_ELEMENTS_TAB_TYPES,
} from '../../types/enums/DocumentationTabTypes';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import { ConfigurationType } from '@server/parser';
import { selectEnvironments } from '../../reducers/global/globalReducer';
import { useCanvasFilters } from '../pages/configuration-canvas-filters/useCanvasFilters';
import { setSfFieldName } from '../pages/rollups/rollupHelpers';
import { appRoutes } from '../../constants/appRoutes';
import SweepStagesModel from '../../models/stagesModel';
import useSweepNavigate from '../common/useSweepNavigate';
import {
  StepEntityType,
  StepPillEntityType,
  GroupPillEntityType,
  GroupLabelEntityType,
  NodeEntityTypes,
} from '../multi-canvas/canvasTypes';
import { getDocumentationTabFromCanvasPill } from '../pages/canvas-pages/getDocumentationTabFromCanvasPill';
import useAutomationsCanvasInteractions from '../pages/canvas-pages/useAutomationsCanvasInteractions';
import useParser from '../parser/useParser';
import {
  createRecordTypeId,
  createStepId,
  findRTStepFromFunnelStep,
} from './selected-object/filters/utils';
import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { selectCrmOrgObjectTypes } from '../pages/environments/environmentsReducer';
import { useSweepCanvasFitView } from '../multi-canvas/useSweepCanvasFitView';
import { useTabFiltersFunctions } from './selected-object/filters/useTabFilterFunctions';
import { ACTIONS_EVENTS } from '../../services/events';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { telemetry } from '../../telemetry';
import { DependenciesContentViewType } from './dependencies/types';
import { useGetFilteredParsedRules } from './selected-object/filters/useGetFilteredParsedRules';
import { ActiveState } from './activity-state/helpers';
import { FiltersMap } from './selected-object/filters/filtersOptions';
import { MASTER_KEY } from '../pages/funnel-map-flow/dialogs/import-funnel/utils';

export type onFieldClickProps = {
  objectName: string;
  fieldName: string;
  crmOrgId: string;
  isRollup: boolean;
  contentType: DependenciesContentViewType;
};

export const useDocumentation = () => {
  const [isLoadingSingleField, setIsLoadingSingleField] = useState(false);
  const { canvasFunnelMap, canvasCrmOrgId: crmOrgId } = useConfigurationCanvas();
  const { isExpandedMode } = useExpandedMode();
  const sendBiEvent = useSendBiEvent();

  const dispatch = useDispatch();
  const { get_crmOrgField } = useCrmOrgsApiFacade();
  const { focusOnCanvasElements, setFilterValues, filterValues } = useCanvasFilters();
  const { navigate } = useSweepNavigate();
  const { parseObject, parseObjectOnDemand, objectsBeingParsed } = useParser({ crmOrgId });
  const { registerPredefinedFilters: registerInternalDocumentationFilters } =
    useTabFiltersFunctions();
  const { onEntityClick } = useAutomationsCanvasInteractions();
  const { fitView } = useSweepCanvasFitView();

  const _crmOrgObjects = useSelector(selectCrmOrgObjectTypes(crmOrgId));
  const globalEnvironments = useSelector(selectEnvironments);
  const fields = useSelector(selectParsedFields);

  const activeOnly =
    filterValues?.[FiltersMap.activeState]?.selectedValues[0] !== ActiveState.activeAndInactive;
  const parsedRecordTypes = useSelector(selectParsedRecordTypes);
  const parsedRules = useGetFilteredParsedRules(activeOnly);

  const dispatchSingleObjectName = useCallback(
    (
      singleObjectName?: ObjectTypeName,
      tab?: DocumentationTabTypes,
      focusOnElement = singleObjectName?.objectType,
      fitAroundFirstStepOnly = false,
    ) => {
      if (!tab) {
        if (isExpandedMode) {
          dispatch(setSingleObjectName({ singleObjectName, tab: DocumentationTabTypes.FIELDS }));
          return;
        }

        dispatch(setObjectName({ objectTypeName: singleObjectName }));
      } else {
        dispatch(setSingleObjectName({ singleObjectName, tab }));
      }

      focusOnElement &&
        focusOnCanvasElements([focusOnElement], { maxZoom: 0.97 }, fitAroundFirstStepOnly);
    },
    [dispatch, focusOnCanvasElements, isExpandedMode],
  );

  const backToObjectList = useCallback(() => {
    dispatchSingleObjectName(undefined);
    dispatch(clearDependencies());

    //to fitView when object list is displayed
    setTimeout(() => {
      fitView({ duration: 400, maxZoom: 1 });
    }, 10);
  }, [dispatch, dispatchSingleObjectName, fitView]);

  const backToElementsList = (event: any, el: { value: string; label: string }) => {
    dispatchSingleObjectName({ label: el.label, objectType: el.value }, undefined);
  };

  const _clearFilters = useCallback(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  const getRollup = useCallback(
    ({
      fieldName,
      objectName,
      crmOrgId,
    }: {
      fieldName: string;
      objectName: string;
      crmOrgId: string;
    }) => {
      const rollups = globalEnvironments?.[crmOrgId]?.data?.rollups;
      return rollups?.find((rollup) => rollup.name === setSfFieldName(fieldName, objectName));
    },
    [globalEnvironments],
  );

  const getParsedField = useCallback(
    (objectName: string, fieldName: string) => {
      return fields?.[objectName]?.find((field) => field.name === fieldName);
    },
    [fields],
  );

  const handleRollup = useCallback(
    ({ fieldName, objectName, crmOrgId, contentType }: onFieldClickProps) => {
      const rollup = getRollup({ fieldName, objectName, crmOrgId });

      if (rollup) {
        dispatch(
          setDependenciesConfigurationItem({
            id: rollup?.rollupId,
            parentType: ConfigurationType.rollups,
            dependencyType: rollup.fieldType,
            name: fieldName,
            objectName,
            clearHistory: true,
            contentType,
          }),
        );
      } else {
        telemetry.captureError(new Error('Field marked as rollup was not found: '), {
          fieldName,
          objectName,
          crmOrgId,
        });
      }
    },
    [getRollup, dispatch],
  );

  const onFieldClick = useCallback(
    async (props: onFieldClickProps) => {
      const { isRollup, fieldName, objectName, crmOrgId, contentType } = props;

      if (isRollup) {
        handleRollup(props);
        return;
      }

      const existingParsedField = getParsedField(objectName, fieldName);

      if (existingParsedField) {
        dispatch(
          setDependenciesConfigurationItem({
            id: existingParsedField.id,
            parentType: ConfigurationType.fields,
            dependencyType: existingParsedField.type,
            name: fieldName,
            objectName,
            clearHistory: true,
            contentType,
          }),
        );
        return;
      }

      setIsLoadingSingleField(true);
      try {
        const field = await get_crmOrgField({ crmOrgId, objectApiName: objectName, fieldName });

        dispatch(
          updateOrAddFieldToParsedConfigurationItems({
            crmOrgId,
            field,
            objectName,
          }),
        );

        dispatch(
          setDependenciesConfigurationItem({
            id: field.id,
            parentType: ConfigurationType.fields,
            dependencyType: field.type,
            name: fieldName,
            objectName,
            clearHistory: true,
            contentType,
          }),
        );
      } catch (e) {
        telemetry.captureError(e);
      }
      setIsLoadingSingleField(false);
    },
    [dispatch, get_crmOrgField, getParsedField, handleRollup],
  );

  const onObjectNotInFunnelMapClick = useCallback(
    async (objectTypeName: ObjectTypeName) => {
      // If no record types are converted for given object, no configuration items (validationRules, apex etc..) are being stored on BE,
      // In order to get them, additional parse call is required and the response of this call is NOT being stored as well
      await parseObjectOnDemand(objectTypeName.objectType, objectTypeName.label);
      dispatchSingleObjectName(objectTypeName);
    },
    [parseObjectOnDemand, dispatchSingleObjectName],
  );

  const onObjectInFunnelMapClick = useCallback(
    (objectTypeName: ObjectTypeName, tab?: DocumentationTabTypes) => {
      dispatchSingleObjectName(objectTypeName, tab);
    },
    [dispatchSingleObjectName],
  );

  const onTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    activeTab: DocumentationTabTypes,
  ) => {
    _clearFilters();
    dispatch(setTab({ tab: activeTab }));
  };

  const onAddObject = useCallback(
    async (objectName: string) => {
      sendBiEvent({
        name: ACTIONS_EVENTS.documentationAddObject,
        props: { object: objectName },
      });

      try {
        await parseObject(objectName);
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [parseObject, sendBiEvent],
  );

  const registerCanvasFilters = useCallback(
    ({
      selectedStepName,
      selectedRecordTypeValue,
    }: {
      selectedRecordTypeValue: string;
      selectedStepName?: string;
    }) => {
      if (selectedStepName) {
        setFilterValues({ recordTypes: [selectedRecordTypeValue], steps: [selectedStepName] });
      } else {
        setFilterValues({ steps: [], recordTypes: [selectedRecordTypeValue] });
      }
    },
    [setFilterValues],
  );

  const onDocumentationEntityClick = useCallback(
    ({
      stepId,
      funnelId,
      recordTypeApiName,
      entity,
      event,
    }: {
      stepId?: string;
      funnelId?: string;
      entity: StepEntityType | StepPillEntityType | GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
      recordTypeApiName?: string;
    }) => {
      const funnel = funnelId ? canvasFunnelMap.funnelsData?.[funnelId] : undefined;
      const recordType = recordTypeApiName
        ? canvasFunnelMap.recordTypesData?.[recordTypeApiName]
        : undefined;

      if (!funnel && !recordType) {
        return;
      }

      if (entity.type === NodeEntityTypes.GroupLabel) {
        const objectName = funnel
          ? funnel.funnelDetails.leadingObject.objectName
          : recordType?.objectName;
        const rtName = recordType?.name ?? funnel?.recordType.name;
        const objectTypeName = _crmOrgObjects?.find((obj) => obj.objectType === objectName);

        if (objectTypeName && rtName) {
          dispatchSingleObjectName(
            objectTypeName,
            DocumentationTabTypes.RECORD_TYPES,
            funnel?.id ?? objectName + '.' + rtName,
            true,
          );
          dispatch(
            setDependenciesConfigurationItem({
              id: objectName + '.' + rtName,
              name: rtName,
              dependencyType: ConfigurationType.recordTypes,
              parentType: ConfigurationType.recordTypes,
              objectName,
              clearHistory: true,
              contentType: DependenciesContentViewType.default,
            }),
          );
        }
        return;
      }

      // Support only pill clicks
      if (entity.type !== NodeEntityTypes.StepPill && entity.type !== NodeEntityTypes.GroupPill) {
        return;
      }

      let selectedRecordTypeValue: string | undefined;
      let stage: SweepStage | undefined;
      let selectedStepName: string | undefined;
      let singleObjectApiName = '';

      const defaultActiveTab = getDocumentationTabFromCanvasPill(entity.canvasPillType);

      if (funnel) {
        const recordTypeName = funnel.recordType.name ?? MASTER_KEY;
        selectedRecordTypeValue = createRecordTypeId(recordTypeName, funnel.recordType.objectName);

        const stagesModel = new SweepStagesModel(funnel.funnelDetails?.stages || []);
        stage = stepId ? stagesModel.stageByIdOrUndefined(stepId)?.getStage() : undefined;

        selectedStepName = stage
          ? createStepId(
              recordTypeName,
              funnel.recordType.objectName,
              findRTStepFromFunnelStep(funnel, stage.stageName, parsedRecordTypes)?.name ??
                stage.stageName,
            )
          : undefined;
        singleObjectApiName = funnel.recordType.objectName ?? '';
      }

      if (recordType && !funnel) {
        selectedRecordTypeValue = createRecordTypeId(recordType.name, recordType.objectName);
        selectedStepName = stepId;
        singleObjectApiName = recordType.objectName ?? '';
      }

      if (defaultActiveTab) {
        if (!SWEEP_ELEMENTS_TAB_TYPES.includes(defaultActiveTab)) {
          registerInternalDocumentationFilters({
            selectedStepName,
            defaultActiveTab,
            selectedRecordTypeValue,
            objects: _crmOrgObjects ?? [],
            singleObjectApiName,
          });

          if (selectedRecordTypeValue) {
            //On object change canvas filters don't receive new objectType on time
            //and this is documentation requirement only not to show object filter (what would fix it)
            //setTimeout is a hack-fix
            setTimeout(() => {
              registerCanvasFilters({
                selectedStepName: stepId,
                selectedRecordTypeValue: selectedRecordTypeValue ?? '',
              });
            }, 0);
          }
        } else {
          registerInternalDocumentationFilters({
            defaultActiveTab,
            objects: _crmOrgObjects ?? [],
            singleObjectApiName,
          });

          //On object change canvas filters don't receive new objectType on time
          //and this is documentation requirement only not to show object filter (what would fix it)
          //setTimeout is a hack-fix
          setTimeout(() => {
            onEntityClick({
              stepId,
              funnelId,
              recordTypeApiName,
              entity,
              event,
            });
          }, 0);
          return;
        }
      }
    },
    [
      dispatch,
      registerInternalDocumentationFilters,
      _crmOrgObjects,
      canvasFunnelMap.funnelsData,
      canvasFunnelMap.recordTypesData,
      onEntityClick,
      parsedRecordTypes,
      registerCanvasFilters,
      dispatchSingleObjectName,
    ],
  );

  const onCrmOrgIdChange = useCallback(
    (crmOrgId: string) => {
      navigate(`${appRoutes.documentation.route}/${crmOrgId}`);
    },
    [navigate],
  );

  const translateStepId = useCallback(
    (stepId: string) => {
      let selectedStage: SweepStage | undefined;

      const funnel = Object.values(canvasFunnelMap.funnelsData ?? {})?.find((_funnel) =>
        _funnel.funnelDetails.stages.find((_stage) => {
          if (_stage._stageId === stepId) {
            selectedStage = _stage;
            return _stage;
          }
        }),
      );

      if (!funnel || !selectedStage) {
        return stepId;
      }

      return createStepId(
        funnel.recordType.name ?? MASTER_KEY,
        funnel.recordType.objectName,
        findRTStepFromFunnelStep(funnel, selectedStage.stageName, parsedRecordTypes)?.name ?? '',
      );
    },
    [canvasFunnelMap, parsedRecordTypes],
  );

  const handleClose = useCallback(() => {
    dispatch(clearDocumentationDialog());
  }, [dispatch]);

  return {
    dispatchSingleObjectName,
    backToObjectList,
    backToElementsList,

    _clearFilters,
    isLoadingSingleField,
    onFieldClick,

    onObjectNotInFunnelMapClick,
    onObjectInFunnelMapClick,

    onTabChange,
    getRollup,

    parsedRules,
    parsedRecordTypes,

    objectsBeingParsed,
    onAddObject,
    onCrmOrgIdChange,
    translateStepId,
    onDocumentationEntityClick,
    handleClose,
  };
};
