import { Typography, colors } from '@sweep-io/sweep-design';
import { Sort } from '../Sort';
import { Box } from '@mui/material';
import { useSortElements } from '../useSortElements';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import pluralize from 'pluralize';

export const ElementSubheader = ({
  items,
  isManagePackageInstalled = false,
  objectLabel,
  activeTab,
}: {
  items: any;
  objectLabel?: string;
  activeTab: DocumentationTabTypes;
  isManagePackageInstalled?: boolean;
}) => {
  const { sortedItems, _sortOptions, _sortKey, onSortChange } = useSortElements({
    items,
    activeTab,
    isManagePackageInstalled,
  });

  const label =
    DocumentationTabTypes.CPQ_DATA === activeTab
      ? objectLabel + pluralize(' Record', sortedItems?.length)
      : activeTab;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pl: 2,
        pr: 3,
        pb: 2,
      }}
    >
      <Typography variant="body-medium" color={colors.grey[700]}>
        {sortedItems?.length ?? ''} {label}
      </Typography>

      <Sort sortKey={_sortKey} onSortChange={onSortChange} options={_sortOptions} />
    </Box>
  );
};
