import {
  Campaign,
  CampaignBase,
} from '../../components/pages/canvas-pages/canvas-hubspot-page/campaign-dialog/campaignTypes';
import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

export const useCampaignsApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_campaigns: useCallback(async (): Promise<Campaign[]> => {
      const response = await sweepApi.get('/campaigns');
      return response.data;
    }, [sweepApi]),
    post_campaign: useCallback(
      async (campaign: CampaignBase): Promise<Campaign> => {
        const response = await sweepApi.post('/campaigns', campaign);
        return response.data;
      },
      [sweepApi],
    ),
    patch_campaign: useCallback(
      async (campaignId: string, campaign: CampaignBase): Promise<Campaign> => {
        const response = await sweepApi.patch(`/campaigns/${campaignId}`, campaign);
        return response.data;
      },
      [sweepApi],
    ),
    delete_campaign: useCallback(
      async (campaignId: string) => {
        await sweepApi.delete(`/campaigns/${campaignId}`);
      },
      [sweepApi],
    ),
  };
};
