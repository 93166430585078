import { useCallback, useEffect, useMemo } from 'react';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { ObjectTypeChip } from '../../common/ObjectTypeChip';

import { getUsedRecordTypesAndObjects } from '../configuration-canvas/utils/getUsedRecordTypesAndObjects';
import { useFitAroundElements } from './useFitAroundElements';
import { AdvancedFilter, AdvancedFilterItem } from '../../common/advanced-filter/AdvancedFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCanvasFilterSelectedValuesFor,
  setCanvasFilterItemsDataFromFilterItems,
  setCanvasFilterSelectedValuesFor,
} from './canvasFiltersReducer';
import { advancedFilterItemsToCanvasFilterItemsData } from './helper';

interface RecordTypesFilterProps {
  funnelsData: FunnelsData;
  recordTypesData?: RecordTypesData;
  automations?: AutomationStructureNew[];
  crmOrgId: string;
  filteredObjects?: string[];
  filterKey: string;
}

export const RecordTypesFilter = (filterProps: RecordTypesFilterProps) => {
  const { filterKey, crmOrgId, funnelsData, recordTypesData, automations, filteredObjects } =
    filterProps;
  const dispatch = useDispatch();
  const selectedValues = useSelector(selectCanvasFilterSelectedValuesFor(filterKey));
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  const { usedRecordTypes } = useMemo(
    () =>
      getUsedRecordTypesAndObjects({
        recordTypesData,
        funnelsData,
        automations,
      }),
    [automations, funnelsData, recordTypesData],
  );

  const recordTypesItems: AdvancedFilterItem[] = useMemo(() => {
    let _recordTypeItems = Object.values(usedRecordTypes).map((recordType) => {
      const object = objectTypesByName[recordType.objectName];
      return {
        value: recordType.id,
        label: recordType.label,
        labelDecoration: (
          <ObjectTypeChip
            objectType={recordType.objectName}
            label={object?.label ?? recordType.objectName}
          />
        ),
      };
    });
    if (filteredObjects && filteredObjects?.length > 0) {
      _recordTypeItems = _recordTypeItems.filter((recordType) =>
        filteredObjects.includes(recordType.value.split('.')[0]),
      );
    }
    return _recordTypeItems;
  }, [filteredObjects, objectTypesByName, usedRecordTypes]);

  const { fitOnElements } = useFitAroundElements({ funnelsData });

  // This useEffect removes selected values that are not in the record types list
  useEffect(() => {
    if (selectedValues.length !== 0) {
      const newSelectedValues = selectedValues.filter((value) =>
        recordTypesItems.map((recordTypeItem) => recordTypeItem.value).includes(value),
      );
      if (newSelectedValues.length !== selectedValues.length) {
        dispatch(
          setCanvasFilterSelectedValuesFor({ filterKey, selectedValues: newSelectedValues }),
        );

        fitOnElements(newSelectedValues);
      }
    }
  }, [dispatch, filterKey, fitOnElements, recordTypesItems, selectedValues]);

  const onSelectedItemsChange = useCallback(
    (items: string[]) => {
      dispatch(setCanvasFilterSelectedValuesFor({ filterKey, selectedValues: items }));
      fitOnElements(items);
    },
    [dispatch, filterKey, fitOnElements],
  );

  useEffect(() => {
    dispatch(
      setCanvasFilterItemsDataFromFilterItems({
        filterKey,
        items: advancedFilterItemsToCanvasFilterItemsData(recordTypesItems),
      }),
    );
  }, [dispatch, filterKey, recordTypesItems]);

  return (
    <AdvancedFilter
      filterButtonDataTestId="record-types-filter-button"
      items={recordTypesItems}
      selectedItems={selectedValues}
      onSelectedItemsChange={onSelectedItemsChange}
      texts={{ title: 'Record type' }}
    />
  );
};
