import { Box, Typography } from '@mui/material';
import {
  EXPANDED_FUNNEL_PREVIEW_HEIGHT,
  MINIMIZED_FUNNEL_PREVIEW_HEIGHT,
} from '../../../constants';
import { DEFAULT_VISIBILITY_MAP } from '../../../constants/visibility';
import { colors } from '@sweep-io/sweep-design';
import { SweepMultiCanvas } from '../../multi-canvas/SweepCanvas';
import { useState, useEffect } from 'react';
import { CanvasMode } from '../../multi-canvas/canvasTypes';
import { getObjectTypeColor } from '../../multi-canvas/helpers/getObjectTypeColor';
import { useFunnelMapElements } from '../../funnel-map-canvas/useFunnelMapElements';

export interface FunnelMapPreviewProps extends Funnel {
  snapshot?: FunnelSnapshot;
}

export const SingleFunnelMapPreview = ({
  funnel,
  expanded,
  sxProps,
  showPreviewBackground,
  fitViewTimeoutValue,
  renderNoPreview,
  showControls,
  noFitView,
  canvasMode = CanvasMode.PREVIEW2,
}: {
  funnel: FunnelMapPreviewProps;
  expanded?: boolean;
  sxProps?: any;
  showPreviewBackground?: boolean;
  fitViewTimeoutValue?: number;
  renderNoPreview?: boolean;
  showControls?: boolean;
  noFitView?: boolean;
  canvasMode?: CanvasMode;
}) => {
  const { sweepNodes, sweepEdges, sweepGroups } = useFunnelMapElements({
    funnelMap: {
      id: 'VIRTUAL_FUNNEL_MAP',
      funnels: {
        [funnel.id]: {
          position: { row: 0, column: 0 },
        },
      },
      recordTypes: {},
      name: funnel.name,
      funnelsData: {
        [funnel.id]: funnel,
      },
      recordTypesData: {},
      hubspotFunnels: {},
      hubspotFunnelsData: {},
      createdAt: '',
      createdById: '',
      updatedAt: '',
      updatedById: '',
      isPinned: false,
    },
    visibilityMap: DEFAULT_VISIBILITY_MAP,
    objectTypesByName: {},
  });

  const renderNoPreviewWithBackground = (!sweepNodes || sweepNodes.length === 0) && renderNoPreview;
  const [renderCanvas, setRenderCanvas] = useState(true);
  const [localCanvasNodesAndEdges, setLocalCanvasNodesAndEdges] = useState({
    sweepNodes,
    sweepEdges,
    sweepGroups,
  });

  // To fix the animation:
  // Problem is that if one uses the use effect after the nodes change
  // sweepCanvas renders first only after does the fitView. This is perceptible.
  // To achieve perfect rendering and perfect fitView we need to use
  // reactFlow initial render fitView properties. This means that we need a new instance
  // That's why are using a timeout of zero to do that.
  // We also only set the nodes on the next render. It will be unmounted by then.
  useEffect(() => {
    setRenderCanvas(false);
    setLocalCanvasNodesAndEdges({ sweepNodes, sweepEdges, sweepGroups });
    setTimeout(() => setRenderCanvas(true), 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnel]);

  return (
    <Box
      sx={{
        height: `${expanded ? EXPANDED_FUNNEL_PREVIEW_HEIGHT : MINIMIZED_FUNNEL_PREVIEW_HEIGHT}`,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid ${colors.grey[300]}`,
        position: 'relative',
        backgroundColor: renderNoPreviewWithBackground
          ? getObjectTypeColor(funnel?.funnelDetails?.leadingObject?.objectName).connection
          : '',
        opacity: renderNoPreviewWithBackground ? '.3' : '',
        ...sxProps,
      }}
      data-testid="funnel-preview"
    >
      {renderNoPreviewWithBackground && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            position: 'relative',
            top: '140px',
          }}
        >
          <Typography variant="caption-bold">No preview</Typography>
        </Box>
      )}

      {sweepNodes && sweepNodes.length > 0 && (
        <>
          <div style={{ width: '100%', height: '100%' }}>
            {renderCanvas && (
              <SweepMultiCanvas
                sweepGroups={localCanvasNodesAndEdges.sweepGroups}
                sweepNodes={localCanvasNodesAndEdges.sweepNodes}
                sweepEdges={localCanvasNodesAndEdges.sweepEdges}
                canvasMode={canvasMode}
                showPreviewBackground={showPreviewBackground}
                fitViewTimeoutValue={fitViewTimeoutValue}
                visibilityMap={DEFAULT_VISIBILITY_MAP}
                onConnectClick={() => {}}
                backgroundObjectType={funnel.funnelDetails.leadingObject.objectName}
                disableGroups
                readonly
                showControls={showControls}
                noFitView={noFitView}
              />
            )}
          </div>
        </>
      )}
    </Box>
  );
};
