import { colors } from '@sweep-io/sweep-design';

const objectTypeToColors: {
  [key: string]: {
    step: string;
    connection: string;
    light: string;
  };
} = {
  Lead: {
    step: colors.Lead,
    connection: colors.sun[500],
    light: colors.sun[100],
  },
  Opportunity: {
    step: colors.lilac[300],
    connection: colors.lilac[500],
    light: colors.lilac[100],
  },
  Contact: {
    step: colors.sky[300],
    connection: colors.sky[500],
    light: colors.sky[100],
  },
  Account: {
    step: colors.seafoam[300],
    connection: colors.seafoam[500],
    light: colors.seafoam[100],
  },
  Case: {
    step: colors.gum[300],
    connection: colors.gum[500],
    light: colors.gum[100],
  },
  __hubspot__: {
    step: '#FDD8D2',
    connection: '#FF7A59',
    light: '#FDD8D2',
  },
  __default: {
    step: colors.blue[100],
    connection: colors.blue[500],
    light: colors.blue[100],
  },
};

export const objectTypeColorNames = Object.keys(objectTypeToColors);

export const getObjectTypeColor = (objectType?: string) =>
  (objectType && objectTypeToColors[objectType]) || objectTypeToColors.__default;
