import { useSelector } from 'react-redux';
import { selectLatestDeployment } from '../../../../../reducers/documentationReducer';
import { ReadOnlyRollup } from '@server/read-only-elements.types';
import { Box } from '@mui/material';
import { keyToName } from './consts';
import { MainSectionWithHeaderWrapper } from './organisms/MainSectionWithHeaderWrapper';
import { Subtext } from './organisms/atoms/Subtext';
import { ElementsUsedByClient } from '@server/read-only-elements.consts';
import { ListWithBullets } from './organisms/atoms/ListWithBullets';
import useObjectTypesWithFetch from '../../../../../hooks/useObjectTypesWithFetch';
import { propertiesToIcon } from './organisms/helpers/icons';
import { getOperationsLabelMap } from '../../../../pages/rollups/rollupHelpers';
import { RollupOperator } from '@server/rollup.consts';

export const RollupTemplate = ({
  elementId,
  crmOrgId,
}: {
  elementId: string;
  crmOrgId: string;
}) => {
  const latestDeployment = useSelector(selectLatestDeployment(elementId));
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId, useSfObjects: true });

  if (!latestDeployment) {
    return <></>;
  }

  const { field, calculation, lookup_relations } = latestDeployment as ReadOnlyRollup;
  const fieldObjectLabel = objectTypesByName[field.objectName]?.label ?? field.objectName;

  return (
    <Box pt={1}>
      <MainSectionWithHeaderWrapper
        title={keyToName['field']}
        titleIcon={propertiesToIcon['field']}
        withPaddingLeft
      >
        <Subtext text={`Object Name: ${field.objectName}`} />
        <Subtext text={`Label: ${fieldObjectLabel}`} />
        <Subtext text={`Type: ${field.type}`} />
        {field.description && <Subtext text={`Description: ${field.description}`} />}
        {field.helpText && <Subtext text={`Description: ${field.helpText}`} />}
      </MainSectionWithHeaderWrapper>

      {lookup_relations && (
        <MainSectionWithHeaderWrapper
          title={keyToName['lookup_relations']}
          titleIcon={propertiesToIcon['lookup_relations']}
          withPaddingLeft
          noPaddingBottom
        >
          <Subtext
            key={lookup_relations.childObject + elementId}
            text={`Child Object: ${lookup_relations.childObject}`}
          />

          {lookup_relations.relationshipField && (
            <Subtext
              key={lookup_relations.relationshipField}
              text={`Relationship Field: ${lookup_relations.relationshipField}`}
            />
          )}

          {lookup_relations.filter[0] === ElementsUsedByClient.ROLLUP_CALCULATION ? (
            <>
              <Subtext
                key={lookup_relations.filter[0] + elementId}
                text={lookup_relations.filter[0]}
              />
              <ListWithBullets
                listItems={lookup_relations.filter.slice(1, lookup_relations.filter.length)}
              />
            </>
          ) : (
            lookup_relations.filter.map((txt) => <Subtext key={txt + elementId} text={txt} />)
          )}
        </MainSectionWithHeaderWrapper>
      )}

      <MainSectionWithHeaderWrapper
        title={keyToName['calculation']}
        titleIcon={propertiesToIcon['calculation']}
        withPaddingLeft
      >
        <Subtext
          text={`Operation: ${
            getOperationsLabelMap(field.type as SweepFieldTypes)[
              calculation.operation as RollupOperator
            ]
          }`}
        />
        {calculation.childAggregatedField && (
          <Subtext text={`Field to calculate: ${calculation.childAggregatedField}`} />
        )}
        {calculation.schedule && <Subtext text={`When to calculate: ${calculation.schedule}`} />}
        <Subtext text={`Calculation sharing mode: ${calculation.sharingMode}`} />
      </MainSectionWithHeaderWrapper>
    </Box>
  );
};
