import { useMemo } from 'react';
import { ConfigurationCanvasPanelHeader } from '../../pages/configuration-canvas-panel/ConfigurationCanvasPanelHeader';
import { useDocumentation } from '../useDocumentation';
import { Breadcrumbs } from './Breadcrumbs';
import { useSelector } from 'react-redux';
import { selectSingleObjectActiveTab } from '../../../reducers/documentationReducer';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';

interface SelectedObjectHeaderProps {
  objectTypeName: ObjectTypeName;
  onCloseDialog?: () => void;
  withExpandedModeButton?: boolean;
  withObjectCardTabActive?: boolean;
}

export const SelectedObjectHeader = ({
  objectTypeName,
  onCloseDialog,
  withExpandedModeButton,
  withObjectCardTabActive,
}: SelectedObjectHeaderProps) => {
  const activeTab = useSelector(selectSingleObjectActiveTab);
  const isObjectCardActiveTab =
    (activeTab && activeTab === DocumentationTabTypes.CARDS_LIST) || withObjectCardTabActive;

  const { backToObjectList, backToElementsList } = useDocumentation();

  const baseBreadcrumbs = useMemo(
    () => [
      {
        value: 'Documentation',
        label: 'Documentation',
        onClick: backToObjectList,
      },
      {
        label: objectTypeName.label,
        value: objectTypeName.objectType,
        onClick: backToElementsList,
      },
    ],
    [objectTypeName, backToElementsList, backToObjectList],
  );

  const breadcrumbList = useMemo(
    () =>
      activeTab && !isObjectCardActiveTab
        ? [...baseBreadcrumbs, { label: activeTab, value: activeTab, onClick: () => {} }]
        : baseBreadcrumbs,
    [activeTab, isObjectCardActiveTab, baseBreadcrumbs],
  );

  return (
    <ConfigurationCanvasPanelHeader
      onClose={onCloseDialog}
      withExpandedModeButton={withExpandedModeButton}
    >
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </ConfigurationCanvasPanelHeader>
  );
};
