import { DialogLoader } from '../../../../../common/dialogs/DialogLoader';
import { colors } from '@sweep-io/sweep-design';
import { LeadingField, ParsedRecordType } from '../../../../../documentation/ParserTypes';
import { SingleFunnelMapPreview } from '../../../../funnel-maps/SingleFunnelMapPreview';
import { useMemo } from 'react';
import { ErrorState } from './ErrorState';
import { RightPanelSkeleton } from './RightPanelSkeleton';
import { SearchSelect, SearchSelectItem } from '../../../../../common/SearchSelect';
import { Box } from '@mui/material';
import { injectSectionsIntoNestedItems } from '../../../../../common/sweep-object-field-selectors/SimpleFieldSelector';
import { CanvasMode } from '../../../../../multi-canvas/canvasTypes';

interface PreviewWrapperProps {
  selectedRecordType?: ParsedRecordType;
  selectedLeadingField?: LeadingField;
  funnelStructure?: ShortFunnel;
  onSelectLeadingField: (item: SearchSelectItem<any>) => void;
  isPanelLoading: boolean;
}

export const PreviewWrapper = ({
  selectedRecordType,
  funnelStructure,
  selectedLeadingField,
  onSelectLeadingField,
  isPanelLoading,
}: PreviewWrapperProps) => {
  const mockedFunnel = useMemo(
    () =>
      ({
        updatedById: '',
        recordType: { name: '', label: '', description: '', objectName: '' },
        accountId: '',
        snapshotsIds: [],
        createdAt: '',
        createdById: '',
        ...funnelStructure,
      }) as Funnel,
    [funnelStructure],
  );

  if (!funnelStructure || isPanelLoading) {
    return <RightPanelSkeleton isLoading={isPanelLoading} />;
  }

  if (!!selectedRecordType && !selectedRecordType.leadingFields?.length) {
    return <ErrorState />;
  }

  const mappedFields = selectedRecordType?.leadingFields.map((item) => ({
    label: item.label || item.name || '',
    value: item.name,
    data: item,
  }));

  const leadingFields = injectSectionsIntoNestedItems(mappedFields ?? []);

  return (
    <>
      <DialogLoader visible={false} />

      {selectedRecordType && selectedRecordType.leadingFields.length > 1 && (
        <Box sx={{ padding: '22px 0 0 0' }}>
          <SearchSelect
            items={leadingFields}
            onChange={onSelectLeadingField}
            SelectProps={{
              value: selectedLeadingField?.name,
              renderValue: () => selectedLeadingField?.label,
              sx: {
                minWidth: '228px',
              },
            }}
            label="Leading field"
          />
        </Box>
      )}

      <SingleFunnelMapPreview
        sxProps={{
          minHeight: '300px',
          height: '300px',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '12px',
          overflow: 'hidden',
        }}
        funnel={mockedFunnel}
        showControls
        renderNoPreview
        canvasMode={CanvasMode.FIT_NODES_PREVIEW}
      />
    </>
  );
};
