import { useSelector } from 'react-redux';

import { useEffect } from 'react';
import { Box } from '@mui/material';
import { TabList } from '@sweep-io/sweep-design';
import { selectSingleObjectActiveTab } from '../../../../reducers/documentationReducer';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { ParsedRule } from '../../ParserTypes';
import { RecordTypesLabelsByNameAndObjectName } from '../../types';
import { useDocumentation } from '../../useDocumentation';
import { SelectedObjectHeader } from '../SelectedObjectHeader';
import { useSelectedObjectMenuElements } from '../useSelectedObjectMenuElements';

interface ExpandedObjectProps {
  objectTypeName: ObjectTypeName;
  parsedRules: ParsedRule[];
  crmOrgId: string;
  stepNamesUsedInCanvas: string[];
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
  onCloseDialog?: () => void;
}

export const ExpandedObject = ({
  objectTypeName,
  parsedRules,
  crmOrgId,
  stepNamesUsedInCanvas,
  recordTypeNamesUsedInCanvas,
  onCloseDialog,
}: ExpandedObjectProps) => {
  const { onTabChange, dispatchSingleObjectName } = useDocumentation();
  const activeTab = useSelector(selectSingleObjectActiveTab);
  const { sfElements, sweepElements } = useSelectedObjectMenuElements({
    parsedRules,
    crmOrgId,
    objectTypeName,
    isExpanded: true,
    recordTypeNamesUsedInCanvas,
    stepNamesUsedInCanvas,
  });

  useEffect(() => {
    if (activeTab === DocumentationTabTypes.CARDS_LIST || !activeTab) {
      dispatchSingleObjectName(objectTypeName, DocumentationTabTypes.FIELDS);
    }
  }, [activeTab, objectTypeName, dispatchSingleObjectName]);

  return (
    <Box height="100%">
      <SelectedObjectHeader
        withObjectCardTabActive
        objectTypeName={objectTypeName}
        withExpandedModeButton
        onCloseDialog={onCloseDialog}
      />
      <Box
        pt={1}
        height="100%"
        sx={{
          '&  > .MuiBox-root, & .MuiTabPanel-root': {
            height: '100%', //TODO add in design system
          },
          '& .MuiTabs-root': {
            pl: 2,
            pr: 2,
          },
        }}
      >
        <TabList
          list={sfElements as Parameters<typeof TabList>['0']['list']}
          dropdown={{
            title: 'Sweep Elements',
            list: sweepElements as Parameters<typeof TabList>['0']['list'],
          }}
          value={activeTab}
          onChange={onTabChange}
        />
      </Box>
    </Box>
  );
};
