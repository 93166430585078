import { Box } from '@mui/material';
import { PromptsBoxProps } from './promptsBoxTypes';
import PromptBubble from './PromptBubble';

export const PromptsBoxSimple = ({ predefinedPrompts, onConfirm, disabled }: PromptsBoxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        pb: 2,
      }}
    >
      {Object.keys(predefinedPrompts).map((key) => {
        const prompt = predefinedPrompts[key];
        return (
          <PromptBubble
            key={key}
            onClick={() => onConfirm(prompt)}
            prompt={prompt}
            disabled={disabled}
          />
        );
      })}
    </Box>
  );
};
