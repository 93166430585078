export const FULL_NAME_SEPARATOR = '%&?';

export const recordTypeToNodeId = (recordTypeId: string, fullName: string) => {
  return `${recordTypeId}${FULL_NAME_SEPARATOR}${fullName}`;
};

export const nodeIdToRecordTypePicklistFullName = (id: string) => {
  const [, recordTypePicklistFullName] = id.split(FULL_NAME_SEPARATOR);
  return recordTypePicklistFullName;
};
