import { Box, Stack, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserById } from '../../reducers/accountUsersReducer';
import { colors, Typography } from '@sweep-io/sweep-design';
import { displayCorrectFormat } from '../common/rule-builder/selectors/DateSelector';

type Size = 'default' | 'large';

enum Sizes {
  default = '24px',
  large = '32px',
}

interface InitialsAvatarProps {
  userIdOrName?: string;
  changedAt?: string;
  size?: Size;
  showUserFullName?: boolean;
  isInactive?: boolean;
}

export const InitialsAvatar = ({
  userIdOrName,
  changedAt,
  size = 'default',
  showUserFullName = false,
  isInactive,
}: InitialsAvatarProps) => {
  const date = displayCorrectFormat(changedAt);
  const user = useSelector(selectUserById(userIdOrName ?? ''));
  const changedByInitials = (user?.name || userIdOrName?.trim())
    ?.split(' ')
    .map((n: any, idx) => ([0, 1].includes(idx) ? n[0]?.slice(0, 1) : undefined))
    .flat()
    .filter((el) => !!el)
    .join('');

  if (!changedByInitials) {
    return (
      <Box pl={1} sx={{ color: isInactive ? colors.grey[500] : 'inherit' }}>
        -
      </Box>
    );
  }

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <Tooltip
        placement="top"
        title={`Last updated by ${user?.name ?? userIdOrName}${date ? `, ${date}` : ''}`}
        disableHoverListener={showUserFullName}
      >
        <Box
          sx={{
            backgroundColor: isInactive ? colors.grey[200] : colors.blue[100],
            borderRadius: '50%',
            height: Sizes[size],
            width: Sizes[size],
            minWidth: Sizes[size],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="avatar"
        >
          <Typography
            variant="body-medium"
            //color is assigned in table cell, in aim to overwrite it for this one scenario !important is used
            color={isInactive ? `${colors.grey[500]} !important` : colors.blue[500]}
          >
            {size === 'large' ? changedByInitials : changedByInitials.slice(0, 1)}
          </Typography>
        </Box>
      </Tooltip>
      {showUserFullName && (
        <Typography
          variant="body"
          color={isInactive ? `${colors.grey[500]} !important` : 'initial'}
        >
          {user?.name ?? userIdOrName}
        </Typography>
      )}
    </Stack>
  );
};
