import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { RoutingTabs, useRoutingTabs } from './useRoutingTabs';
import { RoutingTabElementsList } from './RoutingTabElementsList';
import { RoutingTabContent } from './RoutingTabContent';
import { ConfigurationCanvasLeftPanel } from '../pages/configuration-canvas-panel';
import { useCallback } from 'react';
import MembersDialog from './MembersDialog';
import GroupsDialog from './groups-dialog/GroupsDialog';
import TerritoriesDialog from './TerritoriesDialog';
import { RoutingTabsInfo } from './routingTabsInfo';
import { useSelector } from 'react-redux';
import { useRunOnce } from '../common/useRunOnce';
import { AutomationAssignmentsContextProvider } from './AutomationAssignmentsContextProvider';
import { AutomationScheduledAssignmentsContextProvider } from './AutomationScheduledAssignmentsContextProvider';
import { RoutingAutomationsContextWrapper } from './RoutingAutomationsContextWrapper';

const useIsEmptyState = (routingTab?: RoutingTabs) => {
  const routeInfo = routingTab ? RoutingTabsInfo[routingTab] : undefined;
  const { selector } = routeInfo ?? {};
  const items = useSelector(selector ?? (() => undefined));
  return items?.length === 0;
};

const DRILLABLE_ROUTES = Object.keys(RoutingTabs).filter(
  (route) => !!RoutingTabsInfo[route as RoutingTabs]?.ContentComponent,
);

const RoutingPage = () => {
  const { appCrmOrgId } = useConfigurationCanvas();
  const { clearTab, changeTab, routingTab } = useRoutingTabs();
  const isEmptyState = useIsEmptyState(routingTab);
  const isNoAssignments = useIsEmptyState(RoutingTabs.assignment);
  const isMenuNavigation = !routingTab;
  const isDrillableRoute = routingTab ? DRILLABLE_ROUTES.includes(routingTab) : false;
  const showElementsList = !isDrillableRoute || isEmptyState;
  const disableOpenAnimation = !!routingTab;
  const transitionDuration = disableOpenAnimation ? 0 : undefined;

  const goToTab = (tab: RoutingTabs) => {
    changeTab(tab, appCrmOrgId);
  };

  const goToRoutingHome = useCallback(() => {
    clearTab(appCrmOrgId);
  }, [appCrmOrgId, clearTab]);

  //First Time Experience product: if entering "routing" and there are no assignments, open the templates dialog
  //(should not applicable for deep links, only menu navigation)
  useRunOnce(() => {
    if (isNoAssignments && isMenuNavigation) {
      changeTab(RoutingTabs.assignment, appCrmOrgId);
    }
  });

  return (
    <>
      <ConfigurationCanvasLeftPanel
        sx={{
          '.configuration-canvas-panel-content': {
            overflow: 'hidden',
          },
        }}
      >
        {showElementsList && <RoutingTabElementsList goToTab={goToTab} />}
        {!showElementsList && <RoutingTabContent goToRoutingHome={goToRoutingHome} />}
      </ConfigurationCanvasLeftPanel>

      {routingTab === RoutingTabs.assignment && (
        <AutomationAssignmentsContextProvider>
          <RoutingAutomationsContextWrapper goToRoutingHome={goToRoutingHome} />
        </AutomationAssignmentsContextProvider>
      )}

      {routingTab === RoutingTabs.scheduled && (
        <AutomationScheduledAssignmentsContextProvider>
          <RoutingAutomationsContextWrapper goToRoutingHome={goToRoutingHome} />
        </AutomationScheduledAssignmentsContextProvider>
      )}
      {routingTab === RoutingTabs.members && (
        <MembersDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
      {routingTab === RoutingTabs.groups && (
        <GroupsDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
      {routingTab === RoutingTabs.territories && (
        <TerritoriesDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
    </>
  );
};

export default RoutingPage;
