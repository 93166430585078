import { SxProps, TableRow as MuiTableRow, TableRowProps, Theme } from '@mui/material';
import {
  DataTableBaseColumn,
  DataTableDraggableColumn,
  DataTableRow,
  DataTableSortableColumn,
  DataTableVariant,
} from './TableTypes';
import { colors } from '@sweep-io/sweep-design';
import TableRowCells from './TableRowCells';

interface RowProps<TRow extends DataTableRow> {
  row: TRow;
  columns: DataTableSortableColumn<TRow>[] | DataTableBaseColumn[] | DataTableDraggableColumn[];
  sxRowFunction?: (isDragging: boolean) => SxProps<Theme>;
  tableRowProps?: TableRowProps;
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    columnData: DataTableSortableColumn<TRow> | DataTableBaseColumn | DataTableDraggableColumn,
  ) => void;
  actionableButtonsOnHover?: boolean;
  variant: DataTableVariant;
}

function TableRow<TRow extends DataTableRow = any>({
  row,
  columns,
  sxRowFunction,
  tableRowProps,
  onClick,
  actionableButtonsOnHover,
  variant,
  ...rowProps
}: RowProps<TRow>) {
  const sxRow = sxRowFunction ? sxRowFunction(Boolean(false)) : {};

  return (
    <MuiTableRow
      data-testid="data-table-row"
      sx={{
        minHeight: '60px', //design team asked for it, if your design doesn't match please talk to them
        backgroundColor: row.isActive ? colors.grey[100] : 'transparent',
        '& .actions': {
          opacity: actionableButtonsOnHover ? 0 : 1,
        },
        '&:hover .actions': {
          opacity: 1,
        },
        ...sxRow,
        animation: row._animation ? row._animation : '',
        cursor: row.isClickable ? 'pointer' : 'default',
      }}
      className="SweepDataTableRow"
      {...tableRowProps}
      {...rowProps}
    >
      <TableRowCells
        allowReorder={false}
        isRenderEmpty={false}
        row={row}
        columns={columns}
        onClick={
          !!onClick
            ? (
                e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
                column:
                  | DataTableSortableColumn<TRow>
                  | DataTableBaseColumn
                  | DataTableDraggableColumn,
              ) => onClick(e, column)
            : undefined
        }
        variant={variant}
      />
    </MuiTableRow>
  );
}

export default TableRow;
