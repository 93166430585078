import { useSelector } from 'react-redux';
import { selectParsedRules } from '../../../../reducers/parserReducer';
import {
  selectSingleObject,
  selectSingleObjectActiveTab,
} from '../../../../reducers/documentationReducer';
import { useMemo } from 'react';
import { isItemActive } from '../../helpers';
import { automationTypes } from '../../../parser/parserUtils';
import { RuleType } from '../../ParserTypes';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';

export const useGetFilteredParsedRules = (activeOnly?: boolean) => {
  const parsedRules = useSelector(selectParsedRules);
  const activeDocumentationTab = useSelector(selectSingleObjectActiveTab);
  const objectName = useSelector(selectSingleObject);

  const filteredParsedRules = useMemo(() => {
    const isActiveAndInactive = !activeOnly;

    if (
      !isActiveAndInactive ||
      !activeDocumentationTab ||
      !ACTIVE_INACTIVE_RELEVANT_DOCUMENTATION_TAB_TYPES.includes(activeDocumentationTab) ||
      !objectName
    ) {
      return parsedRules.filter(isItemActive);
    }

    /**
     * we want to get inactive ONLY for current objectName and ruleTypes
     */
    return parsedRules.filter((item) => {
      if (
        item.objectApiNames.includes(objectName.objectType) &&
        DocumentationTabTypeToRuleType[activeDocumentationTab]?.includes(item.type)
      ) {
        return item;
      }
      return isItemActive(item);
    });
  }, [activeDocumentationTab, objectName, activeOnly, parsedRules]);

  return filteredParsedRules;
};

export const ACTIVE_INACTIVE_RELEVANT_DOCUMENTATION_TAB_TYPES = [
  DocumentationTabTypes.APEX,
  DocumentationTabTypes.VALIDATION_RULES,
  DocumentationTabTypes.SF_AUTOMATIONS,
];

const DocumentationTabTypeToRuleType: { [key in any]: RuleType[] } = {
  [DocumentationTabTypes.APEX]: [RuleType.apexTriggers],
  [DocumentationTabTypes.VALIDATION_RULES]: [RuleType.validationRules],
  [DocumentationTabTypes.SF_AUTOMATIONS]: automationTypes,
};
