import PaywallBlockUpgradeDialog from '../../../dashboard/paywall/PaywallBlockUpgradeDialog';
import UpgradeImage from '../../../dashboard/header/generalUpgradeImage.svg?react';
import PaywallBlockStartTrialDialog from '../../../dashboard/paywall/PaywallBlockStartTrialDialog';
import PaywallFunnelDeploy from './PaywallFunnelDeploy';
import { PaywallSource } from '../../../dashboard/paywall/paywallTypes';
import usePaywall, { PaywallAction } from '../../../dashboard/paywall/usePaywall';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';

const TITLE = 'Upgrade to deploy Funnels';
const FEATURE_DESCRIPTION = 'deploying funnels directly to Salesforce in one click';

const CanvasDeployPaywallBlock = ({ closeDialog }: { closeDialog: () => void }) => {
  const { paywallAction, sendEncounterPaywallEvent } = usePaywall();

  useRunOnceWhenTruthy(() => {
    sendEncounterPaywallEvent({
      source: PaywallSource.FUNNEL_DEPLOY,
    });
  }, paywallAction !== PaywallAction.NONE);


  //TODO change to "!isPaywallActive" SWEEP-5282
  if (paywallAction === PaywallAction.NONE) {
    return null;
  }

  //TODO change to "isPaywallActive" SWEEP-5282
  if (paywallAction === PaywallAction.TALK_TO_SALES) {
    return <PaywallFunnelDeploy closeDialog={closeDialog} />;
  }

  if (paywallAction === PaywallAction.START_TRIAL) {
    return (
      <PaywallBlockStartTrialDialog
        closeDialog={closeDialog}
        title={TITLE}
        featureDescription={FEATURE_DESCRIPTION}
        source={PaywallSource.FUNNEL_DEPLOY}
      />
    );
  }

  if (paywallAction === PaywallAction.UPGRADE) {
    return (
      <PaywallBlockUpgradeDialog
        closeDialog={closeDialog}
        img={<UpgradeImage />}
        title={TITLE}
        featureDescription={FEATURE_DESCRIPTION}
      />
    );
  }

  return null;
};

export default CanvasDeployPaywallBlock;
