import { Box, TextField, TextFieldProps } from '@mui/material';
import { IconButton, colors } from '@sweep-io/sweep-design';
import { Search as MagnifyingGlassIcon } from '@sweep-io/sweep-design/dist/icons';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';
import React from 'react';

type SearchInputProps = {
  withFixedMagnifyingGlassIcon?: boolean;
  TextFieldProps?: TextFieldProps;
  dataTestId?: string;
  placeholder?: string;
  onClearButtonClick?: () => void;
  endAdornment?: React.ReactNode;
  forceCloseButton?: boolean;
  autoFocus?: boolean;
};

export const SearchInput = ({
  onClearButtonClick,
  withFixedMagnifyingGlassIcon,
  TextFieldProps = {},
  dataTestId,
  placeholder,
  endAdornment,
  forceCloseButton,
  autoFocus,
}: SearchInputProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <TextField
        data-testid={dataTestId}
        size="small"
        fullWidth
        autoFocus={autoFocus}
        placeholder={placeholder || 'Search'}
        InputProps={{
          sx: {
            paddingLeft: withFixedMagnifyingGlassIcon ? '25px' : 0,
            '&::placeholder': {
              color: colors.grey[700],
            },
          },
          startAdornment: withFixedMagnifyingGlassIcon ? (
            <Box
              sx={{
                color: colors.grey[800],
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <MagnifyingGlassIcon />
            </Box>
          ) : null,
          endAdornment: (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: 1 }}>
              {endAdornment && endAdornment}
              {(forceCloseButton || (!!TextFieldProps.value && TextFieldProps.value !== '')) &&
                onClearButtonClick && (
                  <IconButton variant="flat" size="tiny" onClick={onClearButtonClick}>
                    <CloseIcon />
                  </IconButton>
                )}
            </Box>
          ),
        }}
        {...TextFieldProps}
      />
    </Box>
  );
};
