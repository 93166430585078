import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import { useMemo } from 'react';
import { AutomationCanvasFilter } from '../pages/canvas-pages/canvasAutomationsTypes';
import {
  CanvasFilterTypes,
  SweepCanvasFiltersMap,
} from '../pages/configuration-canvas-filters/filterTypes';
import { selectScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import { scheduledAssignmentsSortOptions } from '../pages/canvas-pages/consts';
import { AutomationScheduledAssignmentsContextProvider } from './AutomationScheduledAssignmentsContextProvider';
import { useShouldRequestToDeployFilter } from '../common/deploy-button/useShouldRequestToDeploy';

interface ScheduledAssignmentsProps {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  externalFilter?: (assignment: AutomationStructureNew) => boolean;
  disableCanvasTemplates?: boolean;
  selectedFilters: AutomationDialogFilters;
  setSelectedFilters: (filters: Partial<AutomationDialogFilters>) => void;
  setHoveredItem?: (automationId?: string | undefined) => void;
}

const useGetScheduledAssignmentFilters = (automations: AutomationStructureNew[] = []) => {
  const requestToDeployFilter = useShouldRequestToDeployFilter(automations, 1);
  const filters = useMemo(() => {
    let assignmentRuleFilters: SweepCanvasFiltersMap = {
      [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
    };

    if (requestToDeployFilter) {
      assignmentRuleFilters = {
        ...assignmentRuleFilters,
        ...requestToDeployFilter,
      };
    }
    return assignmentRuleFilters;
  }, [requestToDeployFilter]);
  return filters;
};

const ScheduledAssignments = ({
  recordTypesData,
  funnelsData,
  externalFilter,
  disableCanvasTemplates,
  selectedFilters,
  setSelectedFilters,
  setHoveredItem,
}: ScheduledAssignmentsProps) => {
  const scheduledAssignments = useSelector(selectScheduledAssignments);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS });

  const assignmentsToDisplay = externalFilter
    ? scheduledAssignments?.filter(externalFilter)
    : scheduledAssignments;

  const filters = useGetScheduledAssignmentFilters(scheduledAssignments);

  if (!scheduledAssignments) return <CenteredCircularProgress />;

  return (
    <>
      <Box pb={2} flex={1}>
        {crmOrg && (
          <AutomationDialogContent
            automations={assignmentsToDisplay ?? []}
            onDeleteAutomation={onDeleteAutomation}
            crmOrgId={crmOrg.id}
            onSaveOrCreate={onSaveOrCreate}
            recordTypesData={recordTypesData}
            funnelsData={funnelsData}
            onToggleActivation={onToggleActivation}
            sortOptions={scheduledAssignmentsSortOptions}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            disableCanvasTemplates={disableCanvasTemplates}
            setHoveredItem={setHoveredItem}
            onDeployAutomation={onDeployAutomation}
          />
        )}
      </Box>
      <ConfigurationCanvasFilters filters={filters} automations={scheduledAssignments} />
    </>
  );
};

const ScheduledAssignmentsWithContext = (scheduledAssignmentProps: ScheduledAssignmentsProps) => {
  return (
    <AutomationScheduledAssignmentsContextProvider>
      <ScheduledAssignments {...scheduledAssignmentProps} />
    </AutomationScheduledAssignmentsContextProvider>
  );
};

export default ScheduledAssignmentsWithContext;
