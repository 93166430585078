import { AutomationDialog } from '../../Automations/AutomationDialog';
import useAutomationApiPerType from '../../Automations/useAutomationApiPerType';
import { MultipleRuleBuildersProvider } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { ConfigurationCanvasFilters } from '../configuration-canvas/ConfigurationCanvasFilters';
import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { useGetAutomationFilters } from './automationFilters';
import { useAutomationsContext } from '../../Automations/AutomationsContext';

export const ConfigurationCanvasAutomationsPanel = ({
  automations,
  onClose,
  disableCanvasTemplates,
}: {
  automations?: AutomationStructureNew[];
  onClose?: () => void;
  disableCanvasTemplates?: boolean;
}) => {
  const { automationVariant } = useAutomationsContext();
  const { canvasFunnelMap, canvasCrmOrgId } = useConfigurationCanvas();
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant });

  const filters = useGetAutomationFilters(automations);

  return (
    <>
      <MultipleRuleBuildersProvider>
        <AutomationDialog
          isLoading={!automations}
          onSaveOrCreate={onSaveOrCreate}
          onToggleActivation={onToggleActivation}
          automations={automations ?? []}
          crmOrgId={canvasCrmOrgId}
          funnelMap={canvasFunnelMap}
          onDeleteAutomation={onDeleteAutomation}
          automationVariant={automationVariant}
          onDeployAutomation={onDeployAutomation}
          onClose={onClose}
          disableCanvasTemplates={disableCanvasTemplates}
        />
      </MultipleRuleBuildersProvider>
      <ConfigurationCanvasFilters filters={filters} automations={automations} />
    </>
  );
};
