import { Box, MenuItem, Paper, TextField } from '@mui/material';
import {
  AssignmentGroupConditionalLimitDetails,
  AssignmentGroupLimit,
  AssignmentGroupLimitTimeLimitRate,
} from '../../../../../reducers/assignmentGroupTypes';

import { LimitMembersTimePanel } from '../LimitMembersTimePanel';
import { Switch, Typography, colors } from '@sweep-io/sweep-design';
import { useCallback } from 'react';
import SweepSelect from '../../../../common/SweepSelect';
import { ConditionalLimitPanel } from '../ConditionalLimitPanel';

export type LimitMembersVars = {
  groupLimit?: DeepPartial<AssignmentGroupLimit> | null;
  resetAllMembersLimit?: number;
};

interface LimitMembersPanelProps {
  onChange: (onChangeProps: LimitMembersVars) => void;
  vars: LimitMembersVars;
}

const { Hour } = AssignmentGroupLimitTimeLimitRate;

export const LimitMembersPanel = ({ onChange, vars }: LimitMembersPanelProps) => {
  const { groupLimit, resetAllMembersLimit } = vars;
  const limitMembers = Boolean(vars.groupLimit);

  const onLimitMembersChanged = useCallback(
    (newLimitMembers: boolean) => {
      let newLimitMembersVars: LimitMembersVars = { ...vars };
      if (newLimitMembers) {
        const rate = groupLimit?.type === 'TIME_LIMIT' ? groupLimit?.details?.rate ?? Hour : Hour;
        newLimitMembersVars = {
          ...vars,
          groupLimit: { ...vars.groupLimit, type: 'TIME_LIMIT', details: { rate } },
        };
      } else {
        newLimitMembersVars.groupLimit = null;
      }

      onChange(newLimitMembersVars);
    },
    [groupLimit?.details, groupLimit?.type, onChange, vars],
  );

  const onConditionalLimitChanged = useCallback(
    (limitDetails: DeepPartial<AssignmentGroupConditionalLimitDetails>) => {
      const newVars: LimitMembersVars = {
        ...vars,
        groupLimit: {
          type: 'CONDITIONAL_LIMIT',
          details: limitDetails,
        },
      };
      onChange(newVars);
    },
    [onChange, vars],
  );

  const selectMethod = (
    <>
      <Typography variant="body" color={colors.grey[800]}>
        Method
      </Typography>
      <SweepSelect
        SelectProps={{
          value: vars.groupLimit?.type ?? 'TIME_LIMIT',
          onChange: (event) => {
            const newLimitMembersVars: LimitMembersVars = Object.assign({}, vars);
            newLimitMembersVars.groupLimit = {
              type: event.target.value as 'TIME_LIMIT' | 'CONDITIONAL_LIMIT',
            };
            onChange(newLimitMembersVars);
          },
        }}
      >
        <MenuItem value={'TIME_LIMIT'}>Time-Based</MenuItem>
        <MenuItem value={'CONDITIONAL_LIMIT'}>Number of records owned by member</MenuItem>
      </SweepSelect>
    </>
  );

  const renderBottomPanel = () => {
    if (groupLimit?.type === 'CONDITIONAL_LIMIT') {
      return (
        <ConditionalLimitPanel
          onChange={onConditionalLimitChanged}
          limitDetails={groupLimit.details}
        >
          {selectMethod}
        </ConditionalLimitPanel>
      );
    }
    if (groupLimit?.type === 'TIME_LIMIT') {
      return (
        <LimitMembersTimePanel
          onChange={onChange}
          vars={{
            groupLimit,
            resetAllMembersLimit: vars.resetAllMembersLimit,
          }}
        >
          {selectMethod}
        </LimitMembersTimePanel>
      );
    }
  };

  return (
    <Box>
      <Box display="flex" gap="12px" alignItems="center">
        <Switch
          checked={Boolean(limitMembers)}
          onChange={(e) => {
            onLimitMembersChanged(e.target.checked);
          }}
        />
        <Typography variant="h4">Set limit for group members</Typography>
      </Box>
      {limitMembers && (
        <Paper sx={{ p: 2, mt: 2, backgroundColor: colors.grey[100] }} elevation={0}>
          <Box display="flex" flexDirection="column" gap={2}>
            {renderBottomPanel()}
            <Box display="flex" gap={2} alignItems="center">
              <Typography variant="body" color={colors.grey[800]}>
                Set limit for all group members (optional)
              </Typography>
              <TextField
                type="number"
                value={resetAllMembersLimit}
                variant="outlined"
                InputProps={{
                  placeholder: 'Insert #',
                  inputProps: {
                    min: 1,
                    max: 99,
                  },
                }}
                sx={{ width: '103px' }}
                onChange={(e) =>
                  onChange({
                    ...vars,
                    resetAllMembersLimit: Number(e.target.value),
                  })
                }
              />
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
};
