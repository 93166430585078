import { useCallback, useState } from 'react';

export function useObjectFlags<T = boolean>(initialFlags: { [object: string]: T } = {}) {
  const [objectFlags, setObjectFlags] = useState<Record<string, T>>(initialFlags);

  const setObjectFlagFor = useCallback(
    (object: string, flag: T) => {
      objectFlags[object] = flag;
      setObjectFlags({ ...objectFlags });
    },
    [objectFlags],
  );

  const getObjectFlagFor = useCallback((object: string) => objectFlags[object], [objectFlags]);

  const hasAny = Boolean(Object.values(objectFlags).find((objectFlag) => Boolean(objectFlag)));

  const removeObject = useCallback(
    (object: string) => {
      const newObjectFlags = { ...objectFlags };
      delete newObjectFlags[object];
      setObjectFlags(newObjectFlags);
    },
    [objectFlags],
  );

  return {
    setObjectFlagFor,
    getObjectFlagFor,
    removeObject,
    hasAny,
    objectFlags,
  };
}
