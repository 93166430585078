import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { useFunnelSettingsDialog } from './useFunnelSettingsDialog';

import { LostStepPanel } from './LostStepPanel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ObjectTypeChip } from '../../../../common/ObjectTypeChip';
import useObjectTypesWithFetch from '../../../../../hooks/useObjectTypesWithFetch';
import { selectFunnelSettingsLostSteps } from './funnelSettingsReducer';

export const SingleFunnelSettings = ({
  funnel,
  crmOrgId,
}: {
  funnel: Funnel;
  crmOrgId: string;
}) => {
  const lostSteps = useSelector(selectFunnelSettingsLostSteps);
  const { addLostStep, updateLostStep, deleteLostStep } = useFunnelSettingsDialog();
  const leadingObject = funnel.funnelDetails.leadingObject;
  const currentLostSteps = leadingObject?.objectName
    ? lostSteps?.[funnel.id as any][leadingObject?.objectName]?.lostSteps ?? []
    : [];

  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  return (
    <Box sx={{ background: colors.grey[100], padding: ' 29px 22px 22px', marginBottom: '24px' }}>
      <Box
        sx={{
          display: 'flex',
          whiteSpace: 'nowrap',
          justifyContent: 'space-between',
          padding: '0 0 26px',
          borderRadius: '8px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: '8px' }}>
          <Typography variant="h4">{funnel.name}</Typography>
          {leadingObject && (
            <ObjectTypeChip
              objectType={leadingObject.objectName}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  {objectTypesByName[leadingObject.objectName]?.label ?? leadingObject.objectName}
                  <ChevronRightIcon sx={{ width: '12px' }} />
                  {leadingObject?._leadingFieldLabels?.[1] ?? ''}
                </Box>
              }
              height="24px"
            />
          )}
        </Box>
      </Box>

      <LostStepPanel
        objectName={leadingObject?.objectName ?? ''}
        lostSteps={currentLostSteps ?? []}
        onChangeStageName={(_stageId, stageName) =>
          updateLostStep({
            funnelId: funnel.id ?? '',
            objectType: leadingObject?.objectName ?? '',
            _stageId,
            stageName,
          })
        }
        onDeleteStage={(objectType: string, stageId: string) =>
          deleteLostStep({ funnelId: funnel.id ?? '', _stageId: stageId, objectType })
        }
        onAddStage={() =>
          addLostStep({
            funnelId: funnel.id ?? '',
            stageName: '',
            objectType: leadingObject?.objectName ?? '',
          })
        }
      />
    </Box>
  );
};
