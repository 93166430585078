import { useEffect, useState } from 'react';
import { DocumentationDialog } from '../../documentation/DocumentationDialog';
import { WithConfigurationCanvas } from '../configuration-canvas/WithConfigurationCanvas';
import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { VisibilityLayers } from '../../../types/enums/VisibilityLayers';
import { HoveredPin } from '../../documentation/types';
import { VisibilityElementLayersSelector } from '../configuration-canvas/types';
import { ConfigurationCanvasLeftPanel } from '../configuration-canvas-panel';
import { useSelector } from 'react-redux';
import { selectSingleObject } from '../../../reducers/documentationReducer';
import { getFunnelsStepNames } from '../../documentation/helpers';
import { useDocumentation } from '../../documentation/useDocumentation';
import { useCanvasFilters } from '../configuration-canvas-filters/useCanvasFilters';
import { getRecordTypeNames } from '../../documentation/selected-object/utils';
import { useHighlightEntities } from '../../documentation/selected-object/filters/useHighlightEntities';

const visibilityElementsLayerSelector: VisibilityElementLayersSelector = {
  type: 'layersSelector',
  layersId: 'configurationCanvas',
  visibilityLayersSelectors: {
    [VisibilityLayers.SWEEP_AUTOMATIONS]: true,
    [VisibilityLayers.NURTURING_STEPS]: true,
    [VisibilityLayers.COMMENTS]: true,
    [VisibilityLayers.VALIDATION_RULES]: true,
    [VisibilityLayers.SFDC_AUTOMATIONS]: true,
    [VisibilityLayers.APEX]: true,
    [VisibilityLayers.PLAYBOOK_ALERTS]: true,
    [VisibilityLayers.ASSIGNMENT_RULES]: true,
    [VisibilityLayers.SCHEDULED_ASSIGNMENTS]: true,
    [VisibilityLayers.FUNNEL_MATCHING_AND_DEDUPLICATION]: true,
  },
  defaultVisibilityMap: {
    [VisibilityLayers.NURTURING_STEPS]: true,
    [VisibilityLayers.COMMENTS]: true,
    [VisibilityLayers.SFDC_AUTOMATIONS]: true,
    [VisibilityLayers.APEX]: true,
    [VisibilityLayers.VALIDATION_RULES]: true,
  },
};

export const CanvasDocumentationPage = () => {
  const { canvasFunnelMap, appCrmOrgId } = useConfigurationCanvas();
  const { _setCanvasFiltersFunnelMap } = useCanvasFilters();
  const { onDocumentationEntityClick, onCrmOrgIdChange } = useDocumentation();

  const [hoveredPin, setHoveredPin] = useState<HoveredPin>();
  const highlightEntities = useHighlightEntities(canvasFunnelMap, hoveredPin);
  const objectType = useSelector(selectSingleObject);

  const visibilityElements = visibilityElementsLayerSelector;

  useEffect(() => {
    //required to make zoom work properly when filters are not yet rendered
    if (canvasFunnelMap) {
      _setCanvasFiltersFunnelMap(canvasFunnelMap);
    }
  }, [canvasFunnelMap, _setCanvasFiltersFunnelMap]);

  const stepNames =
    getFunnelsStepNames({
      funnelsData: canvasFunnelMap.funnelsData,
      recordTypesData: canvasFunnelMap.recordTypesData,
    })[objectType?.objectType ?? ''] ?? [];

  const currentRecordTypesByObjectName = getRecordTypeNames({
    funnelsData: canvasFunnelMap.funnelsData,
    recordTypesData: canvasFunnelMap.recordTypesData,
  });

  return (
    <WithConfigurationCanvas
      onFunnelStepClick={onDocumentationEntityClick}
      onRecordTypeStepClick={onDocumentationEntityClick}
      onFunnelLabelClick={onDocumentationEntityClick}
      onFunnelLabelPillClick={onDocumentationEntityClick}
      onRecordTypeLabelClick={onDocumentationEntityClick}
      onRecordTypeLabelPillClick={onDocumentationEntityClick}
      highlightEntities={highlightEntities}
      visibilityElements={visibilityElements}
      readOnly
    >
      <ConfigurationCanvasLeftPanel withExpandedModeButton>
        <DocumentationDialog
          crmOrgId={appCrmOrgId}
          isOpen
          onCrmOrgIdChange={onCrmOrgIdChange}
          setHoveredPin={setHoveredPin}
          funnelsData={canvasFunnelMap.funnelsData}
          recordTypesData={canvasFunnelMap.recordTypesData}
          stepNamesUsedInCanvas={stepNames}
          recordTypeNamesUsedInCanvas={currentRecordTypesByObjectName}
        />
      </ConfigurationCanvasLeftPanel>
    </WithConfigurationCanvas>
  );
};
