import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { funnelDetailModel } from '../../../../../models/funnelDetailModel';
import SweepStagesModel from '../../../../../models/stagesModel';
import { connectStages } from '../../../../../reducers/multiFunnelFlowCanvasReducer';
import { ConnectStepProps } from './ConnectSteps';
import { ListGroup, ListItem } from '../../../../common/CollapseList_deprecated';

export const useConnectSteps = (funnelsData: FunnelsData) => {
  const dispatch = useDispatch();
  const [connectStepsButtonAnchorEl, setConnectStepsButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [holdNodeHighlighted, setHoldNodeHighlighted] = useState(false);

  const [currentStageAndFunnel, setCurrentStageAndFunnel] = useState<{
    stageId: string;
    funnelId: string;
  }>();

  const handleConnectStepsButtonClick = useCallback(
    (stageId: string, funnelId: string, event: React.MouseEvent<HTMLButtonElement>) => {
      setCurrentStageAndFunnel({ funnelId, stageId });
      setConnectStepsButtonAnchorEl(event.currentTarget);
      setHoldNodeHighlighted(true);
    },
    [],
  );

  const handleConnectStepsButtonClose = () => {
    setConnectStepsButtonAnchorEl(null);
    setHoldNodeHighlighted(false);
  };

  const stepGroups: ListGroup[] = useMemo(() => {
    if (!currentStageAndFunnel) {
      return [];
    }

    const stageToConnectStepItem = (stage: SweepStage): ListItem => ({
      value: stage._stageId,
      label: stage.stageName,
    });
    const currentFunnelId = currentStageAndFunnel.funnelId;

    const currentFunnelGroup = {
      value: funnelsData[currentFunnelId].id,
      parentName: funnelsData[currentFunnelId].name,
      label: funnelsData[currentFunnelId].funnelDetails.leadingObject.objectName,
      items: funnelDetailModel(funnelsData[currentFunnelId].funnelDetails)
        .getAllStageValidConnections(currentStageAndFunnel.stageId)
        .map(stageToConnectStepItem),
    };

    const stageLinks = funnelDetailModel(funnelsData[currentFunnelId].funnelDetails)
      .stageByIdOrUndefined(currentStageAndFunnel.stageId)
      ?.getStageFunnelLinks();

    const doesNotHaveStageFunnelLink = (funnelId: string) => (stage: SweepStage) => {
      return !Boolean(
        stageLinks?.find((link) => link.funnelId === funnelId && stage._stageId === link.stageId),
      );
    };

    // TODO: Verify the connection restrictions for the other groups
    const allOtherFunnelGroups = Object.keys(funnelsData)
      .filter((funnelId) => funnelId !== currentFunnelId)
      .map((funnelId) => ({
        value: funnelsData[funnelId].id,
        parentName: funnelsData[funnelId].name,
        label: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
        items: new SweepStagesModel(funnelsData[funnelId].funnelDetails.stages)
          .nonLostSteps()
          .filter(doesNotHaveStageFunnelLink(funnelId))
          .map(stageToConnectStepItem),
      }));

    return [currentFunnelGroup, ...allOtherFunnelGroups];
  }, [currentStageAndFunnel, funnelsData]);

  const onItemClick = useCallback(
    (item: ListItem, parentGroup: ListGroup) => {
      if (currentStageAndFunnel) {
        dispatch(
          connectStages({
            stageA: {
              funnelId: currentStageAndFunnel.funnelId,
              stageId: currentStageAndFunnel.stageId,
            },
            stageB: {
              funnelId: parentGroup.value,
              stageId: item.value,
            },
          }),
        );
      }
      handleConnectStepsButtonClose();
    },
    [currentStageAndFunnel, dispatch],
  );

  const connectStepsProps: ConnectStepProps = {
    anchorEl: connectStepsButtonAnchorEl || null,
    handleClose: handleConnectStepsButtonClose,
    stepGroups,
    onItemClick,
    startAllExpanded: false,
  };

  return {
    handleConnectStepsButtonClick,
    connectStepsProps,
    holdNodeHighlighted,
  };
};
