import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TerritoriesState {
  territoriesList: Territories[] | undefined;
}

const initialState: TerritoriesState = {
  territoriesList: undefined,
};

export const territoriesSlice = createSlice({
  name: 'territories',
  initialState,
  reducers: {
    setTerritoriesList: (state, action: PayloadAction<{ territories: Territories[] }>) => {
      state.territoriesList = action.payload.territories;
    },
    createTerritoriesListItem: (state, action: PayloadAction<{ newItem: Territories }>) => {
      const { newItem } = action.payload;
      state.territoriesList?.push(newItem);
    },
    updateTerritoriesListItem: (state, action: PayloadAction<{ newItem: Territories }>) => {
      const { newItem } = action.payload;
      state.territoriesList = state.territoriesList?.map((item) =>
        item.id === newItem.id ? newItem : item,
      );
    },
    deleteTerritoriesListItem: (state, action: PayloadAction<{ _id: string }>) => {
      const { _id } = action.payload;
      state.territoriesList = state.territoriesList?.filter((territory) => territory.id !== _id);
    },
  },
});

export const {
  setTerritoriesList,
  createTerritoriesListItem,
  updateTerritoriesListItem,
  deleteTerritoriesListItem,
} = territoriesSlice.actions;
export const selectTerritoriesList = (state: RootState) => state.territories.territoriesList;

export default territoriesSlice.reducer;
