import { memo, useCallback, useMemo } from 'react';
import { EdgeProps, Node, useStore } from 'reactflow';
import {
  calculateHandlePositions,
  calculateHandleXYPositions,
  _reactFlowPositionToRoundedCanvasIndexPosition,
} from '../helpers/calculateHandlePositionsBasedOnCoords';
import { getConnectionPathAndArrow } from '../helpers/getConnectionPathAndArrow';
import {
  ActionButtonAdd,
  ActionButtonDelete,
  actionButtonDimensions,
} from '../nodes/components/CanvasButtons';
import FloatingEdgeConnection from './components/FloatingEdgeConnection';
import CanvasGate, { GATE_SIZE } from './components/CanvasGate';
import '../css/react-flow-styles.css';
import {
  CanvasEdgeConnectionType,
  CanvasElementType,
  CanvasMode,
  RFNodeData,
} from '../canvasTypes';
import { ContextZoomType } from '../SweepCanvas';
import * as path from 'svg-path-properties';
import { DELETE_CONNECTION_DISTANCE_PERCENTAGE, GATE_DISTANCE_PERCENTAGE } from '../const';
import { SweepCanvasReactFlowEdgeDataType } from './SweepCanvasReactFlowEdgeDataType';

export enum ArrowDirection {
  BottomTop = 'bt',
  TopBottom = 'tb',
  LeftRight = 'lr',
  RightLeft = 'rl',
}

export interface RFEdgeData {
  type: SweepCanvasReactFlowEdgeDataType;
  label?: string;
  highlightType?: 'source' | 'target';
  showButtonsOnHighlight: boolean;
  arrowDirection?: ArrowDirection;
  onEdgeAddBetweenBtnClick?: (id: string) => any;
  onEdgeDeleteClick?: (
    sourceNodeId: string,
    targetNodeId: string,
    sourceNodeParentId: string,
    targetNodeParentId: string,
  ) => any;
  sourceColor: string;
  targetColor: string;
  gateColor: string;
  onGateClick?: (source: string, target: string, sourceParentId: string) => any;
  readonly?: boolean;
  connectionType: CanvasEdgeConnectionType;
  connectionIdx?: number;
  noAddBetweenBtn?: boolean;
  sourceParentId?: string;
  targetParentId?: string;
  canvasMode?: CanvasMode;
  contextZoomType?: ContextZoomType;
  showGates?: boolean;
  hideNurturingEdges?: boolean;
}

const GenericFloatingEdge = memo(
  ({
    id,
    source,
    target,
    selected,
    type,
    label,
    highlightType,
    onEdgeAddBetweenBtnClick,
    sourceColor = '#DDDDDD',
    targetColor = '#CCCCCC',
    gateColor = '#eeeeee',
    onGateClick = () => {},
    readonly,
    connectionIdx = 0,
    connectionType,
    noAddBetweenBtn,
    showButtonsOnHighlight,
    onEdgeDeleteClick,
    sourceParentId,
    targetParentId,
    canvasMode = CanvasMode.DEFAULT,
    contextZoomType,
    hideNurturingEdges,
    showGates,
  }: RFEdgeData & {
    source: string;
    target: string;
    selected?: boolean;
    id: string;
  }) => {
    const isContextZoom1_5 = contextZoomType === ContextZoomType.LEVEL1_5;

    const isHighlighted = Boolean(highlightType);

    const _sourceEdgeColor = isHighlighted ? '#1198FF' : sourceColor;
    const _targetEdgeColor = isHighlighted ? '#1198FF' : targetColor;

    const reactFlowSourceNode: Node<RFNodeData> | undefined = useStore(
      useCallback((store) => store.nodeInternals.get(source), [source]),
    );

    const reactFlowSourceParentNode: any = useStore(
      useCallback(
        (store) =>
          reactFlowSourceNode?.parentNode &&
          store.nodeInternals.get(reactFlowSourceNode?.parentNode),
        [reactFlowSourceNode?.parentNode],
      ),
    );

    const reactFlowTargetNode: Node<RFNodeData> | undefined = useStore(
      useCallback((store) => store.nodeInternals.get(target), [target]),
    );
    const reactFlowTargetParentNode: any = useStore(
      useCallback(
        (store) =>
          reactFlowTargetNode?.parentNode &&
          store.nodeInternals.get(reactFlowTargetNode?.parentNode),
        [reactFlowTargetNode?.parentNode],
      ),
    );

    const sourceIndexPosition = _reactFlowPositionToRoundedCanvasIndexPosition(
      reactFlowSourceNode?.positionAbsolute || { x: 0, y: 0 },
    );
    const targetIndexPosition = _reactFlowPositionToRoundedCanvasIndexPosition(
      reactFlowTargetNode?.positionAbsolute || { x: 0, y: 0 },
    );

    const {
      arrowDirection,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
    } = calculateHandlePositions(sourceIndexPosition, targetIndexPosition);

    const { x: sourceX, y: sourceY } = calculateHandleXYPositions({
      type: 'exit',
      nodeXyPosition: reactFlowSourceNode?.position,
      parentXyPosition: reactFlowSourceParentNode?.position,
      width: reactFlowSourceNode?.width || 0,
      height: reactFlowSourceNode?.height || 0,
    })[sourcePos];

    const { x: targetX, y: targetY } = calculateHandleXYPositions({
      type: 'entry',
      nodeXyPosition: reactFlowTargetNode?.position,
      parentXyPosition: reactFlowTargetParentNode?.position,
      width: reactFlowTargetNode?.width || 0,
      height: reactFlowTargetNode?.height || 0,
    })[targetPos];

    const { connectionPath, arrowPath, arrowCirclePath, newTargetX, newTargetY } =
      getConnectionPathAndArrow({
        sourceX,
        sourceY,
        sourcePos,
        targetPos,
        targetX,
        targetY,
        arrowDirection,
        connectionIdx,
        connectionType,
      });

    const { gatePos, addBeforeBtnPos, deleteButtonPos } = useMemo(() => {
      const connectionPathProperties = new path.svgPathProperties(connectionPath);
      const length = connectionPathProperties.getTotalLength();

      const ptGate = connectionPathProperties.getPointAtLength(length * GATE_DISTANCE_PERCENTAGE);
      const ptSource = connectionPathProperties.getPointAtLength(length * 0.3 + 50);
      const ptDelete = connectionPathProperties.getPointAtLength(
        length * DELETE_CONNECTION_DISTANCE_PERCENTAGE,
      );

      return {
        gatePos: {
          x: Math.round(ptGate.x),
          y: Math.round(ptGate.y),
        },
        addBeforeBtnPos: {
          x: Math.round(ptSource.x),
          y: Math.round(ptSource.y),
        },
        deleteButtonPos: {
          x: Math.round(ptDelete.x),
          y: Math.round(ptDelete.y),
        },
      };
    }, [connectionPath]);

    if (!reactFlowSourceNode || !reactFlowTargetNode /*|| !drawnPath*/) {
      return null;
    }

    const renderGate = () => {
      switch (type) {
        case SweepCanvasReactFlowEdgeDataType.DASHED_CIRCLE:
          return (
            <CanvasGate
              dashed
              color={gateColor}
              label={label || ''}
              highlighted={isHighlighted}
              onClick={() => onGateClick(source, target, sourceParentId || '')}
              readonly={readonly}
            />
          );
        case SweepCanvasReactFlowEdgeDataType.CIRCLE:
          return (
            <CanvasGate
              color={gateColor}
              label={label || ''}
              highlighted={isHighlighted || selected}
              onClick={() => onGateClick(source, target, sourceParentId || '')}
              readonly={readonly}
            />
          );
        case SweepCanvasReactFlowEdgeDataType.REMOVABLE:
        case SweepCanvasReactFlowEdgeDataType.SIMPLE:
          return null;
      }
    };

    const renderAddButton = (x: number, y: number) => (
      <foreignObject
        width={actionButtonDimensions.width}
        height={actionButtonDimensions.height}
        x={x - actionButtonDimensions.width / 2}
        y={y - actionButtonDimensions.height / 2}
        className="edgebutton-foreignobject-add-button"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <ActionButtonAdd onClick={() => onEdgeAddBetweenBtnClick && onEdgeAddBetweenBtnClick(id)} />
      </foreignObject>
    );

    const renderDeleteButton = (x: number, y: number) => (
      <foreignObject
        width={actionButtonDimensions.width}
        height={actionButtonDimensions.height}
        x={x - actionButtonDimensions.width / 2}
        y={y - actionButtonDimensions.height / 2}
        className="edgebutton-foreignobject-add-button"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <ActionButtonDelete
          onClick={() =>
            onEdgeDeleteClick?.(source, target, sourceParentId || '', targetParentId || '')
          }
          className="inverted"
        />
      </foreignObject>
    );

    const hasConnector = type !== SweepCanvasReactFlowEdgeDataType.SIMPLE;
    const shouldRenderDelete =
      type === SweepCanvasReactFlowEdgeDataType.REMOVABLE && highlightType && !readonly;

    const shouldRenderAddButton =
      type !== SweepCanvasReactFlowEdgeDataType.REMOVABLE &&
      highlightType === 'source' &&
      !noAddBetweenBtn &&
      showButtonsOnHighlight &&
      !readonly &&
      !isContextZoom1_5;

    if (
      hideNurturingEdges &&
      (reactFlowTargetNode.type === CanvasElementType.NURTURING_BUCKET ||
        reactFlowSourceNode.type === CanvasElementType.NURTURING_BUCKET)
    ) {
      return null;
    }

    return (
      <>
        <FloatingEdgeConnection
          id={id}
          connectionPath={connectionPath}
          arrowPath={arrowPath}
          arrowCirclePath={arrowCirclePath}
          sourceX={sourceX}
          sourceY={sourceY}
          targetX={newTargetX}
          targetY={newTargetY}
          sourceEdgeColor={_sourceEdgeColor}
          targetEdgeColor={_targetEdgeColor}
          strokeWidth={canvasMode === CanvasMode.PREVIEW1 ? 10 : 2}
        />

        {hasConnector && showGates && (
          <foreignObject
            width={GATE_SIZE}
            height={GATE_SIZE}
            x={gatePos.x - GATE_SIZE / 2}
            y={gatePos.y - GATE_SIZE / 2}
            className="edgebutton-foreignobject"
          >
            {renderGate()}
          </foreignObject>
        )}
        {shouldRenderAddButton && renderAddButton(addBeforeBtnPos.x, addBeforeBtnPos.y)}
        {shouldRenderDelete && renderDeleteButton(deleteButtonPos.x, deleteButtonPos.y)}
      </>
    );
  },
);

const FloatingEdge = ({
  id,
  data = {
    type: SweepCanvasReactFlowEdgeDataType.SIMPLE,
    sourceColor: '#DDDDDD',
    targetColor: '#CCCCCC',
    gateColor: '#eeeeee',
    onGateClick: () => {},
    connectionIdx: 0,
    connectionType: CanvasEdgeConnectionType.Bezier,
    showButtonsOnHighlight: false,
    onEdgeDeleteClick: () => {},
  },
  source,
  selected,
  target,
}: EdgeProps<RFEdgeData>) => (
  <GenericFloatingEdge {...data} id={id} source={source} selected={selected} target={target} />
);

export { FloatingEdge };
