import { useEffect, useState } from 'react';
import { SweepCanvasGroup } from './canvasTypes';
import { FitViewOptions } from 'reactflow';
import { useSweepCanvasFitView } from './useSweepCanvasFitView';

interface ManageFirstFunnelDropProps {
  sweepGroups: SweepCanvasGroup[];
  selectedFitViewOptions: FitViewOptions;
  autoFitViewOnFirstNodes?: boolean;
}

export const useManageFirstGroupDrop = ({
  sweepGroups,
  selectedFitViewOptions,
  autoFitViewOnFirstNodes,
}: ManageFirstFunnelDropProps) => {
  const { fitView } = useSweepCanvasFitView();
  const [hideFirstFunnel, setHideFirstFunnel] = useState(
    autoFitViewOnFirstNodes && sweepGroups.length === 0,
  );

  //Center canvas after placing first funnel
  useEffect(() => {
    if (autoFitViewOnFirstNodes) {
      if (sweepGroups.length > 0) {
        if (hideFirstFunnel) {
          setTimeout(() => {
            fitView({
              ...selectedFitViewOptions,
              duration: 0,
            });
            setHideFirstFunnel(false);
          }, 100);
        }
      } else {
        setHideFirstFunnel(true);
      }
    }
  }, [
    fitView,
    hideFirstFunnel,
    selectedFitViewOptions,
    sweepGroups.length,
    autoFitViewOnFirstNodes,
  ]);

  return {
    hideFirstFunnel,
  };
};
