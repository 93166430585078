import CopilotChat from './CopilotChat';
import useCopilotChat from './useCopilotChat';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import { useSelector } from 'react-redux';

const CopilotChatConnected = ({ closeCopilot }: { closeCopilot: () => void }) => {
  const { messages, resetChat, onConfirm, isLoading, error, disableInput } = useCopilotChat();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  return (
    <CopilotChat
      messages={messages}
      closeCopilot={closeCopilot}
      resetChat={resetChat}
      onConfirm={(message) => onConfirm({ crmOrgId, message })}
      isLoading={isLoading}
      errorMsg={error}
      disableInput={disableInput}
    />
  );
};

export default CopilotChatConnected;
