import { Box, Divider, List, ListItemText, MenuItem, Paper } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import _keyBy from 'lodash/keyBy';
import { SearchInput } from '../../../../common/SearchInput';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';
import { Typography, colors, Button, Checkbox, Tag } from '@sweep-io/sweep-design';
import { FlexBox } from '../../../../common/FlexBox';
import { Virtuoso } from 'react-virtuoso';
import { filterItemsBySearch } from '../../../../../lib/filterItemsBySearch';

type ItemsGroupSelectorItemValue = string;

export interface ItemsGroupSelectorItem {
  value: ItemsGroupSelectorItemValue;
  label: string;
}

interface ItemsGroupSelectorProps {
  placeholder?: string;
  items: ItemsGroupSelectorItem[];
  selectedItems?: ItemsGroupSelectorItemValue[];
  onSelectedItemsChange?: (newSelectedItems: ItemsGroupSelectorItemValue[]) => any;
  loading?: boolean;
}

const ItemsGroupSelectorAddItemsPanel = ({
  itemsToAddCount,
  onAddClick,
  disabled,
}: {
  itemsToAddCount: number;
  onAddClick: () => any;
  disabled?: boolean;
}) => {
  return (
    <Paper elevation={1} sx={{ display: 'flex' }}>
      <FlexBox
        sx={{
          height: '44px',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px',
        }}
      >
        <Box>
          <Typography variant="body">{itemsToAddCount} Selected</Typography>
        </Box>
        <Box>
          <Button
            startIconName="Plus"
            variant="flat"
            size="small"
            onClick={onAddClick}
            disabled={disabled}
          >
            Add to Group
          </Button>
        </Box>
      </FlexBox>
    </Paper>
  );
};

export const ItemsGroupSelector = ({
  placeholder,
  items,
  selectedItems = [],
  onSelectedItemsChange,
  loading,
}: ItemsGroupSelectorProps) => {
  const [search, setSearch] = useState('');
  const [itemsToAdd, setItemsToAdd] = useState<Set<ItemsGroupSelectorItemValue>>(new Set());

  const itemsByKey = useMemo(() => _keyBy(items, 'value'), [items]);

  const listItems = items.filter(({ value }) => !selectedItems.includes(value));
  const listItemsToDisplay = filterItemsBySearch(listItems, search, 'label');

  const addSelectedMembers = useCallback(() => {
    onSelectedItemsChange && onSelectedItemsChange([...selectedItems, ...Array.from(itemsToAdd)]);
    setItemsToAdd(new Set());
    setSearch('');
  }, [onSelectedItemsChange, selectedItems, itemsToAdd]);

  const removeMember = useCallback(
    (itemValue: string) => {
      onSelectedItemsChange &&
        onSelectedItemsChange(selectedItems.filter((value) => value !== itemValue));
    },
    [onSelectedItemsChange, selectedItems],
  );

  const itemsToAddCount = itemsToAdd.size;
  const hasItemsToAdd = itemsToAddCount > 0;
  const areAllItemsSelected = itemsToAdd.size === listItems.length;
  const totalSelectedItems = selectedItems.length;

  const toggleSelectAll = () => {
    if (areAllItemsSelected) {
      setItemsToAdd(new Set());
    } else {
      setItemsToAdd(new Set(listItems.map((item) => item.value)));
    }
  };

  const toggleLocalSelectedItem = (itemValue: string) => {
    if (itemsToAdd.has(itemValue)) {
      itemsToAdd.delete(itemValue);
    } else {
      itemsToAdd.add(itemValue);
    }
    setItemsToAdd(new Set(itemsToAdd));
  };

  return (
    <Paper variant="outlined" sx={{ flex: 1, display: 'flex' }}>
      <FlexBox>
        <FlexBox sx={{ width: '50%', padding: 1, flexDirection: 'column' }}>
          <Box sx={{ padding: '8px 12px', display: 'flex', gap: 2 }}>
            <SearchInput
              withFixedMagnifyingGlassIcon
              placeholder={placeholder}
              TextFieldProps={{
                onChange: (e) => setSearch(e.target.value),
                value: search,
              }}
              onClearButtonClick={() => setSearch('')}
            />
            <Button variant="flat" onClick={toggleSelectAll}>
              {areAllItemsSelected ? 'Clear all' : 'Select all'}
            </Button>
          </Box>
          <FlexBox sx={{ overflow: 'auto' }}>
            <List sx={{ width: '100%' }}>
              {loading && <CenteredCircularProgress />}
              {!loading && (
                <Virtuoso
                  style={{ height: '100%' }}
                  data={listItemsToDisplay}
                  itemContent={(index, item) => {
                    return (
                      <MenuItem
                        value={item.value}
                        key={item.value}
                        onClick={() => toggleLocalSelectedItem(item.value)}
                      >
                        <Checkbox checked={itemsToAdd.has(item.value)} />
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    );
                  }}
                />
              )}
            </List>
          </FlexBox>

          <ItemsGroupSelectorAddItemsPanel
            itemsToAddCount={itemsToAddCount}
            onAddClick={addSelectedMembers}
            disabled={!hasItemsToAdd}
          />
        </FlexBox>
        <Divider orientation="vertical" flexItem />
        <FlexBox sx={{ width: '50%', padding: 3, flexDirection: 'column', gap: 3 }}>
          <Box>
            <Typography variant="h4">Group members ({totalSelectedItems})</Typography>
          </Box>

          {!loading && (
            <FlexBox gap={1} flexWrap="wrap" overflow="auto" alignContent="flex-start">
              {selectedItems.map((itemValue) => (
                <Tag
                  key={itemValue}
                  color={colors.blue[100]}
                  label={itemsByKey[itemValue]?.label}
                  onDelete={() => removeMember(itemValue)}
                />
              ))}
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </Paper>
  );
};
