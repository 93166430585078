import { Box, CircularProgress } from '@mui/material';
import { Sparkle } from '@sweep-io/sweep-design/dist/icons';
import { colors, ExpandableTypography, Typography } from '@sweep-io/sweep-design';
import { OpenAiDescription } from '../ParserTypes';
import LabelChip from '../../common/LabelChip';
import { UI_EVENTS } from '../../../services/events';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { MarkdownWrapper } from '../../common/MarkdownWrapper';

const OpenAiSection = ({ openAiDescription }: { openAiDescription?: OpenAiDescription }) => {
  const sendBiEvent = useSendBiEvent();
  const { text, type } = openAiDescription ?? {};

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h4">What does this do?</Typography>
        <LabelChip
          bgColor={colors.blue[100]}
          color={colors.blue[600]}
          startIcon={<Sparkle color={colors.blue[600]} />}
        >
          AI generated
        </LabelChip>
      </Box>

      {!text && <CircularProgress size={20} />}

      {text && (
        <Box
          sx={{
            '& p': {
              m: 0,
              p: 0,
            },
          }}
        >
          <ExpandableTypography
            rows={2}
            variant="body"
            color={type === 'error' ? colors.grey[500] : colors.grey[800]}
            onShowMoreCallback={() => {
              sendBiEvent({ name: UI_EVENTS.documentationShowMore });
            }}
            whiteSpace="break-spaces"
            wordBreak="break-word"
          >
            <MarkdownWrapper component="span" content={text} />
          </ExpandableTypography>
        </Box>
      )}
    </Box>
  );
};

export default OpenAiSection;
