import { Box, Paper, SelectChangeEvent } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { useState } from 'react';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../common/fieldsSelectors/NestedFieldsSelector';
import { SweepFieldsOperatorSelector } from '../SweepFieldsOperatorSelector/SweepFieldsOperatorSelector';
import {
  ApiSweepFieldOperator,
  operatorHasAutomaticValue,
  SweepFieldOperator,
  SweepFieldOperatorsValues,
} from '../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { SweepFieldsValueSelector } from '../SweepFieldsValueSelector/SweepFieldsValueSelector';
import { SelectorValueTypes } from '../../types/enums/SelectorValueTypes';
import {
  MainSelectors,
  SweepFieldsMainSelector,
} from '../SweepFieldsMainSelector/SweepFieldsMainSelector';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { getNestedPath } from '../Automations/helper';
import { FieldContext } from '../../types/enums/FieldContext';
import { RuleBuilderSelectionRow } from '../common/rule-builder/RuleBuilderSelectionRow';
import { parseSweepFieldPolymorphicID } from '../common/fieldsSelectors/nestedFieldsSelectorHelper';

interface SweepFieldsSelectionRowOnChangeEvent {
  lineNumber: number;
  operator: ApiSweepFieldOperator | '';
  fieldInfo: NestedFieldsField;
  fieldType: SweepFieldTypes;
  value?: any;
  valueType: SweepCriterionValueTypes;
  _valueLabels?: string[];
  fieldContext?: FieldContext;
  operatorValue?: OperatorOffset;
}

interface SweepFieldsSelectionRowProps {
  fieldType?: SweepFieldTypes;
  fieldIds: string[];
  fieldLabels: string[];
  objectType: string;
  initialOperator?: ApiSweepFieldOperator | '';
  initialOperatorValue?: SweepFieldOperatorsValues;
  initialValue?: string | string[];
  onChange?: (event: SweepFieldsSelectionRowOnChangeEvent) => any;
  lineNumber: number;
  showDrag?: boolean;
  showDuplicate?: boolean;
  showDelete?: boolean;
  onDelete?: (lineNumber: number) => any;
  onDrag?: (lineNumber: number) => any;
  onDuplicate?: (lineNumber: number) => any;
  crmOrgId: string;
  readonly?: boolean;
  valueType: SweepCriterionValueTypes;
  valueReferencedFieldLabels?: string[];
  removeBorders?: boolean;
  mainSelectorKey?: MainSelectors;
  showCompoundAddress?: boolean;
  referenceObjectType?: string;
  filterOperatorBy?: (
    operatorList: { label: string; operator: SweepFieldOperator }[],
    fieldType?: SweepFieldTypes,
  ) => { label: string; operator: SweepFieldOperator }[];
  hideSelectValuesFromRecord?: boolean;
  isBinaryRuleBuilder?: boolean;
  disableLookupItemsResolve?: boolean;
  nestedSelectorFilterBy?: (field: SweepField) => boolean;
  fieldContext?: FieldContext;
  displayFieldContextMenu?: boolean;
  disableResolvePolymorphic?: boolean;
  onOperatorValueChange?: (operatorValue: OperatorOffset, lineNumber: number) => any;
  operatorOffsetValue?: OperatorOffset;
  selectValuesFromRecordCustomButtonText?: string;
  showCompoundGeolocation?: boolean;
}

export const SweepFieldsSelectionRow = ({
  fieldType,
  objectType,
  initialOperator: operator,
  initialOperatorValue: operatorValue,
  onChange,
  lineNumber,
  showDelete,
  showDrag,
  showDuplicate,
  onDelete,
  onDrag,
  onDuplicate,
  fieldIds,
  fieldLabels,
  initialValue: value,
  crmOrgId,
  readonly,
  valueType,
  valueReferencedFieldLabels,
  removeBorders,
  mainSelectorKey,
  showCompoundAddress,
  referenceObjectType,
  filterOperatorBy,
  hideSelectValuesFromRecord,
  isBinaryRuleBuilder,
  disableLookupItemsResolve,
  nestedSelectorFilterBy,
  fieldContext,
  displayFieldContextMenu,
  disableResolvePolymorphic,
  onOperatorValueChange,
  operatorOffsetValue,
  selectValuesFromRecordCustomButtonText,
  showCompoundGeolocation,
}: SweepFieldsSelectionRowProps) => {
  const [fieldInfo, setFieldInfo] = useState<NestedFieldsField>();
  const nestedFieldsPath = getNestedPath(fieldIds, fieldLabels);

  const __onChange = ({
    _fieldType = fieldType,
    _fieldInfo = fieldInfo || {
      fieldIds,
      fieldLabels,
    },
    _value,
    _operator = operator || '',
    valueType = fieldType === SweepFieldTypes.Address
      ? SelectorValueTypes.TERRITORY
      : SelectorValueTypes.LITERAL,
    _valueLabels,
    _fieldContext = fieldContext,
    _operatorOffsetValue = operatorOffsetValue,
  }: {
    _fieldType?: any;
    _fieldInfo?: any;
    _value?: any;
    _operator?: any;
    valueType?: SweepCriterionValueTypes;
    _valueLabels?: string[];
    _fieldContext?: FieldContext;
    _operatorOffsetValue?: OperatorOffset;
  }) => {
    onChange?.({
      fieldType: _fieldType,
      fieldInfo: _fieldInfo,
      value: _value,
      operator: _operator,
      lineNumber,
      valueType,
      _valueLabels,
      fieldContext: _fieldContext,
      operatorValue: _operatorOffsetValue,
    });
  };

  const hideValueField = operatorHasAutomaticValue(operator as ApiSweepFieldOperator);

  const onNestedSelectorChange = (sweepField: NestedFieldsField) => {
    setFieldInfo(sweepField);
    __onChange({
      _fieldInfo: sweepField,
      _fieldType: sweepField.fieldType,
      _value: '',
      _operator: '',
      _fieldContext: sweepField.fieldContext,
    });
  };

  const onTerritoriesChange = (event: SelectChangeEvent<unknown>) => {
    const val = event.target.value;
    __onChange({
      _fieldInfo: {
        fieldIds: [],
        fieldLabels: [val],
      },
      _fieldType: 'Text',
      _value: '',
      _operator: '',
    });
  };

  const onSfChangeFeedNotificationChange = (event: SelectChangeEvent<unknown>) => {
    const val = event.target.value;
    __onChange({
      _fieldInfo: {
        fieldIds: [],
        fieldLabels: [val],
      },
      _fieldType: 'Text',
      _value: '',
      _operator: '',
    });
  };

  const firstComponent = (
    <SweepFieldsMainSelector
      mainSelectorKey={mainSelectorKey}
      nestedSelectorProps={{
        readonly,
        crmOrgId,
        objectType,
        disableLookupItemsResolve,
        nestedPath: nestedFieldsPath || [],
        onChange: onNestedSelectorChange,
        filterBy: (field: SweepField) => {
          let shouldNotBeFiltered = true;
          if (nestedSelectorFilterBy) {
            shouldNotBeFiltered = nestedSelectorFilterBy(field);
          }

          if (showCompoundAddress && shouldNotBeFiltered) {
            if (field.fieldType === SweepFieldTypes.Address) {
              return true;
            }
          }
          if (showCompoundGeolocation) {
            if (field.fieldType === SweepFieldTypes.Geolocation) {
              return true;
            }
          }
          return shouldNotBeFiltered && field.isCompound !== true;
        },
        displayFieldContextMenu,
        fieldContext: fieldContext || FieldContext.CURRENT_VALUE,
        disableResolvePolymorphic,
      }}
      territorySelectorProps={{
        readonly: !!readonly,
        fieldLabels,
        onChange: onTerritoriesChange,
      }}
      sfChangeFeedSelectorProps={{
        readonly: !!readonly,
        fieldLabels,
        onChange: onSfChangeFeedNotificationChange,
      }}
    />
  );

  const secondeComponent = (
    <SweepFieldsOperatorSelector
      filterOperatorBy={filterOperatorBy}
      removeBorders={removeBorders}
      operator={operator || undefined}
      value={operatorValue}
      fieldType={fieldType}
      onChange={(_operator, _value) => {
        __onChange({ _value, _operator });
      }}
      onOperatorValueChange={(el) => onOperatorValueChange?.(el, lineNumber)}
      operatorValue={operatorOffsetValue}
      disabled={!Boolean(fieldType) || readonly}
      fieldContext={fieldContext}
    />
  );

  const thirdComponent = (
    <>
      {(hideValueField || !operator) && (
        <Paper
          elevation={0}
          sx={{
            height: 40,
            borderRadius: 0,
            backgroundColor: colors.grey[100],
          }}
        />
      )}
      {!hideValueField && operator && isBinaryRuleBuilder && (
        <Box>
          <NestedFieldsSelector
            filterBy={(item: SweepField) => {
              const { isResolvable } = parseSweepFieldPolymorphicID(item.id || '');
              if (!isResolvable && fieldType !== item?.fieldType) {
                return false;
              }
              return true;
            }}
            nestedPath={getNestedPath(
              !Array.isArray(value) ? [] : value,
              valueReferencedFieldLabels,
            )}
            displayLeadingObjectName={false}
            objectType={referenceObjectType ?? ''}
            crmOrgId={crmOrgId ?? ''}
            onChange={(sweepField) =>
              __onChange({
                _valueLabels: sweepField.fieldLabels,
                _value: sweepField.fieldIds,
                valueType: SelectorValueTypes.REFERENCE,
              })
            }
            isReferencedValue
            parentFieldType={fieldType ?? ('' as SweepFieldTypes)}
            readonly={!operator || readonly}
            disableLookupItemsResolve={disableLookupItemsResolve}
            disableResolvePolymorphic={disableResolvePolymorphic}
          />
        </Box>
      )}
      {!hideValueField && operator && !isBinaryRuleBuilder && (
        <SweepFieldsValueSelector
          removeBorders={removeBorders}
          parentFieldType={fieldType ?? ''}
          valueType={valueType}
          initialValue={value}
          shouldBeDisabled={!!(!operator || readonly)}
          onChange={(value) => {
            __onChange({ _value: value });
          }}
          onSelectValuesFromRecordChange={({ _value, _valueLabels, valueType }) => {
            __onChange({
              _value,
              valueType,
              _valueLabels,
            });
          }}
          field={fieldInfo?.sweepField}
          fieldIds={fieldIds}
          crmOrgId={crmOrgId}
          _operator={operator ?? ''}
          objectType={objectType}
          showSelectValuesFromRecord={!hideSelectValuesFromRecord}
          _valueLabels={valueReferencedFieldLabels}
          referenceObjectType={referenceObjectType}
          disableLookupItemsResolve={disableLookupItemsResolve}
          disableResolvePolymorphic={disableResolvePolymorphic}
          selectValuesFromRecordCustomButtonText={selectValuesFromRecordCustomButtonText}
        />
      )}
    </>
  );

  return (
    <RuleBuilderSelectionRow
      lineNumber={lineNumber}
      showDelete={showDelete}
      showDrag={showDrag}
      showDuplicate={showDuplicate}
      onDelete={onDelete}
      onDrag={onDrag}
      onDuplicate={onDuplicate}
      readonly={readonly}
      firstComponent={firstComponent}
      secondComponent={secondeComponent}
      thirdComponent={thirdComponent}
    />
  );
};
