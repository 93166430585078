import { useSelector } from 'react-redux';
import { selectLoadingStatuses } from '../../../reducers/global/globalReducer';
import { selectDefaultCreationEnvironment, selectProductionCrmOrg } from './environmentsReducer';
import {
  EnvConnectionError,
  SweepError,
} from './env-connection-error-page/envConnectionErrorTypes';
import { FetchStatus } from '@server/fetch_status.type';
import useIsManagedPackageInstalled from '../../common/install-managed-package/useIsManagedPackageInstalled';
import { GlobalLoadingStatus } from '../../../reducers/global/globalReducerTypes';
import { isOrgConnectionError } from '../../../hooks/global-reducer/environmentsHelper';
import { useNoConnectedOrgs } from './useNoConnectedOrgs';
import { selectConnectedHubspotOrg } from '../../../reducers/hubspotReducer';
import usePaywall, { PaywallAction } from '../../dashboard/paywall/usePaywall';

const convertFetchStatusToSweepError = (orgFetchStatus: FetchStatus) => {
  switch (orgFetchStatus) {
    case FetchStatus.InvalidCredentials:
      return SweepError.CrmOrgBadCredentials;
    case FetchStatus.Error:
      return SweepError.CrmOrgNotFound;
    default:
      return undefined;
  }
};

const getSweepErrorFromGlobalLoadingStatus = (globalLoadingStatus: GlobalLoadingStatus) => {
  const isConnectionError =
    globalLoadingStatus?.error &&
    [SweepError.CrmOrgNotFound, SweepError.CrmOrgBadCredentials].includes(
      globalLoadingStatus.error?.sweepError,
    );
  if (isConnectionError) {
    return globalLoadingStatus.error?.sweepError;
  }
};

const getSweepErrorFromFetchStatus = (orgFetchStatus: FetchStatus) => {
  const isConnectionError = orgFetchStatus && isOrgConnectionError(orgFetchStatus);
  if (isConnectionError) {
    return convertFetchStatusToSweepError(orgFetchStatus);
  }
};

export const useUnhappyStates = () => {
  const globalLoadingStatuses = useSelector(selectLoadingStatuses);
  const defaultCreationEnv = useSelector(selectDefaultCreationEnvironment);
  const noConnectedOrg = useNoConnectedOrgs();
  const isManagedPackagedInstalled = useIsManagedPackageInstalled();
  const noConnectedHubspot = !Boolean(useSelector(selectConnectedHubspotOrg));
  const noProductionCrmConnected = !Boolean(useSelector(selectProductionCrmOrg)?.isConnected);
  const { paywallAction } = usePaywall();

  const isPaywallV2Block = paywallAction === PaywallAction.TALK_TO_SALES;

  const fetchError = defaultCreationEnv
    ? getSweepErrorFromFetchStatus(defaultCreationEnv.fetchStatus)
    : undefined;
  const globalError = defaultCreationEnv
    ? getSweepErrorFromGlobalLoadingStatus(globalLoadingStatuses[defaultCreationEnv.id])
    : undefined;
  const isConnectionError = fetchError || globalError;
  const connectionError =
    isConnectionError && defaultCreationEnv
      ? ({
          crmOrgId: defaultCreationEnv.id,
          error: fetchError ?? globalError,
        } as EnvConnectionError)
      : undefined;

  const isInitialFetch = defaultCreationEnv?.fetchStatus === FetchStatus.InitialFetching;
  const isCurrentlyProductionOrg = defaultCreationEnv?.isMain;

  return {
    connectionError,
    noConnectedOrg,
    isInitialFetch,
    noManagedPackage: defaultCreationEnv && !isManagedPackagedInstalled,
    noConnectedHubspot,
    noProductionCrmConnected,
    isCurrentlyProductionOrg,
    isPaywallV2Block,
  };
};
