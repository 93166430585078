import { IconButton } from '@sweep-io/sweep-design';
import { ViewInSalesforce } from '@sweep-io/sweep-design/dist/icons';
import { Box } from '@mui/material';
import { TitleWithSubtitle } from './atoms/TitleWithSubtitle';
import { BackButton } from './atoms/BackButton';
import { ChatIcon } from './atoms/ChatIcon';
import { ConfigurationType } from '../types';
import StyledTooltip from '../../../common/StyledTooltip';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { InitialsAvatar } from '../../InitialsAvatar';
import { useDependencies } from '../hooks/useDependencies';
import { ButtonWithActionMenu } from './atoms/ButtonWithActionMenu';
import { Panel } from './atoms/Panel';
import { MiddlePanel } from './atoms/MiddlePanel';

interface DependenciesHeaderProps {
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  crmOrgId: string;
  historyLength?: number;
  stepNamesUsedInCanvas: string[];
}

export const DependenciesHeader = ({
  configurationItem,
  type,
  crmOrgId,
  historyLength,
  stepNamesUsedInCanvas,
}: DependenciesHeaderProps) => {
  const { userInputsComments } = useFeatureToggle();
  const { onGoBackInHistory } = useDependencies(crmOrgId, {});
  const { link, annotations } = configurationItem ?? {};
  const { changedBy, changedAt } = annotations ?? {};

  return (
    <Panel>
      {historyLength && historyLength > 1 && <BackButton onClick={onGoBackInHistory} />}

      <Box flex={1} display="flex" justifyContent="space-between" alignItems="flex-start">
        <TitleWithSubtitle
          configurationItem={configurationItem}
          crmOrgId={crmOrgId}
          stepNamesUsedInCanvas={stepNamesUsedInCanvas}
          parentType={type}
        />

        {!userInputsComments && ( //to be removed
          <MiddlePanel>
            {link && (
              <StyledTooltip placement="top" title="Open in Salesforce">
                <IconButton
                  variant="outlined"
                  size={'tiny'}
                  onClick={() => window.open(link, '_blank')}
                >
                  <ViewInSalesforce />
                </IconButton>
              </StyledTooltip>
            )}
            {changedBy && <InitialsAvatar changedAt={changedAt} userIdOrName={changedBy} />}
          </MiddlePanel>
        )}

        {userInputsComments && (
          <MiddlePanel>
            {changedBy && <InitialsAvatar changedAt={changedAt} userIdOrName={changedBy} />}
            <ChatIcon crmOrgId={crmOrgId} />
            <ButtonWithActionMenu configurationItem={configurationItem} crmOrgId={crmOrgId} />
          </MiddlePanel>
        )}
      </Box>
    </Panel>
  );
};
