import GroupMembersList from '../../pages/assignments/group-members/GroupMembersList';
import { CenteredCircularProgress } from '../../common/CenteredCircularProgress';
import { useCallback, useState } from 'react';
import { GroupsList } from '../../pages/assignments/groups/GroupsList';
import { useAssignmentGroups } from '../../pages/assignments/members/useAssignmentGroups';
import DialogOnCanvas from '../../pages/canvas-pages/DialogOnCanvas';

const GroupsDialog = ({
  closeDialog,
  transitionDuration,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
}) => {
  const [groupId, setGroupId] = useState<string | undefined>();
  const { assignmentGroups } = useAssignmentGroups();
  const onBackClick = useCallback(() => setGroupId(undefined), []);
  const onGroupClick = useCallback((groupId: string) => setGroupId(groupId), []);
  return (
    <DialogOnCanvas
      onClose={closeDialog}
      open={true}
      transitionDuration={transitionDuration}
      sx={{
        '.MuiDialogTitle-root': {
          padding: '32px 32px 24px 32px',
        },
        '.MuiDialogContent-root': {
          padding: '0 32px 32px 32px',
        },
      }}
    >
      {!assignmentGroups && <CenteredCircularProgress />}
      {assignmentGroups && (
        <>
          {groupId ? (
            <GroupMembersList groupId={groupId} onBackClick={onBackClick} onClose={closeDialog} />
          ) : (
            <GroupsList
              assignmentsGroupList={assignmentGroups}
              onGroupClick={onGroupClick}
              onClose={closeDialog}
            />
          )}
        </>
      )}
    </DialogOnCanvas>
  );
};

export default GroupsDialog;
