import { Box } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { waitForWindowToClose } from '../../pages/helper';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';
import { ButtonVariants } from '@sweep-io/sweep-design/dist/components/Button/types';
import { retry } from 'ts-retry-promise';
import { useCallback, useState } from 'react';
import usePermission from '../../common/permissions/usePermission';
import { Permission } from '@server/permissions';
import { useHubspot } from '../../pages/canvas-pages/canvas-hubspot-page/useHubspot';
import { useHubspotApiFacade } from '../../../apis/facades/useHubspotApiFacade';
import { useFetchGlobal } from '../../../hooks/global-reducer/useFetchGlobal';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import { useSelector } from 'react-redux';
import { telemetry } from '../../../telemetry';
import { ACTIONS_EVENTS } from '../../../services/events';
import useSendBiEvent from '../../../hooks/useSendBiEvent';

interface HubspotConnectButtonProps {
  variant?: ButtonVariants;
}

enum HubspotConnectionStatus {
  Connected = 'connected',
  NotConnected = 'not connected',
  LoadingData = 'loading data',
}

export const HubspotConnectButton = ({ variant = 'filled' }: HubspotConnectButtonProps) => {
  const {
    createHubspotOrg,
    getHubspotOrg,
    hubspotOrgs,
    getHubspotRedirectToOAuthUrl,
    connectedHubspotOrg,
    deleteConnectedHubspotOrg,
    getHubspotOrgs,
  } = useHubspot();
  const { delete_hubspot_org } = useHubspotApiFacade();
  const productionOrg = useSelector(selectProductionCrmOrg);
  const { fetchGlobal } = useFetchGlobal();
  const sendBiEvent = useSendBiEvent();

  const [isConnecting, setIsConnecting] = useState(false);

  //Although it's possible to create multiple hubspotOrgs in the BE,
  // the product is to allow integrating only to 1

  const getHubspotStatus = () => {
    if (!hubspotOrgs) {
      return HubspotConnectionStatus.LoadingData;
    }
    if (connectedHubspotOrg) {
      return HubspotConnectionStatus.Connected;
    } else {
      return HubspotConnectionStatus.NotConnected;
    }
  };
  const hubspotStatus = getHubspotStatus();
  const permissionStr: Permission[] = ['create:hubspot-orgs'];
  const [isConnectHubspotAllowed] = usePermission(permissionStr);

  const verifyThatOrgIsConnected = useCallback(
    async (hubspotOrgId: string) => {
      const getConnectedHubspotOrg = async () => {
        const hsOrg = await getHubspotOrg(hubspotOrgId);
        if (hsOrg?.isConnected) {
          return hsOrg;
        } else {
          return null;
        }
      };
      try {
        // Retries up to 40 seconds until the org returns isConnected
        // Fails if timeout
        //TODO can be replaced with websockets implementation
        await retry(getConnectedHubspotOrg, {
          until: (t) => t !== null,
          timeout: 40000,
          delay: 2000,
        });
      } catch (err) {
        telemetry.captureError(err);
      }
    },
    [getHubspotOrg],
  );

  // This function is used to connect to hubspot
  // It creates a new hubspot org if there are no orgs
  // If there is an org and it's not connected, it deletes the org and creates a new one

  const _connect = useCallback(async () => {
    try {
      const hubspotOrg = await createHubspotOrg();
      return hubspotOrg;
    } catch (error: any) {
      if (error.response.status === 409) {
        const orgs = await getHubspotOrgs();
        if (orgs?.length) {
          const [org] = orgs;
          if (org.isConnected) {
            return org;
          } else {
            await delete_hubspot_org();
            const hubspotOrg = await createHubspotOrg();
            return hubspotOrg;
          }
        }
      }
      throw error;
    }
  }, [createHubspotOrg, delete_hubspot_org, getHubspotOrgs]);

  const connectToHubspot = useCallback(async () => {
    sendBiEvent({ name: ACTIONS_EVENTS.hubspotConnect });
    setIsConnecting(true);
    const hubspotOrg = await _connect();

    if (!hubspotOrg) {
      setIsConnecting(false);
      telemetry.captureError(new Error('hubspotOrg was not created correctly'));
      return;
    }
    const _window = window.open(
      getHubspotRedirectToOAuthUrl({ hubspotOrgId: hubspotOrg.id }),
      '_blank',
    );
    if (_window) {
      await waitForWindowToClose(_window);
      await verifyThatOrgIsConnected(hubspotOrg.id);
    } else {
      telemetry.captureError(new Error('Error connecting to hubspot'));
    }
    setIsConnecting(false);
    if (productionOrg) {
      fetchGlobal({ crmOrgId: productionOrg.id });
    }
  }, [
    _connect,
    fetchGlobal,
    getHubspotRedirectToOAuthUrl,
    productionOrg,
    verifyThatOrgIsConnected,
    sendBiEvent,
  ]);

  return (
    <Box>
      {HubspotConnectionStatus.Connected !== hubspotStatus && (
        <RestrictedTooltip
          to={permissionStr}
          notAllowedTitle={'To adjust integration settings, please contact your admin.'}
        >
          <Button
            variant={variant}
            disabled={!isConnectHubspotAllowed}
            loading={hubspotStatus === HubspotConnectionStatus.LoadingData || isConnecting}
            onClick={connectToHubspot}
            size='small'
          >
            Connect
          </Button>
        </RestrictedTooltip>
      )}
      {HubspotConnectionStatus.Connected === hubspotStatus && (
        <RestrictedTooltip
          to={permissionStr} //TODO change to the "disconnect" permission
          notAllowedTitle={'To adjust integration settings, please contact your admin.'}
        >
          <Button variant="outlined" onClick={deleteConnectedHubspotOrg} disabled={!isConnectHubspotAllowed}>
            Disconnect
          </Button>
        </RestrictedTooltip>
      )}
    </Box>
  );
};
