import { Box, Popover, PopoverOrigin, PopoverProps } from '@mui/material';
import { Button, Tag, Typography } from '@sweep-io/sweep-design';
import { useCallback, useContext, useState } from 'react';
import { useCrmOrgsApiFacade } from '../../../../../apis/facades/useCrmOrgsApiFacade';
import { SFDCLeadingField } from '../../../../documentation/ParserTypes';
import { SearchSelect, SearchSelectItem } from '../../../../common/SearchSelect';
import { useRunOnce } from '../../../../common/useRunOnce';
import { useErrorHandling } from '../../../../../hooks/useErrorHandling';
import { recordTypeLeadingObjectCtx } from './recordTypeLeadingObjectPanelCtx';
import { ACTIONS_EVENTS } from '../../../../../services/events';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';
import { telemetry } from '../../../../../telemetry';

export interface CurrentRecord {
  anchorEl: PopoverProps['anchorEl'];
  recordTypeId: string;
  leadingField?: {
    values: PicklistValue[];
    name: string;
  };
  origin: 'editRecord' | 'setRecord';
}

interface RecordTypeLeadingObjectPanelProps {
  currentRecord: CurrentRecord;
  crmOrgId: string;
}

export const RecordTypeLeadingObjectPanel = ({
  currentRecord,
  crmOrgId,
}: RecordTypeLeadingObjectPanelProps) => {
  const sendBiEvent = useSendBiEvent();
  const { setCurrentRecord, setLeadingFieldAndValuesForRecordType } = useContext(
    recordTypeLeadingObjectCtx,
  );
  const { get_objectRecordType } = useCrmOrgsApiFacade();
  const [recordTypeData, setRecordTypeData] = useState<SFDCLeadingField[] | undefined>();
  const [currentLeadingField, setCurrentLeadingField] = useState(currentRecord.leadingField);
  const { errorHandlingBuilder } = useErrorHandling();
  const [, setError] = useState<string | undefined>();

  const [objectType, recordTypeName] = currentRecord.recordTypeId.split('.');

  useRunOnce(() => {
    errorHandlingBuilder()
      .withErrorNotification('Error getting record type')
      .withOnError((e) => {
        setError('Error getting record type...');
        setRecordTypeData([]);
        telemetry.captureError(e);
      })
      .execute(async () => {
        const recordTypeData = await get_objectRecordType({
          crmOrgId,
          objectApiName: objectType,
          recordTypeName,
        });
        setRecordTypeData(recordTypeData);
      });
  });

  const steps = currentLeadingField?.values.map((value) => value.label) || [];

  const onChange = useCallback((item: SearchSelectItem<SFDCLeadingField>) => {
    setCurrentLeadingField(item?.data);
  }, []);

  const onSaveClick = useCallback(() => {
    if (!currentLeadingField) {
      return;
    }

    const recordTypeName = `${objectType}.${currentLeadingField.name}`;
    const [, _recordTypeName] = recordTypeName.trimEnd().split('.');
    setLeadingFieldAndValuesForRecordType({
      recordTypeApiName: currentRecord.recordTypeId,
      crmOrgId,
      recordTypeName: _recordTypeName,
      recordTypeDataValues: currentLeadingField.values.map((value) => ({
        label: value.label,
        fullName: value.fullName,
        default: false,
      })),
      leadingField: currentLeadingField.name,
    });
    setCurrentRecord(undefined);
  }, [
    currentLeadingField,
    objectType,
    setLeadingFieldAndValuesForRecordType,
    currentRecord.recordTypeId,
    crmOrgId,
    setCurrentRecord,
  ]);

  const onClose = useCallback(() => {
    setCurrentRecord(undefined);
  }, [setCurrentRecord]);

  if (!currentRecord) return null;

  const loading = !recordTypeData;

  const items: SearchSelectItem<SFDCLeadingField>[] =
    recordTypeData?.map((field) => {
      return {
        label: field.label,
        value: field.name,
        data: field,
      };
    }) || [];

  const dialogSx = {
    width: '384px',
    minHeight: '200px',
    display: 'flex',
    gap: 3,
    padding: 3,
  };

  const anchorOriginStylesMap: {
    [key: string]: {
      anchorOrigin: PopoverOrigin;
      transformOrigin?: PopoverOrigin;
    };
  } = {
    editRecord: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: { vertical: 'top', horizontal: 'center' },
    },
    setRecord: {
      anchorOrigin: {
        vertical: -18,
        horizontal: 0,
      },
    },
  };

  return (
    <Popover
      anchorEl={currentRecord.anchorEl}
      open
      onClose={onClose}
      {...anchorOriginStylesMap[currentRecord.origin]}
    >
      {loading && (
        <Box {...dialogSx} alignItems="center" justifyContent="center">
          <CenteredCircularProgress />
        </Box>
      )}
      {!loading && (
        <Box {...dialogSx} flexDirection="column">
          <Typography variant="h2">Show Business Process</Typography>
          {Boolean(recordTypeData.length) && (
            <>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body-medium">
                  Select the field that reflects the stages in your business process
                </Typography>
                <SearchSelect
                  items={items}
                  onChange={onChange}
                  dataTestId="record-type-selector"
                  SelectProps={{
                    value: currentLeadingField?.name || 'loading',
                    placeholder: 'Choose field',
                  }}
                  valueRenderer={(item) => item?.label}
                />
              </Box>
              {currentLeadingField && (
                <Box display="flex" flexDirection="column" gap={'12px'}>
                  <Typography variant="body-medium">Process stages</Typography>
                  <Box display="flex" gap={1} flexWrap="wrap" rowGap={1}>
                    {steps.map((step) => (
                      <Tag key={step} label={step} size="medium" />
                    ))}
                  </Box>
                </Box>
              )}
            </>
          )}
          {!Boolean(recordTypeData.length) && (
            <Typography variant="body-medium">
              This record type doesn't have any fields that represent a business process.
            </Typography>
          )}

          <Box display="flex" gap={1} justifyContent="flex-end">
            <Button variant="outlined" onClick={onClose}>
              {recordTypeData.length ? 'Cancel' : 'Close'}
            </Button>
            {Boolean(recordTypeData.length) && (
              <Button
                disabled={(!currentLeadingField && steps.length === 0) || loading}
                onClick={() => {
                  sendBiEvent({ name: ACTIONS_EVENTS.visualizeRecordType });
                  onSaveClick();
                }}
              >
                Save
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Popover>
  );
};
