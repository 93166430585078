import pluralize from 'pluralize';
import { ReactNode } from 'react';
import { UserInfo } from '../../../../types/UserInfoTypes';
import { AutomationType } from '../../../../types/enums/AutomationType';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { getDisplayLineTexts } from '../../../Automations/helper';
import { ParsedRecordType, RuleType } from '../../ParserTypes';
import { ConfigurationType } from '../../dependencies/types';
import { ParsedRuleWithParentType } from '../../types';
import { ExpandedObjectDataTableElement } from './ExpandedObjectContent';
import { telemetry } from '../../../../telemetry';
import { isItemActive } from '../../helpers';

export const automationTypeToConfigurationType = (type: AutomationType) => {
  switch (type) {
    case AutomationType.Alert:
      return ConfigurationType.sweepAlert;
    case AutomationType.Assignment:
      return ConfigurationType.sweepAssignment;
    case AutomationType.Dedupe:
      return ConfigurationType.sweepDedupe;
    case AutomationType.Matching:
      return ConfigurationType.sweepMatching;
    case AutomationType.ScheduledAssignment:
      return ConfigurationType.sweepScheduledAssignment;
    case AutomationType.Default:
      return ConfigurationType.sweepAutomation;

    case AutomationType.ScheduledReport:
      //no documentation support yet
      break;

    default:
      telemetry.captureError(new Error('unknown type in automationTypeToConfigurationType'), {
        type,
      });
      break;
  }
};

const ruleTypeToConfigurationType = (type: RuleType) => {
  switch (type) {
    case RuleType.apexTriggers:
      return ConfigurationType.apexTriggers;
    case RuleType.approvalProcesses:
      return ConfigurationType.approvalProcesses;
    case RuleType.workflowRules:
      return ConfigurationType.workflowRules;
    case RuleType.flows:
      return ConfigurationType.flows;
    case RuleType.processBuilderFlows:
      return ConfigurationType.processBuilderFlows;
    case RuleType.cpqData:
      return ConfigurationType.cpqData;
    case RuleType.layouts:
      return ConfigurationType.layouts;
    case RuleType.validationRules:
      return ConfigurationType.validationRules;
    default:
      telemetry.captureError('unknown type in ruleTypeToConfigurationType', { type });
      return type as unknown as ConfigurationType;
  }
};

export const translateItemsToDataTableElements = (
  documentationTabType: DocumentationTabTypes,
  items:
    | FieldMetadataRecordProperties[]
    | AutomationStructureNew[]
    | ParsedRuleWithParentType[]
    | ParsedRecordType[],
  sweepUsers?: UserInfo[],
): ExpandedObjectDataTableElement[] => {
  switch (documentationTabType) {
    case DocumentationTabTypes.FIELDS:
      return (items as FieldMetadataRecordProperties[]).map((item) => ({
        id: item.id,
        label: item.label,
        sfApiName: item.name,
        _sfApiName: item.name,
        createdAt: item.annotations?.createdAt,
        updatedAt: item.annotations?.changedAt,
        createdBy: item.annotations?.createdBy,
        updatedBy: item.annotations?.changedBy,
        description: item.description,
        type: item.type,
        _type: item.type,
        usage: item.usage,
        isRollup: item.isRollup,
        parentType: item.isRollup ? ConfigurationType.rollups : ConfigurationType.fields,
        isActive: true, //fields cannot be inactive
      }));

    case DocumentationTabTypes.RECORD_TYPES:
      return (items as ParsedRecordType[]).map((item) => ({
        id: item.id,
        label: item.label,
        sfApiName: item.name,
        _sfApiName: item.name,
        createdAt: item.annotations?.createdAt,
        updatedAt: item.annotations?.changedAt,
        createdBy: item.annotations?.createdBy,
        updatedBy: item.annotations?.changedBy,
        description: item.description,
        isActive: isItemActive(item),
        type: ConfigurationType.recordTypes,
        _type: ConfigurationType.recordTypes,
        parentType: ConfigurationType.recordTypes,
      }));

    case DocumentationTabTypes.SWEEP_AUTOMATIONS:
    case DocumentationTabTypes.PLAYBOOK_ALERTS:
    case DocumentationTabTypes.ASSIGNMENTS:
    case DocumentationTabTypes.SCHEDULED_ASSIGNMENTS:
    case DocumentationTabTypes.MATCHING_DEDUPE:
      return (items as AutomationStructureNew[])
        .map((item) => {
          const user = sweepUsers?.find((user) => user.id === item.updatedById);
          const conditionsCounter = item.automationDetails.when?.criteria?.criteria.length;
          const conditionsStr = conditionsCounter
            ? `\n(${conditionsCounter} ${pluralize('Condition')})`
            : '';

          const { actionText, triggerText } = getDisplayLineTexts(item);
          let _actionText = '';

          const components: ReactNode[] = [];

          actionText.forEach((action) => {
            if (typeof action === 'string') {
              _actionText = _actionText === '' ? action : _actionText + ',\n' + action;
            } else {
              components.push(action);
            }
          });

          components.push(_actionText);

          return {
            id: item.automationId,
            label: item.name,
            sfApiName: item.name,
            _sfApiName: item.name,
            updatedAt: item.updatedAt,
            updatedBy: user?.name ?? 'No data',
            type: item.type,
            _type: item.type,
            trigger: triggerText + conditionsStr,
            actions: components,
            status: item.isActive ? 'Active' : 'Inactive',
            parentType: automationTypeToConfigurationType(item.type) as ConfigurationType,
            isActive: true, //sweep elements cannot be inactive
          };
        })
        .filter((item) => !!item.parentType); //to filter out unsupported types;

    default:
      return (items as ParsedRuleWithParentType[]).map((item) => ({
        id: item.id,
        label: item.label,
        sfApiName: item.name,
        _sfApiName: item.name,
        createdAt: item.annotations?.createdAt,
        updatedAt: item.annotations?.changedAt,
        createdBy: item.annotations?.createdBy,
        updatedBy: item.annotations?.changedBy,
        description: item.description,
        type: item.type,
        _type: item.type,
        parentType: ruleTypeToConfigurationType(item.type),
        errorMessage: item.errorMessage,
        isActive: isItemActive(item),
      }));
  }
};
