import { useDispatch, useSelector } from 'react-redux';
import {
  selectLeftPanelFilters,
  setLeftPanelFiltersValues,
} from '../../../../reducers/leftPanelReducer';
import { VisibilityLayers } from '../../../../types/enums/VisibilityLayers';
import { VisibilityMap } from '../../../../types/VisibilityTypes';
import DedupMatching from './DedupMatching';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../../configuration-canvas-panel';
import { VisibilityElements } from '../../configuration-canvas/types';
import { WithConfigurationCanvas } from '../../configuration-canvas/WithConfigurationCanvas';
import { useConfigurationCanvas } from '../../configuration-canvas/public/useConfigurationCanvas';
import useAutomationsCanvasInteractions from '../useAutomationsCanvasInteractions';
import { useCallback } from 'react';
import { MultipleRuleBuildersProvider } from '../../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';

const visibilityElements: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
};

const visibilityElementsMap: VisibilityElements = {
  type: 'map',
  visibilityMap: visibilityElements,
};

export const CanvasDedupAndMatchingPage = () => {
  const dispatch = useDispatch();
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { funnelsData, recordTypesData } = canvasFunnelMap;
  const selectedFilters = useSelector(selectLeftPanelFilters);
  const { setHoveredItem } = useAutomationsCanvasInteractions();

  const setSelectedFilters = useCallback(
    (filters: Partial<AutomationDialogFilters>) => {
      dispatch(setLeftPanelFiltersValues(filters));
    },
    [dispatch],
  );

  return (
    <WithConfigurationCanvas visibilityElements={visibilityElementsMap}>
      <ConfigurationCanvasLeftPanel>
        <ConfigurationCanvasPanelHeader>Dedupe & Matching</ConfigurationCanvasPanelHeader>
        <ConfigurationCanvasPanelContent sx={{ mt: -2 }}>
          <MultipleRuleBuildersProvider>
            <DedupMatching
              funnelsData={funnelsData}
              recordTypesData={recordTypesData}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setHoveredItem={setHoveredItem}
            />
          </MultipleRuleBuildersProvider>
        </ConfigurationCanvasPanelContent>
      </ConfigurationCanvasLeftPanel>
    </WithConfigurationCanvas>
  );
};
