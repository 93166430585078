import { Grid } from '@mui/material';
import { InviteeSummary } from './InviteeSummary';
import { UserSummaryNew } from './UserSummaryNew';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useUserInfo from '../../../hooks/useUserInfo';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { Template } from '../../../types/UserInfoTypes';
import { useSweepNotifications } from '../../notifications/useSweepNotifications';
import { InviteUserInput, MessageResponse } from './InviteUserInput';
import ConfirmDestructiveDialog from '../dialogs/ConfirmDestructiveDialog';
import { getIsUserInactive } from '../../../lib/getIsUserActive';
import { selectAccountUsersData } from '../../../reducers/accountUsersReducer';
import { ACTIONS_EVENTS } from '../../../services/events';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { telemetry } from '../../../telemetry';
import { selectRoleGroups } from '../../../reducers/roleGroupsReducer';
import { RoleGroupType } from '@server/role-group-interface';

export const removeInvitationMessages = {
  [SweepNotificationVariant.Success]: `Invitation removed successfully`,
  [SweepNotificationVariant.Error]: `Invitation removal failed, please try again later`,
};

export const removeUserMessages = {
  [SweepNotificationVariant.Success]: `User removed successfully`,
  [SweepNotificationVariant.Error]: `User removal failed, please try again later`,
};

export const resentMessages = (email: string) => ({
  [SweepNotificationVariant.Success]: `Invitation email to ${email} was sent successfully`,
  [SweepNotificationVariant.Error]: `Invite to Sweep failed, please try again`,
});

interface InviteUsersInputWithListProps {
  funnelMapName?: string;
  funnelMapId?: string;
  disableRoleDropdown?: boolean;
  template?: Template;
  messagePlaceholder?: string;
}

export const InviteUsersInputWithList = ({
  funnelMapId,
  funnelMapName,
  disableRoleDropdown,
  template = Template.Account,
  messagePlaceholder,
}: InviteUsersInputWithListProps) => {
  const { addNotification } = useSweepNotifications();
  const { getAccountUsersData, sendInvitation, updateUserRoles, removeInvitation, removeUser } =
    useUserInfo();
  const sendBiEvent = useSendBiEvent();

  const userInfo = useSelector(selectUserInfoData);
  const accountUsersData = useSelector(selectAccountUsersData);
  const roles = useSelector(selectRoleGroups)?.filter((role) => role.type === RoleGroupType.SYSTEM);

  const orgUsers = accountUsersData?.users || [];
  const orgInvitations = accountUsersData?.invitations || [];

  const activeOrgUsersWithoutCurrent = orgUsers.filter(
    (user) => user.id !== userInfo?.id && !getIsUserInactive(user.status),
  );

  const [userToRemove, setUserToRemove] = useState('');

  const displayNotification = useCallback(
    (
      messageResponse: MessageResponse,
      variant: SweepNotificationVariant.Success | SweepNotificationVariant.Error,
    ) => {
      addNotification({
        message: messageResponse[variant],
        variant,
      });
    },
    [addNotification],
  );

  const afterSendActions = useCallback(
    async (isError: boolean, messageResponse?: MessageResponse) => {
      messageResponse &&
        displayNotification(
          messageResponse,
          isError ? SweepNotificationVariant.Error : SweepNotificationVariant.Success,
        );
      getAccountUsersData();
    },
    [displayNotification, getAccountUsersData],
  );

  const updateUserRole = useCallback(
    async (userId: string, newRole: string) => {
      await updateUserRoles(userId, newRole);
      afterSendActions(true);
    },
    [updateUserRoles, afterSendActions],
  );

  const sendNewInvitation = useCallback(
    async (email: string, roleGroupId: string, message: string) => {
      sendBiEvent({ name: ACTIONS_EVENTS.inviteUser });
      return await sendInvitation({
        email,
        roleGroupId,
        message,
        entryPointId: funnelMapId,
        entryPointName: funnelMapName,
        template,
      });
    },
    [funnelMapId, funnelMapName, sendBiEvent, sendInvitation, template],
  );

  return (
    <>
      <ConfirmDestructiveDialog
        open={Boolean(userToRemove)}
        onConfirm={async () => {
          try {
            await removeUser(userToRemove);
            afterSendActions(false, removeUserMessages);
            setUserToRemove('');
          } catch (error) {
            telemetry.captureError(new Error('Error while removing user'), error);
            afterSendActions(true, removeUserMessages);
            setUserToRemove('');
          }
        }}
        onClose={() => setUserToRemove('')}
        title="Remove user"
        confirmText="Confirm"
        cancelText="Cancel"
      >
        Confirming this action will remove user permanently from your workspace.
      </ConfirmDestructiveDialog>

      <InviteUserInput
        roles={roles ?? []}
        isAccountConnected={!!userInfo?.account?.id}
        onSendInvitationEmail={sendNewInvitation}
        afterSendActions={afterSendActions}
        messagePlaceholder={messagePlaceholder}
      />

      <Grid container flexDirection="column" gap="24px" sx={{ padding: '16px 0' }}>
        <div data-testid="user-summary-current">
          <UserSummaryNew
            user={userInfo}
            roles={roles ?? []}
            onChangeRoleCb={updateUserRole}
            disableRoleDropdown //always disabled so current user won't change his role
          />
        </div>

        {activeOrgUsersWithoutCurrent.map((user, index) => (
          <div key={'user_' + user.id} data-testid={`user-summary-${index}`}>
            <UserSummaryNew
              user={user}
              roles={roles ?? []}
              onChangeRoleCb={updateUserRole}
              onUserRemove={setUserToRemove}
              disableRoleDropdown={disableRoleDropdown}
            />
          </div>
        ))}

        {orgInvitations.map((user) => (
          <div key={'invitee_' + user.id}>
            <InviteeSummary
              user={user}
              onResend={async (user) => {
                try {
                  const res = await sendInvitation({
                    email: user.invitee,
                    roleGroupId: user.roleGroupId,
                    template: user.template,
                    message: user.message,
                    entryPointName: user.entryPointName,
                    entryPointId: user.entryPointId,
                  });
                  afterSendActions(false, resentMessages(user.invitee));
                  return res;
                } catch (error) {
                  telemetry.captureError(error);
                  afterSendActions(true, resentMessages(user.invitee));
                }
              }}
              onRemove={async (invitationId) => {
                try {
                  const res = await removeInvitation(invitationId);
                  afterSendActions(false, removeInvitationMessages);
                  return res;
                } catch (error) {
                  telemetry.captureError(error);
                  afterSendActions(true, removeInvitationMessages);
                }
              }}
            />
          </div>
        ))}
      </Grid>
    </>
  );
};
