import { OnPluginClickEvent, SweepMultiCanvasInternal } from '../multi-canvas/SweepCanvas';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { HighlightEntities, NewFunnelTransientData, PlacingTemplate } from './funnelMapCanvasTypes';
import {
  GroupLabelEntityType,
  GroupPillEntityType,
  NodeEntityTypes,
  OnNodeClickProps,
  StepEntityType,
  StepPillEntityType,
  SweepNodesChangeEvent,
  SweepNodesChangeType,
} from '../multi-canvas/canvasTypes';
import { TRANSIENT_GROUP_ID, useFunnelMapElements } from './useFunnelMapElements';
import { useCallback, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { PillsMap } from '../canvas-pills/canvasPillsReducer';
import { nodeIdToRecordTypePicklistFullName } from './helper';
import { MoveCanvasGroups } from '../multi-canvas/useMoveGroups';
import SweepStagesModel from '../../models/stagesModel';
import keyBy from 'lodash/keyBy';
import { Dictionary } from 'lodash';

const EMPTY_OBJECT = {};

export interface FunnelMapCanvasProps {
  funnelMap: FunnelMap;
  readonly?: boolean;
  funnelStageMetadata?: StageMetadata[];
  visibilityMap: VisibilityMap;
  hideGroupInfo?: boolean;
  onRemoveFunnelLinkClick?: (
    stageId: string,
    targetStageId: string,
    funnelId: string,
    targetFunnelId: string,
  ) => void;
  onFunnelStepClick?: (props: {
    stepId: string;
    funnelId: string;
    entity: StepEntityType | StepPillEntityType;
    event: React.MouseEvent;
  }) => void;
  onRecordTypeStepClick?: (props: {
    stepId: string;
    recordTypePicklistFullName: string;
    recordTypeApiName: string;
    entity: StepEntityType | StepPillEntityType;
    event: React.MouseEvent;
  }) => void;
  onFunnelLabelClick?: (props: {
    funnelId: string;
    entity: GroupPillEntityType | GroupLabelEntityType;
    event: React.MouseEvent;
  }) => any;
  onFunnelLabelPillClick?: (props: {
    funnelId: string;
    entity: GroupPillEntityType | GroupLabelEntityType;
    event: React.MouseEvent;
  }) => any;
  onRecordTypeLabelClick?: (props: {
    recordTypeApiName: string;
    entity: GroupPillEntityType | GroupLabelEntityType;
    event: React.MouseEvent;
  }) => any;
  onRecordTypeLabelPillClick?: (props: {
    recordTypeApiName: string;
    entity: GroupPillEntityType | GroupLabelEntityType;
    event: React.MouseEvent;
  }) => any;
  onFunnelOverlayClick?: (funnelId: string) => void;
  onPluginClick?: OnPluginClickEvent;
  onStageGateClick?: (funnelId: string, stageId: string, exitCriteriaId: string) => void;
  selectedGateId?: string;
  selectedStageId?: string;
  onConnectStepsClick?: (
    stepId: string,
    funnelId: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  holdNodeHighlighted?: boolean;
  isLoadingCursor?: boolean;
  onRedPillClick?: () => void;
  newFunnelTransientData?: NewFunnelTransientData;
  placingTemplate?: PlacingTemplate;
  onFunnelMapPositionsChange?: (funnelMap: FunnelMap) => void;
  onGhostRecordTypeStepButtonClick?: (props: {
    recordTypeId: string;
    event: React.MouseEvent;
  }) => void;
  highlightEntities?: HighlightEntities[];
  renderZeroCountPills?: boolean;
  pills?: Partial<PillsMap>;
  moveGroups?: MoveCanvasGroups | undefined;
  autoFitViewOnFirstNodes?: boolean;
  onSweepElementsChange?: (event: SweepNodesChangeEvent) => void;
  transientFunnel?: Funnel;
  controlsRightMargin?: number;
  disableNodeHighlight?: boolean;
  objectTypesByName: Dictionary<ObjectTypeName>;
  onRecordTypeEditButtonClick?: (props: { recordTypeId: string; event: React.MouseEvent }) => any;
}

export const FunnelMapCanvas = ({
  funnelMap,
  readonly,
  funnelStageMetadata,
  visibilityMap,
  hideGroupInfo,
  onRemoveFunnelLinkClick,
  onFunnelOverlayClick,
  onPluginClick,
  selectedGateId,
  holdNodeHighlighted,
  selectedStageId,
  isLoadingCursor,
  onRedPillClick,
  newFunnelTransientData,
  onFunnelMapPositionsChange,
  onGhostRecordTypeStepButtonClick,
  onFunnelLabelClick,
  onRecordTypeLabelClick,
  onFunnelLabelPillClick,
  onRecordTypeLabelPillClick,
  onRecordTypeStepClick,
  onFunnelStepClick,
  highlightEntities,
  renderZeroCountPills,
  pills,
  moveGroups,
  autoFitViewOnFirstNodes,
  onSweepElementsChange,
  onConnectStepsClick,
  onStageGateClick,
  transientFunnel,
  placingTemplate,
  controlsRightMargin,
  disableNodeHighlight,
  objectTypesByName,
  onRecordTypeEditButtonClick,
}: FunnelMapCanvasProps) => {
  const isPlacingPlugin = Boolean(onFunnelOverlayClick);

  const funnelsData: FunnelsData = funnelMap.funnelsData || EMPTY_OBJECT;
  const recordTypesData: RecordTypesData = funnelMap.recordTypesData || EMPTY_OBJECT;

  const _funnelMapWithTransientFunnel = cloneDeep(funnelMap);
  const _funnelsDataWithTransientFunnel = cloneDeep(funnelsData);

  if (transientFunnel) {
    _funnelMapWithTransientFunnel.funnels[transientFunnel.id] = {
      position: {
        column: 0,
        row: 0,
      },
    };
    _funnelsDataWithTransientFunnel[transientFunnel.id] = transientFunnel;
  }
  if (placingTemplate) {
    placingTemplate.template.funnelTemplates.forEach((template) => {
      _funnelMapWithTransientFunnel.funnels[template.id] = {
        position: template.position,
      };
      _funnelsDataWithTransientFunnel[template.id] = {
        id: template.id,
        name: template.name,
        recordType: {
          description: '',
          label: '',
          name: template.objectName,
          objectName: template.objectName,
        },
        funnelDetails: template.templateFunnelDetails,
        description: '',
        accountId: '',
        createdAt: '',
        createdById: '',
        updatedAt: '',
        snapshotsIds: [],
        stageMetadata: [],
        updatedById: '',
      };
    });
  }

  const newFunnelMap = useMemo(
    () => ({
      ..._funnelMapWithTransientFunnel,
      funnelsData: _funnelsDataWithTransientFunnel,
      recordTypesData,
    }),
    [_funnelMapWithTransientFunnel, _funnelsDataWithTransientFunnel, recordTypesData],
  );

  const { sweepEdges, sweepGroups, sweepNodes } = useFunnelMapElements({
    funnelMap: newFunnelMap,
    funnelStageMetadata,
    visibilityMap,
    transientFunnelData: newFunnelTransientData,
    highlightEntities,
    renderZeroCountPills,
    pills,
    objectTypesByName,
  });

  const getGroupTypeFromNodeId = useCallback(
    (nodeId: string) => {
      if (Object.keys(funnelMap.funnels).includes(nodeId)) {
        return 'funnel';
      }
      if (Object.keys(funnelMap.hubspotFunnels).includes(nodeId)) {
        return 'hubspotFunnel';
      }
      if (!Object.keys(funnelMap.recordTypes).includes(nodeId) && nodeId !== TRANSIENT_GROUP_ID) {
        return undefined;
      }
      return 'recordType';
    },
    [funnelMap.funnels, funnelMap.hubspotFunnels, funnelMap.recordTypes],
  );

  const onSweepNodesChange = useCallback(
    (event: SweepNodesChangeEvent) => {
      onSweepElementsChange?.(event);
      if (!onFunnelMapPositionsChange) return;

      const { change, type } = event;
      switch (type) {
        case SweepNodesChangeType.MoveGroup: {
          const { groupsToMove } = change;
          groupsToMove.forEach((group) => {
            const { nodeId, newPosition } = group;
            const newFunnelMap = cloneDeep(funnelMap); // TODO: Remove this
            switch (getGroupTypeFromNodeId(nodeId)) {
              case 'recordType': {
                newFunnelMap.recordTypes[nodeId].position = newPosition;
                onFunnelMapPositionsChange(newFunnelMap);
                break;
              }
              case 'funnel': {
                newFunnelMap.funnels[nodeId].position = newPosition;
                onFunnelMapPositionsChange(newFunnelMap);
                break;
              }
              case 'hubspotFunnel': {
                newFunnelMap.hubspotFunnels[nodeId].position = newPosition;
                onFunnelMapPositionsChange(newFunnelMap);
                break;
              }
              default:
                break;
            }
          });
          break;
        }

        case SweepNodesChangeType.MoveNode:
          break;
      }
    },
    [funnelMap, getGroupTypeFromNodeId, onFunnelMapPositionsChange, onSweepElementsChange],
  );

  const onNodeClick = useCallback(
    ({ id, parentId, entity, event }: OnNodeClickProps) => {
      const nodeType = getGroupTypeFromNodeId(parentId || id);

      switch (entity.type) {
        case NodeEntityTypes.GhostStepButton: {
          const [recordTypeId] = id.split('-');
          onGhostRecordTypeStepButtonClick?.({
            recordTypeId,
            event,
          });
          break;
        }
        case NodeEntityTypes.Step:
        case NodeEntityTypes.StepPill: {
          if (!parentId) {
            throw new Error('parentId was not found');
          }
          if (nodeType === 'funnel') {
            onFunnelStepClick?.({ stepId: id, funnelId: parentId, entity, event });
          } else {
            onRecordTypeStepClick?.({
              stepId: id,
              recordTypePicklistFullName: nodeIdToRecordTypePicklistFullName(id),
              recordTypeApiName: parentId,
              entity,
              event,
            });
          }
          break;
        }
        case NodeEntityTypes.GroupLabel:
          if (nodeType === 'funnel') {
            onFunnelLabelClick?.({ funnelId: id, entity, event });
          } else {
            onRecordTypeLabelClick?.({ recordTypeApiName: id, entity, event });
          }
          break;
        case NodeEntityTypes.GroupOverlay:
          onFunnelOverlayClick?.(id);
          break;
        case NodeEntityTypes.GroupEditButton:
          onRecordTypeEditButtonClick?.({ recordTypeId: id, event });
          break;
      }
    },
    [
      getGroupTypeFromNodeId,
      onFunnelLabelClick,
      onFunnelOverlayClick,
      onFunnelStepClick,
      onGhostRecordTypeStepButtonClick,
      onRecordTypeEditButtonClick,
      onRecordTypeLabelClick,
      onRecordTypeStepClick,
    ],
  );

  const onLabelClick = useCallback(
    ({ id, parentId, entity, event }: OnNodeClickProps) => {
      const nodeType = getGroupTypeFromNodeId(parentId || id);

      switch (entity.type) {
        case NodeEntityTypes.GroupLabel:
          if (nodeType === 'funnel') {
            onFunnelLabelClick?.({ funnelId: id, entity, event });
          } else {
            onRecordTypeLabelClick?.({ recordTypeApiName: id, entity, event });
          }
          break;
      }
    },
    [getGroupTypeFromNodeId, onFunnelLabelClick, onRecordTypeLabelClick],
  );

  const onPillClick = useCallback(
    ({ id, parentId, entity, event }: OnNodeClickProps) => {
      const nodeType = getGroupTypeFromNodeId(parentId || id);

      switch (entity.type) {
        case NodeEntityTypes.StepPill: {
          if (!parentId) {
            throw new Error('parentId was not found');
          }
          if (nodeType === 'funnel') {
            onFunnelStepClick?.({ stepId: id, funnelId: parentId, entity, event });
          } else {
            onRecordTypeStepClick?.({
              stepId: id,
              recordTypePicklistFullName: nodeIdToRecordTypePicklistFullName(id),
              recordTypeApiName: parentId,
              entity,
              event,
            });
          }
          break;
        }
        case NodeEntityTypes.GroupPill: {
          if (nodeType === 'funnel') {
            onFunnelLabelPillClick?.({ funnelId: id, entity, event });
          } else {
            onRecordTypeLabelPillClick?.({ recordTypeApiName: id, entity, event });
          }
          break;
        }
      }
    },
    [
      getGroupTypeFromNodeId,
      onRecordTypeLabelPillClick,
      onFunnelLabelPillClick,
      onFunnelStepClick,
      onRecordTypeStepClick,
    ],
  );

  const funnelDetailsStages = Object.keys(funnelsData)
    .map((funnelId) => funnelsData[funnelId].funnelDetails.stages)
    .flat();

  const sweepStagesByKey = useMemo(
    () => keyBy(funnelDetailsStages, '_stageId'),
    [funnelDetailsStages],
  );

  const findExitCriteriaByTarget = useCallback(
    (sourceStageId: string, targetStageId: string) =>
      sweepStagesByKey[sourceStageId].exitCriteria.find(
        (exitCriteria) => exitCriteria._nextStageId === targetStageId,
      ),
    [sweepStagesByKey],
  );

  const onGateClick = useCallback(
    (sourceNodeId: string, targetNodeId: string, sourceNodeParentId: string) => {
      if (!onStageGateClick) return;
      const exitCriteria = findExitCriteriaByTarget(sourceNodeId, targetNodeId);
      const objectName = funnelsData[sourceNodeParentId]?.funnelDetails.leadingObject.objectName;
      const stagesModel = new SweepStagesModel(
        funnelsData[sourceNodeParentId]?.funnelDetails.stages || [],
      );
      const stageModel = stagesModel.stageByIdOrUndefined(sourceNodeId);

      if (!exitCriteria || !stageModel || !objectName) return;

      onStageGateClick(sourceNodeParentId, sourceNodeId, exitCriteria._exitCriteriaId);
    },
    [findExitCriteriaByTarget, funnelsData, onStageGateClick],
  );

  const validateStageName = useCallback(
    (name: string, funnelId: string) => {
      const stagesModel = new SweepStagesModel(funnelsData[funnelId]?.funnelDetails.stages || []);
      return stagesModel.validateStageName(name.trim());
    },
    [funnelsData],
  );

  const validators = useMemo(
    () => ({
      onNodeNameChange: validateStageName,
    }),
    [validateStageName],
  );

  const hasPillClick = !!(onFunnelLabelPillClick || onRecordTypeLabelPillClick);
  const hasLabelClick = !!(onFunnelLabelClick || onRecordTypeLabelClick);

  return (
    <SweepMultiCanvasInternal
      selectedNodeId={selectedStageId}
      selectedEdgeId={selectedGateId}
      sweepGroups={sweepGroups}
      sweepNodes={sweepNodes}
      sweepEdges={sweepEdges}
      visibilityMap={visibilityMap}
      onNodeClick={onNodeClick}
      onPillClick={hasPillClick ? onPillClick : undefined}
      onLabelClick={hasLabelClick ? onLabelClick : undefined}
      onEdgeDeleteClick={onRemoveFunnelLinkClick}
      readonly={readonly}
      onRedPillClick={onRedPillClick}
      holdNodeHighlighted={holdNodeHighlighted}
      hideGroupInfo={hideGroupInfo}
      autoFitViewOnFirstNodes={autoFitViewOnFirstNodes}
      isLoadingCursor={isLoadingCursor}
      showGroupOverlays={isPlacingPlugin}
      onPluginClick={onPluginClick}
      onSweepNodesChange={onSweepNodesChange}
      moveGroups={moveGroups}
      onConnectClick={onConnectStepsClick}
      onGateClick={onGateClick}
      validators={validators}
      controlsRightMargin={controlsRightMargin}
      disableNodeHighlight={disableNodeHighlight}
    />
  );
};
