import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import {
  selectElementDependencies,
  selectEnvironments,
} from '../../../../reducers/global/globalReducer';
import { GlobalData } from '../../../../reducers/global/globalReducerTypes';
import { ConfigurationItemMap } from '../../configuration-item-tables/shared/helpers';
import {
  createConfigurationItemFromRollupField,
  createConfigurationItemFromAutomation,
} from '../../helpers';
import { ConfigurationType } from '../types';

export const useGetUsedBySweepElements = ({
  configurationId,
  crmOrgId,
  isSupportedType,
}: {
  configurationId: string;
  crmOrgId: string;
  isSupportedType: boolean;
}) => {
  const usedBy = {} as ConfigurationItemMap;
  const elementDependencies = useSelector(selectElementDependencies(crmOrgId));
  const globalEnvs = useSelector(selectEnvironments);

  if (!isSupportedType) {
    return;
  }

  const elements = globalEnvs?.[crmOrgId]?.data;
  const rollups = elements?.rollups;
  const automationElements = pick(
    elements,
    'automations',
    'alerts',
    'scheduledAssignments',
    'assignments',
    'dedup',
    'matching',
  );

  Object.entries(elementDependencies ?? {}).map(([type, children]) => {
    Object.entries(children ?? {}).map(([childId, childValues]) => {
      if (childValues?.length && childValues?.includes(configurationId)) {
        const configType = type as ConfigurationType;

        const oldUsedByType = usedBy[configType] ?? [];
        const newItem = automationElements?.[
          type as keyof Pick<
            GlobalData,
            'automations' | 'alerts' | 'assignments' | 'dedup' | 'matching' | 'scheduledAssignments'
          >
        ]?.find((el) => el.automationId === childId);

        const rollupField = rollups?.find(
          (rollup) => rollup.rollupId === childId || rollup.sfFieldName === childId,
        )?.rollupField;

        const parsedRollupObjectFields =
          globalEnvs?.[crmOrgId]?.data?.documentation?.parsedFields?.[
            rollupField?.objectName ?? ''
          ];
        const { usage, link } =
          parsedRollupObjectFields?.find((field) => field.name === rollupField?.sfFieldName) ?? {};

        const newRollup = rollupField
          ? createConfigurationItemFromRollupField({
              id: childId,
              sweepField: rollupField,
              usage,
              link,
            })
          : undefined;
        const newConfigItem = createConfigurationItemFromAutomation(newItem);

        if (newConfigItem) {
          usedBy[configType] = [...oldUsedByType, newConfigItem];
        }

        if (newRollup) {
          usedBy[configType] = [...oldUsedByType, newRollup];
        }
      }
    });
  });
  return usedBy;
};
