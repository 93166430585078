import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  closeFunnelSettingsDialog as actionCloseFunnelSettingsDialog,
  FunnelSettingsDialogProps,
  openFunnelSettingsDialog as actionOpenFunnelSettingsDialog,
  addLostStep as actionAddLostStep,
  updateLostStep as actionUpdateLostStep,
  deleteLostStep as actionDeleteLostStep,
  updateName as actionUpdateName,
} from './funnelSettingsReducer';
import { updateFunnelMapSettings as actionUpdateFunnelMapSettings } from '../../../../../reducers/multiFunnelFlowCanvasReducer';

const useFunnelSettingsDialog = () => {
  const dispatch = useDispatch();

  const openFunnelSettingsDialog = (props: Omit<FunnelSettingsDialogProps, 'isOpen'>) => {
    dispatch(actionOpenFunnelSettingsDialog(props));
  };

  const updateFunnelMapName = (newName: string) => {
    dispatch(actionUpdateName({ name: newName }));
  };

  const closeFunnelSettingsDialog = useCallback(() => {
    dispatch(actionCloseFunnelSettingsDialog());
  }, [dispatch]);

  const addLostStep = ({
    funnelId,
    stageName,
    objectType,
  }: {
    funnelId: string;
    stageName: string;
    objectType: string;
  }) => {
    dispatch(
      actionAddLostStep({
        objectType,
        funnelId,
        stageName,
      }),
    );
  };

  const updateLostStep = ({
    funnelId,
    _stageId,
    stageName,
    objectType,
  }: {
    funnelId: string;
    _stageId: string;
    stageName: string;
    objectType: string;
  }) => {
    dispatch(actionUpdateLostStep({ funnelId, _stageId, stageName, objectType }));
  };

  const deleteLostStep = ({
    funnelId,
    _stageId,
    objectType,
  }: {
    funnelId: string;
    _stageId: string;
    objectType: string;
  }) => {
    dispatch(actionDeleteLostStep({ funnelId, _stageId, objectType }));
  };

  const updateFunnelMapSettingsAndCloseDialog = useCallback(
    ({ funnelData, funnelMap }: { funnelData: Funnel[]; funnelMap: { name: string } }) => {
      const newFunnelsData: FunnelsData = {};

      funnelData.forEach((funnel) => {
        newFunnelsData[funnel.id as any] = funnel;
      });

      dispatch(
        actionUpdateFunnelMapSettings({
          funnelMap,
          funnelsData: newFunnelsData,
        }),
      );

      closeFunnelSettingsDialog();
    },
    [dispatch, closeFunnelSettingsDialog],
  );

  return {
    openFunnelSettingsDialog,
    closeFunnelSettingsDialog,
    updateFunnelMapName,
    addLostStep,
    updateLostStep,
    deleteLostStep,
    updateFunnelMapSettingsAndCloseDialog,
  };
};

export { useFunnelSettingsDialog };
