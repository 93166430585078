import uniq from 'lodash/uniq';
import { AdvancedFilterItem } from '../../common/deprecated-advanced-filter/DeprecatedAdvancedFilter';
import { useState } from 'react';
import { AdvancedFilter } from '../../common/advanced-filter/AdvancedFilter';

interface RolesAndProfilesFiltersProps {
  roleItems: AdvancedFilterItem[];
  profileItems: AdvancedFilterItem[];
  selectedRoleItems: string[];
  setSelectedRoleItems: (items: string[]) => void;
  selectedProfileItems: string[];
  setSelectedProfileItems: (items: string[]) => void;
}

export const useRolesAndProfileFilterProps = ({
  crmOrgUsers
}: { crmOrgUsers: CrmOrgUserBase[] }) => {
  const [selectedRoleItems, setSelectedRoleItems] = useState<string[]>([]);
  const [selectedProfileItems, setSelectedProfileItems] = useState<string[]>([]);

  const roleItems = uniq(crmOrgUsers.map((user) => user.roleName)).map((role) => ({
    label: role || '(No role)',
    value: role || ''
  }))

  const profileItems = uniq(crmOrgUsers.map((user) => user.profileName)).map((profile) => ({
    label: profile || '(No profile)',
    value: profile || ''
  }))



  return {
    roleItems,
    selectedRoleItems,
    setSelectedRoleItems,
    profileItems,
    selectedProfileItems,
    setSelectedProfileItems,
  }
}


export const RolesAndProfilesFilters = ({
  roleItems,
  profileItems,
  selectedRoleItems,
  setSelectedRoleItems,
  selectedProfileItems,
  setSelectedProfileItems,

}: RolesAndProfilesFiltersProps) => {
  return <>
    <AdvancedFilter
      items={roleItems}
      selectedItems={selectedRoleItems}
      onSelectedItemsChange={setSelectedRoleItems}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      texts={{
        title: 'Role',
        allSelected: 'All',
      }}
    />
    <AdvancedFilter
      items={profileItems}
      selectedItems={selectedProfileItems}
      onSelectedItemsChange={setSelectedProfileItems}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      texts={{
        title: 'Profile',
        allSelected: 'All',
      }}

    />
  </>
}