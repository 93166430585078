import { Popover } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { CollapseList, ListGroup, ListItem } from '../../../../common/CollapseList';

export interface ConnectStepProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => any;
  stepGroups: ListGroup[];
  onItemClick: (item: ListItem, parentGroup: ListGroup) => any;
  startAllExpanded?: boolean;
}

export const ConnectStep = ({
  anchorEl,
  handleClose,
  stepGroups,
  onItemClick,
  startAllExpanded: openAllExpanded,
}: ConnectStepProps) => {
  const isOpen = Boolean(anchorEl);
  const allItems = Object.values(stepGroups)
    .map((group) => group.items)
    .flat();
  const showSearchField = allItems.length >= 7;

  if (allItems.length === 0) {
    return (
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            marginLeft: '10px',
            marginTop: '-2px',
            background: colors.black,
            padding: '8px',
            borderRadius: '4px',
            color: colors.white,
          },
        }}
      >
        <Typography variant="caption">
          No available destination steps. Steps can only be connected to a later step
        </Typography>
      </Popover>
    );
  }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          maxWidth: 373,
          maxHeight: 400,
          overflow: 'hidden',
          pb: 1,

          '& .collapse-list': {
            p: '0 8px',
          },
        },
      }}
    >
      <CollapseList
        isOpen={isOpen}
        groups={stepGroups}
        showSearchField={showSearchField}
        onItemClick={onItemClick}
        openAllExpanded={openAllExpanded}
        componentHeaderText="Choose destination step"
        openFirstExpanded
      />
    </Popover>
  );
};
