import { v4 as uuidv4 } from 'uuid';
import { StageType } from '../types/enums/StageType';
import { exitCriteriaModel } from './exitCriteriasModel';
import { anExitCriterion, exitCriterionModel } from './exitCriterionModel';
import _isEqual from 'lodash/isEqual';

const aStage = (stage: Partial<SweepStage>): SweepStage => ({
  stageName: '',
  _stageId: uuidv4(),
  exitCriteria: [],
  assignmentRules: [], //TODO remove after removed from BE SweepStage class
  entryInits: [],
  exitInits: [],
  apexClasses: [],
  stageDescription: '',
  _branchIndex: 0,
  stageType: StageType.REGULAR,
  ...stage,
});

const stageModel = (stage: SweepStage) => {
  const setStageName = (stageName: string) => {
    stage = { ...stage, stageName };
  };

  const setStageDescription = (stageDescription: string) => {
    stage = { ...stage, stageDescription };
  };

  const setTeam = (team: string | undefined) => {
    stage = { ...stage, _team: team };
  };

  const setBranchIndex = (_branchIndex: number) => {
    stage._branchIndex = _branchIndex;
  };

  const getBranchIndex = () => stage._branchIndex;

  const getStage = () => stage;
  const getStageId = () => stage._stageId;
  const getStageName = () => stage.stageName;
  const getStageDescription = () => stage.stageDescription;
  const getStageTeam = () => stage._team;

  const createExitCriteria = ({
    criteria,
    criteriaLogic,
    _nextStageId,
  }: {
    criteria?: SweepCriterion[];
    criteriaLogic?: string;
    _nextStageId?: string;
  } = {}): SweepExitCriteria => {
    const exitCriteria = anExitCriterion({
      criteria,
      criteriaLogic,

      _nextStageId: _nextStageId,
    });
    stage.exitCriteria.push(exitCriteria);
    return exitCriteria;
  };

  const getExitCriteriaByIdOrUndefined = (_exitCriteriaId: string) => {
    const exitCriteria = stage.exitCriteria.find(
      (exitCriteria: SweepExitCriteria) => _exitCriteriaId === exitCriteria._exitCriteriaId,
    );
    if (exitCriteria) {
      return exitCriterionModel(exitCriteria);
    }
  };

  const getAllExitCriteria = () => {
    const exitCriteriasModels = stage.exitCriteria.map((exC) => exitCriterionModel(exC));
    return stage.exitCriteria ? exitCriteriasModels : [];
  };

  const getExitCriteriaByNextStatusId = (nextStatusId: string) => {
    const exitCriteria = stage.exitCriteria.find(
      (exitCriteria: SweepExitCriteria) => nextStatusId === exitCriteria._nextStageId,
    );
    if (exitCriteria) {
      return exitCriterionModel(exitCriteria);
    }
  };

  const deleteExitCriteriaById = (_exitCriteriaId: string) => {
    const idx = stage.exitCriteria.findIndex(
      (exitCriteria: SweepExitCriteria) => _exitCriteriaId === exitCriteria._exitCriteriaId,
    );

    if (idx !== -1) {
      const ec = [...stage.exitCriteria];
      return (stage = {
        ...stage,
        exitCriteria: ec.filter((ec, index) => index !== idx),
      });
    }
  };

  const updateExitCriteriaById = (newExitCriteria: SweepExitCriteria) => {
    stage = {
      ...stage,
      exitCriteria: stage.exitCriteria?.map((_ec: SweepExitCriteria) =>
        _ec._exitCriteriaId === newExitCriteria._exitCriteriaId ? newExitCriteria : _ec,
      ),
    };
  };

  const getAllConnectedStageIds = () =>
    stage.exitCriteria.map((exitCriteria) => exitCriteria._nextStageId);

  const isNurturingBucket = () => isStageNurturingBucket(stage);

  const countGates = () => stage.exitCriteria.length;

  const countConnectedStages = () => {
    return stage.exitCriteria.length;
  };

  const setPosition = (col: number, row: number) => {
    stage._branchIndex = row;
    stage._stageColumnIndex = col;
  };

  const clearStageExitCriteria = () => {
    stage.exitCriteria = stage.exitCriteria.map((ec) => ({
      ...ec,
      criteria: [],
      criteriaLogic: '',
      isAutoMoveToNextStage: false,
    }));
  };

  const replaceStage = (_stage: SweepStage) => {
    stage = _stage;
  };

  const exitCriteria = () => exitCriteriaModel(stage.exitCriteria);

  const connectToStageInFunnel = (funnelId: string, stageId: string) => {
    if (!stage.funnelLinks) {
      stage.funnelLinks = [];
    }
    const isAlreadyConnected = Boolean(
      stage.funnelLinks.find((link) =>
        _isEqual(link, {
          funnelId,
          stageId,
        }),
      ),
    );
    if (!isAlreadyConnected) {
      stage.funnelLinks.push({
        funnelId,
        stageId,
      });
    }
  };

  const getStageFunnelLinks = () => stage.funnelLinks;

  const removeFunnelLink = (targetFunnelId: string, targetStageId: string) => {
    // Stage Id's are unique so we can ignore the funnelId
    if (stage.funnelLinks) {
      const idx = stage.funnelLinks.findIndex((link) => link.stageId === targetStageId);
      if (idx !== -1) {
        stage.funnelLinks?.splice(idx, 1);
      }
    }
  };

  return {
    setStageName,
    setStageDescription,
    setBranchIndex,
    setPosition,
    setTeam,
    replaceStage,

    getBranchIndex,
    getStage,
    getStageId,
    getStageDescription,
    getStageTeam,
    getStageName,
    getAllExitCriteria,
    getExitCriteriaByIdOrUndefined,
    getExitCriteriaByNextStatusId,
    getAllConnectedStageIds,

    createExitCriteria,
    deleteExitCriteriaById,
    updateExitCriteriaById,
    clearStageExitCriteria,
    isNurturingBucket,
    countGates,
    countConnectedStages,
    exitCriteria,
    connectToStageInFunnel,
    getStageFunnelLinks,
    removeFunnelLink,
  };
};

export const isStageNurturingBucket = (stage: SweepStage) => stage.stageType === 'nurturing';

export type StageModelType = ReturnType<typeof stageModel>;
export { stageModel, aStage };
