export enum ChatCompletionRequestMessageRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}
export type SalesforceConfigurationType = string;

export type ChatGptPrompt = string;

export interface ConfigurationDescription {
  data: string;
  error: string;
}

export interface ChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole;
  content: ChatGptPrompt;
}
