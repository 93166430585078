import { useCallback, useContext, useEffect, useState } from 'react';
import { SweepCanvasEdge } from './canvasTypes';
import { Node as ReactFlowNode } from 'reactflow';
import { CanvasHighlightContext } from './internal-context/HighlightContext';

const HIGHLIGHT_TIMEOUT = 3000;

export interface HighlightNodeData {
  highlightedNodeId: string;
  data: ReactFlowNode;
  showButtons?: boolean;
  edgeIds?: string[];
}

export const useHighlightNode = ({
  disableHighlight,
  holdNodeHighlighted,
  zoom,
}: {
  disableHighlight?: boolean;
  holdNodeHighlighted?: boolean;
  zoom: number;
}) => {
  const { highlightedNodeData, setHighlightedNodeData } = useContext(CanvasHighlightContext);
  const [highlightTimeoutInstance, setHighlightTimeoutInstance] = useState<NodeJS.Timeout>();

  const highlightNode = useCallback(
    (node: ReactFlowNode) => {
      if (highlightTimeoutInstance) {
        clearTimeout(highlightTimeoutInstance);
      }

      if (!disableHighlight) {
        const _highlightTimeoutInstance = setTimeout(
          () => setHighlightedNodeData(undefined),
          HIGHLIGHT_TIMEOUT,
        );
        setHighlightTimeoutInstance(_highlightTimeoutInstance);
        setHighlightedNodeData({
          highlightedNodeId: node.id,
          data: node,
          showButtons: zoom > 0.3,
        });
      } else {
        setHighlightedNodeData(undefined);
      }
    },
    [highlightTimeoutInstance, disableHighlight, setHighlightedNodeData, zoom],
  );

  const isEdgePartOfHighlightedNode = (edge: SweepCanvasEdge) => {
    const { source, target } = edge;
    return highlightedNodeData && [source, target].includes(highlightedNodeData.highlightedNodeId);
  };

  const removeNodeHighlight = useCallback(() => {
    highlightTimeoutInstance && clearTimeout(highlightTimeoutInstance);
    setHighlightedNodeData(undefined);
  }, [highlightTimeoutInstance, setHighlightedNodeData]);

  useEffect(() => {
    if (holdNodeHighlighted) {
      clearTimeout(highlightTimeoutInstance);
    } else {
      setHighlightedNodeData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdNodeHighlighted]);

  useEffect(() => {
    if (disableHighlight) {
      removeNodeHighlight();
    }
  }, [disableHighlight, removeNodeHighlight]);

  return {
    highlightedNodeData,
    highlightNode,
    removeNodeHighlight,
    isEdgePartOfHighlightedNode,
  };
};
