import { Box } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import { InviteUsersInputWithList } from '../../common/invite-users/InviteUsersInputWithList';
import { useRoleManagerContext } from '../role-manager/RoleManagerContext';
import { useSweepRoleGroups } from '../../common/useSweepRoleGroups';

const OrgInfoTab = ({
  accountName,
  userRoleGroupId,
}: {
  accountName?: string;
  userRoleGroupId?: string;
}) => {
  const { isAdmin } = useSweepRoleGroups();
  const { setRoleManager } = useRoleManagerContext();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      data-testid="org-info"
      sx={{ zIndex: 1 }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          margin: '0 0 24px 0',
        }}
      >
        <Typography ellipsis variant="h2">
          {accountName ? accountName : 'Workspace'}
        </Typography>
        {isAdmin(userRoleGroupId ?? '') && (
          <Button variant="outlined" onClick={() => setRoleManager(true)} size="small">
            Manage roles
          </Button>
        )}
      </Box>

      <InviteUsersInputWithList />
    </Box>
  );
};

export { OrgInfoTab };
