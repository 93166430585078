import { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { SidebarMenu } from './SidebarMenu';
import DashboardHeader from './header/DashboardHeader';
import { UserDialogProvider } from './user-dialog/UserDialogContext';
import { WithUnhappyStates } from '../pages/environments/WithUnhappyStates';
import useWhenInitialFetchDone from './useWhenInitialFetchDone';
import usePaywallTrialCarousel from './header/usePaywallTrialCarousel';
import { DASHBOARD_HEADER_HEIGHT_PX } from '../../constants';
import { useExpandedDocumentationForFreeUsers } from './useExpandedDocumentationForFreeUsers';

interface DashboardProps {
  children?: React.ReactNode;
}

const Dashboard = ({ children }: DashboardProps) => {
  const [open, setOpen] = useState(true);

  useWhenInitialFetchDone();
  usePaywallTrialCarousel();
  useExpandedDocumentationForFreeUsers();

  const toggleHandler = () => {
    setOpen(!open);
  };

  const setSideMenuOpen = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <DashboardHeader sidebarMenuOpen={open} toggle={toggleHandler} />
      <Box sx={{ display: 'flex', height: `calc(100vh - ${DASHBOARD_HEADER_HEIGHT_PX})` }}>
        <SidebarMenu open={open} />
        <Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}>
          <WithUnhappyStates setSideMenuOpen={setSideMenuOpen}>{children}</WithUnhappyStates>
        </Box>
      </Box>
    </Box>
  );
};

const DashboardWithContext = (props: DashboardProps) => {
  return (
    <UserDialogProvider>
      <Dashboard {...props} />
    </UserDialogProvider>
  );
};

export { DashboardWithContext as Dashboard };
