import FunnelDialog from './FunnelDialog';
import { FunnelPanelDataType } from './funnelPanelTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFunnelMapFunnelById,
  updateFunnelName,
  updateFunnelDescription,
  removeFunnel,
} from '../../../../../reducers/multiFunnelFlowCanvasReducer';
import * as yup from 'yup';
import { EditableLabelValidationFunction } from '../../../../common/EditableLabel';
import { useCallback } from 'react';
import useFunnelNameValidationSchema from '../../../../../hooks/useFunnelNameValidationSchema';
import { MultipleRuleBuildersProvider } from '../../../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import ConfirmDestructiveDialog from '../../../../common/dialogs/ConfirmDestructiveDialog';
import useConfirm from '../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';

interface ConnectedFunnelDialogProps {
  closeDialog: () => void;
  isOpen: boolean;
  readonly: boolean;
  data: FunnelPanelDataType;
  crmOrgId: string;
  funnelMapId: string;
}

const ConnectedFunnelDialog = ({
  closeDialog,
  isOpen,
  data,
  readonly,
  crmOrgId,
  funnelMapId,
}: ConnectedFunnelDialogProps) => {
  const dispatch = useDispatch();
  const { openConfirm, onCancel, onConfirm } = useConfirm();
  const { funnelId } = data;
  const funnel = useSelector(selectFunnelMapFunnelById(funnelId));
  const objectName = funnel?.funnelDetails?.leadingObject.objectName;

  const funnelNameValidationSchema = useFunnelNameValidationSchema({
    initialName: funnel?.name ?? '',
    funnelId,
    getObjectName: () => objectName,
  });

  const validateFunnelName: EditableLabelValidationFunction = useCallback(
    async (name: string) => {
      try {
        await funnelNameValidationSchema.validate(name);
        return {
          isValid: true,
        };
      } catch (e) {
        const errors = (e as yup.ValidationError).errors;
        return {
          isValid: false,
          error: errors[0],
        };
      }
    },
    [funnelNameValidationSchema],
  );

  const onRenameConfirm = (newName: string) => {
    dispatch(updateFunnelName({ funnelId, name: newName }));
  };

  const onDescriptionUpdate = (newDescription: string) => {
    dispatch(updateFunnelDescription({ funnelId, description: newDescription }));
  };

  const onRemoveFunnel = () => {
    if (!funnel) return;
    openConfirm(
      <ConfirmDestructiveDialog
        open={true}
        onConfirm={() => {
          dispatch(removeFunnel({ funnelId }));
          onConfirm();
          closeDialog();
        }}
        onClose={onCancel}
        title={`Remove “${funnel.name}”?`}
        confirmText="Remove"
        cancelText="Cancel"
      >
        Are you sure you want to remove “{funnel.name}“ from the canvas?
      </ConfirmDestructiveDialog>,
    );
  };

  if (!isOpen || !funnel) {
    return null;
  }

  return (
    <FunnelDialog
      closeDialog={closeDialog}
      readonly={readonly}
      funnel={funnel}
      validateFunnelName={validateFunnelName}
      onRenameConfirm={onRenameConfirm}
      onDescriptionUpdate={onDescriptionUpdate}
      onRemoveFunnel={onRemoveFunnel}
      crmOrgId={crmOrgId}
      funnelMapId={funnelMapId}
    />
  );
};

const ConnectedFunnelDialogWithProviders = (props: ConnectedFunnelDialogProps) => (
  <MultipleRuleBuildersProvider>
    <ConnectedFunnelDialog {...props} />
  </MultipleRuleBuildersProvider>
);

export default ConnectedFunnelDialogWithProviders;
