import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../../../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../../../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../environments/environmentsReducer';
import { selectDedup, selectMatching } from '../../../../reducers/global/dedupMatchingReducers';
import useDedupMatching from './useDedupMatching';
import { useCallback, useMemo } from 'react';
import { ConfigurationCanvasFilters } from '../../configuration-canvas/ConfigurationCanvasFilters';
import {
  CanvasFilterTypes,
  SweepCanvasFiltersMap,
} from '../../configuration-canvas-filters/filterTypes';
import { DedupMatchingType } from '../../../../types/enums/DedupMatchingType';
import { AutomationCanvasFilter } from '../canvasAutomationsTypes';
import { dedupMatchingSortOptions } from '../consts';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../../../Automations/AutomationsContext';
import { AutomationType } from '../../../../types/enums/AutomationType';
import DrawerObjectsImage from '../../../../components/common/empty-state/svgs/drawerObjects.svg?react';
import { UI_EVENTS } from '../../../../services/events';
import { useAutomationsApiFacade } from '../../../../apis/facades/useAutomationsApiFacade';

export const dedupAutomationContextData: AutomationsContextType = {
  automationType: AutomationType.Dedupe,
  permissions: {
    create: 'create:dedupe-matching',
    edit: 'edit:dedupe-matching',
    deploy: 'deploy:dedupe-matching',
    delete: 'delete:dedupe-matching',
    notifyAdmin: 'edit:dedupe-matching:notify-admin',
  },
  emptyStateTitle: 'Create your first matching and dedupe operation',
  emptyStateImage: <DrawerObjectsImage />,
  defaultName: 'New Dedupe',
  placeholder: 'New Dedupe',
  nameInMessage: 'deduplication and matching rules',
  nameInMessageSingular: 'deduplication / matching rule',
  openEvent: UI_EVENTS.matchingDedupeOpen,
  automationVariant: AutomationFormVariant.DEDUP_MATCHING,
  templateSupport: false, //dedup/matching templates are implemented differently than the other types of automations
  requestDeployNotifyAdmin: async () => {},
};

export const DEDUP_OPERATIONS = [
  DedupMatchingType.LEAD_TO_LEAD_DEDUP,
  DedupMatchingType.CONTACT_TO_CONTACT_DEDUP,
  DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP,
  DedupMatchingType.LEAD_TO_CONTACT_DEDUP,
];
export const MATCHING_OPERATIONS = [
  DedupMatchingType.LEAD_TO_LEAD_MATCHING,
  DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING,
];

export const dedupMatchingFilters: SweepCanvasFiltersMap = {
  [AutomationCanvasFilter.OPERATIONS]: {
    type: CanvasFilterTypes.MULTI_GENERIC,
    position: 0,
    label: 'Operations',
    items: [
      //if new added DEDUP_OPERATIONS should be updated
      { value: 'deduplication_section', label: 'Deduplication', isSectionItem: true },
      { value: DedupMatchingType.LEAD_TO_LEAD_DEDUP, label: 'Lead to Lead' },
      { value: DedupMatchingType.CONTACT_TO_CONTACT_DEDUP, label: 'Contact to Contact' },
      { value: DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP, label: 'Account to Account' },
      { value: DedupMatchingType.LEAD_TO_CONTACT_DEDUP, label: 'Lead to Contact' },
      //if new added DEDUP_OPERATIONS should be updated
      { value: 'matching_section', label: 'Matching', isSectionItem: true },
      { value: DedupMatchingType.LEAD_TO_LEAD_MATCHING, label: 'Lead to Lead' },
      { value: DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING, label: 'Lead to Account' },
    ],
  },
};

const DedupMatching = ({
  recordTypesData,
  funnelsData,
  externalFilter,
  selectedFilters,
  setSelectedFilters,
  setHoveredItem,
}: {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  externalFilter?: (assignment: AutomationStructureNew) => boolean;
  disableCanvasTemplates?: boolean;
  selectedFilters: AutomationDialogFilters;
  setSelectedFilters: (filters: Partial<AutomationDialogFilters>) => void;
  setHoveredItem?: (automationId?: string | undefined) => void;
}) => {
  const dedupList = useSelector(selectDedup);
  const matchingList = useSelector(selectMatching);

  const dedupMatchingList = useMemo(
    () => (dedupList && matchingList ? [...dedupList, ...matchingList] : undefined),
    [dedupList, matchingList],
  );

  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const {
    editDedupMatching,
    createDedupMatching,
    deleteDedupMatching,
    toggleDedupMatching,
    deployDedupMatching,
  } = useDedupMatching();

  const dedupMatchingListToDisplay = useMemo(
    () => (externalFilter ? dedupMatchingList?.filter(externalFilter) : dedupMatchingList),
    [externalFilter, dedupMatchingList],
  );

  const onSaveOrCreate = useCallback(
    async (createAssignmentDto: AutomationStructureNew, crmOrgIds: string[], isEdit: boolean) => {
      const toSend = {
        ...createAssignmentDto,
        deployToOrgIds: crmOrgIds,
      };

      if (isEdit) {
        return await editDedupMatching({
          deployToOrgIds: crmOrgIds,
          updateAutomationDto: toSend,
          versionId: toSend.versionId,
        });
      } else {
        return await createDedupMatching({
          deployToOrgIds: crmOrgIds,
          createAutomationDto: toSend,
        });
      }
    },
    [createDedupMatching, editDedupMatching],
  );

  const onToggleActivation = useCallback(
    async (
      toggleAssignmentDto: AutomationStructureNew,
      deployToOrgIds: string[],
      isActive: boolean,
    ) => {
      const toSend = {
        ...toggleAssignmentDto,
        deployToOrgIds,
        isActive,
      };
      await toggleDedupMatching({
        deployToOrgIds,
        updateAutomationDto: toSend,
        versionId: toSend.versionId,
      });
    },
    [toggleDedupMatching],
  );

  const onDeleteAutomation = useCallback(
    async (automationId: string, automationType: AutomationType) => {
      await deleteDedupMatching({ automationId, automationType });
    },
    [deleteDedupMatching],
  );

  const onDeployAutomation = useCallback(
    async (deployAutomationDto: AutomationStructureNew, deployToOrgIds: string[]) => {
      const toSend = {
        ...deployAutomationDto,
        deployToOrgIds,
      };
      return await deployDedupMatching({
        deployAutomationDto: toSend,
        automationId: toSend.automationId,
        versionId: toSend.versionId,
        deployToOrgIds,
      });
    },
    [deployDedupMatching],
  );

  const filters: SweepCanvasFiltersMap = useMemo(() => dedupMatchingFilters, []);

  const { patch_dedupe_matching_admin_notification } = useAutomationsApiFacade();
  const _dedupAutomationContextData: AutomationsContextType = useMemo(() => {
    return {
      ...dedupAutomationContextData,
      requestDeployNotifyAdmin: patch_dedupe_matching_admin_notification,
    };
  }, [patch_dedupe_matching_admin_notification]);

  if (!dedupMatchingList) return <CenteredCircularProgress />;

  return (
    <>
      <ConfigurationCanvasFilters filters={filters} />
      <Box pt={2} pb={2} flex={1}>
        {crmOrg && (
          <AutomationsContextProvider value={_dedupAutomationContextData}>
            <AutomationDialogContent
              automations={dedupMatchingListToDisplay ?? []}
              onDeleteAutomation={onDeleteAutomation}
              crmOrgId={crmOrg.id}
              onSaveOrCreate={onSaveOrCreate}
              recordTypesData={recordTypesData}
              funnelsData={funnelsData}
              onToggleActivation={onToggleActivation}
              sortOptions={dedupMatchingSortOptions}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setHoveredItem={setHoveredItem}
              onDeployAutomation={onDeployAutomation}
            />
          </AutomationsContextProvider>
        )}
      </Box>
    </>
  );
};

export default DedupMatching;
