import { FunnelMapCanvas, FunnelMapCanvasProps } from '../../../funnel-map-canvas/FunnelMapCanvas';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFunnelStagesMetadata,
  selectImportFunnelTransient,
  selectIsLoadingCursor,
  selectNewFunnelTransientData,
  selectPlacingTemplate,
} from '../../../../reducers/multiFunnelFlowNoHistoryReducer';

import { useCallback, useMemo } from 'react';
import { selectAllVisibilityMaps } from '../../../../reducers/canvasLayersReducer';
import { VisibilityLayers } from '../../../../types/enums/VisibilityLayers';
import { useCalculatePills } from '../../../canvas-pills/useCalculatePills';
import { useConfigurationCanvasContext } from '../../configuration-canvas/ConfigurationCanvasContext';
import { visibilityMapToLayersSelectorVisibilityMap } from '../../configuration-canvas/canvas-container/layers-selector/utils';
import { useConfigurationCanvasState } from '../../configuration-canvas/useConfigurationCanvasState';
import { useConnectSteps } from '../../funnel-map-flow/dialogs/connect-steps-dialog/useConnectStep';
import { ConnectStep } from '../../funnel-map-flow/dialogs/connect-steps-dialog/ConnectSteps';
import { removeStepFunnelConnection } from '../../../../reducers/multiFunnelFlowCanvasReducer';
import {
  CanvasPillTypes,
  GroupLabelEntityType,
  GroupPillEntityType,
  NodeEntityTypes,
} from '../../../multi-canvas/canvasTypes';
import { useFitAroundElements } from '../../configuration-canvas-filters/useFitAroundElements';
import { useGetReservedSpaceForPanels } from '../../configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { usePlacePluginIntoFunnel } from '../../funnel-map-flow/templates/funnel-map-plugins/usePlacePluginIntoFunnel';
import { PlacingPluginLayerConsumer } from '../../funnel-map-flow/templates/placing-plugin/PlacingPluginLayer';
import useObjectTypesWithFetch from '../../../../hooks/useObjectTypesWithFetch';

interface UnitedConnectedFunnelMapFlowCanvasProps {
  onSweepElementsChange: FunnelMapCanvasProps['onSweepElementsChange'];
  moveGroups: FunnelMapCanvasProps['moveGroups'];
}

export const UnitedConnectedFunnelMapFlowCanvas = ({
  moveGroups,
  onSweepElementsChange,
}: UnitedConnectedFunnelMapFlowCanvasProps) => {
  const dispatch = useDispatch();
  const transientFunnel = useSelector(selectImportFunnelTransient);
  const placingTemplate = useSelector(selectPlacingTemplate);
  const transientFunnelData = useSelector(selectNewFunnelTransientData);
  const isLoadingCursor = useSelector(selectIsLoadingCursor);
  const { pills, canvasFunnelMap: funnelMap } = useConfigurationCanvasState();
  const { canvasCrmOrgId: crmOrgId } = useConfigurationCanvasContext();
  useCalculatePills({ crmOrgId, funnelMap });
  const { rightReservedSpace } = useGetReservedSpaceForPanels();
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  const {
    configurationCanvasProps: {
      visibilityElements,
      onFunnelStepClick,
      onFunnelLabelClick,
      onFunnelLabelPillClick,
      onStageGateClick,
      selectedStageId,
      selectedGateId,
    },
  } = useConfigurationCanvasContext();

  const allVisibilityMaps = useSelector(selectAllVisibilityMaps);
  const visibilityMap = useMemo(() => {
    return visibilityMapToLayersSelectorVisibilityMap(
      visibilityElements.type === 'map'
        ? {
            // Remove old layers from the canvas if present
            [VisibilityLayers.NURTURING_STEPS]: true,
            [VisibilityLayers.GATES]: false,
            ...visibilityElements.visibilityMap,
          }
        : allVisibilityMaps?.[visibilityElements.layersId] || {},
    );
  }, [allVisibilityMaps, visibilityElements]);

  const { placePlugin, onPluginClick, isPlacingPlugin } = usePlacePluginIntoFunnel({
    crmOrgId,
  });

  const { connectStepsProps, handleConnectStepsButtonClick, holdNodeHighlighted } = useConnectSteps(
    funnelMap.funnelsData,
  );

  const onRemoveFunnelLinkClick = useCallback(
    (stageId: string, targetStageId: string, funnelId: string, targetFunnelId: string) => {
      dispatch(
        removeStepFunnelConnection({
          funnelId,
          stageId,
          targetFunnelId,
          targetStageId,
        }),
      );
    },
    [dispatch],
  );
  const funnelStageMetadata = useSelector(selectFunnelStagesMetadata);

  const { fitOnElements } = useFitAroundElements({
    funnelsData: funnelMap.funnelsData,
    recordTypesData: funnelMap.recordTypesData,
  });

  const _onFunnelLabelClick = useCallback(
    (props: {
      funnelId: string;
      entity: GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
    }) => {
      if (
        props.entity.type === NodeEntityTypes.GroupPill &&
        props.entity.canvasPillType === CanvasPillTypes.groupAggregated
      ) {
        fitOnElements([props.funnelId], true);
        return;
      }

      if (onFunnelLabelClick) {
        onFunnelLabelClick(props);
      }
    },
    [fitOnElements, onFunnelLabelClick],
  );

  return (
    <>
      <PlacingPluginLayerConsumer />
      <ConnectStep {...connectStepsProps} />
      <FunnelMapCanvas
        funnelMap={funnelMap}
        visibilityMap={visibilityMap}
        onStageGateClick={onStageGateClick}
        onFunnelStepClick={onFunnelStepClick}
        onFunnelLabelClick={onFunnelLabelClick ? _onFunnelLabelClick : undefined}
        onFunnelLabelPillClick={onFunnelLabelPillClick ? onFunnelLabelPillClick : undefined}
        pills={pills}
        isLoadingCursor={isLoadingCursor}
        moveGroups={moveGroups}
        autoFitViewOnFirstNodes
        holdNodeHighlighted={holdNodeHighlighted}
        onRemoveFunnelLinkClick={onRemoveFunnelLinkClick}
        onConnectStepsClick={handleConnectStepsButtonClick}
        onSweepElementsChange={onSweepElementsChange}
        selectedStageId={selectedStageId}
        selectedGateId={selectedGateId}
        transientFunnel={transientFunnel}
        placingTemplate={placingTemplate}
        funnelStageMetadata={funnelStageMetadata}
        newFunnelTransientData={transientFunnelData}
        onPluginClick={onPluginClick}
        onFunnelOverlayClick={isPlacingPlugin ? placePlugin : undefined}
        controlsRightMargin={rightReservedSpace}
        objectTypesByName={objectTypesByName}
      />
    </>
  );
};
