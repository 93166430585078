import { Box, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { useCallback, useMemo, useState } from 'react';
import { DialogLoader } from '../../../../common/dialogs/DialogLoader';
import { BaseDialog } from '../../../../common/dialogs/BaseDialog';
import { CreateEditDialogHeader } from '../../../../common/create-edit-dialog/CreateEditDialogHeader';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SelectAvatar } from '../../../../avatar/SelectAvatar';
import { AvatarType, EmojiType } from '../../../../avatar/avatarTypes';
import {
  AssignmentGroup,
  AssignmentGroupMember,
} from '../../../../../reducers/assignmentGroupTypes';
import { assignmentGroupEmojis } from '../../groups/assignmentGroupEmojis';
import usePermission from '../../../../common/permissions/usePermission';
import { CreateEditDialogButtons } from '../../../../common/create-edit-dialog/CreateEditDialogButtons';
import { MembersPanel } from './MembersPanel';
import { Permission } from '@server/permissions';
import { LimitMembersPanel, LimitMembersVars } from './LimitMembersPanel';
import { validateLimitMembersPanel } from '../LimitMembersTimePanel';
import { validateConditionalLimitPanel } from '../ConditionalLimitPanel';
import { telemetry } from '../../../../../telemetry';
import { generateNewAssignmentGroupData } from '../group-settings-wizard/generateNewAssignmentGroupData';

export enum AssignmentGroupDialogElements {
  AvatarAndDescription = 'AvatarAndDescription',
  Members = 'Members',
  LimitMembers = 'LimitMembers',
}

interface AssignmentGroupsDialogProps {
  open: boolean;
  assignmentGroupData?: Partial<AssignmentGroup>;
  onClose?: () => any;
  onSave: (
    assignmentGroupData: Partial<AssignmentGroup>,
    resetAllMembersLimit?: number,
  ) => void | Promise<void>;
  defaultEmoji?: EmojiType;

  elements?: AssignmentGroupDialogElements[];
}
export const GroupSettingsDialog = ({
  open,
  assignmentGroupData,
  onClose,
  onSave,
  defaultEmoji,
  elements = [
    AssignmentGroupDialogElements.Members,
    AssignmentGroupDialogElements.AvatarAndDescription,
    AssignmentGroupDialogElements.LimitMembers,
  ],
}: AssignmentGroupsDialogProps) => {
  const { addNotification } = useSweepNotifications();

  const [localGroupData, setLocalGroupData] = useState<DeepPartial<AssignmentGroup>>(
    assignmentGroupData || generateNewAssignmentGroupData(defaultEmoji),
  );
  const [showLoader, setShowLoader] = useState(false);
  const [resetAllMembersLimit, setResetAllMembersLimit] = useState<number | undefined>();

  const permissionString: Permission[] = ['edit:assignment-groups'];
  const [isAllowedBtn] = usePermission(permissionString);

  const save = useCallback(async () => {
    setShowLoader(true);
    try {
      await onSave(localGroupData as Partial<AssignmentGroup>, resetAllMembersLimit);
    } catch (e) {
      telemetry.captureError(e);
      addNotification({
        message: 'Error Saving Assignment Group',
        variant: SweepNotificationVariant.Error,
      });
      return false;
    } finally {
      setShowLoader(false);
    }
    setShowLoader(false);
    onClose && onClose();
    return true;
  }, [addNotification, localGroupData, onClose, onSave, resetAllMembersLimit]);

  const editMode = Boolean(assignmentGroupData);

  const showAvatarNameAndDescription = elements.includes(
    AssignmentGroupDialogElements.AvatarAndDescription,
  );
  const showMembers = elements.includes(AssignmentGroupDialogElements.Members);
  const showLimitMembers = elements.includes(AssignmentGroupDialogElements.LimitMembers);

  const saveButtonText = editMode ? 'Save changes' : 'Create group';

  const isNameAndDescriptionSectionValid =
    !showAvatarNameAndDescription || Boolean(localGroupData.name);

  const isLimitMembersSectionValid = useMemo(() => {
    if (!showLimitMembers) return true;

    if (localGroupData.groupLimit?.type === 'TIME_LIMIT') {
      return (
        !showLimitMembers ||
        validateLimitMembersPanel({
          groupLimit: localGroupData.groupLimit,
          resetAllMembersLimit,
        })
      );
    }
    if (localGroupData.groupLimit && localGroupData.groupLimit?.type === 'CONDITIONAL_LIMIT') {
      return validateConditionalLimitPanel(localGroupData.groupLimit.details);
    }

    return true;
  }, [localGroupData.groupLimit, resetAllMembersLimit, showLimitMembers]);

  const isValid = isNameAndDescriptionSectionValid && isLimitMembersSectionValid;
  const saveDisabled = !isValid || !isAllowedBtn;

  const vars = useMemo(
    () => ({
      groupLimit: localGroupData.groupLimit,
      resetAllMembersLimit,
    }),
    [localGroupData.groupLimit, resetAllMembersLimit],
  );

  const limitMembersPanelOnChange = useCallback(
    ({ groupLimit, resetAllMembersLimit }: LimitMembersVars) => {
      setLocalGroupData({
        ...localGroupData,
        groupLimit,
      } as DeepPartial<AssignmentGroup>);
      setResetAllMembersLimit(resetAllMembersLimit);
    },
    [localGroupData],
  );

  const handleAvatarChange = useCallback(
    (avatar: AvatarType) => {
      setLocalGroupData({
        ...localGroupData,
        avatar,
      });
    },
    [localGroupData],
  );
  return (
    <BaseDialog open={open} onClose={onClose} disableBackdropClickClose>
      <DialogTitle sx={{ paddingBottom: 3 }}>
        {showAvatarNameAndDescription && (
          <Box display="flex" gap={3}>
            <Box sx={{ width: '72px', height: '72px' }}>
              <SelectAvatar
                avatar={localGroupData.avatar}
                avatarPictureSize={72}
                emojis={assignmentGroupEmojis}
                onChange={handleAvatarChange}
                disableImageUpload
              />
            </Box>
            <CreateEditDialogHeader
              hideBackButton
              showCancelButton
              value={localGroupData.name as string}
              placeholder={'Add group name'}
              buttonContent={saveButtonText}
              onChange={(value) => setLocalGroupData({ ...localGroupData, name: value })}
              onButtonClick={save}
              buttonDisabled={saveDisabled}
              maxLength={30}
              onCancelClick={onClose}
              editDisabled={!isAllowedBtn}
            />
          </Box>
        )}
        {!showAvatarNameAndDescription && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h1-bold">Manage members</Typography>
            <CreateEditDialogButtons
              confirmButtonContent={saveButtonText}
              onConfirm={save}
              confirmButtonDisabled={saveDisabled}
              onCancel={onClose}
              showCancelButton
            />
          </Box>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          width: '904px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          paddingBottom: 4,
          paddingTop: 4,
        }}
      >
        {showAvatarNameAndDescription && (
          <TextField
            multiline
            placeholder="Add description here (optional)"
            value={localGroupData.description}
            onChange={(e) => setLocalGroupData({ ...localGroupData, description: e.target.value })}
            inputProps={{ maxLength: 100 }}
          />
        )}
        {showMembers && localGroupData.members && (
          <MembersPanel
            members={localGroupData.members as AssignmentGroupMember[]}
            onChange={(newMembers) => {
              setLocalGroupData({ ...localGroupData, members: newMembers });
            }}
          />
        )}
        {showLimitMembers && <LimitMembersPanel vars={vars} onChange={limitMembersPanelOnChange} />}
      </DialogContent>
      <DialogLoader visible={showLoader} />
    </BaseDialog>
  );
};
