import { Box } from '@mui/material';

export const PageTemplateGrid = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box sx={{ display: 'flex', gap: '32px', flexDirection: 'row', flexWrap: 'wrap' }}>
      {children}
    </Box>
  );
};
