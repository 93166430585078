import { useSelector } from 'react-redux';
import { selectUserById } from '../../../reducers/accountUsersReducer';
import { Box } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { humanizeDate } from '../../helpers/humanizeDate';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../../constants';
import { getIsUserInactive } from '../../../lib/getIsUserActive';
import { ActionsMenu } from '../../common/actions-menu/ActionsMenu';
import { Commands } from '../../../types/enums/Common';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import ConfirmDestructiveDialog from '../../common/dialogs/ConfirmDestructiveDialog';

interface TerritoryCardProps {
  territory: Territories;
  isAllowedBtnEdit: boolean;
  isAllowedBtnDelete: boolean;
  onEdit: () => any;
  onDuplicate: () => any;
  onDelete: () => any;
}

const TerritoryCard = ({
  territory,
  isAllowedBtnEdit,
  isAllowedBtnDelete,
  onEdit,
  onDuplicate,
  onDelete,
}: TerritoryCardProps) => {
  const user = useSelector(selectUserById(territory.updatedById ?? ''));
  const { openConfirm, onCancel, onConfirm } = useConfirm();

  const confirmDelete = async () => {
    const isConfirmed = await openConfirm(
      <ConfirmDestructiveDialog
        open={true}
        onConfirm={onConfirm}
        onClose={onCancel}
        title="Delete Territory?"
        confirmText="Delete"
        cancelText="Cancel"
      >
        Are you sure you want to delete "{territory.name}"?
      </ConfirmDestructiveDialog>,
    );
    if (!isConfirmed) return;
    onDelete();
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="50px"
      sx={{ borderBottom: `1px solid ${colors.grey[300]}`, cursor: 'pointer' }}
      onClick={onEdit}
    >
      <Typography variant="body-medium">{territory.name}</Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="caption">
          {`Last updated by ${user?.name}, ${humanizeDate({
            dateOrTimestamp: territory.updatedAt ?? '',
          })} `}
        </Typography>
        <Box>
          <AvatarPicture
            avatar={{ emoji: user?.emoji, imageUrl: user?.imageUrl }}
            dimension={DIMENSION_AVATAR_VERY_SMALL}
            clickable={false}
            isInactive={getIsUserInactive(user?.status)}
          />
        </Box>
        <ActionsMenu
          actions={[
            {
              label: Commands.Edit,
              value: Commands.Edit,
              disabled: !isAllowedBtnEdit,
            },
            {
              label: Commands.Duplicate,
              value: Commands.Duplicate,
              disabled: !isAllowedBtnEdit,
            },
            {
              label: Commands.Delete,
              value: Commands.Delete,
              disabled: !isAllowedBtnDelete,
            },
          ]}
          onClick={(action) => {
            switch (action.value) {
              case Commands.Edit:
                onEdit();
                break;
              case Commands.Duplicate:
                onDuplicate();
                break;
              case Commands.Delete:
                confirmDelete();
                break;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default TerritoryCard;
