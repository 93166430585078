import { useSelector } from 'react-redux';
import {
  selectElementDependencies,
  selectOrgSweepFields,
} from '../../../../reducers/global/globalReducer';
import { ElementDependencies } from '../../../../reducers/global/globalReducerTypes';
import { ConfigurationItemMap } from '../../configuration-item-tables/shared/helpers';
import { createConfigurationItemFromSweepField } from '../../helpers';
import { SweepElementTypes } from '../../types';
import { ConfigurationType } from '../types';

export const useGetSweepElementsDependencies = (
  crmOrgId: string,
  configurationItemId: string,
  sweepElementType: SweepElementTypes,
) => {
  const elementDependencies = useSelector(selectElementDependencies(crmOrgId));
  const sweepFields = useSelector(selectOrgSweepFields(crmOrgId));

  const fields =
    elementDependencies?.[sweepElementType as keyof ElementDependencies]?.[configurationItemId];

  const dependencies = {
    [ConfigurationType.fields]:
      fields
        ?.map((fieldId) =>
          sweepFields && sweepFields[fieldId]
            ? createConfigurationItemFromSweepField(sweepFields[fieldId])
            : undefined,
        )
        ?.filter((item) => !!item) ?? [],
  };

  return dependencies as ConfigurationItemMap;
};
