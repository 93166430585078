import { Box, Collapse, Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, FilterLines } from '@sweep-io/sweep-design/dist/icons';
import { Radio, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { StyledRadioGroup } from '../../common/StyledComponents';
import { CustomHeader } from '../../common/rule-builder/CustomHeader';
import {
  SweepBinaryRuleBuilder,
  SweepFieldsRuleBuilder,
} from '../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { DedupMatchingType } from '../../../types/enums/DedupMatchingType';
import { useRefForMultipleRuleBuilders } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { StyledFormControlLabel, greyLayout, settingsPanelLayout } from './helpers';

interface SettingsFilterPanelProps {
  readonly?: boolean;
  filters?: SweepFilter[];
  crmOrgId: string;
  objectType: string;
  type: DedupMatchingType;
  onChange: (filter?: SweepFilter) => any;
  title?: string;
  subType?: number;
  referenceObjectType?: string;
}

export enum SweepFilterType {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
  EMPTY = 'EMPTY',
}

const SettingsFilterPanel = ({
  readonly,
  filters,
  crmOrgId,
  objectType,
  type,
  onChange,
  title,
  subType,
  referenceObjectType,
}: SettingsFilterPanelProps) => {
  const getFilterActionType = useMemo(() => {
    const filterObj = filters?.[0];
    if (filterObj) {
      return filterObj.type as SweepFilterType;
    }
    return SweepFilterType.EMPTY;
  }, [filters]);
  const [isPanelExpanded, setIsPanelExpanded] = useState(
    getFilterActionType !== SweepFilterType.EMPTY,
  );

  const getLabelsByTypeOrSubtype = (
    option: 'subTitle' | 'optionEmpty' | 'optionExclude' | 'optionInclude',
  ) => {
    if (subType === 2) {
      switch (option) {
        case 'subTitle': {
          return 'Specify conditions on both records that potential matches must meet';
        }
        case 'optionEmpty': {
          return 'Keep all potential matches';
        }
        case 'optionExclude': {
          return 'Exclude matches that meet the following criteria';
        }
        case 'optionInclude': {
          return 'Include only matches that meet the following criteria';
        }
      }
    } else return labelsByType[type][option];
  };

  const labelsByType = {
    [DedupMatchingType.LEAD_TO_LEAD_DEDUP]:
      subType === 1
        ? {
            subTitle:
              'Specify the conditions existing leads must meet for being evaluated as potential duplicates',
            optionEmpty: 'Check for duplicates for every existing lead',
            optionExclude: 'Exclude leads that meet the following criteria',
            optionInclude: 'Include only leads that meet the following criteria',
          }
        : {
            subTitle:
              'Specify the conditions leads must meet for being evaluated as potential duplicates',
            optionEmpty: `Don't filter`,
            optionExclude: 'Exclude leads that meet the following criteria',
            optionInclude: 'Include only leads that meet the following criteria',
          },
    [DedupMatchingType.LEAD_TO_CONTACT_DEDUP]:
      subType === 1
        ? {
            subTitle:
              'Specify the conditions existing contacts must meet for being evaluated as potential duplicates',
            optionEmpty: 'Check for duplicates for every existing contact',
            optionExclude: 'Exclude contacts that meet the following criteria',
            optionInclude: 'Include only contacts that meet the following criteria',
          }
        : {
            subTitle:
              'Specify the conditions leads must meet for being evaluated as potential duplicates',
            optionEmpty: `Don't filter`,
            optionExclude: 'Exclude leads that meet the following criteria',
            optionInclude: 'Include only leads that meet the following criteria',
          },

    [DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING]:
      subType === 1
        ? {
            subTitle: 'Specify the conditions accounts must meet to be matched',
            optionEmpty: 'Match all existing accounts',
            optionExclude: 'Exclude account that meet the following criteria',
            optionInclude: 'Include only accounts that meet the following criteria',
          }
        : {
            subTitle: 'Specify the conditions leads must meet for being matched to account',
            optionEmpty: 'Match all leads to accounts',
            optionExclude: 'Exclude leads that meet the following criteria',
            optionInclude: 'Include only leads that meet the following criteria',
          },
    [DedupMatchingType.LEAD_TO_LEAD_MATCHING]:
      subType === 1
        ? {
            subTitle: 'Specify the conditions existing leads must meet to be matched',
            optionEmpty: 'Match all existing leads',

            optionExclude: 'Exclude leads that meet the following criteria',
            optionInclude: 'Include only leads that meet the following criteria',
          }
        : {
            subTitle: 'Specify the conditions new leads must meet to be matched to existing leads',
            optionEmpty: 'Match all new leads',
            optionExclude: 'Exclude leads that meet the following criteria',
            optionInclude: 'Include only leads that meet the following criteria',
          },
    [DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP]:
      subType === 1
        ? {
            subTitle:
              'Specify the conditions existing accounts must meet for being evaluated as potential duplicates',
            optionEmpty: 'Check for duplicates for every existing account',
            optionExclude: 'Exclude accounts that meet the following criteria',
            optionInclude: 'Include only accounts that meet the following criteria',
          }
        : {
            subTitle:
              'Specify the conditions accounts must meet for being evaluated as potential duplicates',
            optionEmpty: `Don't filter`,
            optionExclude: 'Exclude accounts that meet the following criteria',
            optionInclude: 'Include only accounts that meet the following criteria',
          },
    [DedupMatchingType.CONTACT_TO_CONTACT_DEDUP]:
      subType === 1
        ? {
            subTitle:
              'Specify the conditions existing contacts must meet for being evaluated as potential duplicates',
            optionEmpty: 'Check for duplicates for every existing contact',
            optionExclude: 'Exclude contacts that meet the following criteria',
            optionInclude: 'Include only contacts that meet the following criteria',
          }
        : {
            subTitle:
              'Specify the conditions contacts must meet for being evaluated as potential duplicates',
            optionEmpty: `Don't filter`,
            optionExclude: 'Exclude contacts that meet the following criteria',
            optionInclude: 'Include only contacts that meet the following criteria',
          },
    [DedupMatchingType.CASE_TO_CASE_DEDUP]:
      subType === 1
        ? {
            subTitle:
              'Specify the conditions existing cases must meet for being evaluated as potential duplicates',
            optionEmpty: 'Check for duplicates for every existing case',
            optionExclude: 'Exclude cases that meet the following criteria',
            optionInclude: 'Include only cases that meet the following criteria',
          }
        : {
            subTitle:
              'Specify the conditions cases must meet for being evaluated as potential duplicates',
            optionEmpty: `Don't filter`,
            optionExclude: 'Exclude cases that meet the following criteria',
            optionInclude: 'Include only cases that meet the following criteria',
          },
    [DedupMatchingType.OPP_TO_OPP_DEDUP]:
      subType === 1
        ? {
            subTitle:
              'Specify the conditions existing opportunities must meet for being evaluated as potential duplicates',
            optionEmpty: 'Check for duplicates for every existing opportunity',
            optionExclude: 'Exclude opportunities that meet the following criteria',
            optionInclude: 'Include only opportunities that meet the following criteria',
          }
        : {
            subTitle:
              'Specify the conditions opportunities must meet for being evaluated as potential duplicates',
            optionEmpty: `Don't filter`,
            optionExclude: 'Exclude opportunities that meet the following criteria',
            optionInclude: 'Include only opportunities that meet the following criteria',
          },
  };

  const [selectFilterType, setSelectFilterType] = useState<SweepFilterType>(getFilterActionType);
  const ruleBuilderRefInclude = useRefForMultipleRuleBuilders();
  const ruleBuilderRefExclude = useRefForMultipleRuleBuilders();

  const firstCondition = filters?.[0].condition;
  const endpoint = subType === 1 ? 'DESTINATION' : subType === 2 ? 'MATCH' : 'SOURCE';

  const headerRowComponent = (
    <Box sx={{ display: 'flex', marginLeft: '36px', mb: '16px', pr: '36px' }}>
      <Grid container flexWrap="nowrap">
        <Grid item xs={4}>
          <Tag label={`Triggering ${objectType.toLowerCase()}`} color={colors.grey[200]} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={5}>
          <Tag label={`Existing ${referenceObjectType?.toLowerCase()}`} color={colors.grey[200]} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <FilterLines variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">{title || 'Filters'}</Typography>
              <Typography variant="body">{getLabelsByTypeOrSubtype('subTitle')}</Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          <StyledRadioGroup
            sx={{ marginTop: '12px' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = (event.target as HTMLInputElement).value as SweepFilterType;
              setSelectFilterType(value);
              if (value === SweepFilterType.EMPTY) {
                onChange(undefined);
              } else {
                onChange({
                  ...filters?.[0],
                  type: value,
                  endpoint,
                });
              }
            }}
            value={selectFilterType}
          >
            <StyledFormControlLabel
              disabled={readonly}
              value={SweepFilterType.EMPTY}
              control={<Radio />}
              label={
                <Typography variant="body">{getLabelsByTypeOrSubtype('optionEmpty')}</Typography>
              }
            />
            <StyledFormControlLabel
              disabled={readonly}
              value={SweepFilterType.INCLUDE}
              control={<Radio />}
              label={
                <Typography variant="body">{getLabelsByTypeOrSubtype('optionInclude')}</Typography>
              }
            />
            {selectFilterType === SweepFilterType.INCLUDE && (
              <Box sx={{ ...greyLayout }}>
                <>
                  <CustomHeader customHeader="When these conditions are met" />
                  {subType === 2 ? (
                    <SweepBinaryRuleBuilder
                      headerRowComponent={headerRowComponent}
                      referenceObjectType={referenceObjectType}
                      readOnly={readonly}
                      crmOrgId={crmOrgId}
                      objectType={objectType}
                      sweepCondition={firstCondition}
                      ref={ruleBuilderRefInclude}
                      onChange={(sweepCondition) => {
                        onChange({
                          type: SweepFilterType.INCLUDE,
                          condition: sweepCondition,
                          endpoint,
                        });
                      }}
                      disableResolvePolymorphic
                    />
                  ) : (
                    <SweepFieldsRuleBuilder
                      readOnly={readonly}
                      crmOrgId={crmOrgId}
                      objectType={objectType}
                      sweepCondition={firstCondition}
                      ref={ruleBuilderRefInclude}
                      onChange={(sweepCondition) => {
                        onChange({
                          type: SweepFilterType.INCLUDE,
                          condition: sweepCondition,
                          endpoint,
                        });
                      }}
                      disableResolvePolymorphic={true}
                    />
                  )}
                </>
              </Box>
            )}
            <StyledFormControlLabel
              disabled={readonly}
              value={SweepFilterType.EXCLUDE}
              control={<Radio />}
              label={
                <Typography variant="body">{getLabelsByTypeOrSubtype('optionExclude')}</Typography>
              }
            />

            {selectFilterType === SweepFilterType.EXCLUDE && (
              <Box sx={{ ...greyLayout }}>
                <>
                  <CustomHeader customHeader="When these conditions are met" />
                  {subType === 2 ? (
                    <SweepBinaryRuleBuilder
                      headerRowComponent={headerRowComponent}
                      referenceObjectType={referenceObjectType}
                      readOnly={readonly}
                      crmOrgId={crmOrgId}
                      objectType={objectType}
                      sweepCondition={firstCondition}
                      ref={ruleBuilderRefExclude}
                      onChange={(sweepCondition) => {
                        onChange({
                          type: SweepFilterType.EXCLUDE,
                          condition: sweepCondition,
                          endpoint,
                        });
                      }}
                      disableResolvePolymorphic
                    />
                  ) : (
                    <SweepFieldsRuleBuilder
                      readOnly={readonly}
                      crmOrgId={crmOrgId}
                      objectType={objectType}
                      sweepCondition={firstCondition}
                      ref={ruleBuilderRefExclude}
                      onChange={(sweepCondition) => {
                        onChange({
                          type: SweepFilterType.EXCLUDE,
                          condition: sweepCondition,
                          endpoint,
                        });
                      }}
                      disableResolvePolymorphic
                    />
                  )}
                </>
              </Box>
            )}
          </StyledRadioGroup>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SettingsFilterPanel;
