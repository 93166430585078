import Box from '@mui/material/Box';
import SweepSelect from '../../../common/SweepSelect';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material';
import { AutomationActionRowProps } from '../types';
import { VerifyURLResultKeys } from '@server/verify-url';
import { getVerifiedUrlOptionsByType } from '../utils/getVerifiedUrlByType';

const translateHeader: { [key in VerifyURLResultKeys]: string } = {
  [VerifyURLResultKeys.errorDetails]: 'Error Details',
  [VerifyURLResultKeys.finalRedirectUrl]: 'Final URL After Redirects',
  [VerifyURLResultKeys.finalRedirectDomain]: 'Final Redirect Domain',
  [VerifyURLResultKeys.numberOfRedirects]: 'Number of Redirects',
  [VerifyURLResultKeys.originalUrl]: 'Original URL',
  [VerifyURLResultKeys.verifyUrlTimestamp]: 'Verification Timestamp',
};

export const VerifyURLSelect = ({
  readonly,
  onChange,
  _field,
  showVerifiedUrlOption,
}: Pick<AutomationActionRowProps, 'readonly' | 'onChange' | 'showVerifiedUrlOption'> & {
  _field: AutomationActionField;
}) => {
  return (
    <Box>
      <SweepSelect
        FormControlProps={{
          sx: { minWidth: '120px', marginRight: '8px', width: '100%' },
        }}
        SelectProps={{
          disabled: readonly,
          placeholder: 'Choose Option',
          value: _field.value ?? '',
          onChange: (event: SelectChangeEvent<unknown>) => {
            const val = event.target.value;
            onChange({
              ..._field,
              value: val as string,
            });
          },
        }}
      >
        {getVerifiedUrlOptionsByType(_field, showVerifiedUrlOption)?.map((key) => (
          <MenuItem key={key} value={key}>
            {translateHeader[key as keyof typeof VerifyURLResultKeys]}
          </MenuItem>
        ))}
      </SweepSelect>
    </Box>
  );
};
