import {
  ActionableDataTable,
  ActionableDataTableActionTypes,
} from '../common/table/ActionableDataTable';
import { humanReadableCriteria } from '../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { DataTableOnOrderChangeClbk } from '../common/table/TableTypes';
import usePermission from '../common/permissions/usePermission';
import { useSelector } from 'react-redux';
import { useTerritoriesActions } from '../../hooks/useTerritoriesActions';
import { selectTerritoriesList } from '../../reducers/territoriesReducer';
import { useRunOnce } from '../common/useRunOnce';
import { Permission } from '@server/permissions';
import { useSweepFieldsLabels } from '../../sweep-fields/useSweepFieldsLabels';
import { getDisplayLineTextForActionList } from './helper';
import { Box } from '@mui/material';
import { ArrowRight } from '@sweep-io/sweep-design/dist/icons/ArrowRight';
import { useMemo } from 'react';

interface ConditionalActionsTableProps {
  conditionalActions: AutomationConditionalAction[];
  onChange: (assignmentRules: AutomationConditionalAction[]) => any;
  onRuleEdit: (assignmentRuleId: string) => any;
  onRuleDuplicate: (assignmentRuleId: string) => any;
  readonly?: boolean;
}

const columns = [
  {
    field: 'priority',
    headerName: 'Priority',
    width: '78px',
    showDragHandle: true,
  },
  { field: 'name', headerName: 'Name', width: '33%' },
  { field: 'conditions', headerName: 'Conditions', width: '33%' },
  { field: 'actions', headerName: 'Actions', width: '33%' },
];

interface ConditionalActionsDataRow {
  id: string;
  priority: string;
  name: string;
  conditions: string;
  actions: JSX.Element;
}

export const ConditionalActionsTable = ({
  conditionalActions,
  onChange,
  onRuleEdit,
  onRuleDuplicate,
  readonly,
}: ConditionalActionsTableProps) => {
  const permissionString: Permission[] = ['edit:funnels'];
  const [isAllowedBtn] = usePermission(permissionString);

  const territories = useSelector(selectTerritoriesList);
  const { getTerritories } = useTerritoriesActions();
  const { enrichCriteriaWithLabels: populateCriteriaWithLabels } = useSweepFieldsLabels();

  useRunOnce(async () => {
    await getTerritories();
  });

  const tableRows: ConditionalActionsDataRow[] = useMemo(() => {
    return conditionalActions.map((rule, idx) => {
      const actionText = getDisplayLineTextForActionList(rule?.actions);
      return {
        id: rule._id,
        priority: '' + (idx + 1),
        actions: (
          <Box>
            {actionText.map((el, index) => {
              return (
                <Box
                  key={index}
                  sx={{ mb: '2px', svg: { position: 'relative', top: '3px', mr: '8px' } }}
                >
                  <ArrowRight />
                  {el}
                </Box>
              );
            })}
          </Box>
        ),

        name: rule._name,
        conditions: humanReadableCriteria(
          rule.when?.criteria?.criteria.length
            ? rule.when?.criteria?.criteria.map((c) => populateCriteriaWithLabels(c))
            : [],
          rule.when?.criteria?.criteriaLogic,
          territories,
        ),
      };
    });
  }, [conditionalActions, populateCriteriaWithLabels, territories]);

  const onOrderChange: DataTableOnOrderChangeClbk = ({ sourceIndex, destinationIndex }) => {
    const newActions = [...conditionalActions];
    newActions.splice(sourceIndex, 1);
    newActions.splice(destinationIndex, 0, conditionalActions[sourceIndex]);
    onChange(newActions);
  };

  const tableActions = useMemo(() => {
    return !isAllowedBtn || readonly
      ? []
      : [
          { type: ActionableDataTableActionTypes.EDIT },
          {
            type: ActionableDataTableActionTypes.DUPLICATE,
          },
          {
            type: ActionableDataTableActionTypes.DELETE,
            confirm: true,
            confirmButtonOkLabel: 'Yes, Delete',
            confirmButtonCancelLabel: 'Cancel',
            confirmTitle: 'Delete Action',
            confirmSubtitle: 'Are you sure you want to delete this action?',
          },
        ];
  }, [isAllowedBtn, readonly]);

  return (
    <ActionableDataTable
      allowReorder={isAllowedBtn && !readonly}
      columns={columns}
      rows={tableRows}
      onOrderChange={onOrderChange}
      actions={tableActions}
      onActionClick={({ actionType, row }) => {
        switch (actionType) {
          case ActionableDataTableActionTypes.DELETE:
            const newActions = conditionalActions.filter((a) => a._id !== row.id);
            onChange(newActions);
            break;
          case ActionableDataTableActionTypes.EDIT:
            onRuleEdit(row.id);
            break;
          case ActionableDataTableActionTypes.DUPLICATE:
            onRuleDuplicate(row.id);
            break;
        }
      }}
      onRowClick={(
        e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
        row: ConditionalActionsDataRow,
      ) => {
        onRuleEdit(row.id);
      }}
    />
  );
};
