import { UserInputTypes } from '@server/user-inputs';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback } from 'react';
import { getRandomColorName } from '../../../../../lib/getRandomColorName';
import { InlineAutocompleteItem } from '../../../../common/InlineAutocomplete/InlineAutocomplete';
import { createUserInputData } from './utils';
import { useDependencies } from '../../hooks/useDependencies';
import { useDispatch, useSelector } from 'react-redux';
import {
  openEditTag,
  selectDependencyCustomNotesById,
} from '../../../../../reducers/documentationReducer';
import { useTags } from './useTags';

export const useConfigurationTags = (configurationItemId: string, crmOrgId: string) => {
  const { onAddTag } = useTags(crmOrgId);
  const { updateDependencyTags } = useDependencies(crmOrgId, {});
  const dispatch = useDispatch();

  const dependencyTags = useSelector(selectDependencyCustomNotesById(configurationItemId));

  const onSelectItem = useCallback(
    (item: InlineAutocompleteItem, parentKey: UserInputTypes) => {
      const newCustomNotes = dependencyTags ? cloneDeep(dependencyTags) : createUserInputData();

      if (parentKey === UserInputTypes.Owner) {
        newCustomNotes[parentKey] = item.value;
      } else {
        newCustomNotes[parentKey].push(item.value);
      }

      updateDependencyTags({ [configurationItemId]: newCustomNotes });
    },
    [dependencyTags, configurationItemId, updateDependencyTags],
  );

  const onCreateTag = useCallback(
    async (name: string) => {
      const colorName = getRandomColorName();
      const tagId = await onAddTag({ name, color: colorName });

      if (tagId) {
        const newDependencyTags = cloneDeep(dependencyTags) ?? createUserInputData();
        newDependencyTags[UserInputTypes.Tags].push(tagId);

        updateDependencyTags({
          [configurationItemId]: newDependencyTags,
        });
      }
    },
    [configurationItemId, dependencyTags, onAddTag, updateDependencyTags],
  );

  const onDeleteItem = useCallback(
    (item: InlineAutocompleteItem, parentKey: UserInputTypes) => {
      const newDependencyTags = cloneDeep(dependencyTags);

      if (newDependencyTags) {
        switch (parentKey) {
          case UserInputTypes.Owner:
            newDependencyTags[parentKey] = '';
            break;
          default:
            newDependencyTags[parentKey] = newDependencyTags[parentKey].filter(
              (tagId) => tagId !== item.value,
            );
        }

        updateDependencyTags({ [configurationItemId]: newDependencyTags });
      }
    },
    [dependencyTags, configurationItemId, updateDependencyTags],
  );

  const onTagClick = useCallback(
    (tag: InlineAutocompleteItem) => {
      dispatch(openEditTag({ tagId: tag.value, tagName: tag.label }));
    },
    [dispatch],
  );

  return { onSelectItem, onDeleteItem, onCreateTag, onTagClick };
};
