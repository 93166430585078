import { Box, DialogTitle } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';

interface GroupsDialogHeaderProps {
  children?: React.ReactNode;
  onClose: () => void;
  headerActions?: React.ReactNode;
}
export const GroupsDialogHeader = ({
  children,
  onClose,
  headerActions,
}: GroupsDialogHeaderProps) => {
  return (
    <DialogTitle display="flex" justifyContent="space-between" gap={1} alignItems="center">
      {children ?? <Box />}
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          height: '40px',
          '.MuiButtonBase-root': {
            whiteSpace: 'nowrap',
          },
        }}
      >
        {headerActions}
        <IconButton variant="flat" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  );
};
