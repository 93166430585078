import { useCallback } from 'react';
import { useWorkflowsApiFacade } from '../../../../../apis/facades/useWorkflowsApiFacade';
import { useDispatch, useSelector } from 'react-redux';
import {
  addWorkflow,
  loadWorkflows,
  removeWorkflow,
  startLoadingWorkflows,
  updateWorkflow,
} from '../../../../../reducers/hubspotReducer';
import { WorkflowBaseDto, WorkflowCreateDto } from '../types';
import {
  selectPillsMap,
  setHubspotEnrollmentPills,
} from '../../../../canvas-pills/canvasPillsReducer';

export const useHubspotWorkflows = () => {
  const dispatch = useDispatch();
  const { post_workflow, get_workflows, put_workflow, delete_workflow } = useWorkflowsApiFacade();
  const pills = useSelector(selectPillsMap);
  const hubspotEnrollmentsCount = pills.hubspotEnrollments.funnels['hubspot'] || 0;

  const createHubspotWorkflow = useCallback(
    async (newWorkflow: WorkflowCreateDto) => {
      const workflow = await post_workflow(newWorkflow);
      dispatch(addWorkflow(workflow));
      dispatch(setHubspotEnrollmentPills(hubspotEnrollmentsCount + 1));
      return workflow;
    },
    [dispatch, hubspotEnrollmentsCount, post_workflow],
  );

  const updateHubspotWorkflow = useCallback(
    async (workflow: Required<WorkflowBaseDto>) => {
      const updatedWorkflow = await put_workflow({
        name: workflow.name,
        workflowId: workflow.id,
        workflowDetails: workflow.workflowDetails,
      });
      dispatch(updateWorkflow(updatedWorkflow));

      return updatedWorkflow;
    },
    [dispatch, put_workflow],
  );

  const getWorkflows = useCallback(
    async (hsOrgId: string) => {
      dispatch(startLoadingWorkflows());
      const workflows = await get_workflows(hsOrgId);
      dispatch(loadWorkflows(workflows));
      dispatch(setHubspotEnrollmentPills(workflows.length));
    },
    [dispatch, get_workflows],
  );

  const deleteHubspotWorkflow = useCallback(
    async (workflowId: string) => {
      await delete_workflow(workflowId);
      dispatch(removeWorkflow({ workflowId }));
      dispatch(setHubspotEnrollmentPills(hubspotEnrollmentsCount - 1));
    },
    [delete_workflow, dispatch, hubspotEnrollmentsCount],
  );

  return {
    updateHubspotWorkflow,
    createHubspotWorkflow,
    getWorkflows,
    deleteHubspotWorkflow,
  };
};
