import { Typography, Radio, Switch } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp, UserPlus } from '@sweep-io/sweep-design/dist/icons';
import { Box, Collapse } from '@mui/material';
import { StyledRadioGroup } from '../../common/StyledComponents';
import { EnrichLeadIcon } from '../../../icons/generalIcons';
import { useCallback, useEffect, useState } from 'react';
import { useRunOnce } from '../../common/useRunOnce';
import { DedupMatchingType, SweepFilterEndpoint } from '../../../types/enums/DedupMatchingType';
import { CustomHeader } from '../../common/rule-builder/CustomHeader';
import { SweepFieldsRuleBuilder } from '../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';
import { useSweepFields } from '../../../sweep-fields/useCachedSweepFields';
import { useRefForMultipleRuleBuilders } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import FieldLabel from '../../common/FieldLabel';
import { DeepPartial } from 'redux';
import React from 'react';
import {
  AssignmentActionSelectionType,
  DefaultFieldsData,
  StyledFormControlLabel,
  getAssignActionType,
  getAssignSelection,
  getFiltersByType,
  getFiltersByTypeToSet,
  getHandleUpdateCondition,
  getMappingAssignArray,
  getMatchingCondition,
  getNotifyAction,
  greyLayout,
  handleInnerNotifyAction,
  handleMatchSelection,
  settingsPanelLayout,
} from './helpers';
import { FieldAssignmentComponent } from './FieldAssignmentComponent';
import { MatchingNotification } from './MatchingNotification';
import SettingsFilterPanel from './SettingsFilterPanel';
import SettingsTieBreakersPanel from './SettingsTieBreakersPanel';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import SettingsPermissionModePanel from './SettingsPermissionModePanel';

interface MatchingLeadLeadPageProps {
  readonly?: boolean;
  matchingObject: DeepPartial<AutomationStructureNew>;
  crmOrgId: string;
  onChangeProperty: (
    name: string,
    value: AutomationDetailsDtoNew[keyof AutomationDetailsDtoNew],
  ) => any;
}

enum InvalidSections {
  AssignField = 'AssignField',
  MatchFields = 'MatchFields',
}

export const MatchingLeadLeadPage = ({
  readonly,
  crmOrgId,
  matchingObject,
  onChangeProperty,
}: MatchingLeadLeadPageProps) => {
  const { automationPermissionMode } = useFeatureToggle();
  const { getSweepFieldIdsByName } = useSweepFields();
  const [displayError, setDisplayError] = useState(false);
  const [actionsArray, setActionsArray] = useState<(DeepPartial<AutomationAction> | undefined)[]>(
    matchingObject?.automationDetails?.actions || [],
  );
  const [leadOwnerFieldId, setLeadOwnerFieldId] = useState<string>();

  useRunOnce(async () => {
    // Get the ownerId fields for lead for default values
    const res = await getSweepFieldIdsByName({
      crmOrgId,
      fieldNames: [DefaultFieldsData.LeadOwnerId],
    });
    const { fieldIds } = res;
    const valueFieldIdsArray = Object.values(fieldIds);
    setLeadOwnerFieldId(valueFieldIdsArray[0][0]);
  });
  useEffect(() => {
    matchingObject && setActionsArray(matchingObject?.automationDetails?.actions ?? []);
  }, [matchingObject]);

  const getMatchingAction = useCallback(() => {
    return actionsArray?.[0] as MatchingAutomationAction;
  }, [actionsArray]);

  const [SelectAssignType, setSelectAssignType] = useState<AssignmentActionSelectionType>(
    getAssignActionType(matchingObject),
  );
  const [isEnrichPanelExpanded, setEnrichPanelExpanded] = useState(
    getMatchingAction()?.actionParams.fieldMapping.length > 0,
  );

  const [isLeadConversionExpanded, setLeadConversionPanelExpanded] = useState(
    getMatchingAction()?.actionParams.leadConversion,
  );
  const [invalidSections, setInvalidSection] = useState<InvalidSections>();
  const ruleBuilderRef = useRefForMultipleRuleBuilders();

  const handleMatchingAction = useCallback(
    (prop: string, value: any) => {
      const tempActionMatch = { ...getMatchingAction() };
      const newObj: MatchingAutomationAction = {
        ...tempActionMatch,
        actionParams: {
          ...tempActionMatch.actionParams,
          [prop]: value,
        },
      };
      const newArr = actionsArray.map((el) => (el?.actionType === 'MATCHING' ? newObj : el));
      setActionsArray(newArr);
      onChangeProperty('actions', newArr as AutomationAction[]);
    },
    [actionsArray, getMatchingAction, onChangeProperty],
  );

  const handleUpdateCondition = (exitCriteria: SweepCondition) => {
    const ret = getHandleUpdateCondition(matchingObject, exitCriteria);
    handleMatchingAction('followUpActions', ret);
  };

  const defaultFieldStruct: FieldMappingStruct = {
    fromField: {
      fieldIds: leadOwnerFieldId ? [leadOwnerFieldId] : [],
    },
    toField: {
      fieldIds: leadOwnerFieldId ? [leadOwnerFieldId] : [],
    },
  };

  const setFiltersByType = (filter: SweepFilter | undefined, endpoint: SweepFilterEndpoint) => {
    const ret = getFiltersByTypeToSet(matchingObject, filter, endpoint);
    handleMatchingAction('filters', ret);
  };

  const handleNotifyAction = (
    automationAction: EmailAutomationAction | SlackAutomationAction | null,
  ) => {
    const ret = handleInnerNotifyAction(matchingObject, automationAction);
    handleMatchingAction('followUpActions', ret);
  };

  return (
    <>
      <Box>
        <Typography variant="h4">When a match is found</Typography>

        <StyledRadioGroup
          sx={{ marginTop: '12px' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDisplayError(false);
            setInvalidSection(undefined);
            const value = (event.target as HTMLInputElement).value as AssignmentActionSelectionType;
            setSelectAssignType(value);
            const ret = handleMatchSelection(matchingObject, value, defaultFieldStruct);
            handleMatchingAction('followUpActions', ret);
          }}
          value={SelectAssignType}
        >
          <StyledFormControlLabel
            disabled={readonly}
            value={AssignmentActionSelectionType.Empty}
            control={<Radio />}
            label={<Typography variant="body">Only match leads</Typography>}
          />
          <StyledFormControlLabel
            disabled={readonly}
            value={AssignmentActionSelectionType.Assign}
            control={<Radio />}
            label={
              <Typography variant="body">Match and then assign to the matched lead</Typography>
            }
          />
          {SelectAssignType === AssignmentActionSelectionType.Assign && (
            <Box sx={{ ...greyLayout }}>
              <Box sx={{ paddingLeft: '24px' }}>
                <Typography variant="body-bold">Assign lead</Typography>
                <FieldAssignmentComponent
                  readonly={readonly}
                  restrictToUser={true}
                  displayError={displayError && invalidSections === InvalidSections.AssignField}
                  assignFromObject={'Lead'}
                  assignToObject={'Lead'}
                  assignFromLabel={'Existing Lead Ownership Field'}
                  assignToLabel={'New Lead Ownership field'}
                  assignFromPlaceholder={'Select Existing Lead Field'}
                  assignToPlaceholder={'Select New Lead Field'}
                  crmOrgId={crmOrgId}
                  mappingArray={getMappingAssignArray(matchingObject)}
                  onChange={(fields: FieldMappingStruct[]) => {
                    const ret = getAssignSelection(matchingObject, fields);
                    handleMatchingAction('followUpActions', ret);
                  }}
                />
              </Box>
            </Box>
          )}
          <StyledFormControlLabel
            disabled={readonly}
            value={AssignmentActionSelectionType.Condition}
            control={<Radio />}
            label={
              <Typography variant="body">
                Match and then assign to the matched lead, only if conditions are met
              </Typography>
            }
          />

          {SelectAssignType === AssignmentActionSelectionType.Condition && (
            <Box sx={{ ...greyLayout }}>
              <Box>
                <>
                  <CustomHeader customHeader="When these conditions are met" />
                  <SweepFieldsRuleBuilder
                    readOnly={readonly}
                    crmOrgId={crmOrgId}
                    objectType={'Lead'}
                    sweepCondition={getMatchingCondition(matchingObject)}
                    ref={ruleBuilderRef}
                    onChange={(sweepCondition: SweepCondition) => {
                      handleUpdateCondition(sweepCondition);
                    }}
                    disableResolvePolymorphic
                  />
                </>
              </Box>
              <Box sx={{ paddingLeft: '24px' }}>
                <Typography variant="body-bold">Assign lead</Typography>
                <FieldAssignmentComponent
                  readonly={readonly}
                  restrictToUser={true}
                  displayError={displayError && invalidSections === InvalidSections.AssignField}
                  assignFromObject={'Lead'}
                  assignToObject={'Lead'}
                  assignFromLabel={'Existing Lead Ownership Field'}
                  assignToLabel={'New Lead Ownership field'}
                  assignFromPlaceholder={'Select Existing Lead Field'}
                  assignToPlaceholder={'Select New Lead Field'}
                  crmOrgId={crmOrgId}
                  mappingArray={getMappingAssignArray(matchingObject)}
                  onChange={(fields: FieldMappingStruct[]) => {
                    const ret = getAssignSelection(matchingObject, fields);
                    handleMatchingAction('followUpActions', ret);
                  }}
                />
              </Box>
            </Box>
          )}
        </StyledRadioGroup>
      </Box>
      <MatchingNotification
        readonly={readonly}
        objectName={SFDCObjectType.Lead}
        action={getNotifyAction(matchingObject)}
        crmOrgId={crmOrgId}
        displayError={displayError}
        onChange={handleNotifyAction}
      ></MatchingNotification>

      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h4">Settings</Typography>

        <Box>
          <SettingsFilterPanel
            readonly={readonly}
            title={'New leads filters'}
            filters={getFiltersByType(matchingObject, SweepFilterEndpoint.SOURCE)}
            type={DedupMatchingType.LEAD_TO_LEAD_MATCHING}
            crmOrgId={crmOrgId}
            objectType={'Lead'}
            onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.SOURCE)}
          />
        </Box>
        <Box>
          <SettingsFilterPanel
            readonly={readonly}
            subType={1}
            title={'Existing leads filters'}
            filters={getFiltersByType(matchingObject, SweepFilterEndpoint.DESTINATION)}
            type={DedupMatchingType.LEAD_TO_LEAD_MATCHING}
            crmOrgId={crmOrgId}
            objectType={'Lead'}
            onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.DESTINATION)}
          />
        </Box>
        <Box>
          <SettingsFilterPanel
            readonly={readonly}
            subType={2}
            title={`Match filters`}
            filters={getFiltersByType(matchingObject, SweepFilterEndpoint.MATCH)}
            type={
              getMatchingAction().actionParams?.dmType ?? DedupMatchingType.LEAD_TO_LEAD_MATCHING
            }
            crmOrgId={crmOrgId}
            objectType={'Lead'}
            referenceObjectType={'Lead'}
            onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.MATCH)}
          />
        </Box>
        <Box sx={{ ...settingsPanelLayout }}>
          <Collapse in={isEnrichPanelExpanded} collapsedSize={24}>
            <Box
              className="panelHeader"
              onClick={() => setEnrichPanelExpanded(!isEnrichPanelExpanded)}
            >
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <EnrichLeadIcon />
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
                  <Typography variant="body-bold">Enrich new lead</Typography>
                  <Typography variant="body">
                    Update new lead fields with values from the existing matched lead
                  </Typography>
                </Box>
              </Box>
              <Box>
                {isEnrichPanelExpanded ? (
                  <ChevronUp variant="large" />
                ) : (
                  <ChevronDown variant="large" />
                )}
              </Box>
            </Box>
            <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
              <FieldAssignmentComponent
                readonly={readonly}
                displayError={displayError && invalidSections === InvalidSections.MatchFields}
                assignFromObject={'Lead'}
                assignToObject={'Lead'}
                assignFromLabel={'Existing Lead Field'}
                assignToLabel={'New Lead Field'}
                assignFromPlaceholder={'Select Existing Lead Field'}
                assignToPlaceholder={'Select New Lead Field'}
                showAddFields={true}
                crmOrgId={crmOrgId}
                mappingArray={getMatchingAction()?.actionParams?.fieldMapping || []}
                onAddCallback={(emptyField: FieldMappingStruct) => {
                  const tmpObj = getMatchingAction();
                  const tempFields = [...(tmpObj?.actionParams?.fieldMapping || [])];
                  tempFields.push(emptyField);
                  handleMatchingAction('fieldMapping', tempFields);
                }}
                onChange={(fields: FieldMappingStruct[]) => {
                  handleMatchingAction('fieldMapping', fields);
                }}
              />
            </Box>
          </Collapse>
        </Box>

        <Box sx={{ ...settingsPanelLayout }}>
          <Collapse in={isLeadConversionExpanded} collapsedSize={24}>
            <Box
              className="panelHeader"
              onClick={() => setLeadConversionPanelExpanded(!isLeadConversionExpanded)}
            >
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <UserPlus variant="large" />
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
                  <Typography variant="body-bold">Lead conversion settings</Typography>
                  <Typography variant="body">
                    Choose how to handle matched leads upon conversion
                  </Typography>
                </Box>
              </Box>
              <Box>
                {isLeadConversionExpanded ? (
                  <ChevronUp variant="large" />
                ) : (
                  <ChevronDown variant="large" />
                )}
              </Box>
            </Box>
            <Box sx={{ marginTop: '16px', marginLeft: '44px', display: 'flex' }}>
              <FieldLabel
                label="Automatically convert when the matched lead is converted"
                id="match-lead-lead"
                showTooltip={true}
                // eslint-disable-next-line max-len
                infoTooltipTitle="Once converted, Sweep will use the existing account that was created (or used, if existed before) in the matched lead conversion. A new contact will be created under that account, and an opportunity will not be created."
              />
              <Switch
                disabled={readonly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleMatchingAction('leadConversion', event.target.checked);
                }}
                checked={getMatchingAction()?.actionParams.leadConversion}
              />
            </Box>
          </Collapse>
        </Box>

        <Box>
          <SettingsTieBreakersPanel
            readonly={readonly}
            action={getMatchingAction()?.actionParams}
            type={DedupMatchingType.LEAD_TO_LEAD_MATCHING}
            crmOrgId={crmOrgId}
            objectType={'Lead'}
            onChange={(_tieBreakers) => {
              handleMatchingAction('tieBreakers', _tieBreakers);
            }}
          />
        </Box>
        {automationPermissionMode && (
          <SettingsPermissionModePanel
            readonly={readonly}
            automationDetails={matchingObject?.automationDetails}
            onChange={(val: AutomationPermissionMode) => {
              onChangeProperty?.('permissionMode', val);
            }}
          />
        )}
      </Box>
    </>
  );
};
