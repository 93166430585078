import { useSelector } from 'react-redux';
import {
  selectParsedConfigurationItems,
  selectDependencyByType,
} from '../../../../reducers/documentationReducer';
import { selectParsedRules } from '../../../../reducers/parserReducer';
import { ConfigurationItemMap } from '../../configuration-item-tables/shared/helpers';
import { generateFieldId, ID_NAME_SEPARATOR } from '../../universal-search/utils';
import { ConfigurationType } from '../types';
import { checkIfActive, createConfigurationItemFromParsedRule } from '../../helpers';

interface UseGetParsedDepedencies {
  configurationItemId: string;
  crmOrgId: string;
  type: 'dependsOnIdsForRules' | 'usedByIdsForRules' | 'usedByIdsForFields';
  name: string;
  objectName: string;
  isSupportedType: boolean;
  onlyActive: boolean;
}

export const useGetParsedDepedencies = ({
  configurationItemId,
  crmOrgId,
  type,
  name,
  objectName,
  isSupportedType,
  onlyActive,
}: UseGetParsedDepedencies) => {
  const _fieldId = generateFieldId({ id: configurationItemId, name, objectName });
  const configs = useSelector(selectParsedConfigurationItems(crmOrgId));
  const parsedRules = useSelector(selectParsedRules);

  const id = type === 'usedByIdsForFields' ? _fieldId : configurationItemId ?? '';
  const rulesIds = useSelector(selectDependencyByType(type))?.[crmOrgId]?.[id];

  if (!rulesIds || !isSupportedType) {
    return {} as ConfigurationItemMap;
  }

  const newDep = {} as ConfigurationItemMap;

  Object.keys(rulesIds).forEach((key) => {
    const configKey = key as ConfigurationType;
    const items = rulesIds[configKey];
    const newConfigItems: ConfigurationItem[] = [];

    items.forEach((id) => {
      const [_id, _name] = id.split(ID_NAME_SEPARATOR);

      const ruleIdx =
        parsedRules?.findIndex((rule) => rule.id === _id && (_name ? rule.name === _name : true)) ??
        -1;

      if (ruleIdx !== -1 && parsedRules) {
        const rule = parsedRules[ruleIdx];

        if (checkIfActive(onlyActive, rule)) {
          newConfigItems.push(createConfigurationItemFromParsedRule(rule));
        }
        return;
      }

      const configItemWithIdAndNameIdx =
        configs?.findIndex(
          (config) => config.id === _id && (_name ? config.name === _name : true),
        ) ?? -1;

      if (configItemWithIdAndNameIdx !== -1) {
        const config = configs[configItemWithIdAndNameIdx];

        if (checkIfActive(onlyActive, config)) {
          newConfigItems.push(config);
        }
      }
      return;
    });

    newDep[configKey] = newConfigItems;
  });

  return newDep;
};
