import { MenuList, Stack } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SlackConnectButton } from './SlackConnectButton';
import { SlackLogo } from '../../../icons/generalIcons';
import { HubspotConnectButton } from './HubspotConnectButton';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { CloudingoConnectButton } from './CloudingoConnectButton';
import { IntegrationOption } from './IntegrationsOption';
import { SlackIncludeSandboxesCheckbox } from './SlackIncludeSandboxesCheckbox';
import { HubspotNewLogo } from '../../common/connect-sweep-to/integrationIcons';

export const IntegrationsTab = () => {
  const { hubspotIntegration } = useFeatureToggle();

  return (
    <Stack gap="24px">
      <Typography variant="h2">Integrations</Typography>
      <MenuList
        sx={{
          '&.MuiList-root': {
            padding: 0,
          },
          '.MuiMenuItem-root': {
            padding: 2,
            gap: 2,
            borderBottom: `1px solid ${colors.grey[200]}`,
            margin: 0,
            minHeight: '94px',
          },
        }}
      >
        <IntegrationOption
          name="Slack"
          logo={<SlackLogo />}
          description="Connect Slack to keep your GTM teams informed with actionable notifications"
          actionButton={<SlackConnectButton />}
          additionalContent={<SlackIncludeSandboxesCheckbox />}
        />

        {hubspotIntegration && (
          <IntegrationOption
            name="Hubspot"
            logo={<HubspotNewLogo />}
            description="Connect HubSpot to visualize funnels and manage campaign enrollments"
            actionButton={<HubspotConnectButton />}
          />
        )}
        <IntegrationOption
          name="Cloudingo"
          logo={
            <img
              src="https://asset.brandfetch.io/ido-ApkhlX/idpHL_OSQO.jpeg"
              width={40}
              height={40}
            />
          }
          description="Merge duplicates with Cloudingo"
          actionButton={<CloudingoConnectButton />}
        />
      </MenuList>
    </Stack>
  );
};
