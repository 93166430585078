import { Fragment, ReactNode } from 'react';
import { ExpandedObjectDataTableElement } from './ExpandedObjectContent';
import { FunnelFieldLabels } from '../../../../constants/fieldsManagementConsts';
import { ClampedText } from '../../../common/ClampedText';
import { Percentage } from '../../../common/Percentage';
import { DataTableVariant } from '../../../common/table/TableTypes';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { TableTypography } from '../../configuration-item-tables/shared/helpers';
import { ConfigurationToName, ConfigurationType } from '../../dependencies/types';
import { InitialsAvatar } from '../../InitialsAvatar';
import { colors } from '@sweep-io/sweep-design';
import { InactiveTag } from '../../activity-state/InactiveTag';
import { Stack } from '@mui/material';

const tableTypographyVariant = dataTableVariants[DataTableVariant.default].fontVariant;

const getColor = (isInactive: boolean) => (isInactive ? colors.grey[500] : 'inherit');

const renderRowCell = (
  id: string,
  key: keyof ExpandedObjectDataTableElement,
  value: string | number | boolean | undefined | ReactNode[],
  isManagePackageInstalled: boolean,
  isInactive: boolean,
) => {
  switch (key) {
    case 'createdAt':
    case 'updatedAt':
      return value ? (
        <ClampedText
          key={(id + value) as string}
          text={humanizeDate({
            dateOrTimestamp: value as string,
          })}
          typographyVariant={tableTypographyVariant}
          textColor={getColor(isInactive)}
        />
      ) : (
        '-'
      );

    case 'usage':
      return (
        <Percentage
          key={(id + value) as string}
          preCalculated={value as number}
          active={isManagePackageInstalled}
          typographyVariant="caption"
        />
      );

    case 'type':
      return (
        <ClampedText
          key={(id + value) as string}
          text={
            FunnelFieldLabels[value as SweepFieldTypes] ??
            ConfigurationToName[value as ConfigurationType] ??
            value
          }
          typographyVariant={tableTypographyVariant}
          textColor={getColor(isInactive)}
        />
      );

    case 'sfApiName':
      return (
        <ClampedText
          key={(id + value) as string}
          text={typeof value === 'string' ? decodeURI(value) : value}
          typographyVariant={tableTypographyVariant}
          textColor={getColor(isInactive)}
        />
      );

    case 'actions':
      return (
        <Fragment key={(id + value) as string}>
          {(value as ReactNode[])?.map((val, idx) =>
            typeof val === 'string' ? (
              <TableTypography key={val} variant={DataTableVariant.default}>
                {val}
              </TableTypography>
            ) : (
              <Fragment key={idx}>{val}</Fragment>
            ),
          )}
        </Fragment>
      );

    case 'label':
      return (
        <Stack direction="row">
          <ClampedText
            key={(id + value) as string}
            text={value ?? '-'}
            typographyVariant={tableTypographyVariant}
            textColor={getColor(isInactive)}
          />
          <InactiveTag isActive={!isInactive} />
        </Stack>
      );

    default:
      return (
        <ClampedText
          key={(id + value) as string}
          text={value ?? '-'}
          typographyVariant={tableTypographyVariant}
          textColor={getColor(isInactive)}
        />
      );
  }
};

export const renderRows = ({
  items,
  activeItemId,
  isManagePackageInstalled,
}: {
  items: ExpandedObjectDataTableElement[];
  activeItemId: string;
  isManagePackageInstalled: boolean;
}) => {
  return items.map((row) => ({
    ...Object.assign(
      {},
      ...Object.keys(row).map((key) => ({
        [key]: renderRowCell(
          row.id,
          key as keyof ExpandedObjectDataTableElement,
          row[key as keyof typeof row],
          !!isManagePackageInstalled,
          !row.isActive,
        ),
      })),
    ),
    _sfApiName: row.sfApiName,
    _type: row.type,
    parentType: row.parentType,
    id: row.id,
    isActive: activeItemId === row.id,
    isClickable: true,
    isRollup: row.isRollup,
    createdBy: (
      <InitialsAvatar
        isInactive={!row.isActive}
        showUserFullName
        userIdOrName={row.createdBy}
        changedAt={row.createdAt}
      />
    ),
    updatedBy: (
      <InitialsAvatar
        showUserFullName
        isInactive={!row.isActive}
        userIdOrName={row.updatedBy}
        changedAt={row.updatedAt}
      />
    ),
  }));
};
