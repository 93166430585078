import { useState, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentation } from '../useDocumentation';
import uniqueId from 'lodash/uniqueId';
import { useCrmOrgsApiFacade } from '../../../apis/facades/useCrmOrgsApiFacade';
import {
  selectUniversalSearch,
  setUniversalSearchIsResultLoading,
  setUniversalSearchResults,
  setDependenciesConfigurationItem,
  setUniversalSearchTxt,
  clearUniversalSearch,
  setUniversalSearchFilter,
  clearDependencies,
  addConfigurationItems,
  selectLoader,
  selectObjects,
  selectLayoutsByObjectName,
} from '../../../reducers/documentationReducer';
import { OPTION_ALL, getTypeOptionsWithCounter } from '../../common/single-select-filter/utils';
import {
  ConfigurationType,
  DependenciesContentViewType,
  NewDependencyProps,
} from '../dependencies/types';
import { configurationTypesOptions } from './utils';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { telemetry } from '../../../telemetry';
import { useLayouts } from '../useLayouts';
import { SearchResponse, SearchType } from './types';
import uniq from 'lodash/uniq';

export const useUniversalSearch = (crmOrgId: string) => {
  const dispatch = useDispatch();

  const universalSearch = useSelector(selectUniversalSearch);
  const { results, isResultsLoading, isUniversalSearchListOpen, filterKey } = universalSearch ?? {};

  const { searchV2 } = useFeatureToggle();
  const { fetchLayoutsData } = useLayouts();

  const { isLoading: _isLoadingObjects, objectTypesByName } = useObjectTypesWithFetch({
    crmOrgId,
    useSfObjects: true,
  });

  const showLoader = useSelector(selectLoader(crmOrgId));
  const layoutsByObjectName = useSelector(selectLayoutsByObjectName(crmOrgId));
  const [selectedSearchType, setSelectedSearchType] = useState(SearchType.apiNameAndLabel);
  const configurationOptionsValues = configurationTypesOptions.map((item) => item.value);
  const dynamicFilterOptions = Object.keys(results ?? {})
    ?.map((item) => ({
      value: item,
      label: item,
    }))
    .filter((item) => !configurationOptionsValues.includes(item.value as ConfigurationType));

  const filterOptions = [
    OPTION_ALL,
    ...getTypeOptionsWithCounter(
      uniq([...configurationTypesOptions, ...dynamicFilterOptions]),
      (type) => results?.[type.value as keyof SearchResponse]?.length ?? 0,
    ),
  ];

  const searchTypeOptions = searchV2
    ? [
        {
          value: SearchType.apiNameAndLabel,
          label: 'Search in name fields',
        },
        {
          value: SearchType.content,
          label: 'Search in all content',
        },
      ]
    : [];

  const filteredResults =
    filterKey && filterKey !== OPTION_ALL.value
      ? ({ [filterKey]: results?.[filterKey as keyof SearchResponse] } as SearchResponse)
      : results;

  const [isLoadingDependency, setIsLoadingDependency] = useState(false);
  const [_searchTxt, setSearchTxt] = useState('');

  const { get_searchNameAndLabel, get_searchContent } = useCrmOrgsApiFacade();
  const { onObjectNotInFunnelMapClick, dispatchSingleObjectName } = useDocumentation();

  const objects = useSelector(selectObjects);

  const onClearButtonClick = useCallback(() => {
    dispatch(clearUniversalSearch());
    dispatch(clearDependencies());
    setSearchTxt('');
  }, [dispatch]);

  const onSearchKeydown = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>, _selectedSearchType?: SearchType) => {
      if (!crmOrgId) {
        return null;
      }

      if (event.key === 'Enter' && _searchTxt !== '') {
        const loadingId = uniqueId();
        dispatch(setUniversalSearchIsResultLoading({ isLoading: true, id: loadingId }));
        dispatch(setUniversalSearchTxt({ searchTxt: _searchTxt }));

        if (
          _selectedSearchType
            ? _selectedSearchType === SearchType.content
            : selectedSearchType === SearchType.content
        ) {
          try {
            const results = await get_searchContent({
              crmOrgId,
              searchTxt: _searchTxt ?? '',
            });
            dispatch(setUniversalSearchResults({ results, loadingId, crmOrgId }));
          } catch (e) {
            telemetry.captureError(e);
            dispatch(
              setUniversalSearchResults({ results: {} as SearchResponse, loadingId, crmOrgId }),
            );
          }
        } else {
          try {
            const results = await get_searchNameAndLabel({
              crmOrgId,
              searchTxt: _searchTxt ?? '',
            });
            dispatch(setUniversalSearchResults({ results, loadingId, crmOrgId }));
          } catch (e) {
            telemetry.captureError(e);
            dispatch(
              setUniversalSearchResults({ results: {} as SearchResponse, loadingId, crmOrgId }),
            );
          }
        }
      } else if (event.key === 'Enter' && _searchTxt === '') {
        onClearButtonClick();
      }
    },
    [
      get_searchNameAndLabel,
      get_searchContent,
      selectedSearchType,
      dispatch,
      crmOrgId,
      _searchTxt,
      onClearButtonClick,
    ],
  );

  const onSelectSearchTypeOption = useCallback(
    (value: string) => {
      setSelectedSearchType(value as SearchType);
      if (_searchTxt) {
        onSearchKeydown({ key: 'Enter' } as any, value as SearchType);
      }
    },
    [_searchTxt, onSearchKeydown],
  );

  const onListItemClick = useCallback(
    async (props: NewDependencyProps) => {
      const { parentType, dependencyType, name, id, objectName, clearHistory, contentType } = props;
      let item: ConfigurationItem | NameProperties | undefined;

      const configurationItems = results?.[parentType as keyof SearchResponse];
      const shouldRenderSidePanel =
        ConfigurationType.objects !== parentType ||
        (ConfigurationType.objects === parentType &&
          contentType === DependenciesContentViewType.sourceCode);

      switch (parentType) {
        case ConfigurationType.layouts:
          const layouts = layoutsByObjectName?.[objectName ?? ''];
          item = layouts?.layouts?.find((_item: any) => _item.id === id);

          if (!layouts?.layouts && objectName) {
            setIsLoadingDependency(true);
            const newLayouts = await fetchLayoutsData({ crmOrgId, objectName: objectName });
            item = newLayouts?.find((_item: any) => _item.id === id);
            setIsLoadingDependency(false);
          }
          break;

        case ConfigurationType.fields:
          item = configurationItems?.find(
            (_item: any) => _item.name === name && _item.objectName === objectName,
          );
          break;

        case ConfigurationType.objects:
          if (contentType !== DependenciesContentViewType.sourceCode) {
            const objectTypeName = objects.favorites?.find((object) => object.objectType === name);

            if (!!objectTypeName) {
              dispatchSingleObjectName(objectTypeName);
            } else {
              const _objectTypeName = objectTypesByName[name];
              setIsLoadingDependency(true);

              if (_objectTypeName) {
                await onObjectNotInFunnelMapClick(_objectTypeName);
              } else {
                telemetry.captureError(new Error('Couldnt find objectName for:'), { name });
              }
              setIsLoadingDependency(false);
            }
            return;
          }

          item = configurationItems?.find((_item: any) => _item.name === name);
          break;

        default:
          const itemById = configurationItems?.find((_item: any) => _item.id === id);
          const itemByNameAndType = configurationItems?.find(
            (_item: any) => _item.name === name && _item.type === parentType,
          ); //for unclassified and files
          item = itemById ?? itemByNameAndType;
          break;
      }

      if (!shouldRenderSidePanel) {
        return;
      }

      if (!item) {
        telemetry.captureError(
          `Dependency item not found id: ${id}, parentType: ${parentType}, dependencyType: ${dependencyType} in object: ${objectName}`,
        );
        return;
      }

      //if user wants to drill inside search results the item needs to be added to configuration items first
      dispatch(
        addConfigurationItems({
          crmOrgId,
          newConfigurationItems: { [parentType]: [item] } as any,
        }),
      );

      dispatch(
        setDependenciesConfigurationItem({
          id,
          parentType: parentType as ConfigurationType,
          dependencyType: dependencyType ?? '',
          name,
          objectName,
          clearHistory,
          contentType,
        }),
      );
    },
    [
      dispatch,
      onObjectNotInFunnelMapClick,
      dispatchSingleObjectName,
      crmOrgId,
      results,
      objectTypesByName,
      objects.favorites,
      layoutsByObjectName,
      fetchLayoutsData,
    ],
  );

  //To be deprecated
  const onChooseNewRule = useCallback(
    async (props: NewDependencyProps) => {
      const { parentType, dependencyType, name, id, objectName, clearHistory, contentType } = props;

      if (parentType === ConfigurationType.objects) {
        const objectTypeName = objects.favorites?.find((object) => object.objectType === name);

        if (!!objectTypeName) {
          dispatchSingleObjectName(objectTypeName);
        } else {
          const _objectTypeName = objectTypesByName[name];
          setIsLoadingDependency(true);

          if (_objectTypeName) {
            await onObjectNotInFunnelMapClick(_objectTypeName);
          } else {
            telemetry.captureError(new Error('Couldnt find objectName for:'), { name });
          }
          setIsLoadingDependency(false);
        }
      } else {
        if (parentType === ConfigurationType.layouts) {
          const layouts = layoutsByObjectName?.[objectName ?? ''];

          if (!layouts?.layouts && objectName) {
            setIsLoadingDependency(true);
            await fetchLayoutsData({ crmOrgId, objectName: objectName });
            setIsLoadingDependency(false);
          }
        }

        const _id = parentType === ConfigurationType.fields ? '' : id; //fields don't have real ids
        const configurationItems = results?.[parentType as keyof SearchResponse];

        const item = configurationItems?.find((_item: any) =>
          parentType === ConfigurationType.fields
            ? _item.name === name && _item.objectName === objectName
            : _item.id === id,
        );

        //if user wants to drill inside search results the item needs to be added to configuration items first
        dispatch(
          addConfigurationItems({
            crmOrgId,
            newConfigurationItems: { [parentType]: [item] } as any,
          }),
        );

        dispatch(
          setDependenciesConfigurationItem({
            id: _id,
            parentType: parentType as ConfigurationType,
            dependencyType: dependencyType ?? '',
            name,
            objectName,
            clearHistory,
            contentType,
          }),
        );
      }
    },
    [
      dispatch,
      onObjectNotInFunnelMapClick,
      dispatchSingleObjectName,
      crmOrgId,
      results,
      objectTypesByName,
      objects.favorites,
      layoutsByObjectName,
      fetchLayoutsData,
    ],
  );

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchTxt(event ? event.target.value : '');
    },
    [],
  );

  const onSelectedFilterItem = useCallback(
    (filterKey?: ConfigurationType) => {
      dispatch(setUniversalSearchFilter({ filterKey }));
    },
    [dispatch],
  );

  return {
    onSearchKeydown,
    onChooseNewRule,
    onClearButtonClick,
    onSearchChange,
    onSelectedFilterItem,
    searchTxt: _searchTxt,
    isLoadingDependency,
    isLoadingObjects: _isLoadingObjects || showLoader,
    isUniversalSearchListOpen,
    isLoadingResults: isResultsLoading,
    results: filteredResults,
    filterOptions,
    selectedFilterValue: filterKey,
    onListItemClick,
    searchTypeOptions,
    selectedSearchType,
    onSelectSearchTypeOption,
  };
};
