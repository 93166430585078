import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Typography } from '@sweep-io/sweep-design';

const EmptyState = ({
  image,
  line1,
  line2,
  children,
}: {
  image: ReactNode;
  line1: string;
  line2?: string;
  children: ReactNode;
}) => {
  return (
    <Stack gap={2} alignItems="center">
      {image}
      <Stack alignItems="center">
        <Typography variant="body">{line1}</Typography>
        {line2 && <Typography variant="body">{line2}</Typography>}
      </Stack>
      <Stack position="relative">{children}</Stack>
    </Stack>
  );
};

export default EmptyState;
