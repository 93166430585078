import { Badge, colors, Typography } from '@sweep-io/sweep-design';
import { ListElementWrapper } from '../../shared/ListElementWrapper';
import Box from '@mui/material/Box/Box';
import { SelectedObjectListElements } from '../../types';
import { NoResults } from '../../NoResults';

interface ElementsWrapperProps {
  elements: SelectedObjectListElements;
  onClick: (event: any, key: any) => void;
}

export const ListWithBadge = ({ elements, onClick }: ElementsWrapperProps) => {
  return (
    <>
      {!elements.length && (
        <Box pl={2}>
          <NoResults />
        </Box>
      )}

      {elements.map((element) => {
        const key = element.value as any;

        return (
          <ListElementWrapper
            key={key}
            onWrapperClick={(event) => onClick(event, key)}
            sx={{ height: '48px' }}
            leftContent={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="body-medium" color={colors.black}>
                  {element.label}
                </Typography>
                <Badge showZero variant="secondary" label={element.elements?.length ?? 0} />
              </Box>
            }
          />
        );
      })}
    </>
  );
};
