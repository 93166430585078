import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { StageDialogTabTypes } from '../../types/enums/StageDialogTabTypes';
import { selectFunnelMapFunnelById, selectStageById } from '../multiFunnelFlowCanvasReducer';

export interface StageDialogProps {
  opportunityTypesList?: OpportunityType[];
  showStageDialogLoader: boolean;
  data?: {
    selectedStageId: string;
    activeTab: StageDialogTabTypes;
    funnelId: string;
    gateIsDirtyMap: { [exitCriteriaId: string]: boolean };
  };
}

const initialState: StageDialogProps = {
  showStageDialogLoader: false,
};

const stageDialogSlice = createSlice({
  name: 'stageDialog',
  initialState,
  reducers: {
    openStageDialog: (
      state,
      action: PayloadAction<{
        stageId: string;
        activeTab: StageDialogTabTypes;
        funnelId: string;
      }>,
    ) => {
      const { stageId, activeTab, funnelId } = action.payload;

      if (state.data?.selectedStageId === stageId) {
        state.data.activeTab = activeTab;
        return;
      }

      state.data = {
        selectedStageId: stageId,
        activeTab,
        funnelId,
        gateIsDirtyMap: {},
      };
    },
    closeStageDialog: (state) => {
      state.data = undefined;
    },
    displayStageDialogLoader: (
      state,
      action: PayloadAction<{
        showLoader: boolean;
      }>,
    ) => {
      state.showStageDialogLoader = action.payload.showLoader;
    },
    setOpportunityLists: (
      state,
      action: PayloadAction<{
        types: OpportunityType[];
      }>,
    ) => {
      state.opportunityTypesList = action.payload.types;
    },
    setStageDialogActiveTab: (state, action: PayloadAction<StageDialogTabTypes>) => {
      if (state.data) {
        state.data.activeTab = action.payload;
      }
    },
    setGateIsDirty: (
      state,
      action: PayloadAction<{ exitCriteriaId: string; isDirty: boolean }>,
    ) => {
      const { isDirty, exitCriteriaId } = action.payload;
      if (state.data) {
        state.data.gateIsDirtyMap = { ...state.data.gateIsDirtyMap, [exitCriteriaId]: isDirty };
      }
    },
    resetGatesDirtyMap: (state) => {
      if (state.data) {
        state.data.gateIsDirtyMap = {};
      }
    },
  },
});

export const selectStageDialogIsOpened = (state: RootState) => !!state.stageDialog.data;

export const selectStageDialogStage = (state: RootState) => {
  const stageId = state.stageDialog.data?.selectedStageId;
  const funnelId = state.stageDialog.data?.funnelId;
  if (!funnelId || !stageId) return undefined;
  return selectStageById(funnelId, stageId)(state);
};

export const selectStageDialogStageId = (state: RootState) =>
  state.stageDialog.data?.selectedStageId;
export const selectStageDialogFunnelId = (state: RootState) => state.stageDialog.data?.funnelId;

export const selectStageFunnelDetails = (state: RootState) => {
  const funnelId = state.stageDialog.data?.funnelId;
  if (!funnelId) return undefined;
  const funnel = selectFunnelMapFunnelById(funnelId)(state);
  return funnel?.funnelDetails;
};

export const selectStageFunnelId = (state: RootState) => state.stageDialog.data?.funnelId;

export const selectStageDialogActiveTab = (state: RootState) =>
  state.stageDialog.data?.activeTab || StageDialogTabTypes.GATES;

export const selectStageDialogLoader = (state: RootState) =>
  state.stageDialog.showStageDialogLoader;

export const selectOpportunityTypesList = (state: RootState) =>
  state.stageDialog.opportunityTypesList;

export const selectStageDialogHasChanges = (state: RootState) => {
  if (state.stageDialog.data) {
    const allDirtyValues = Object.values(state.stageDialog.data.gateIsDirtyMap);
    return allDirtyValues.some((v) => v === true);
  }
};

export const {
  openStageDialog,
  closeStageDialog,
  setOpportunityLists,
  displayStageDialogLoader,
  setStageDialogActiveTab,
  setGateIsDirty: setGateIsDirtyAction,
  resetGatesDirtyMap,
} = stageDialogSlice.actions;

export default stageDialogSlice.reducer;
