import noop from 'lodash/noop';
import { CurrentRecord } from './RecordTypeLeadingObjectPanel';
import { createContext, useState } from 'react';

interface RecordTypeLeadingObjectPanelCtx {
  currentRecord?: CurrentRecord;
  setCurrentRecord: (record: CurrentRecord | undefined) => void;
  setLeadingFieldAndValuesForRecordType: (params: {
    recordTypeApiName: string;
    recordTypeName: string;
    recordTypeDataValues: PicklistValue[];
    leadingField: string;
    crmOrgId: string;
  }) => void;
  addRecordTypeDataEntries: (params: { crmOrgId: string; recordTypes: SingleRecordType[] }) => void;
}

export const recordTypeLeadingObjectCtx = createContext<RecordTypeLeadingObjectPanelCtx>({
  setCurrentRecord: noop,
  setLeadingFieldAndValuesForRecordType: noop,
  addRecordTypeDataEntries: noop,
});

export const RecordTypeLeadingObjectProvider = ({
  children,
  setLeadingFieldAndValuesForRecordType,
  addRecordTypeDataEntries,
}: {
  children: React.ReactNode;
  setLeadingFieldAndValuesForRecordType: (params: {
    recordTypeApiName: string;
    recordTypeName: string;
    recordTypeDataValues: PicklistValue[];
    leadingField: string;
    crmOrgId: string;
  }) => void;
  addRecordTypeDataEntries: (params: { crmOrgId: string; recordTypes: SingleRecordType[] }) => void;
}) => {
  const [currentRecord, setCurrentRecord] = useState<CurrentRecord>();

  return (
    <recordTypeLeadingObjectCtx.Provider
      value={{
        currentRecord,
        setCurrentRecord,
        setLeadingFieldAndValuesForRecordType,
        addRecordTypeDataEntries,
      }}
    >
      {children}
    </recordTypeLeadingObjectCtx.Provider>
  );
};
