import { SearchSelect, SearchSelectItem } from '../../../../common/SearchSelect';
import { selectHubspotFields } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { HubspotField } from '../hubspot.types';

// Supported types are 'string', 'number', 'enumeration', 'datetime', 'date', 'bool', 'object_coordinates', 'phone_number'

interface HubspotFieldSelectorProps {
  onChange: (field: SearchSelectItem<HubspotField>) => void;
  value?: string;
  disabled?: boolean;
}

export const HubspotFieldSelector = ({ onChange, value, disabled }: HubspotFieldSelectorProps) => {
  const hubspotFields = useSelector(selectHubspotFields);

  // Leaving this here for debugging purposes
  // const allFieldsTypes = Array.from(new Set(hubspotFields.map((field) => field.type)));
  // console.log('hubspotFields', allFieldsTypes);
  // allFieldsTypes.forEach((fieldType) => {
  //   console.log(hubspotFields.find((field) => field.type === fieldType));
  // });

  const items: SearchSelectItem<HubspotField>[] = useMemo(() => {
    return (
      hubspotFields?.map((field) => ({
        label: field.label,
        value: field.name,
        data: field,
      })) || []
    );
  }, [hubspotFields]);

  return (
    <SearchSelect
      items={items}
      FormControlProps={{ fullWidth: true }}
      onChange={onChange}
      SelectProps={{
        value,
      }}
      removeBorders
      disabled={disabled}
    />
  );
};
