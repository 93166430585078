import { useMemo } from 'react';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { ParsedRule } from '../ParserTypes';
import { isCPQObject } from '../cpq/utils';
import { RecordTypesLabelsByNameAndObjectName, SelectedObjectListElements } from '../types';
import { useFilterTabs } from './filters/useFilterTabs';
import { ExpandedObjectContent } from './expanded-mode/ExpandedObjectContent';

interface UseSelectedObjectMenuElementsProps {
  parsedRules: ParsedRule[];
  crmOrgId: string;
  objectTypeName: ObjectTypeName;
  isExpanded?: boolean;
  stepNamesUsedInCanvas: string[];
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
}

export const useSelectedObjectMenuElements = ({
  parsedRules,
  crmOrgId,
  objectTypeName,
  isExpanded,
  recordTypeNamesUsedInCanvas,
  stepNamesUsedInCanvas,
}: UseSelectedObjectMenuElementsProps) => {
  const {
    searchTxt,
    apex,
    automations,
    validationRules,
    fields,
    cpqData,
    sweepAutomations,
    sweepAlerts,
    sweepDedupe,
    sweepMatching,
    sweepAssignments,
    sweepScheduledAssignments,
    recordTypes,
    layouts,
  } = useFilterTabs({
    parsedRules,
    crmOrgId,
    objectTypeName,
  });

  const sfElements = useMemo(() => {
    const objs = [
      {
        label: 'Fields',
        elements: fields ?? [],
        value: DocumentationTabTypes.FIELDS,
        badgeLabel: fields?.length ?? 0,
      },
      {
        label: 'Salesforce Automations',
        elements: automations ?? [],
        value: DocumentationTabTypes.SF_AUTOMATIONS,
        badgeLabel: automations.length ?? 0,
      },
      {
        label: 'Apex',
        elements: apex ?? [],
        value: DocumentationTabTypes.APEX,
        badgeLabel: apex.length ?? 0,
      },
      {
        label: 'Validation Rules',
        elements: validationRules ?? [],
        value: DocumentationTabTypes.VALIDATION_RULES,
        badgeLabel: validationRules.length ?? 0,
      },
      {
        label: 'Layouts',
        elements: layouts ?? [],
        value: DocumentationTabTypes.LAYOUTS,
        badgeLabel: layouts.length ?? 0,
      },
      {
        label: 'Record Types',
        elements: recordTypes,
        value: DocumentationTabTypes.RECORD_TYPES,
        badgeLabel: recordTypes.length ?? 0,
      },
    ] as SelectedObjectListElements;

    if (isCPQObject(objectTypeName)) {
      objs.push({
        label: 'CPQ Configurations',
        elements: cpqData ?? [],
        value: DocumentationTabTypes.CPQ_DATA,
        badgeLabel: cpqData.length ?? 0,
      });
    }

    return objs;
  }, [recordTypes, fields, apex, automations, cpqData, validationRules, layouts, objectTypeName]);

  const sweepElements = useMemo(
    () =>
      [
        {
          label: 'Sweep Automations',
          elements: sweepAutomations ?? [],
          value: DocumentationTabTypes.SWEEP_AUTOMATIONS,
          badgeLabel: sweepAutomations.length ?? 0,
        },
        {
          label: 'Alerts',
          elements: sweepAlerts ?? [],
          value: DocumentationTabTypes.PLAYBOOK_ALERTS,
          badgeLabel: sweepAlerts.length ?? 0,
        },
        {
          label: 'Assignment Rules',
          elements: sweepAssignments ?? [],
          value: DocumentationTabTypes.ASSIGNMENTS,
          badgeLabel: sweepAssignments.length ?? 0,
        },
        {
          label: 'Scheduled Assignment Rules',
          elements: sweepScheduledAssignments ?? [],
          value: DocumentationTabTypes.SCHEDULED_ASSIGNMENTS,
          badgeLabel: sweepScheduledAssignments.length ?? 0,
        },
        {
          label: 'Dedupe & Matching',
          elements: [...(sweepDedupe ?? []), ...(sweepMatching ?? [])],
          value: DocumentationTabTypes.MATCHING_DEDUPE,
          badgeLabel: [...(sweepDedupe ?? []), ...(sweepMatching ?? [])].length ?? 0,
        },
      ] as SelectedObjectListElements,
    [
      sweepAutomations,
      sweepAlerts,
      sweepDedupe,
      sweepMatching,
      sweepScheduledAssignments,
      sweepAssignments,
    ],
  );

  const allElements = useMemo(
    () => [...sfElements, ...sweepElements] as SelectedObjectListElements,
    [sfElements, sweepElements],
  );

  const renderedElements = useMemo(
    () =>
      isExpanded
        ? allElements.map((el) => ({
            ...el,
            content: (
              <ExpandedObjectContent
                objectName={objectTypeName.objectType}
                documentationTabType={el.value as DocumentationTabTypes}
                label={el.label}
                elements={el.elements}
                recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
                stepNamesUsedInCanvas={stepNamesUsedInCanvas}
                crmOrgId={crmOrgId}
              />
            ),
          }))
        : allElements,
    [
      allElements,
      isExpanded,
      crmOrgId,
      objectTypeName.objectType,
      recordTypeNamesUsedInCanvas,
      stepNamesUsedInCanvas,
    ],
  );

  const renderedSfElements = useMemo(
    () =>
      isExpanded
        ? sfElements.map((el) => ({
            ...el,
            content: (
              <ExpandedObjectContent
                objectName={objectTypeName.objectType}
                documentationTabType={el.value as DocumentationTabTypes}
                label={el.label}
                elements={el.elements}
                recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
                stepNamesUsedInCanvas={stepNamesUsedInCanvas}
                crmOrgId={crmOrgId}
              />
            ),
          }))
        : sfElements,
    [
      sfElements,
      isExpanded,
      crmOrgId,
      objectTypeName.objectType,
      recordTypeNamesUsedInCanvas,
      stepNamesUsedInCanvas,
    ],
  );

  const renderedSweepElements = useMemo(
    () =>
      isExpanded
        ? sweepElements.map((el) => ({
            ...el,
            content: (
              <ExpandedObjectContent
                objectName={objectTypeName.objectType}
                documentationTabType={el.value as DocumentationTabTypes}
                label={el.label}
                elements={el.elements}
                recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
                stepNamesUsedInCanvas={stepNamesUsedInCanvas}
                crmOrgId={crmOrgId}
              />
            ),
          }))
        : sweepElements,
    [
      sweepElements,
      isExpanded,
      crmOrgId,
      objectTypeName.objectType,
      recordTypeNamesUsedInCanvas,
      stepNamesUsedInCanvas,
    ],
  );

  return {
    sfElements: renderedSfElements,
    sweepElements: renderedSweepElements,
    elements: renderedElements,
    searchTxt,
  };
};
