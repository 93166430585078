import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFunnelMap } from '../../../../../reducers/multiFunnelFlowCanvasReducer';
import { selectCrmOrgs } from '../../../environments/environmentsReducer';
import { MASTER_KEY, mapRecordTypeToFunnel } from './utils';
import { selectObjects, setImportFunnelObjects } from '../../../../../reducers/importFunnelReducer';
import { ListGroup, ListItem } from '../../../../common/CollapseList_deprecated';
import { FieldValue, LeadingField, ParsedRecordType } from '../../../../documentation/ParserTypes';
import { useFunnels } from '../../../../../hooks/useFunnels';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { useAddFunnel } from '../../add-funnel/useAddFunnel';
import { DEFAULT_OBJECTS_API_NAME } from '../../../../parser/parserUtils';
import { ListItemAddedTag } from './main-view/ListItemAddedTag';
import { ListItemButtons } from './main-view/ListItemButtons';
import { SearchSelectItem } from '../../../../common/SearchSelect';
import { NotDeployedTag } from './main-view/NotDeployedTag';
import { useCrmOrgsApiFacade } from '../../../../../apis/facades/useCrmOrgsApiFacade';
import {
  SIMPLE_FIELD_SELECTOR_ALL,
  SIMPLE_FIELD_SELECTOR_SUGGESTED,
  injectSectionsIntoNestedItems,
} from '../../../../common/sweep-object-field-selectors/SimpleFieldSelector';
import useObjectTypesWithFetch from '../../../../../hooks/useObjectTypesWithFetch';
import { telemetry } from '../../../../../telemetry';

export const useImportFunnel = ({
  onDialogClose,
  crmOrgId,
}: {
  onDialogClose: () => void;
  crmOrgId: string;
}) => {
  const crmOrgs = useSelector(selectCrmOrgs);
  const [selectedRecordType, setSelectedRecordType] = useState<ParsedRecordType>();
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isRecordTypeLoading, setIsRecordTypeLoading] = useState(false);
  const [isMapItemLoading, setIsMapItemLoading] = useState(false);
  const [selectedLeadingField, setSelectedLeadingField] = useState<LeadingField>();
  const [selectedFunnel, setSelectedFunnel] = useState<ShortFunnel>();

  const funnelMap = useSelector(selectFunnelMap);
  const importedEnvs = useSelector(selectObjects);

  const { addFunnelProps } = useAddFunnel({
    crmOrgId,
    addFunnelRef: null,
  });
  const { onItemClick: addFunnel } = addFunnelProps;
  const { objectTypes } = useObjectTypesWithFetch({
    crmOrgId: crmOrgId === 'VIRTUAL_CRM_ORG_ID' ? '' : crmOrgId,
  });

  const dispatch = useDispatch();
  const { get_recordTypesAndFunnels } = useCrmOrgsApiFacade();
  const { get_objectRecordType } = useCrmOrgsApiFacade();
  const { convertRecordTypeToFunnel } = useFunnels();
  const { addNotification } = useSweepNotifications();

  const selectedItemFunnelStructure = useMemo(
    () =>
      selectedRecordType
        ? mapRecordTypeToFunnel(selectedRecordType, selectedLeadingField)
        : selectedFunnel,
    [selectedRecordType, selectedFunnel, selectedLeadingField],
  );

  const currentCrmOrg = crmOrgs.find((crmOrg) => crmOrg.id === crmOrgId);
  const currentCrmOrgRecordTypesAndFunnels = importedEnvs[crmOrgId];

  const funnelsInMap = useMemo(
    () => (funnelMap?.funnels ? Object.keys(funnelMap.funnels) : []),
    [funnelMap?.funnels],
  );

  const loadRecordTypesAndFunnels = useCallback(
    async (_crmOrgId: string) => {
      if (!funnelMap?.id || importedEnvs[_crmOrgId]) return;

      setIsDialogLoading(true);
      try {
        const response = await get_recordTypesAndFunnels({
          crmOrgId: _crmOrgId,
        });
        console.log(response);
        dispatch(setImportFunnelObjects({ objects: response, crmOrgId: _crmOrgId }));
      } catch (e) {
        telemetry.captureError(e);
      }

      setIsDialogLoading(false);
    },
    [dispatch, funnelMap?.id, importedEnvs, get_recordTypesAndFunnels],
  );

  useEffect(() => {
    if (!funnelMap?.id) return;
    loadRecordTypesAndFunnels(crmOrgId);
  }, [crmOrgId, funnelMap?.id, loadRecordTypesAndFunnels]);

  const getRecordTypeInfo = useCallback(
    async (selectedItem: ListItem, objectApiName: string) => {
      try {
        const _leadingFields = await get_objectRecordType({
          crmOrgId,
          objectApiName,
          recordTypeName: selectedItem.value,
        });
        if (_leadingFields) {
          return {
            id: selectedItem.value,
            label: selectedItem.label,
            name: selectedItem.value,
            objectApiName: objectApiName,
            isActive: true,
            leadingFields: _leadingFields.map((value) => {
              const values: FieldValue[] = value.values.map((value) => ({
                label: value.label,
                name: value.fullName,
              }));
              return {
                name: value.name,
                label: value.label,
                values: values,
                hasPath: false,
              };
            }),
          };
        }
      } catch (e) {
        telemetry.captureError(e);
      }
      return;
    }
    ,
    [crmOrgId, get_objectRecordType],
  );

  const getFirstLeadingField = (selectedRecordType: ParsedRecordType) => {
    const mappedFields = selectedRecordType?.leadingFields.map((item) => ({
      label: item.label || item.name || '',
      value: item.name,
      data: item,
    }));

    const leadingFieldsInCorrectOrder = injectSectionsIntoNestedItems(mappedFields ?? []);
    const filtered = leadingFieldsInCorrectOrder.filter(
      (field) =>
        field.value !== SIMPLE_FIELD_SELECTOR_SUGGESTED &&
        field.value !== SIMPLE_FIELD_SELECTOR_ALL,
    );

    return filtered[0]?.data as LeadingField;
  };

  const onSelectRecordType = useCallback(
    async (item: ListItem, parentGroup: ListGroup) => {
      setIsRecordTypeLoading(true);
      const objectData = currentCrmOrgRecordTypesAndFunnels?.objectsData.find(
        (objectDataItem) => objectDataItem.name === parentGroup.value,
      );

      const funnel = objectData?.funnels.find((funnel) => funnel.id === item.value);

      if (objectData) {
        if (!funnel) {
          setSelectedFunnel(undefined);

          const recordType = await getRecordTypeInfo(item, objectData.name);
          const leadingField = recordType ? getFirstLeadingField(recordType) : undefined;

          setSelectedLeadingField(leadingField);
          setSelectedRecordType(recordType);
          setSelectedFunnel(undefined);
        } else {
          setSelectedFunnel(funnel);
          setSelectedRecordType(undefined);
        }
      }

      setIsRecordTypeLoading(false);
    },
    [currentCrmOrgRecordTypesAndFunnels, getRecordTypeInfo],
  );

  const onAddItemToMapClick = useCallback(
    async (selectedRecordType?: ParsedRecordType, selectedFunnel?: ShortFunnel) => {
      const isRecordType = !!selectedRecordType;

      setIsMapItemLoading(true);

      let addFunnelPayload = {
        value: selectedFunnel?.id ?? '',
        label: selectedFunnel?.name ?? '',
        smallLabel: selectedFunnel?.funnelDetails.leadingObject.objectName ?? '',
      };

      if (isRecordType) {
        const { name, label, description, objectApiName, leadingFields } = selectedRecordType;
        const leadingFieldLabel = selectedLeadingField?.label ?? leadingFields?.[0].label ?? '';
        const objectLabel = objectTypes?.find((obj) => obj.objectType === objectApiName)?.label;

        const nameToUse =
          name === MASTER_KEY
            ? `${objectLabel} - ${leadingFieldLabel}` //Product specification
            : label;

        const firstLeadingField = leadingFields?.[0];
        if (!selectedLeadingField && !firstLeadingField) {
          telemetry.captureError(new Error('no leading field selected, unable to convert'));
          setIsMapItemLoading(false);
          return;
        }

        try {
          const funnel = await convertRecordTypeToFunnel({
            defaultCrmOrgId: crmOrgId,
            objectName: objectApiName,
            recordTypeName: name,
            recordTypeLabel: label,
            recordTypeDescription: description,
            leadingField: selectedLeadingField ?? firstLeadingField,
            funnelName: nameToUse,
          });

          if (funnel) {
            addFunnelPayload = {
              value: funnel.id,
              label: funnel.name,
              smallLabel: funnel.funnelDetails.leadingObject.objectName,
            };
          }
        } catch (errorMsg) {
          addNotification({
            message: errorMsg?.toString() ?? 'Unknown error',
            variant: SweepNotificationVariant.Error,
          });
          setIsMapItemLoading(false);
          return;
        }
      }

      setIsMapItemLoading(false);
      addFunnelPayload.value && addFunnel(addFunnelPayload);

      onDialogClose();
    },
    [
      objectTypes,
      addFunnel,
      onDialogClose,
      crmOrgId,
      convertRecordTypeToFunnel,
      addNotification,
      selectedLeadingField,
    ],
  );

  const getRecordTypeAndAddToFunnel = useCallback(
    async ({ selectedItem, objectApiName }: { selectedItem: ListItem; objectApiName: string }) => {
      setIsMapItemLoading(true);
      const recordType = await getRecordTypeInfo(selectedItem, objectApiName);

      if ((recordType && recordType.leadingFields.length === 1) || selectedLeadingField) {
        await onAddItemToMapClick(recordType, undefined);
        setIsMapItemLoading(false);
      } else {
        setSelectedRecordType(recordType);
        setSelectedLeadingField(recordType?.leadingFields?.[0]);
        setIsMapItemLoading(false);
      }
    },
    [getRecordTypeInfo, selectedLeadingField, onAddItemToMapClick],
  );

  const listDataStructure = useMemo(() => {
    const promoted: ListGroup[] = [];
    const custom: ListGroup[] = [];
    const others: ListGroup[] = [];

    currentCrmOrgRecordTypesAndFunnels?.objectsData.forEach((parserObject) => {
      const { name, label, funnels, recordTypes } = parserObject;
      const mappedFunnels = funnels.map(
        (funnel) =>
          ({
            label: funnel.name,
            value: funnel.id,
            EndAdornment: funnelsInMap?.includes(funnel.id) ? (
              <ListItemAddedTag label={'Added to Canvas'} />
            ) : (
              <ListItemButtons
                onPreviewClick={() => setSelectedFunnel(funnel)}
                onAddClick={() => onAddItemToMapClick(undefined, funnel)}
              />
            ),
            ListItemTag: funnel.isDeployed ? undefined : <NotDeployedTag />,
          }) as ListItem,
      );

      const mappedRecordTypes = recordTypes.map((recordType) => ({
        label: recordType.label,
        value: recordType.name,
        EndAdornment: (
          <ListItemButtons
            onPreviewClick={() =>
              onSelectRecordType(
                { label: recordType.label, value: recordType.name },
                { value: name, label, items: [] },
              )
            }
            onAddClick={() =>
              getRecordTypeAndAddToFunnel({
                selectedItem: { label: recordType.label, value: recordType.name },
                objectApiName: name,
              })
            }
          />
        ),
      }));

      //Sorted by product definition:
      //Lead, Opportunity, Contact, Account, Case
      //Custom objects - alphabetically
      //All other objects - alphabetically
      if (DEFAULT_OBJECTS_API_NAME.includes(name)) {
        const idx = DEFAULT_OBJECTS_API_NAME.findIndex((a) => a === name);
        promoted[idx] = {
          value: name,
          label,
          items: mappedFunnels.concat(mappedRecordTypes),
        };
      } else if (name.match(/__c$/)) {
        custom.push({
          value: name,
          label,
          items: mappedFunnels.concat(mappedRecordTypes),
        });
      } else {
        others.push({
          value: name,
          label,
          items: mappedFunnels.concat(mappedRecordTypes),
        });
      }
    });

    custom.sort((a, b) => a.value.localeCompare(b.value));
    others.sort((a, b) => a.value.localeCompare(b.value));

    return promoted.concat(custom.concat(others));
  }, [
    currentCrmOrgRecordTypesAndFunnels,
    funnelsInMap,
    getRecordTypeAndAddToFunnel,
    onAddItemToMapClick,
    onSelectRecordType,
  ]);

  const onSelectLeadingField = useCallback(
    (_leadingField: SearchSelectItem) => {
      const leadingField = selectedRecordType?.leadingFields.find(
        (LF) => LF.name === _leadingField.value,
      );
      setSelectedLeadingField(leadingField);
    },
    [selectedRecordType],
  );

  const backToMainView = useCallback(() => {
    setSelectedFunnel(undefined);
    setSelectedRecordType(undefined);
    setSelectedLeadingField(undefined);
  }, []);

  return {
    listDataStructure,
    isDialogLoading,
    onSelectRecordType,
    selectedRecordType,
    setSelectedRecordType,
    onSelectLeadingField,
    selectedLeadingField,
    isRecordTypeLoading,
    selectedItemFunnelStructure,
    onAddItemToMapClick,
    backToMainView,
    isMapItemLoading,
    currentCrmOrg,
  };
};
