import _range from 'lodash/range';
import { DialogContent, Stack } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import { GroupCard } from './deprecated/GroupCard';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { DefaultCardsLayout } from '../../../common/CardsGridLayout';
import { GroupSettingsDialog } from '../group-settings/deprecated/GroupSettingsDialog';
import { GroupSkeletonCard } from './deprecated/GroupSkeletonCard';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { ConfirmDeleteGroupDialog } from '.././groups/ConfirmDeleteGroupDialog';
import { useState } from 'react';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../common/permissions/usePermission';
import { AssignmentGroupMember, AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { useGetFirstAvailableEmoji } from '../groups/useGetFirstAvailableEmoji';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { Permission } from '@server/permissions';
import useConfirm from '../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import EmptyStateWithButton from '../../../common/empty-state/EmptyStateWithButton';
import GroupsEmptyImage from '../../../common/empty-state/svgs/groupsEmpty.svg?react';
import { telemetry } from '../../../../telemetry';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { GroupSettingsWizard } from '../group-settings/group-settings-wizard/GroupSettingsWizard';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { useSelector } from 'react-redux';
import { GroupsDialogHeader } from '../../../routing/groups-dialog/GroupsDialogHeader';
import { GroupListItem, GroupListItemSkeleton } from './GroupListItem';

const permissionString: Permission[] = ['create:assignment-groups'];
const ADD_NOT_ALLOWED_TITLE = 'To create a new assignment group, please contact your admin';

const countActiveMembers = (members: AssignmentGroupMember[]) =>
  members.filter((member) => member.membershipActive).length;

interface GroupsListProps {
  assignmentsGroupList?: AssignmentGroup[];
  onGroupClick: (groupId: string) => void;
  onClose: () => void;
}

export const GroupsList = ({ assignmentsGroupList, onGroupClick, onClose }: GroupsListProps) => {
  const { assignmentGroupsV2 } = useFeatureToggle();
  const {
    getAssignmentsGroupList,
    createNewAssignmentGroup,
    duplicateAssignmentGroup,
    removeAssignmentGroup,
  } = useAssignmentsApiWithReducer();

  const getFirstAvailableEmoji = useGetFirstAvailableEmoji();
  const { addNotification } = useSweepNotifications();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const [isAllowedBtn] = usePermission(permissionString);

  const [membersDialogOpen, setMembersDialogOpen] = useState(false);
  const [showNewCardSkeleton, setShowNewCardSkeleton] = useState(false);
  const { openConfirm, onCancel, onConfirm } = useConfirm();

  const openMembersDialog = () => setMembersDialogOpen(true);

  const isEmpty = assignmentsGroupList?.length === 0;

  const onDeleteClick = async (group: AssignmentGroup) => {
    const isConfirmed = await openConfirm(
      <ConfirmDeleteGroupDialog
        assignmentGroup={group}
        onConfirm={onConfirm}
        handleClose={onCancel}
      />,
    );
    if (isConfirmed) {
      try {
        await removeAssignmentGroup(group.id);
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error removing assignment group',
          variant: SweepNotificationVariant.Error,
        });
        getAssignmentsGroupList();
      }
    }
  };

  const renderDialogs = () => {
    if (membersDialogOpen) {
      if (assignmentGroupsV2 && crmOrgId) {
        return (
          <GroupSettingsWizard
            crmOrgId={crmOrgId}
            onClose={() => setMembersDialogOpen(false)}
            onSave={async (assignmentGroupData, resetAllMembersLimit) => {
              await createNewAssignmentGroup(
                assignmentGroupData as AssignmentGroup,
                resetAllMembersLimit,
              );
            }}
          />
        );
      }
      if (!assignmentGroupsV2) {
        return (
          <GroupSettingsDialog
            open={membersDialogOpen}
            onClose={() => setMembersDialogOpen(false)}
            onSave={async (assignmentGroupData, resetAllMembersLimit) => {
              await createNewAssignmentGroup(
                assignmentGroupData as AssignmentGroup,
                resetAllMembersLimit,
              );
            }}
            defaultEmoji={getFirstAvailableEmoji()}
            elements={undefined}
          />
        );
      }
    }
  };

  const renderDeprecatedContent = () => {
    if (isEmpty) {
      return (
        <Stack pt="6vh">
          <EmptyStateWithButton
            image={<GroupsEmptyImage />}
            notAllowedTitle={ADD_NOT_ALLOWED_TITLE}
            permissionString={['create:assignment-groups']}
            line1="Optimize and create new assignment groups"
            onClick={openMembersDialog}
          />
        </Stack>
      );
    }
    return (
      <DefaultCardsLayout minWidth="440px">
        {assignmentsGroupList &&
          assignmentsGroupList?.map((group) => {
            const { id, name, description, createdAt, avatar, members } = group;
            return (
              <GroupCard
                readonly={!isAllowedBtn}
                key={id}
                name={name}
                avatar={avatar}
                description={description}
                lastModified={
                  humanizeDate({
                    dateOrTimestamp: createdAt ?? '',
                  }) ?? ''
                }
                activeMembers={countActiveMembers(members) || 0}
                onClick={() => onGroupClick(id)}
                onDelete={() => onDeleteClick(group)}
                onDuplicate={async () => {
                  try {
                    setShowNewCardSkeleton(true);
                    await duplicateAssignmentGroup(group);
                  } catch (e) {
                    telemetry.captureError(e);
                    addNotification({
                      message: 'Error duplicating assignment group',
                      variant: SweepNotificationVariant.Error,
                    });
                  }
                  setShowNewCardSkeleton(false);
                }}
              />
            );
          })}
        {!assignmentsGroupList && _range(0, 3).map((v) => <GroupSkeletonCard key={v} />)}
        {showNewCardSkeleton && <GroupSkeletonCard />}
      </DefaultCardsLayout>
    );
  };

  const renderContent = () => {
    if (isEmpty) {
      return (
        <Stack pt="6vh">
          <EmptyStateWithButton
            image={<GroupsEmptyImage />}
            notAllowedTitle={ADD_NOT_ALLOWED_TITLE}
            permissionString={['create:assignment-groups']}
            line1="Optimize and create new assignment groups"
            onClick={openMembersDialog}
          />
        </Stack>
      );
    }

    return (
      <Stack>
        {assignmentsGroupList &&
          assignmentsGroupList?.map((group) => {
            const { id, name, description, createdAt, avatar, members } = group;
            return (
              <GroupListItem
                readonly={!isAllowedBtn}
                key={id}
                name={name}
                avatar={avatar}
                description={description}
                lastModified={
                  humanizeDate({
                    dateOrTimestamp: createdAt ?? '',
                  }) ?? ''
                }
                activeMembers={countActiveMembers(members) || 0}
                onClick={() => onGroupClick(id)}
                onDelete={() => onDeleteClick(group)}
                onDuplicate={async () => {
                  try {
                    setShowNewCardSkeleton(true);
                    await duplicateAssignmentGroup(group);
                  } catch (e) {
                    telemetry.captureError(e);
                    addNotification({
                      message: 'Error duplicating assignment group',
                      variant: SweepNotificationVariant.Error,
                    });
                  }
                  setShowNewCardSkeleton(false);
                }}
              />
            );
          })}
        {!assignmentsGroupList && _range(0, 3).map((v) => <GroupListItemSkeleton key={v} />)}
        {showNewCardSkeleton && <GroupListItemSkeleton />}
      </Stack>
    );
  };

  return (
    <>
      <GroupsDialogHeader
        headerActions={
          Boolean(assignmentsGroupList?.length) && (
            <RestrictedTooltip to={permissionString} notAllowedTitle={ADD_NOT_ALLOWED_TITLE}>
              <Button disabled={!isAllowedBtn} onClick={openMembersDialog} startIconName="Plus">
                New group
              </Button>
            </RestrictedTooltip>
          )
        }
        onClose={onClose}
      >
        <Typography variant="h1">Groups</Typography>
      </GroupsDialogHeader>
      <DialogContent>
        {assignmentGroupsV2 ? renderContent() : renderDeprecatedContent()}
      </DialogContent>
      {renderDialogs()}
    </>
  );
};
